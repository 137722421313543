import React, { useState } from 'react';
import { useSubcription } from './services';
import DataTable from 'components/shared/DataTable';
import { pageSizes } from 'utils/datatable';
import {
	TextField,
	Button,
	Select,
	FormControl,
	MenuItem,
	InputLabel,
	Tab,
} from '@material-ui/core';
import moment from 'moment';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Link } from 'react-router-dom';
import { statusOptions } from './helper';
import { useAgentLead } from 'hooks/leadAgent';
import { Autocomplete } from '@material-ui/lab';
const getStatusLabel = (status) => {
	if (!status) return '';
	const arr = [
		{ value: 0, label: 'Chưa phê duyệt' },
		{ value: 1, label: 'Đã duyệt' },
		{ value: 11, label: 'Thu thập F1 thất bại' },
		{ value: 12, label: 'Đang thu thập F1' },
		{ value: 13, label: 'Thu thập f1 thành công' },
		{ value: 2, label: 'Danh sách bạn bè đóng' },
		{ value: 21, label: 'Đang tạo lead' },
		{ value: 22, label: 'Tạo lead f1 thất bại' },
		{ value: 23, label: 'Tạo lead f1 thành công' },
		{ value: 31, label: 'Phân bổ lead f1 thất bại' },
		{ value: 3, label: 'Phân bổ lead f1 thành công' },
		{ value: 32, label: 'Cần đổi sang F0' },
	];
	const returnValue = arr.find((item) => item.value === status);
	return returnValue ? returnValue.label : '';
};

const tableConfig = {
	columns: [
		{ name: 'action', title: '#' },
		{ name: 'status', title: 'Trạng thái' },
		{ name: 'agent_name', title: 'Agent name' },
		{ name: 'agent_phone', title: 'Agent phone' },
		{ name: 'agent_code', title: 'Agent code' },
		{ name: 'mc_code', title: 'Merchant code' },
		{ name: 'f0_full_name', title: 'Tên F0' },
		{ name: 'f0_fb_url', title: 'F0 FB URL' },
		{ name: 'f0_phone', title: 'SDT F0' },
		{ name: 'type', title: 'Loại' },
		{ name: 'note', title: 'Ghi chú' },
		{ name: 'created_at', title: 'Tạo ngày' },
		{ name: 'finished_at', title: 'Dừng lúc' },
	],
	columnWidths: [
		{ columnName: 'action', align: 'center', width: 300 },
		{ columnName: 'agent_name', align: 'center', width: 150 },
		{ columnName: 'agent_phone', align: 'center', width: 150 },
		{ columnName: 'agent_code', align: 'center', width: 150 },
		{ columnName: 'mc_code', align: 'center', width: 150 },
		{ columnName: 'f0_full_name', align: 'center', width: 150 },
		{ columnName: 'f0_fb_url', align: 'center', width: 300 },
		{ columnName: 'f0_phone', align: 'center', width: 150 },
		{ columnName: 'type', align: 'center', width: 150 },
		{ columnName: 'status', align: 'center', width: 150 },
		{ columnName: 'created_at', align: 'center', width: 150 },
		{ columnName: 'finished_at', align: 'center', width: 150 },
		{ columnName: 'note', align: 'center', width: 150 },
	],
};

const Subcription = () => {
	const [params, setParams] = useState({
		pagesize: 15,
		page: 1,
		agent_name: '',
		agent_phone: '',
		id: '',
		agent_id: '',
		date_from: moment()
			.subtract(6, 'month')
			.startOf('day')
			.format('YYYY-MM-DD hh:mm'),
		date_to: moment().endOf('day').format('YYYY-MM-DD hh:mm'),

		f0_fb_uid: '',
		f0_phone: '',
		f0_full_name: '',
		status: 'ALL',
		type: 0,
		orderby: 'created_at',
		asc: false,
	});
	const { data, isLoading, refetch } = useSubcription(params);
	const { data: agents } = useAgentLead({ page: 0, size: 0 });

	const handleChange = (event) => {
		const { name, value } = event.target;
		setParams({
			...params,
			page: 1,
			[name]: value,
		});
	};
	return (
		<div>
			<div>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: 'repeat(5, 1fr)',
						alignItems: 'center',
						gap: 20,
					}}
				>
					<FormControl>
						<Autocomplete
							onChange={(e, v) =>
								v && setParams({ ...params, agent_id: v.id, page: 1 })
							}
							onInputChange={(e, v, r) =>
								r === 'clear' && setParams({ ...params, agent_id: '' })
							}
							options={agents?.data}
							getOptionLabel={(option) => option.name}
							renderInput={(params) => (
								<TextField {...params} label="Agent" margin="normal" />
							)}
						/>
					</FormControl>
					<TextField
						label="Agent phone"
						value={params.agent_phone}
						name="agent_phone"
						onChange={handleChange}
					/>

					<TextField
						label="F0 facebook uid"
						value={params.f0_fb_uid}
						name="f0_fb_uid"
						onChange={handleChange}
					/>

					<TextField
						label="F0 facebook url"
						value={params.f0_fb_url}
						name="f0_fb_url"
						onChange={handleChange}
					/>
					<TextField
						label="Tên F0"
						value={params.f0_full_name}
						name="f0_full_name"
						onChange={handleChange}
					/>
					<FormControl>
						<InputLabel>Trạng thái</InputLabel>
						<Select onChange={handleChange} name="status" value={params.status}>
							<MenuItem value={'ALL'}>Tất cả</MenuItem>
							{statusOptions.map((item) => (
								<MenuItem value={item.value} key={item.value}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						type="datetime-local"
						value={params.date_from}
						name="date_from"
						label="Thời gian từ"
						onChange={handleChange}
					/>
					<TextField
						value={params.date_to}
						name="date_to"
						label="Đến"
						type="datetime-local"
						onChange={handleChange}
					/>
					<Button onClick={refetch} variant="contained" color="primary">
						Tìm
          </Button>
				</div>
			</div>
			<DataTable
				rows={data?.data}
				totalCount={data?.total_count}
				columns={tableConfig.columns}
				columnWidths={tableConfig.columnWidths}
				pageSizes={pageSizes}
				loading={isLoading}
				pageSize={params.pagesize}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				currentPage={params.page - 1}
				changePageSize={(size) => setParams({ ...params, pagesize: size })}
				CellComponent={CellComponent}
				sorting={[{ columnName: 'created_at', direction: 'desc' }]}
				changeSorting={(e) =>
					setParams({
						...params,
						orderby: e[0].columnName,
						asc: params.asc ? false : true,
					})
				}
			/>
		</div>
	);
};

const ActionCell = (props) => {
	return (
		<Table.Cell
			style={{
				padding: 1,
			}}
		>
			<div
				style={{
					justifyContent: 'center',
					fontSize: '13px',
					display: 'flex',
					columnGap: '10px',
				}}
			>
				{(props.row.status === 0 || props.row.status === 1) && (
					<Link to={{ pathname: '/subcription/detail/', state: props.row }}>
						<Button variant="contained" color="primary" size="small">
							Phê duyệt
            </Button>
					</Link>
				)}
				{props.row.status > 1 && props.row.status !== 32 && (
					<Link
						to={{
							pathname: '/subcription/reset/',
							state: props.row,
						}}
					>
						<Button variant="contained" color="primary" size="small">
							Reset
            </Button>
					</Link>
				)}

				{props.row.status === 32 && props.row.f0_fb_uid !== '' && (
					<Button
						component={Link}
						to={{ pathname: '/subcription/conver-sub-f0', state: props.row }}
						variant="contained"
						color="primary"
						size="small"
					>
						Convert F0
					</Button>
				)}

				{props.row.status === 32 && (
					<Link to={{ pathname: '/subcription/add-fb-uid', state: props.row }}>
						<Button variant="contained" color="primary" size="small">
							Add FB UID
            </Button>
					</Link>
				)}
			</div>
		</Table.Cell>
	);
};

const CellComponent = (props) => {
	const { column, row } = props;
	switch (column.name) {
		case 'action':
			return <ActionCell {...props} />;

		case 'f0_fb_url':
			return (
				<Table.Cell>
					{
						<a href={row.f0_fb_url} target="_blank">
							{row.f0_fb_url}
						</a>
					}
				</Table.Cell>
			);

		case 'status': {
			return <Table.Cell>{getStatusLabel(row.status)}</Table.Cell>;
		}
		case 'created_at':
			return (
				<Table.Cell>{moment(row.created_at).format('YYYY-MM-DD')}</Table.Cell>
			);
		case 'agent_phone':
			return (
				<Table.Cell>
					{row.agent_phone?.replace(/\d(?!\d{0,3}$)/g, '*')}
				</Table.Cell>
			);
		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default Subcription;
