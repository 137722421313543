import React, { useState } from 'react'
import { DialogTitle, DialogContent, DialogActions, Box, Typography, Button, Link } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'
import { useMutation } from '@tanstack/react-query'
import merchantApi from "../../../api/merchant.api"
import file_mau from "./file/template_import_truong_phong.xlsx"
import { toast } from 'react-toastify'

const DialogImportMerchant = ({ onClose }) => {
	const [file, setFile] = useState(null)
	const importFileMutation = useMutation(() => merchantApi.importMerchant(file), {
		onSuccess: (data) => {
			if (data.data.code !== 100) {
				return toast.error("Có lỗi xảy ra, vui lòng thử lại sau")
			}
			toast.success(`Tạo thành công ${data.data.updated_qty} agent`)
		},
		onError: () => toast.error("Có lỗi xảy ra, vui lòng thử lại sau")
	})
	const handelImportFile = () => {
		importFileMutation.mutate()
	}
	return (
		<>

			<DialogTitle>
				<Typography variant="h4" component="h4" align='center'>
					Import danh sách trưởng phòng
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box>
					<Box>
						<Typography variant="h5" component="h5">Upload file</Typography>
						<Box>
							<DropzoneArea
								dropzoneText={'Nhập file upload'}
								maxFileSize={50000000}
								filesLimit={20}
								showAlerts={true}
								showPreviewsInDropzone={true}
								onChange={(e) => setFile(e[0])}
							/>
						</Box>
						<Box display="flex" gridGap="10px" justifyContent="flex-end" marginTop={2}>
							<Button>
								<Link href={file_mau} download>File mẫu</Link>
							</Button>
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={onClose}>Huỷ</Button>
				<Button color="primary" variant="contained" disabled={importFileMutation.isLoading} onClick={handelImportFile}>Upload</Button>
			</DialogActions>
		</>
	)
}

export default DialogImportMerchant
