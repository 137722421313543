export const getItemLocal = (item) => {
	const itemLocal = localStorage.getItem(item);
	if (itemLocal) return JSON.parse(itemLocal);
	return null;
};
export const checkFuncRoleWithId = (code) => {
	if (!code) return false;
	const funcRole = getItemLocal('aidriven-general-user');
	if (!funcRole || funcRole.functionRoles.length === 0) return false;
	return funcRole.functionRoles.findIndex(
		(item) => item.functionRoleId === code
	) >= 0
		? true
		: false;
};
export const checkRoleWithId = (code) => {
	if (!code) return false;
	const role = getItemLocal('aidriven-general-user')?.role;
	if (!role) return false;
	return role === code;
};

export const getUserLevel = () => {
	return getItemLocal('aidriven-general-user')?.user_level;
};
export const getUserId = () => {
	return getItemLocal('aidriven-general-user')?.user_id;
};
