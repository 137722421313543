import React, { useState } from 'react';
import DialogWrapper from 'components/shared/DialogWrapper';
import { useHistory, useLocation } from 'react-router-dom';
import {
	DialogContent,
	DialogActions,
	Button,
	FormControl,
	FormLabel,
	Input,
} from '@material-ui/core';
import { useAddFBUID } from './services';

const AddFBUID = () => {
	const [uid, setUid] = useState('');
	const history = useHistory();
	const { state } = useLocation();
	const { mutateAsync } = useAddFBUID();
	const onSubmit = () => {
		mutateAsync({
			f0_fb_uid: uid,
			id: state.id,
		}).then(() => history.goBack());
	};
	return (
		<DialogWrapper title="Thêm/Cập nhật facebook uid cho lead">
			<DialogContent>
				<FormControl fullWidth>
					<FormLabel>Facebook uid</FormLabel>
					<Input
						onChange={(e) => setUid(e.target.value)}
						placeholder="Facebook uid"
						fullWidth
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => history.goBack()}>Huỷ</Button>
				<Button onClick={onSubmit} variant="contained" color="primary">
					Lưu
				</Button>
			</DialogActions>
		</DialogWrapper>
	);
};

export default AddFBUID;
