import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	FormControl,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	DialogActions,
	Button,
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useStates } from 'hooks/address';
import { useBranch, useTeam } from 'hooks/lead';
import { useMerchantProduct } from 'hooks/merchant';
import { useUpdateAgent } from './services';
import { agentStatus } from './helper';

const UpdateAgent = () => {
	const { state } = useLocation();
	const history = useHistory();
	const [user, setUser] = useState({
		id: state.id,
		phone: state.phone,
		code: state.code,
		name: state.name,
		branch_name: state.branch_name,
		team_name: state.team_name,
		location_id: state.adr_state_code,
		email: state.email,
		mc_product_code_list: [],
		agent_status: state.agent_status,
	});

	const { data: states } = useStates({});
	const { data: branchs } = useBranch();
	const { data: teams } = useTeam({ branch_name: user.branch_name });
	const { data: products } = useMerchantProduct();
	const { mutateAsync } = useUpdateAgent();
	const handleSubmit = () => {
		mutateAsync(user).then(() => {
			history.goBack();
		});
	};


	return (
		<Dialog open={true}>
			<DialogTitle>Chỉnh sửa thông tin agent</DialogTitle>
			<DialogContent>
				<div className="update-agent-container">
					<FormControl>
						<TextField
							label="SĐT"
							type="search"
							value={user.phone}
							onChange={(e) => setUser({ ...user, phone: e.target.value })}
							disabled
						/>
					</FormControl>
					<FormControl>
						<TextField
							label="Tên"
							type="search"
							value={user.name}
							onChange={(e) => setUser({ ...user, name: e.target.value })}
						/>
					</FormControl>
					<FormControl>
						<TextField
							label="Code"
							type="search"
							value={user.code}
							onChange={(e) => setUser({ ...user, code: e.target.value })}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>Chi nhánh</InputLabel>
						<Select
							value={user.branch_name}
							onChange={(e) =>
								setUser({ ...user, branch_name: e.target.value })
							}
						>
							{branchs?.data?.map((item, index) => (
								<MenuItem value={item.name} key={index}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Phòng</InputLabel>
						<Select
							value={user.team_name}
							onChange={(e) =>
								setUser({ ...user, team_name: e.target.value, page: 1 })
							}
						>
							{teams?.data?.map((item, index) => (
								<MenuItem key={index} value={item.name}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl>
						<TextField
							label="Email"
							type="search"
							value={user.email}
							onChange={(e) => setUser({ ...user, email: e.target.value })}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>Tỉnh/Tp</InputLabel>
						<Select
							value={user.location_id}
							onChange={(e) =>
								setUser({ ...user, location_id: e.target.value })
							}
						>
							{states?.data.map((item) => (
								<MenuItem value={item.state_code} key={item.state_code}>
									{item.state_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl>
						<InputLabel>Sản phẩm</InputLabel>
						<Select
							multiple
							value={user.mc_product_code_list}
							onChange={(e) =>
								setUser({ ...user, mc_product_code_list: e.target.value })
							}
						>
							{products?.data.map((item) => (
								<MenuItem value={item.code} key={item.code}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Trạng thái làm viêc</InputLabel>
						<Select
							value={user.agent_status}
							defaultValue={state.agent_status}
							onChange={(e) =>
								setUser({
									...user,
									agent_status: e.target.value,
								})
							}
						>
							{agentStatus.map((item) => (
								<MenuItem value={item.value} key={item.value}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					variant="outlined"
					onClick={() => history.goBack()}
				>
					Huỷ
        </Button>
				<Button color="primary" variant="contained" onClick={handleSubmit}>
					Cập nhật
        </Button>
			</DialogActions>
		</Dialog>
	);
};

export default UpdateAgent;
