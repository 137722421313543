import React, { useState } from 'react';
import ChangePassword from './ChangePassword';
import {
	Dialog,
	DialogActions,
	Button,
	DialogContent,
	DialogTitle,
} from '@material-ui/core';
import { useLogout } from './services';

const HomeFirstTime = () => {
	const [openChangePassword, setOpenChangePassword] = useState(true);
	const [openReload, setOpenReload] = useState(false);

	const { mutateAsync: logout } = useLogout();

	const handleLogOut = () => {
		logout();
	};

	return (
		<div>
			<ChangePassword
				open={openChangePassword}
				onClose={() => {
					setOpenChangePassword(false);
					setOpenReload(true);
				}}
			/>
			<Dialog open={openReload} maxWidth="sm" fullWidth>
				<DialogTitle>
					Thành công
				</DialogTitle>
				<DialogContent>
					<h1>Bạn đã đổi mật khẩu thành công</h1>
					<h2>Bạn cần đăng nhập lại</h2>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleLogOut}>Reload</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default HomeFirstTime;
