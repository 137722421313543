import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Box,
	Button,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { isTelesaleMerchant } from '../../service';
import DataTable from 'components/shared/DataTable';
import { checkFuncRoleWithId } from 'utils/auth';
import { useProduct } from 'hooks/products';
import { useReportByDate, useExportRaw,useExportDetail  } from './services';
import { pageSizes } from 'utils/datatable';
import './reportByDate.css';

const tableConfig = {
	grid2Columns: [
		{ name: 'report_date', title: 'Ngày' },
		{ name: 'product_code', title: 'Sản phẩm' },
		{ name: 'total_qty', title: 'Tổng số' },
	],
	grid2ColumnConfig: [
		{ columnName: 'report_date', align: 'center', width: 200 },
		{ columnName: 'product_code', align: 'center', width: 200 },
		{ columnName: 'total_qty', align: 'center', width: 200 },
	],
};

const DailySummaryByDate = () => {
	const [params, setParams] = useState({
		new: true,
		orderby: 'report_date',
		product_code: 'all',
		report_date_from: moment()
			.subtract(14, 'day')
			.startOf('day')
			.format('YYYY-MM-DD'),
		report_date_to: moment()
			.endOf('day')
			.format('YYYY-MM-DD'),
		page: 1,
		pagesize: 15,
		lead_type: -1,
	});
	const [exportDate, setExportDate] = useState(
		moment().endOf('day').format('YYYY-MM-DD')
	);
	const { data: products } = useProduct(
		{},
		isTelesaleMerchant() ? 'merchant-product' : 'product'
	);
	const { data, isLoading } = useReportByDate(params);
	const checkRole = checkFuncRoleWithId(501);

	const { mutateAsync: exportRaw } = useExportRaw();
	const { mutateAsync: exportDetail } = useExportDetail();

	const handleExport = () => {
		exportDetail({
			product_code: params.product_code,
			date_to: moment(exportDate).endOf('day').unix(),
			lead_type: params.lead_type,
		});
	};
	const handleExportRaw = () => {
		exportRaw({
			product_code: params.product_code,
			date_to: moment(params.report_date_to).endOf('day').unix(),
			date_from: moment(params.report_date_from).startOf('day').unix(),
			lead_type: params.lead_type,
		});
	};

	return (
		<div>
			<div className="report-date-search">
				<form className="report-date-form">
					<TextField
						id="search_date_start"
						label="Từ ngày"
						value={params.report_date_from}
						onChange={(e) =>
							setParams({ ...params, report_date_from: e.target.value })
						}
						type="date"
						InputLabelProps={{
							shrink: true,
						}}
					/>

					<TextField
						id="search_date_end"
						label="Đến ngày"
						value={params.report_date_to}
						onChange={(e) =>
							setParams({ ...params, report_date_to: e.target.value })
						}
						type="date"
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<FormControl>
						<InputLabel htmlFor="productId">Sản phẩm</InputLabel>
						<Select
							value={params.product_code}
							onChange={(e) =>
								setParams({ ...params, product_code: e.target.value })
							}
							inputProps={{
								name: 'product',
								id: 'productId',
							}}
						>
							<MenuItem value={'all'} disabled>
								<div>Tẩt cả</div>
							</MenuItem>
							{products?.data?.map((pro) => (
								<MenuItem value={pro.code} key={pro.code}>
									<div>{pro.name}</div>
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</form>
				{checkRole && (
					<form className="report-date-form-export">
						<FormControl>
							<InputLabel>Loại lead</InputLabel>
							<Select
								style={{ width: '100%', marginBottom: 9 }}
								onChange={(e) =>
									setParams({ ...params, lead_type: e.target.value })
								}
								defaultValue={params.lead_type}
							>
								<MenuItem value={-1}>Tất cả</MenuItem>
								<MenuItem value={0}>Auto</MenuItem>
								<MenuItem value={1}>F0/F1</MenuItem>
								<MenuItem value={2}>Tạo thủ công</MenuItem>
								<MenuItem value={3}>Danh bạ</MenuItem>
								<MenuItem value={4}>Web kế hoạch tài chính</MenuItem>
							</Select>
						</FormControl>
						<Box>
							<Box
								style={{
									display: 'flex',
									gap: 10,
									border: '1px solid #ccc',
									padding: 10,
								}}
							>
								<TextField
									id="search_date_end"
									label="Đến ngày"
									value={exportDate}
									onChange={(e) => setExportDate(e.target.value)}
									type="date"
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<Button
									variant="contained"
									color={'primary'}
									onClick={handleExport}
									disabled={params.product_code === 'all'}
								>
									Xuất báo cáo chi tiết
								</Button>
							</Box>
							{/* <Box style={{ marginTop: 10 }}> */}
							{/* 	<Button */}
							{/* 		color={'primary'} */}
							{/* 		variant="contained" */}
							{/* 		onClick={handleExportRaw} */}
							{/* 		disabled={disabledExport} */}
							{/* 		fullWidth */}
							{/* 	> */}
							{/* 		Xuất báo cáo thô */}
							{/*          </Button> */}
							{/* </Box> */}
						</Box>
					</form>
				)}
			</div>
			<DataTable
				rows={data?.data}
				columns={tableConfig.grid2Columns}
				columnWidths={tableConfig.grid2ColumnConfig}
				pageSize={params.pagesize}
				pageSizes={pageSizes}
				currentPage={params.page - 1}
				loading={isLoading}
				totalCount={data?.total_count}
				CellComponent={CellComponent}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
			/>
		</div>
	);
};

const CellComponent = (props) => {
	return (
		<Table.Cell
			{...props}
			style={{
				padding: 2,
			}}
		/>
	);
};

export default DailySummaryByDate;
