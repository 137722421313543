import { useMutation } from '@tanstack/react-query';
import { authAxios } from 'utils/api';
import { toast } from 'react-toastify';

export const useAssign = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/merchant-leader/assign',
				data: params,
				method: 'POST',
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.code === 100) return toast.success('Phân bổ lead thành công');

				toast.error('Phân bổ lead thất bại');
			},
			onError: () => {
				toast.error('Phân bổ lead thất bại');
			},
		}
	);
};
