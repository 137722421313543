import React, { useState } from 'react';
import {
	Grid,
	Button,
	List,
	CircularProgress,
	InputLabel,
	FormControl,
	Select,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { DropzoneArea } from 'material-ui-dropzone';
import { useImportLead } from 'hooks/lead';
import { useMerchant } from 'hooks/merchant';
import { useHubProduct } from 'hooks/product';
import './importLead.css';

const ImportLead = () => {
	const [params, setParams] = useState({
		merchant_code: null,
		product_code: null,
		merchant: {},
	});
	const [file, setFile] = useState(null);
	const { data: merchants } = useMerchant();
	const { data: products } = useHubProduct({ mc_id: params.merchant?.id });

	const importURL =
		'https://media.aidriven.goldengate.biz.vn/images/sample/thong_tin_lead.xlsx';

	const { mutateAsync: importLead, data, isLoading } = useImportLead();

	const handleSubmit = () => {
		const form = new FormData();

		form.append('excel-file', file);
		form.append('merchant_code', params.merchant_code);
		form.append('product_code', params.product_code);
		importLead(form);
	};

	return (
		<div>
			<div>
				<FormControl fullWidth>
					<InputLabel htmlFor="merchantId">Merchant (*)</InputLabel>
					<Select
						value={params.merchant}
						onChange={(option) =>
							setParams({
								...params,
								merchant_code: option.target.value.code,
								merchant: option.target.value,
							})
						}
					>
						{merchants?.data?.map((item) => (
							<MenuItem value={item} key={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel htmlFor="productId">Product (*)</InputLabel>
					<Select
						value={params.product_code}
						onChange={(option) =>
							setParams({ ...params, product_code: option.target.value })
						}
					>
						{products?.data?.map((item) => (
							<MenuItem value={item.code}>{item.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<Grid item>
					<div>
						<List>
							{data?.error_desc?.map((er, i) => (
								<span>dòng: {er}</span>
							))}
						</List>
					</div>
				</Grid>
				<Grid
					container
					direction="column"
					justify="space-between"
					alignItems="stretch"
					spacing={1}
				>
					{isLoading && (
						<div style={{ height: 150 }}>
							<CircularProgress
								style={{
									position: 'relative',
									left: '45%',
									top: '70px',
								}}
							/>
						</div>
					)}
					{!isLoading && (
						<Grid item>
							<DropzoneArea
								acceptedFiles={[
									'application/vnd.ms-excel',
									'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
								]}
								dropzoneText={
									file && file.name ? file.name : 'Upload file exel theo mẫu'
								}
								maxFileSize={50000000}
								filesLimit={1}
								showAlerts={false}
								showPreviewsInDropzone={false}
								onChange={(e) => setFile(e[0])}
							/>
						</Grid>
					)}
				</Grid>
				<div className="button-container">
					<a
						download="import-lead-template.zip"
						target="_blank"
						href={importURL}
						style={{ fontSize: 13, marginTop: 2 }}
					>
						File mẫu
					</a>
					<Button
						variant="contained"
						onClick={handleSubmit}
						disabled={
							file && params.merchant_code && params.product_code ? false : true
						}
						color="primary"
					>
						Tạo Lead
					</Button>
				</div>
				{data?.data && (
					<div className="import-result">
						<div>
							<span>Kết quả</span>
							<div>
								<table className="tbl-import-result">
									<thead>
										<tr>
											<th>Dòng</th>
											<th>Lỗi</th>
											<th>Code</th>
											<th>Trạng thái</th>
										</tr>
									</thead>
									<tbody>
										{data?.data?.results.map((item) => {
											const i = JSON.parse(item);
											return (
												<tr>
													<td> {i.rowNo}</td>
													<td>{i.error}</td>
													<td>{i.code}</td>
													<td>
														<span
															style={{
																color: i.code === 100 ? '#198754' : '#ff3333',
															}}
														>
															{i.code === 100 ? 'Thành công' : 'Thất bại'}
														</span>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
						<div>
							<span>Lỗi</span>
							<div>
								<table className="tbl-import-result">
									<thead>
										<th>Dòng</th>
										<th>Lỗi</th>
										<th>Code</th>
										<th>Trạng thái</th>
									</thead>
									<tbody>
										{data?.data?.errors.map((item) => {
											const i = JSON.parse(item);
											return (
												<tr>
													<td> {i.rowNo}</td>
													<td>{i.error}</td>
													<td>{i.code}</td>
													<td>
														<span
															style={{
																color: i.code === 100 ? '#198754' : '#ff3333',
															}}
														>
															{i.code === 100 ? 'Thành công' : 'Thất bại'}
														</span>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default ImportLead;
