import axios from 'axios';
import cs from 'const.js';

export const authAxios = axios.create({
	baseURL: process.env.REACT_APP_API_GENERAL_URL,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
		Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
	},
});

export const authAxiosV2 = axios.create({
	baseURL: process.env.REACT_APP_API_GENERAL_URL_V2,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
		Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
	},
});
