import axios from "./axios"

const uploadMedia = async (file) => {
	const form = new FormData()
	form.append("upload", file)
	const { data } = await axios({
		url: "/telesale/dc-article/article/media/upload",
		method: "POST",
		data: form,
		headers: { "Content-Type": "multipart/form-data" },
	})
	return data;
}

const getListMedia = async (params) => {
	const { data } = await axios({
		url: "telesale/dc-article/article/media/list",
		method: "GET",
		params: params
	})
	return data;
}

const deleteMedia = async (file) => {

	const { data } = await axios({
		url: "telesale/dc-article/article/media/delete",
		method: "POST",
		data: file
	})
	return data;
}

const createPost = async (post) => {
	const { data } = await axios({
		url: "telesale/dc-article/article/create",
		method: "POST",
		data: post
	})
	return data;
}

const editPost = async (post) => {
	const { data } = await axios({
		url: "telesale/dc-article/article/edit",
		method: "POST",
		data: post
	})
	return data;
}

const deletePost = async (params) => {
	const { data } = await axios({
		url: "telesale/dc-article/article/delete",
		method: "POST",
		data: params
	})
	return data;
}

const listPost = async (params) => {
	const fix = { ...params }
	for (const key in fix) {
		if (fix[key] === "ALL")
			fix[key] = null
	}
	const { data } = await axios({
		url: "telesale/dc-article/article/list",
		method: "POST",
		data: fix
	})
	return data;
}

const listCategory = async (params) => {
	const { data } = await axios({
		url: "telesale/dc-article/article/category/list",
		method: "GET",
		params: params
	})
	return { ...data, data: data.data ? data.data : [] }
}
const listProduct = async (params) => {
	const { data } = await axios({
		url: "telesale/dc-article/article/product/list",
		method: "GET",
		params: params
	})
	return { ...data, data: data.data ? data.data : [] }
}
const getAgentArticle = async (params) => {
	const { data } = await axios({
		url: "/telesale/dc-article/article/agent/list",
		method: "POST",
		data: params
	})
	return data
}

const getLeadArticle = async (params) => {
	const { data } = await axios({
		url: "/telesale/dc-article/article/lead/list",
		method: "POST",
		data: params
	})
	return data
}

const getLinkShareArticle = async (params) => {
	const { data } = await axios({
		url: "/telesale/dc-article/article/get-share-url",
		method: "POST",
		data: params
	})
	return data
}

const approveArticle = async (params) => {
	const { data } = await axios({
		url: "/telesale/dc-article/article/approve",
		method: "POST",
		data: params
	})
	return data
}

const hubReject = async (params) => {
	const { data } = await axios({
		url: "/telesale/dc-article/article/hub-reject",
		method: "POST",
		data: params
	})
	return data
}

const updateButton = async(params)=>{
	const { data } = await axios({
		url: "/telesale/dc-article/article/setSubmitText",
		method: "POST",
		data: params
	})
	return data
}


export default {
	uploadMedia,
	getListMedia,
	deleteMedia,
	createPost,
	editPost,
	deletePost,
	listPost,
	listCategory,
	listProduct,
	getAgentArticle,
	getLeadArticle,
	getLinkShareArticle,
	approveArticle,
	hubReject,
	updateButton
}
