import DialogWrapper from 'components/shared/DialogWrapper';
import {
	Button,
	DialogActions,
	DialogContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { useAgentLead } from 'hooks/leadAgent';
import { useProduct } from 'hooks/products';
import React, { useState } from 'react';
import { isTelesaleMerchant } from 'service';
import { useUpdateRevenueKpi } from './services';
import { useHistory, useLocation } from "react-router-dom"

const EditRevenue = () => {
	const { state } = useLocation()
	const history = useHistory()
	const [params, setParams] = useState({
		id: state.id,
		year: state.year,
		month: state.month,
		agent_code: state.agent_code,
		agent_name: state.agent_name,
		agent_phone: state.agent_phone,
		mc_product_code: state.mc_product_code,
		mc_product_name: state.mc_product_name,
		team_name: state.team_name,
		revenue_plan: state.revenue_plan,
	});

	const { data: agents } = useAgentLead({});
	const { data: products } = useProduct(
		{},
		isTelesaleMerchant() ? 'merchant-product' : 'product'
	);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setParams({ ...params, [name]: value });
	};

	const handleChangeAtribute = (event) => {
		const atribute = event.nativeEvent.target.dataset;
		delete atribute.value;
		setParams({
			...params,
			...atribute,
		});
	};
	const { mutateAsync } = useUpdateRevenueKpi();

	const handleSubmit = () => {
		mutateAsync(params).then(() => {
			history.goBack();
		});
	};

	return (
		<DialogWrapper title="Edit kpi" history={history}>
			<DialogContent>
				<form className="form-create-revenue-kpi">
					<TextField
						label="Năm"
						onChange={(e) =>
							setParams({ ...params, year: parseInt(e.target.value) })
						}
						value={params.year}
						type="number"
					/>
					<FormControl>
						<InputLabel>Tháng</InputLabel>
						<Select name="month" onChange={handleChange} value={params.month}>
							{Array.from({ length: 12 }, (_, i) => (
								<MenuItem value={i + 1} key={i}>
									{i + 1}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Agent</InputLabel>
						<Select
							name="agent_code"
							value={params.agent_code}
							onChange={handleChangeAtribute}
						>
							{agents?.data.map((item) => (
								<MenuItem
									value={item.code}
									key={item.code}
									data-agent_code={item.code}
									data-agent_phone={item.phone}
									data-agent_name={item.name}
									data-team_name={item.team_name}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Product</InputLabel>
						<Select
							name="mc_product_code"
							value={params.mc_product_code}
							onChange={handleChangeAtribute}
						>
							{products?.data.map((item) => (
								<MenuItem
									value={item.code}
									data-mc_product_code={item.code}
									key={item.code}
									data-mc_product_name={item.name}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						value={params.revenue_plan}
						onChange={handleChange}
						label="Plan (triệu đồng)"
						name="revenue_plan"
						type="number"
					/>
				</form>
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>history.goBack()}>Huỷ</Button>
				<Button color="primary" variant="contained" onClick={handleSubmit}>
					Lưu
				</Button>
			</DialogActions>
		</DialogWrapper>
	);
};

export default EditRevenue;
