import React, { useState } from 'react';
import {
	DialogTitle,
	DialogActions,
	DialogContent,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@material-ui/core';
import { useTaskConfig, useEditTask, useAssigne } from './services';
import { useLocation } from 'react-router-dom';
import { getUserId } from 'utils/auth';
const userId = getUserId();

const EditTask = ({ onClose, item, refetch, type }) => {
	const { pathname } = useLocation();
	const [params, setParams] = useState({
		id: item.id,
		assignee: item.assignee?.id,
		assigneeIsAgent: item.assignee?.isAgent,
		priority: item.priority?.id,
		status: item.status?.id,
	});
	const { data: taskConfig } = useTaskConfig();
	const { data: assigneeData } = useAssigne({ pathname });

	const { mutateAsync } = useEditTask();

	const handleSubmit = () => {
		mutateAsync(params).then(() => {
			onClose();
			refetch();
		});
	};
	const handleChange = (event) => {
		const { name, value } = event.target;
		setParams({
			...params,
			[name]: value,
		});
	};
	const handleChangeAssignee = (event) => {
		const atribute = event.nativeEvent.target.dataset;

		setParams({
			...params,
			assigneeIsAgent: JSON.parse(atribute.assigneeIsAgent),
			assignee: parseInt(atribute.assignee),
		});
	}
	return (
		<>
			<DialogTitle>Chỉnh sửa task</DialogTitle>
			<DialogContent>
				<form className="edit-task create-task">
					{userId === item.reporter?.id && (
						<>
							<FormControl variant="outlined">
								<InputLabel>Độ ưu tiên</InputLabel>
								<Select
									value={params.priority}
									name="priority"
									onChange={handleChange}
									label="Độ ưu tiên"
								>
									<MenuItem value="ALL" disabled>
										Tất cả
                  </MenuItem>
									{taskConfig?.data
										.filter((i) => i.type === 'priority')
										.map((i) => (
											<MenuItem value={i.id} key={i.id}>
												{i.name}
											</MenuItem>
										))}
								</Select>
							</FormControl>
							<FormControl variant="outlined">
								<InputLabel>Trạng thái</InputLabel>
								<Select
									value={params.status}
									name="status"
									label="Trạng thái"
									onChange={handleChange}
								>
									<MenuItem value="ALL" disabled>
										Tất cả
                  </MenuItem>
									{taskConfig?.data
										.filter((item) => item.type === 'status')
										.map((item) => (
											<MenuItem value={item.id} key={item.id}>
												{item.name}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</>
					)}
					{userId === item.assignee?.id && (
						<FormControl variant="outlined">
							<InputLabel>Giao việc cho</InputLabel>
							<Select
								value={params.assignee}
								name="assignee"
								onChange={handleChangeAssignee}
								label="Giao việc cho"
							>
								<MenuItem
									value={0}
									data-assignee-is-agent={false}
									data-assignee={0}
								>
									Không
                </MenuItem>
								{assigneeData?.data.map((item) => (
									<MenuItem
										value={item.id}
										data-assignee-is-agent={item.isAgent}
										data-assignee={item.id}
										key={item.id}
									>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Huỷ</Button>
				<Button onClick={handleSubmit} variant="contained" color="primary">
					Cập nhật
        </Button>
			</DialogActions>
		</>
	);
};

export default EditTask;
