import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import cs from 'const.js';
import DialogWrapper from 'components/shared/DialogWrapper';
import DataTable from 'components/shared/DataTable';
const EXECUTIVE_ORDER_DETAIL_URL =
	cs.MarketplaceURL + '/api/executive/order/detail';
// const EXECUTIVE_ORDER_DETAIL_URL = cs.BaseURL + '/telesale/external/executive/order/detail';

function formatValue(value) {
	console.log(value);
	if (value && value.originalPrice) {
		value.originalPrice = value.originalPrice
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	if (value && value.totalSale) {
		value.totalSale = value.totalSale
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return value;
}

class OrderDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				{ name: 'productName', title: 'Sản phẩm' },
				{ name: 'productVariationName', title: 'Phân loại' },
				{ name: 'quantity', title: 'Số lượng' },
				{ name: 'originalPrice', title: 'Đơn giá gốc' },
				{ name: 'discountRate', title: 'Chiết khấu(%)' },
				{ name: 'totalSale', title: 'Thành tiền' },
				{ name: 'leadFullName', title: 'Khách hàng' },
				{ name: 'leadTel', title: 'SĐT KH' },
				{ name: 'leadFullAddress', title: 'Địa chỉ KH' },
				{ name: 'leadCommuneName', title: 'Phường/Xã' },
				{ name: 'leadDistrictName', title: 'Quận/Huyện' },
				{ name: 'leadProvinceName', title: 'Tỉnh/Thành phố' },
				{ name: 'warehouseExecutiveName', title: 'Mã NV kho' },
				{ name: 'telesaleAgentFullname', title: 'NV chốt đơn' },
				{ name: 'telesaleAgentTeam', title: 'Team' },
				{ name: 'updateTime', title: 'Thời gian cập nhật' },
				{ name: 'verifiedBy', title: 'NV xác nhận' },
				{ name: 'mcCode', title: 'Công ty' },
			],
			rows: [],
			selection: [],
			tableColumnExtensions: [
				{ columnName: 'productName', align: 'center', width: 300 },
				{ columnName: 'productVariationName', align: 'center', width: 200 },
				{ columnName: 'quantity', align: 'center', width: 150 },
				{ columnName: 'originalPrice', align: 'center', width: 150 },
				{ columnName: 'discountRate', align: 'center', width: 150 },
				{ columnName: 'totalSale', align: 'center', width: 150 },
				{ columnName: 'leadFullName', align: 'center', width: 200 },
				{ columnName: 'leadTel', align: 'center', width: 150 },
				{ columnName: 'leadFullAddress', align: 'center', width: 200 },
				{ columnName: 'leadCommuneName', align: 'center', width: 200 },
				{ columnName: 'leadDistrictName', align: 'center', width: 200 },
				{ columnName: 'leadProvinceName', align: 'center', width: 200 },
				{ columnName: 'warehouseExecutiveName', align: 'center', width: 200 },
				{ columnName: 'telesaleAgentFullname', align: 'center', width: 200 },
				{ columnName: 'telesaleAgentTeam', align: 'center', width: 200 },
				{ columnName: 'updateTime', align: 'center', width: 200 },
				{ columnName: 'verifiedBy', align: 'center', width: 200 },
				{ columnName: 'mcCode', align: 'center', width: 150 },
			],
			// pageSizes: [5, 10, 20, 30, 60],
			totalCount: 0,
			pageSize: 10,
			currentPage: 0,
			loading: true,
			sorting: [{ columnName: 'update_time', direction: 'desc' }],
		};
		this.loadData();
	}

	loadData = () => {
		const queryString = `${EXECUTIVE_ORDER_DETAIL_URL}?maDonHang=${this.props.match.params.id}`;
		console.log(queryString);

		fetch(queryString, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					if (data.data.orderItems.length > 0) {
						for (var i = 0; i < data.data.orderItems.length; i++) {
							data.data.orderItems[i] = formatValue(data.data.orderItems[i]);
						}
						this.setState({
							rows: data.data.orderItems,
							totalCount: data.total_count,
							loading: false,
						});
					}
				} else {
					this.setState({
						rows: [],
						totalCount: 0,
						loading: false,
					});
				}
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	};

	handleFieldChange = (name) => (event) => {
		this.setState(
			{
				[name]: event.target.value,
			},
			() => { }
		);
	};
	render() {
		const {
			rows,
			columns,
			tableColumnExtensions,
			pageSizes,
			pageSize,
			currentPage,
			totalCount,
			loading,
			sorting,
		} = this.state;
		return (
			<div>
				<DialogWrapper title={'Chi tiết đơn hàng'} width={'md'} {...this.props}>
					<DialogContent>
						<DataTable
							rows={rows}
							columns={columns}
							columnWidths={tableColumnExtensions}
							pageSizes={pageSizes}
							pageSize={pageSize}
							currentPage={currentPage}
							loading={loading}
							sorting={sorting}
							totalCount={totalCount}
							changeCurrentPage={this.changeCurrentPage}
							changePageSize={this.changePageSize}
							changeSorting={this.changeSorting}
							autoLoadData={this.loadData}
						/>
					</DialogContent>
				</DialogWrapper>
			</div>
		);
	}
}

export default OrderDetail;
