import { useMutation } from '@tanstack/react-query';
import authApi from 'api/auth.api';
import cs from '../const';
import { toast } from 'react-toastify';

export const useChangePassword = () => {
	return useMutation((params) => authApi.changePassword(params), {
		onSuccess: (data) => {
			if (data.code === 100) {
				localStorage.clear();
				window.location.reload();
			}
		},
	});
};

export const useLogin = () => {
	return useMutation((params) => authApi.login(params), {
		onSuccess: (data) => {
			if (data.code === 100) {
				localStorage.setItem(
					cs.System_Code + '-general-user',
					JSON.stringify({
						name: data.data.user_name,
						fullname: data.data.user_fullname,
						role: data.data.user_role,
						type: data.data.user_type,
						functionRoles: data.data.function_roles,
						user_level: data.data.user_level,
						user_id: data.data.user_id
					})
				);
				localStorage.setItem(
					cs.System_Code + '-general-token',
					data.data.token
				);
				window.location.reload();
			} else {
				toast.error(data.error);
			}
		},
		onError: () => {
			toast.error('Có lỗi xảy ra');
		},
	});
};
