import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import cs from 'const.js';
import moment from "moment";
// import { DropzoneArea } from 'material-ui-dropzone';

const URL = cs.BaseURL + '/telesale/package/list/info';
const updatePackageURL = cs.BaseURL + "/telesale/package/info/update";

const styles = theme => ({
    locked_button: {
        marginTop: 10,
        marginLeft: 0
    },
    dropZone: {
        paddingTop: 10,
        minHeight: 200
    },
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },
});

function formatValue(value) {
    // format display of time
    if (value && value.created_at)
        value.created_at = moment
            .unix(value.created_at)
            .format('YYYY-MM-DD');

    if (value && value.ended_at)
        value.ended_at = moment
            .unix(value.ended_at)
            .format('YYYY-MM-DD');

    // format short forms
    if (value && value.current_province) value.current_province = value.current_province.replace('Thành phố', 'TP').replace('Tỉnh', '');

    return value;
}

const isTelesaleOP = () => {
    var user = localStorage.getItem(cs.System_Code + '-general-user');
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole === cs.Role_Telesale_Hub_Leader
        || userrole === cs.Role_Telesale_Hub_Manager
       );
}

class EditPackage extends React.Component {
    state = {
        id: this.props.match.params.id,
        // name: "",
        // code: "",
        // locked: false,
        // file: null,
        package_name: '',
        package_type: '',
        package_types: ["analysis", "marketing", "marketing"],
        amount: 0
    };
    constructor(props) {
        super(props);
     
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        //this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
        //this.loadMerchants();
        this.loadData();
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?id=${id}`;
        return queryString;
    }

    loadData() {
        if (!isTelesaleOP()) {
            window.location.href="/";
            return;
        }
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: localStorage.getItem(cs.System_Code + "-general-token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.data);
                if (data && data.data[0]) {
                    let tmp = formatValue(data.data[0]);
                    this.setState({
                        package_name: tmp.package_name,
                        package_type: tmp.package_type,
                        amount: tmp.amount,
                    });

                    console.log("state", this.state)
                } else {
                    this.setState({
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        // sessionStorage.clear();
                        // localStorage.clear();
                        // window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleDialogAgree = () => {
        console.log(this.state)
        if (!this.state.package_name || !this.state.package_type || !this.state.amount) {
            window.alert('Please input information !');
            return;
        }

        if (this.state.amount && isNaN(this.state.amount)) {
            window.alert('Incorrect amount !');
            return;
        }

        let queryString = `${updatePackageURL}`;
        let requestBody = {
            id: parseInt(this.state.id),
            package_name: this.state.package_name,
            //package_type: this.state.package_type,
            amount: this.state.amount,
        };

        console.log(requestBody);
        fetch(queryString, {
            method: "POST",

            body: JSON.stringify(requestBody),
            headers: {
                Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code) ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: "error",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add("Package info is updated !", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                // this.props.history.push({
                //     pathname: '/package-info',
                //     state: { reload: true }
                // });
                window.location.href = "/package-info";
                //window.location.reload();
            })
            .catch(() => { });
    };

    handleChange = name => event => {
        // if (name == "locked") this.setState({ locked: event.target.checked });
        // else
        this.setState({
            [name]: event.target.value
        });

    };

    handleDropZoneChange(files) {
        this.setState({
            file: files[0]
        });
    }

    render() {
        const { classes } = this.props;
        const { merchant } = this.state;
        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                    <DialogTitle id="edit_merchant_form-dialog-title">
                        Update Package Info
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="merchantId">Package Type (*)</InputLabel>
                            <Select
                                disabled
                                value={this.state.package_type}
                                onChange={this.handleChange('package_type')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {this.state.package_types.map(item => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>



                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="campaign_name"
                            required={true}
                            value={this.state.package_name}
                            onChange={this.handleChange('package_name')}
                            label="Package name "
                            type="name"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="amount"
                            required={true}
                            value={this.state.amount}
                            onChange={this.handleChange('amount')}
                            label="amount"
                            type="number"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.history.goBack}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleDialogAgree}
                            color="primary"
                        >
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

EditPackage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditPackage);
