import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import AssignLeader from './AssignLeader';
import DataTable from 'components/shared/DataTable.jsx';
import { useStates, useDistricts, useWards } from 'hooks/address';
import { useMerchantLeader } from 'hooks/lead';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Button,
} from '@material-ui/core';
import { pageSizes } from 'utils/datatable';
import moment from "moment"

const tableConfig = {
	columns: [
		{ name: 'action', title: 'Thao tác' },
		{ name: 'fullname', title: 'Họ và tên' },
		{ name: 'branch_director_name', title: 'Tên chi nhánh' },
		{ name: 'gender', title: 'Giới tính' },
		{ name: 'phone', title: 'Số điện thoại' },
		{ name: 'birthday', title: 'Ngày sinh' },
		{ name: 'adr_state', title: 'Tỉnh/Thành phố' },
		{ name: 'adr_district', title: 'Huyện/Quận' },
		{ name: 'adr_ward', title: 'Xã' },
		{ name: 'has_logged_in', title: 'Đã đăng nhập' },
	],
	tableColumnExtensions: [
		{ columnName: 'action', align: 'center', width: 250 },
		{ columnName: 'fullname', align: 'center', width: 200 },
		{ columnName: 'branch_director_name', align: 'center', width: 200 },
		{ columnName: 'gender', align: 'center', width: 200 },
		{ columnName: 'phone', align: 'center', width: 200 },
		{ columnName: 'birthday', align: 'center', width: 200 },
		{ columnName: 'adr_state', align: 'center', width: 200 },
		{ columnName: 'adr_district', align: 'center', width: 200 },
		{ columnName: 'adr_ward', align: 'center', width: 200 },
		{ columnName: 'has_logged_in', align: 'center', width: 200 },
	],
};

const BranchDirectorList = () => {
	const [params, setParams] = useState({
		name: '',
		code: '',
		phone: '',
		adr_state_code: 'ALL',
		adr_district_code: 'ALL',
		adr_ward_code: 'ALL',
		team_code: '',
		team_name: '',
		pagesize: 15,
		page: 1,
	});

	const { data: states } = useStates({ hub: params.hub });
	const { data: districts } = useDistricts({
		state_code: params.adr_state_code,
	});
	const { data: wards } = useWards({ district_code: params.adr_district_code });
	const { data, isLoading, refetch } = useMerchantLeader(params);

	return (
		<div>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Tìm kiếm ...</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box
						display="grid"
						gridTemplateColumns="repeat(6, 1fr)"
						gridColumnGap={10}
						width="100%"
					>
						<TextField
							id="agent_name"
							label="Họ & Tên"
							type="search"
							value={params.search_name}
							onChange={(e) => setParams({ ...params, name: e.target.value })}
						/>
						<TextField
							id="agent_code"
							label="Code"
							type="search"
							value={params.search_code}
							onChange={(e) => setParams({ ...params, code: e.target.value })}
						/>
						<TextField
							id="agent_phone"
							label="Số điện thoại"
							type="search"
							value={params.search_phone}
							onChange={(e) => setParams({ ...params, phone: e.target.value })}
						/>
						<FormControl>
							<InputLabel htmlFor="search_adr_state">
								Tỉnh/thành phố (*)
              </InputLabel>
							<Select
								value={params.adr_state_code}
								onChange={(e) =>
									setParams({ ...params, adr_state_code: e.target.value })
								}
								inputProps={{
									name: 'adr_state_code',
									id: 'adr_state_id',
								}}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{states?.data?.map((pro) => (
									<MenuItem value={pro.state_code} key={pro.state_code}>
										{pro.state_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl>
							<InputLabel htmlFor="search_adr_district">
								Quận / huyện (*)
              </InputLabel>
							<Select
								value={params.adr_district_code}
								onChange={(e) =>
									setParams({ ...params, adr_district_code: e.target.value })
								}
								inputProps={{
									name: 'adr_district_code',
									id: 'adr_district_id',
								}}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{districts?.data?.map((pro) => (
									<MenuItem value={pro.district_code} key={pro.district_code}>
										{pro.district_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<InputLabel htmlFor="search_adr_ward">Xã/Phường</InputLabel>
							<Select
								value={params.adr_ward_code}
								onChange={(e) =>
									setParams({ ...params, adr_ward_code: e.target.value })
								}
								inputProps={{
									name: 'adr_ward_code',
									id: 'adr_ward_id',
								}}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{wards?.data?.map((pro) => (
									<MenuItem value={pro.ward_code} key={pro.ward_code}>
										{pro.ward_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Button
							color="primary"
							variant="contained"
							onClick={() => refetch()}
						>
							Tìm
            </Button>
					</Box>
				</AccordionDetails>
			</Accordion>
			<Box marginTop="10px"></Box>
			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.tableColumnExtensions}
				pageSizes={pageSizes}
				pageSize={params.pagesize}
				currentPage={params.page - 1}
				loading={isLoading}
				/* sorting={sorting} */
				totalCount={data?.total_count}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				changePageSize={(p) => setParams({ ...params, pagesize: p })}
				/* changeSorting={this.changeSorting} */
				CellComponent={CellComponent}
			/* autoloadLeader={this.loadLeader} */
			/>
			<Route
				exact
				path="/branch-director/assign/:id"
				component={AssignLeader}
			/>
		</div>
	);
};

class ActionCell extends React.Component {
	render() {
		return (
			<Table.Cell
				style={{
					padding: 1,
					justifyContent: 'center',
					fontSize: '12px',
				}}
			>
				<span>
					<Button
						onClick={this.handleClick}
						color="primary"
						component={Link}
						to={{
							pathname: 'branch-director/assign/' + this.props.row.id,
							state: { previous: '/branch-director' },
							fullname: this.props.row.fullname,
						}}
					>
						Phân bổ trưởng phòng
          </Button>
				</span>
			</Table.Cell>
		);
	}
}

const CellComponent = (props) => {
	const { column, row } = props;
	switch (column.name) {
		case 'action':
			return <ActionCell {...props} />;
		case 'gender':
			return <Table.Cell> {row.gender === 0 ? 'Nam' : 'Nữ'}</Table.Cell>;

		case 'phone':
			return (
				<Table.Cell>{row.phone?.replace(/\d(?!\d{0,3}$)/g, '*')}</Table.Cell>
			);
		case 'birthday':
			return (
				<Table.Cell> {moment(row.birthday * 1000).format('DD/MM/YYYY')}</Table.Cell>
			);
		case 'has_logged_in':
			return <Table.Cell>{row.has_logged_in ? 'Đã login' : 'Chưa'}</Table.Cell>;
		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						//color: '#81557a',
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default withRouter(BranchDirectorList);
