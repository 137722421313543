import DialogWrapper from 'components/shared/DialogWrapper';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useViewContract } from './services';
import { Button, DialogActions, DialogContent } from '@material-ui/core';

const Contract = () => {
	const history = useHistory();
	const { state } = useLocation();
	const { data } = useViewContract({ lead_tel: state.tel });

	return (
		<DialogWrapper history={history} title="Chi tiết hợp đồng">
			<DialogContent>
				<h2>Danh sách hợp đồng</h2>
				<div>
					{data?.data?.ma_hop_dong.map((item) => (
						<h3 key={item}>{item}</h3>
					))}
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => history.goBack()}
					variant="contained"
					color="primary"
				>
					Đóng
				</Button>
			</DialogActions>
		</DialogWrapper>
	);
};

export default Contract;
