import React, { useState } from 'react';
import { useSearchTask, useTaskConfig, useAssigne } from './services';
import './styles.css';
import Table from 'rc-table';
import './table.css';
import moment from 'moment';
import { AiTwotoneEdit } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import {
	TextField,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Button,
	Dialog,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import CreateTask from './create';
import EditTask from './edit';
import { useSubmerchant } from 'telesales/ranking/services';
import { getUserId, getItemLocal } from 'utils/auth';
const userId = getUserId();

const SortComponent = ({ onChange, field, sort_by, sort_type }) => {
	return (
		<>
			{field !== sort_by ? (
				<FaSort onClick={() => onChange(field, 'asc')} />
			) : sort_type === 'asc' ? (
				<FaSortUp onClick={() => onChange(field, 'desc')} />
			) : (
						<FaSortDown onClick={() => onChange(field, 'asc')} />
					)}
		</>
	);
};

const WorkManagement = () => {
	const { pathname } = useLocation();
	const regex = /agent/.test(pathname);
	const user_level = getItemLocal('aidriven-general-user')?.user_level;

	const [params, setParams] = useState({
		sub_mc_code: 'ALL',

		reporter: regex ? userId : 'ALL',
		reporter_is_agent: false,

		assignee: 'ALL',
		assignee_is_agent: regex ? true : false,

		summary: undefined,
		description: undefined,
		priority: 'ALL',
		status: 'ALL',
		due_date: undefined,
		relation_task: undefined,
		relation_type: undefined,
		page: 1,
		size: 10,
		sort_by: 'priority',
		sort_type: 'desc',
		type: 'ALL', // assignee | reporter | monitor
	});

	const [openCreate, setOpenCreate] = useState(false);
	const [openEdit, setOpenEdit] = useState({ item: {}, open: false });

	const { data, refetch } = useSearchTask(params, pathname);
	const { data: taskConfig } = useTaskConfig();
	const { data: subMerchants } = useSubmerchant();
	const { data: assigneeData } = useAssigne({ pathname });

	const handleChange = (event) => {
		const { value, name } = event.target;
		setParams({
			...params,
			[name]: value,
		});
	};
	const handleChangeSort = (sort_by, sort_type) => {
		setParams({ ...params, sort_by: sort_by, sort_type: sort_type });
	};

	const handleChangeType = (event) => {
		const { value } = event.target;
		switch (value) {
			case 'assignee':
				setParams({
					...params,
					type: value,
					assignee: 'ALL',
					assignee_is_agent: undefined,
				});
			case 'reporter':
				setParams({
					...params,
					type: value,
					reporter: 'ALL',
					reporter_is_agent: undefined,
				});
			default:
				setParams({
					...params,
					type: value,
				});
		}
	};

	const columns = [
		{
			title: 'ID',
			key: 'id',
			dataIndex: 'id',
			render: (o) => (
				<Link
					to={/agent/.test(pathname) ? `works_agent/${o}` : `works_user/${o}`}
				>
					{o}
				</Link>
			),
			width: 20,
			fixed: 'left',
			align: 'center',
		},
		{
			title: (
				<span className="table-cell-head">
					Hạn ngày
					<SortComponent
						sort_type={params.sort_type}
						sort_by={params.sort_by}
						field={'dueDate'}
						onChange={handleChangeSort}
					/>
				</span>
			),
			render: (o, row) => moment(row.dueDate).format('hh:mm DD/MM/YYYY'),
			key: 'dueDate',
			width: 40,
		},
		{
			title: 'Người nhận việc',
			render: (row) => row?.name,
			key: 'assignee',
			dataIndex: 'assignee',
			width: 50,
		},
		{
			title: 'Người đề xuất',
			render: (row) => row.name,
			key: 'reporter',
			dataIndex: 'reporter',
			width: 50,
		},
		{
			title: (
				<span className="table-cell-head">
					Độ ưu tiên
					<SortComponent
						sort_type={params.sort_type}
						sort_by={params.sort_by}
						field={'priority'}
						onChange={handleChangeSort}
					/>
				</span>
			),
			dataIndex: 'priority',
			render: (row) => (
				<span className="status" style={{ backgroundColor: row.color }}>
					{row.name}
				</span>
			),
			key: 'priority',
			width: 20,
		},
		{
			title: (
				<span className="table-cell-head">
					Trạng thái
					<SortComponent
						sort_type={params.sort_type}
						sort_by={params.sort_by}
						field={'status'}
						onChange={handleChangeSort}
					/>
				</span>
			),
			render: (o, row) => (
				<span className="status" style={{ backgroundColor: row.status.color }}>
					{row.status.name}
				</span>
			),
			key: 'status',
			width: 20,
		},
		{
			title: 'Tóm tắt',
			dataIndex: 'summary',
			key: 'summary',
			width: 100,
		},
		{
			title: '#',
			render: (o, r) => (
				<span className="actions" style={{ fontSize: '20px' }}>
					{params.type !== 'monitor' && (
						<AiTwotoneEdit
							onClick={() => setOpenEdit({ item: o, open: true })}
						/>
					)}
				</span>
			),
			width: 30,
			fixed: 'right',
			align: 'center',
		},
	];

	return (
		<div className="work-management">
			<h3>
				{/agent/.test(pathname)
					? 'Quản trị công việc đại lý'
					: 'Quản trị công việc điều hành kinh doanh'}
			</h3>
			<div>
				<form className="form-search">
					<TextField
						name="summary"
						onChange={handleChange}
						label="Tóm tắt"
						value={params.summary}
					/>
					<FormControl>
						<InputLabel>Trạng thái</InputLabel>
						<Select value={params.status} name="status" onChange={handleChange}>
							<MenuItem value="ALL">Tất cả</MenuItem>
							{taskConfig?.data
								.filter((item) => item.type === 'status')
								.map((item) => (
									<MenuItem value={item.id} key={item.id}>
										{item.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Độ ưu tiên</InputLabel>
						<Select
							value={params.priority}
							name="priority"
							onChange={handleChange}
						>
							<MenuItem value="ALL">Tất cả</MenuItem>
							{taskConfig?.data
								.filter((item) => item.type === 'priority')
								.map((item) => (
									<MenuItem value={item.id} key={item.id}>
										{item.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					{user_level === 0 && (
						<FormControl>
							<InputLabel>Sub Merchant</InputLabel>
							<Select
								value={params.sub_mc_code}
								name="sub_mc_code"
								onChange={handleChange}
							>
								<MenuItem value={'ALL'}>Tất cả</MenuItem>
								{subMerchants?.map((item) => (
									<MenuItem value={item.sub_mc_code} key={item.sub_mc_code}>
										{item.sub_mc_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<FormControl>
						<InputLabel>Người nhận việc</InputLabel>
						<Select
							value={params.assignee}
							name="assignee"
							onChange={(e) => {
								const atribute = e.nativeEvent.target.dataset;
								setParams({
									...params,
									assignee_is_agent: atribute.assigneeIsAgent,
									assignee: atribute.assignee,
								});
							}}
							disabled={params.type === 'assignee'}
						>
							<MenuItem
								value={'ALL'}
								data-assignee-is-agent={'false'}
								data-assignee={'ALL'}
							>
								Không
              </MenuItem>
							{assigneeData?.data.map((item) => (
								<MenuItem
									value={item.id}
									data-assignee-is-agent={item.isAgent}
									data-assignee={item.id}
									key={item.id}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl>
						<InputLabel>Người đề xuât</InputLabel>
						<Select
							value={params.reporter}
							name="reporter"
							onChange={(e) => {
								const atribute = e.nativeEvent.target.dataset;
								setParams({
									...params,
									reporter_is_agent: atribute.reporterIsAgent,
									reporter: atribute.reporter,
								});
							}}
							disabled={params.type === 'reporter'}
						>
							<MenuItem
								value={'ALL'}
								data-reporter-is-agent={'false'}
								data-reporter={'ALL'}
							>
								Không
              </MenuItem>
							{assigneeData?.data.map((item) => (
								<MenuItem
									value={item.id}
									data-reporter-is-agent={item.isAgent}
									data-reporter={item.id}
									key={item.id}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<TextField
						label="Thời hạn"
						type="date"
						name="due_date"
						format="yyyy/MM/dd"
						value={params.due_date}
						onChange={handleChange}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</form>
				<div className="btn-search">
					<Button
						onClick={() => setOpenCreate(true)}
						color="primary"
						variant="outlined"
					>
						Tạo task mới
          </Button>
					<Button onClick={refetch} color="primary" variant="contained">
						Tìm
          </Button>
				</div>
			</div>
			<div style={{ width: 300, marginBottom: 5 }}>
				<FormControl fullWidth>
					<InputLabel>Loại công việc</InputLabel>
					<Select value={params.type} name="type" onChange={handleChangeType}>
						<MenuItem value="ALL">Tất cả</MenuItem>
						<MenuItem value="reporter">Công việc tôi đã giao</MenuItem>
						{!regex && (
							<MenuItem value="assignee">Công việc tôi được giao</MenuItem>
						)}
						<MenuItem value="monitor">Công việc tôi giám sát</MenuItem>
					</Select>
				</FormControl>
			</div>
			<Table columns={columns} data={data?.data} rowKey={(row) => row.id} />

			<div className="pagination">
				<Pagination
					count={data?.count && Math.ceil(data.count / params.size)}
					onChange={(e, p) => setParams({ ...params, page: p })}
					defaultPage={params.page}
					page={params.page}
				/>
			</div>
			<Dialog
				maxWidth="md"
				fullWidth
				open={openCreate}
				onClose={() => setOpenCreate(false)}
			>
				<CreateTask onClose={() => setOpenCreate(false)} refetch={refetch} />
			</Dialog>
			<Dialog
				maxWidth="md"
				fullWidth
				open={openEdit.open}
				onClose={() => setOpenEdit({ open: false, item: {} })}
			>
				<EditTask
					item={openEdit.item}
					onClose={() => setOpenEdit({ open: false, item: {} })}
					refetch={refetch}
					type={params.type}
				/>
			</Dialog>
		</div>
	);
};

export default WorkManagement;
