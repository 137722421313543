import React, { useState } from 'react';
import SearchPanel from 'telesales/lead/components/SearchPanel';
import DataTable from 'components/shared/DataTable';
import moment from 'moment';
import { Link, Route, useParams } from 'react-router-dom';
import NoteLooking from './NoteLooking';
import cs from 'const';
import Typography from '@material-ui/core/Typography';
import { getRole } from '../../service';
import { Button, Icon } from '@material-ui/core';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { useLead, useExportData } from './services';
import { pageSizes } from 'utils/datatable';
import Contract from './contract';
import { getItemLocal, checkFuncRoleWithId } from 'utils/auth';
const config = {
	columns: [
		{ name: 'action', title: '#' },
		{ name: 'full_name', title: 'Họ và tên KH' },
		{ name: 'status_desc', title: 'Trạng thái' },
		{ name: 'current_province', title: 'Tỉnh/TP' },
		{ name: 'tel', title: 'SĐT' },
		{ name: 'product_code', title: 'Sản phẩm' },
		{ name: 'number_of_calls_to_lead', title: 'Số lần gọi' },
		{ name: 'first_lead_creator_name', title: 'Người tạo lead' },
		{ name: 'first_lead_creator_phone', title: 'SĐT người tạo lead' },
		{ name: 'telesale_agent_name', title: 'Agent name' },
		{ name: 'telesale_agent_phone', title: 'Agent phone' },
		{ name: 'lead_created_at_unix', title: 'Ngày tạo' },
		{ name: 'last_status_updated_at_unix', title: 'Ngày cập nhật' },
	],
	columnWidths: [
		{ columnName: 'lead_created_at_unix', align: 'center', width: 150 },
		{ columnName: 'last_status_updated_at_unix', align: 'center', width: 150 },
		{ columnName: 'leader_name', align: 'center', width: 200 },
		{ columnName: 'leader_tel', align: 'center', width: 200 },
		{ columnName: 'first_lead_creator_name', align: 'center', width: 200 },
		{ columnName: 'first_lead_creator_phone', align: 'center', width: 200 },
		{ columnName: 'full_name', align: 'center', width: 300 },
		{ columnName: 'current_province', align: 'center', width: 200 },
		{ columnName: 'tel', align: 'center', width: 150 },
		{ columnName: 'product_code', align: 'center', width: 200 },
		{ columnName: 'number_of_calls_to_lead', align: 'center', width: 150 },
		{ columnName: 'telesale_agent_name', align: 'center', width: 150 },
		{ columnName: 'telesale_agent_phone', align: 'center', width: 150 },
		{ columnName: 'status_desc', align: 'center', width: 195 },
		{ columnName: 'action', align: 'center', width: 200 },
	],
};

const Lead = () => {
	const { id } = useParams();
	const [params, setParams] = useState({
		telesale_agent_name: '',
		search_content: '',
		mc_product_code: '',
		current_province: '',
		search_submitted_start: moment()
			.subtract(6, 'month')
			.startOf('day')
			.format('YYYY-MM-DD hh:mm'),
		search_submitted_end: moment().endOf('day').format('YYYY-MM-DD hh:mm'),
		lead_update_date_start: moment()
			.subtract(6, 'month')
			.startOf('day')
			.format('YYYY-MM-DD hh:mm'),
		lead_update_date_end: moment().endOf('day').format('YYYY-MM-DD hh:mm'),
		page: 1,
		pagesize: 15,
		lead_type: -1,
	});

	const [search, setSearch] = useState(true);

	const { data, isLoading, refetch } = useLead({
		...params,
		status: id,
		search: search,
	});
	const { mutateAsync: exportData } = useExportData();
	const handleExportData = () => {
		exportData({
			...params,
			status: id,
			search: search,
		});
	};

	return (
		<div>
			<Typography variant="h5" component="h4">
				Quản lý lead
      </Typography>
			<SearchPanel
				search={search}
				setSearch={setSearch}
				params={params}
				setParams={setParams}
				refetch={refetch}
			/>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					{(id === 0 || id === 'all') &&
						(getRole() === cs.Role_Telesale_Hub_User ||
							getRole() === cs.Role_Telesale_Hub_Leader ||
							getRole() === cs.Role_Telesale_Hub_Manager) ? (
							<Button
								color="inherit"
								aria-label="Import Lead"
								component={Link}
								to={'/import/lead'}
							>
								<Icon>cloud_upload</Icon>
								<span style={{ marginLeft: 10 }}>Tạo hàng loạt Lead</span>
							</Button>
						) : (
							<></>
						)}
				</div>
				<div>
					{id == 8 && checkFuncRoleWithId(10) && (
						<Button color="default" onClick={handleExportData}>
							Export
						</Button>
					)}
				</div>
			</div>

			<DataTable
				rows={data?.data}
				columns={config.columns}
				columnWidths={config.columnWidths}
				currentPage={params.page - 1}
				totalCount={data?.total_count}
				pageSize={params.pagesize}
				pageSizes={pageSizes}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				loading={isLoading}
				CellComponent={CellComponent}
			/>
			<Route exact path="/lead_unprocessed/note/:id" component={NoteLooking} />
			<Route exact path="/lead/:id/contract/" component={Contract} />
		</div>
	);
};

const ActionCell = (props) => {
	const { id } = useParams();
	const user_level = getItemLocal('aidriven-general-user').user_level;
	return (
		<Table.Cell
			style={{
				padding: 1,
			}}
		>
			{user_level === 0 && (
				<div
					style={{
						justifyContent: 'center',
						fontSize: '13px',
						display: 'flex',
						columnGap: '10px',
					}}
				>
					<Link
						to={{
							pathname: `/lead/${id}/history`,
							state: props.row,
						}}
					>
						<Icon color="primary">zoom_in</Icon>
					</Link>
					{(props.row.lead_status === -2 || props.row.lead_status === 0) && (
						<Link
							to={{
								pathname: `/lead/${id}/reassign`,
								state: props.row,
							}}
						>
							<Button variant="contained" color="primary" size="small">
								Phân bổ
              </Button>
						</Link>
					)}
					{
						// bỏ các trạng thái: đã mua hàng(8), chưa phân bổ(0), đã thu hồi(-2)
						//các trạng thái này không thể thu hồi lead
						props.row.lead_status !== 8 &&
						props.row.lead_status !== 0 &&
						props.row.lead_status !== -2 && (
							<Link
								to={{
									pathname: `/lead/${id}/release-lead`,
									state: props.row,
								}}
							>
								<Button variant="contained" color="primary" size="small">
									Thu hồi
                  </Button>
							</Link>
						)
					}
					{props.row.lead_status === 8 && (
						<Link
							to={{
								pathname: `/lead/${id}/contract`,
								state: props.row,
							}}
						>
							<Button variant="contained" color="primary" size="small">
								Hợp đồng
              </Button>
						</Link>
					)}
				</div>
			)}
		</Table.Cell>
	);
};

const CellComponent = (props) => {
	const { column, row } = props;
	switch (column.name) {
		case 'action':
			return <ActionCell {...props} />;
		case 'tel':
			return (
				<Table.Cell>{row.tel?.replace(/\d(?!\d{0,3}$)/g, '*')}</Table.Cell>
			);
		case 'telesale_agent_phone':
			return (
				<Table.Cell>
					{row.telesale_agent_phone?.replace(/\d(?!\d{0,3}$)/g, '*')}
				</Table.Cell>
			);
		case 'first_lead_creator_phone':
			return (
				<Table.Cell>
					{row.first_lead_creator_phone?.replace(/\d(?!\d{0,3}$)/g, '*')}
				</Table.Cell>
			);
		case 'lead_created_at_unix':
			return (
				<Table.Cell>
					{moment(row.lead_created_at_unix * 1000).format('DD/MM/YYYY')}
				</Table.Cell>
			);
		case 'last_status_updated_at_unix':
			return (
				<Table.Cell>
					{moment(row.last_status_updated_at_unix * 1000).format('DD/MM/YYYY')}
				</Table.Cell>
			);

		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default Lead;
