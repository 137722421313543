import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'const.js';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import DataTable from 'components/shared/DataTable';
import { useWards, useDistricts, useStates } from 'hooks/address';
import { useAgentLead } from 'hooks/leadAgent';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Checkbox,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { pageSizes } from 'utils/datatable';
import { useExportAgent } from './services';
import { checkFuncRoleWithId } from 'utils/auth';
import './style.css';
import moment from 'moment';

const isTelesaleMerchantManager = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	if (!user) return false;
	var userrole = JSON.parse(String(user)).role;
	return userrole === cs.Role_Telesale_Merchant_Manager;
};

const tableConfig = {
	columns: [
		{ name: 'action', title: 'Actions' },
		{ name: 'has_logged_in', title: 'Đã login' },
		{ name: 'auto_assign_ready', title: 'Nhận lead tự động' },
		{ name: 'agent_contact_is_saved', title: 'Đồng bộ F0 từ danh bạ' },
		{ name: 'last_save_contact_at', title: 'Đăng nhập app lần cuối' },
		{ name: 'name', title: 'Họ và tên' },
		{ name: 'team_name', title: 'Tên trưởng phòng' },
		{ name: 'branch_name', title: 'Tên chi nhánh' },
		{ name: 'gender', title: 'Giới tính' },
		{ name: 'phone', title: 'Số điện thoại' },
		{ name: 'birthday', title: 'Ngày sinh' },
		{ name: 'adr_state', title: 'Tỉnh / Thành phố' },
		{ name: 'adr_district', title: 'Huyện / Quận' },
		{ name: 'adr_ward', title: 'Xã' },
	],
	tableColumnExtensions: [
		{ columnName: 'action', align: 'center', width: 430 },
		{ columnName: 'name', align: 'center', width: 150 },
		{ columnName: 'team_name', align: 'center', width: 200 },
		{ columnName: 'branch_name', align: 'center', width: 200 },
		{ columnName: 'last_save_contact_at', align: 'center', width: 200 },
		{ columnName: 'gender', align: 'center', width: 150 },
		{ columnName: 'phone', align: 'center', width: 200 },
		{ columnName: 'birthday', align: 'center', width: 150 },
		{ columnName: 'adr_state', align: 'center', width: 200 },
		{ columnName: 'adr_district', align: 'center', width: 200 },
		{ columnName: 'adr_ward', align: 'center', width: 200 },
		{ columnName: 'has_logged_in', align: 'center', width: 200 },
		{ columnName: 'auto_assign_ready', align: 'center', width: 200 },
		{ columnName: 'agent_contact_is_saved', align: 'center', width: 200 },
	],
};

const Agent = () => {
	const [params, setParams] = useState({
		name: '',
		code: '',
		phone: '',
		adr_state_code: 'ALL',
		adr_district_code: 'ALL',
		adr_ward_code: 'ALL',
		team_code: '',
		team_name: '',
		page: 1,
		pagesize: 15,
		locked: 'ALL',
		has_logged_in: 'ALL',
		agent_contact_is_saved: 'ALL',
		last_save_contact_at_start: moment()
			.subtract(14, 'day')
			.startOf('day')
			.format('YYYY-MM-DD'),
		last_save_contact_at_end: moment().endOf('day').format('YYYY-MM-DD'),
	});
	const [searchTime, setSearchTime] = useState(false);

	const inputDate = new Date().toISOString().substring(0, 10);
	const [paramsExport, setParamsExport] = useState({
		login: false,
		action: false,
		last_login_to: inputDate,
		last_login_from: inputDate,
		last_lead_action_to: inputDate,
		last_lead_action_from: inputDate,
	});

	const [openExport, setOpenExport] = useState(false);

	const { data: states } = useStates({});
	const { data: districts } = useDistricts({
		state_code: params.adr_state_code,
	});
	const { data: wards } = useWards({ district_code: params.adr_district_code });
	const { data, isLoading, refetch } = useAgentLead({ ...params, searchTime });
	const routerParams = useParams();

	useEffect(() => {
		refetch();
	}, [routerParams]);

	const { mutateAsync: exportAgent } = useExportAgent();

	const handleClickExportAgent = () => {
		exportAgent(paramsExport);
	};

	return (
		<div>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Tìm kiếm ...</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box
						display="grid"
						gridTemplateColumns="repeat(6, 1fr)"
						gridColumnGap={10}
						width="100%"
					>
						<TextField
							id="agent_name"
							label="Họ & Tên"
							type="search"
							value={params.name}
							onChange={(e) => setParams({ ...params, name: e.target.value })}
						/>
						<TextField
							id="agent_code"
							label="Mã agent"
							type="search"
							value={params.code}
							onChange={(e) => setParams({ ...params, code: e.target.value })}
						/>

						<TextField
							id="agent_phone"
							label="Số điện thoại"
							type="search"
							value={params.phone}
							onChange={(e) => setParams({ ...params, phone: e.target.value })}
						/>

						<FormControl>
							<InputLabel htmlFor="search_adr_state">
								Tỉnh/thành phố (*)
              </InputLabel>
							<Select
								value={params.adr_state_code}
								onChange={(e) =>
									setParams({ ...params, adr_state_code: e.target.value })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{states?.data?.map((pro) => (
									<MenuItem value={pro.state_code} key={pro.state_code}>
										{pro.state_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl>
							<InputLabel htmlFor="search_adr_district">
								Quận / huyện (*)
              </InputLabel>
							<Select
								value={params.adr_district_code}
								onChange={(e) =>
									setParams({ ...params, adr_district_code: e.target.value })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{districts?.data?.map((pro) => (
									<MenuItem value={pro.district_code} key={pro.district_code}>
										{pro.district_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl>
							<InputLabel htmlFor="search_adr_ward">Xã/Phường</InputLabel>
							<Select
								value={params.adr_ward_code}
								onChange={(e) =>
									setParams({ ...params, adr_ward_code: e.target.value })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{wards?.data?.map((pro) => (
									<MenuItem value={pro.ward_code} key={pro.ward_code}>
										{pro.ward_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl>
							{isTelesaleMerchantManager() && (
								<>
									<TextField
										id="team_name"
										label="Team Name"
										type="search"
										value={params.team_name}
										onChange={(e) =>
											setParams({ ...params, team_name: e.target.value })
										}
									/>
								</>
							)}
						</FormControl>
						<FormControl>
							{isTelesaleMerchantManager() && (
								<>
									<TextField
										id="team_code"
										label="Team Code"
										type="search"
										value={params.team_code}
										onChange={(e) =>
											setParams({ ...params, team_code: e.target.value })
										}
									/>
								</>
							)}
						</FormControl>
						<FormControl>
							<InputLabel htmlFor="search_adr_ward">Khoá</InputLabel>
							<Select
								value={params.locked}
								onChange={(e) =>
									setParams({ ...params, locked: e.target.value })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								<MenuItem value={1}>Khoá</MenuItem>
								<MenuItem value={0}>Không khoá</MenuItem>
							</Select>
						</FormControl>
						<FormControl>
							<InputLabel htmlFor="search_adr_ward">Đã đăng nhập</InputLabel>
							<Select
								value={params.has_logged_in}
								onChange={(e) =>
									setParams({ ...params, has_logged_in: e.target.value })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								<MenuItem value={1}>Đã đăng nhập</MenuItem>
								<MenuItem value={0}>Chưa đăng nhập</MenuItem>
							</Select>
						</FormControl>
						<FormControl>
							<InputLabel htmlFor="search_adr_ward">
								Đã đồng bộ danh bạ
              </InputLabel>
							<Select
								value={params.agent_contact_is_saved}
								onChange={(e) =>
									setParams({
										...params,
										agent_contact_is_saved: e.target.value,
									})
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								<MenuItem value={1}>Đã đồng bộ danh bạ</MenuItem>
								<MenuItem value={0}>Chưa đồng bộ danh bạ</MenuItem>
							</Select>
						</FormControl>
						<div />
						<h4>
							Khoảng thời gian cuối cùng sử dụng App{' '}
							<Checkbox
								onChange={() => setSearchTime(!searchTime)}
								checked={searchTime}
							/>
						</h4>
						{searchTime && (
							<>
								<TextField
									type="date"
									label="Từ"
									value={params.last_save_contact_at_start}
									onChange={(e) =>
										setParams({
											...params,
											last_save_contact_at_start: e.target.value,
										})
									}
								/>
								<TextField
									type="date"
									label="Đến"
									value={params.last_save_contact_at_end}
									onChange={(e) =>
										setParams({
											...params,
											last_save_contact_at_end: e.target.value,
										})
									}
								/>
							</>
						)}
					</Box>
				</AccordionDetails>
			</Accordion>
			<div className="btn-container">
				<Button
					onClick={() => setOpenExport(true)}
					color="primary"
					disabled={!checkFuncRoleWithId(503)}
				>
					Export
        </Button>
				<Button onClick={() => refetch()} variant="contained" color="primary">
					Tìm
        </Button>
			</div>
			<Dialog open={openExport} onClose={() => setOpenExport(false)}>
				<DialogTitle>Export agent</DialogTitle>
				<DialogContent>
					<div className="export-params-field">
						<h4>Thời gian đăng nhập</h4>
						<div className="input-container">
							<Checkbox
								onChange={() =>
									setParamsExport({
										...paramsExport,
										login: !paramsExport.login,
									})
								}
								checked={paramsExport.login}
							/>
							{paramsExport.login && (
								<div>
									<span className="time-select">
										<span className="input-before">Từ</span>
										<input
											type="date"
											value={paramsExport.last_login_from}
											onChange={(e) =>
												setParamsExport({
													...paramsExport,
													last_login_from: e.target.value,
												})
											}
										/>
									</span>
									<span className="time-select">
										<span className="input-before">Đến</span>
										<input
											type="date"
											value={paramsExport.last_login_to}
											onChange={(e) =>
												setParamsExport({
													...paramsExport,
													last_login_to: e.target.value,
												})
											}
										/>
									</span>
								</div>
							)}
						</div>
					</div>
					<div className="export-params-field">
						<h4>Thời gian thay đổi trạng thái lead</h4>
						<div className="input-container">
							<Checkbox
								onChange={() =>
									setParamsExport({
										...paramsExport,
										action: !paramsExport.action,
									})
								}
								checked={paramsExport.action}
							/>
							{paramsExport.action && (
								<div>
									<span className="time-select">
										<span className="input-before">Từ</span>
										<input
											type="date"
											value={paramsExport.last_lead_action_from}
											onChange={(e) =>
												setParamsExport({
													...paramsExport,
													last_lead_action_from: e.target.value,
												})
											}
										/>
									</span>
									<span className="time-select">
										<span className="input-before">Đến</span>
										<input
											type="date"
											value={paramsExport.last_lead_action_to}
											onChange={(e) =>
												setParamsExport({
													...paramsExport,
													last_lead_action_to: e.target.value,
												})
											}
										/>
									</span>
								</div>
							)}
						</div>
					</div>
					<DialogActions>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setOpenExport(false)}
						>
							Huỷ
            </Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleClickExportAgent}
						>
							Xuất
            </Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.tableColumnExtensions}
				pageSizes={pageSizes}
				pageSize={params.pagesize}
				currentPage={params.page - 1}
				loading={isLoading}
				/* sorting={sorting} */
				totalCount={data?.total_count}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				changePageSize={(p) => setParams({ ...params, pagesize: p })}
				/* changeSorting={this.changeSorting} */
				CellComponent={CellComponent}
			/* autoLoadData={this.loadData} */
			/>
		</div>
	);
};

const ActionCell = (props) => {
	return (
		<Table.Cell
			style={{
				padding: 1,
				justifyContent: 'center',
				fontSize: '13px',
			}}
		>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '4px',
				}}
			>
				<Link
					to={`/agent-list/lock/${props.row.id}/${
						props.row.code + '.' + props.row.locked
						}`}
				>
					<Button variant="contained" color="primary" size="small">
						{props.row.locked ? 'Mở khoá' : 'Khoá'}
					</Button>
				</Link>
				{checkFuncRoleWithId(10) && (
					<Link
						to={{
							pathname: `/release-all-lead/${props.row.id}`,
							state: props.row,
						}}
					>
						<Button size="small" variant="contained" color="primary">
							Lead
            </Button>
					</Link>
				)}
				<Link to={{ pathname: '/agent-list/edit', state: props.row }}>
					<Button size="small" variant="contained" color="primary">
						Chỉnh sửa
          </Button>
				</Link>
				<Link to={{ pathname: '/agent-list/send-email', state: props.row }}>
					<Button size="small" variant="contained" color="primary">
						Gửi email HD sử dụng
          </Button>
				</Link>
			</div>
		</Table.Cell>
	);
};

const CellComponent = (props) => {
	const { column, row } = props;
	switch (column.name) {
		case 'action':
			return <ActionCell {...props} />;
		case 'birthday':
			return (
				<Table.Cell>
					{row.birthday && moment(row.birthday * 1000).format('DD/MM/YYYY')}
				</Table.Cell>
			);

		case 'last_save_contact_at':
			return (
				<Table.Cell>
					{row.last_save_contact_at &&
						moment(row.last_save_contact_at * 1000).format('DD/MM/YYYY')}
				</Table.Cell>
			);
		case 'phone':
			return (
				<Table.Cell>{row.phone?.replace(/\d(?!\d{0,3}$)/g, '*')}</Table.Cell>
			);
		case 'gender':
			return <Table.Cell> {row.gender === 0 ? 'Nam' : 'Nữ'}</Table.Cell>;
		case 'auto_assign_ready':
			return (
				<Table.Cell> {row.auto_assign_ready === 0 ? 'Chưa' : 'Có'}</Table.Cell>
			);
		case 'agent_contact_is_saved':
			return (
				<Table.Cell>
					{row.agent_contact_is_saved === 0 ? 'Chưa' : 'Có'}
				</Table.Cell>
			);
		case 'has_logged_in':
			return <Table.Cell>{row.has_logged_in ? 'Đã login' : 'Chưa'}</Table.Cell>;

		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						// color: '#81557a',
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default Agent;
