import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import cs from 'const';
import { openInNewTab } from 'const';
import cl from 'mycolor';
import { getRole } from 'service';
import ChangePassword from './auth/ChangePassword';
import NestedList from './components/NestedList';
import { menu } from './menu.js';
import AppRoute from './AppRoute';
import MenuItem from '@material-ui/core/MenuItem';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { Menu } from '@material-ui/core';

const URL = cs.BaseURL + '/user/detail';
const revokeTokenURL = cs.BaseURL + '/auth/logout';
const drawerWidth = 290;

const styles = (theme) => ({
	'@global': {
		'*::-webkit-scrollbar': {
			width: '0.4em',
			backgroundColor: '#F5F5F5',
		},
		'*::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'*::-webkit-scrollbar-thumb': {
			borderRadius: '10px',
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
			backgroundColor: 'rgba(0,0,0,.1)',
		},
	},
	root: {
		height: '100vh',
		maxHeight: '100vh',
		display: 'flex',
		marginTop: 0,
		paddingTop: 10,
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
		//backgroundColor: 'rgb(68, 126, 236)'
		backgroundColor: cl.bluestone,
	},
	toolbarHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 2px',
		...theme.mixins.toolbar,
	},
	username: {
		textAlign: 'right',
		fontWeight: 550,
		fontSize: 14,
	},
	avatar: {
		marginRight: 6,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		paddingTop: '60px',
		maxHeight: '100vh',
		overflow: 'scroll',
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		paddingTop: 0,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		overflow: 'auto',
	},
	chartContainer: {
		marginLeft: -22,
	},
	tableContainer: {
		height: 320,
	},
	h5: {
		marginBottom: theme.spacing(2),
	},
	logo: {
		margin: 20,
		width: 100,
	},
	listItemText: {
		fontSize: '0.2rem',
	},
	notfound: {
		display: 'flex',
		justifyContent: 'center',
	},
});

const NavbarTitle = ({ title }) => {
	return (
		<Typography variant="h5" color="inherit" noWrap style={{ flexGrow: 1 }}>
			{title}
		</Typography>
	);
};
/**
 * Home page for telesales
 */
class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			open: true,
			showCreateOrder: false,
			showImportOrder: false,
			showChangePassword: false,
			anchorEl: null,
		};
		var user = localStorage.getItem(cs.System_Code + '-general-user');
		this.userrole = JSON.parse(String(user)).role;

		this.handleShowChangePassword = this.handleShowChangePassword.bind(this);
		this.handleCloseChangePassword = this.handleCloseChangePassword.bind(this);
		this.postOneSignalPlayerIdToServer =
			this.postOneSignalPlayerIdToServer.bind(this);
		//this.loadData();
	}

	loadData() {
		var username = '';
		var user = localStorage.getItem(cs.System_Code + '-general-user');
		if (user) username = JSON.parse(String(user)).name;

		if (!username) return;
		let queryString = `${URL}?name=${username}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					this.setState({
						user: data.data,
						loading: false,
					});
				} else {
					this.setState({
						user: {},
						loading: false,
					});
				}
			})
			.catch(() => this.setState({ loading: false }));
	}
	handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	flipDrawer = () => {
		this.setState({ open: !this.state.open });
	};

	handleLogOut = () => {
		let queryString = `${revokeTokenURL}`;
		fetch(queryString, {
			method: 'POST',
			body: JSON.stringify({}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				sessionStorage.clear();
				localStorage.clear();
				window.location.reload();
			})
			.catch(() => {
				sessionStorage.clear();
				localStorage.clear();
				window.location.reload();
			});

		sessionStorage.clear();
		localStorage.clear();
		window.location.reload();
	};

	handleShowChangePassword = () => {
		this.setState({ showChangePassword: true });
	};

	handleCloseChangePassword = () => {
		this.setState({ showChangePassword: false });
	};

	postOneSignalPlayerIdToServer() {
		var token = localStorage.getItem(cs.System_Code + '-general-token');
		if (!token || token === null || token === 'null' || token === undefined)
			return;

		var OneSignal = window.OneSignal || [];
		if (OneSignal.length !== 0) {
			OneSignal.getUserId(function(userId) {
				const URL = cs.BaseURL + '/notification/register_notify_device';
				fetch(URL, {
					method: 'POST',
					body: JSON.stringify({
						onesignal_player_id: userId,
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: token,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (
							data &&
							(data.code === cs.erAuthenticationFailed.code ||
								data.code === cs.erAccountIsLocked.code)
						) {
							sessionStorage.clear();
							window.location.reload();
						}
					})
					.catch(() => { });
			});
		}
	}

	render() {
		const { classes } = this.props;

		var username = '';
		var user = localStorage.getItem(cs.System_Code + '-general-user');
		if (user) username = JSON.parse(String(user)).fullname;
		if (!username) username = JSON.parse(String(user)).name;

		var OneSignal = window.OneSignal || [];
		var that = this;
		OneSignal.push(function() {
			OneSignal.on('subscriptionChange', function(isSubscribed) {
				if (isSubscribed) that.postOneSignalPlayerIdToServer();
			});

			OneSignal.init({
				appId: '5dec764a-8795-4628-88ae-587365d3330c',
			});
		});

		this.postOneSignalPlayerIdToServer();

		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar
					position="absolute"
					className={classNames(
						classes.appBar,
						this.state.open
						//, classes.appBarShift
					)}
				>
					<Toolbar
						disableGutters={!this.state.open}
						className={classes.toolbar}
					>
						<IconButton
							color="inherit"
							aria-label="Flip drawer"
							onClick={this.flipDrawer}
							className={classNames(
								classes.menuButton
								// this.state.open && classes.menuButtonHidden
							)}
						>
							<MenuIcon />
						</IconButton>
						{getRole() === cs.Role_Telesale_Merchant_Manager && (
							<NavbarTitle
								title={'Cấp Tổng Giám đốc - Quản trị Sales tại Công ty'}
							/>
						)}
						{getRole() === cs.Role_Telesale_Merchant_Leader && (
							<NavbarTitle
								title={'Cấp Trưởng phòng - Quản trị Sales tại Công ty'}
							/>
						)}
						{getRole() === cs.Role_Telesale_Merchant_User && (
							<NavbarTitle
								title={'Cấp Nhân viên - Quản trị Sales tại Công ty'}
							/>
						)}

						{getRole() === cs.Role_Telesale_Hub_Manager && (
							<NavbarTitle
								title={'Cấp Giám đốc - Quản trị Telesales tại Hub'}
							/>
						)}
						{getRole() === cs.Role_Telesale_Hub_Leader && (
							<NavbarTitle
								title={'Cấp Trưởng phòng - Quản trị Telesales tại Hub'}
							/>
						)}
						{getRole() === cs.Role_Telesale_Hub_User && (
							<NavbarTitle
								title={'Cấp Nhân viên - Quản trị Telesales tại Hub'}
							/>
						)}

						{getRole() === cs.Role_Mc_Board_Of_Control_Member && (
							<NavbarTitle
								title={'Cấp Thành viên ban kiểm soát tại Công ty'}
							/>
						)}
						{getRole() === cs.Role_Telesale_Merchant_Branch_Director && (
							<NavbarTitle
								title={'Cấp Giám đốc chi nhánh - Quản trị Sales tại Công ty'}
							/>
						)}

						<div>
							<IconButton
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={(e) => {
									this.setState({ anchorEl: e.target });
								}}
								color="inherit"
							>
								<DragIndicator />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={this.state.anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(this.state.anchorEl)}
								onClose={() => this.setState({ anchorEl: null })}
							>
								<MenuItem
									onClick={() => {
										openInNewTab(cs.SalesplusUrl);
									}}
								>
									SALESPLUS
									</MenuItem>

								<MenuItem
									onClick={() => {
										openInNewTab(cs.SalesURL);
									}}
								>
									Quản trị Sales
									</MenuItem>

								<MenuItem
									onClick={() => {
										openInNewTab(cs.ShopUrl);
									}}
								>
									Bán hàng với Salesplus
									</MenuItem>

								<MenuItem
									onClick={() => {
										openInNewTab(cs.HRUrl);
									}}
								>
									Quản trị Nhân sự
									</MenuItem>
								<MenuItem
									onClick={() => {
										openInNewTab(cs.EtrainingUrl);
									}}
								>
									eTraining
									</MenuItem>
							</Menu>
						</div>

						<Button
							color="inherit"
							onClick={this.handleShowChangePassword}
							aria-label="Đổi mật khẩu"
						>
							<Icon>vpn_key</Icon>
							<span style={{ marginLeft: 10 }}>Đổi mật khẩu</span>
						</Button>

						<Button
							color="inherit"
							aria-label="Đăng xuất"
							onClick={this.handleLogOut}
						>
							<Icon>exit_to_app</Icon>
							<span style={{ marginLeft: 10 }}>Đăng xuất</span>
						</Button>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					classes={{
						paper: classNames(
							classes.drawerPaper,
							!this.state.open && classes.drawerPaperClose
						),
					}}
					open={this.state.open}
				>
					<div className={classes.toolbarHeader}>
						{cs.System_Id === 1 ? (
							<Avatar
								className={classes.avatar}
								src={'/static/img/logo-app-trans.png'}
							/>
						) : (
								<Avatar
									className={classes.avatar}
									src={'/static/img/logo-small.png'}
								/>
							)}
						{this.state.open && (
							<Typography variant="h5" className={classes.username}>
								{username}
							</Typography>
						)}
					</div>
					<Divider />
					<List>
						<NestedList menu={menu} />
					</List>
				</Drawer>

				<main className={classes.content}>
					<div className={classes.appBarSpacer} />

					<AppRoute userrole={this.userrole} />

					<ChangePassword
						open={this.state.showChangePassword}
						onClose={this.handleCloseChangePassword}
					/>
				</main>
			</div>
		);
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
