import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import DialogWrapper from 'components/shared/DialogWrapper'
import { DialogContent, DialogActions, Box, Icon } from '@material-ui/core'
import { useStatusHistory } from "./services"

const css = {
	item: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: "1px solid #ccc",
		flex: 1
	}
}

const StatusHistory = () => {
	const history = useHistory()
	const { state } = useLocation()
	const { data: statusHistory } = useStatusHistory({ order_code: state.order_code, agent_phone: state.telesale_agent_phone })

	return (
		<DialogWrapper title="Thông tin chi tiết" history={history}>
			<DialogContent>
				<Box>
					<h4>
						Lịch sử chuyển đối trạng thái
					</h4>
					<div style={{ width: "400px" }}>
						<div style={{ display: "flex" }}>
							<div style={{ ...css.item, borderBottom: "1px solid #111" }}>
								<h5>Từ</h5>
								<Icon>arrow_right_alt</Icon>
								<h5>Đến</h5>
							</div>
							<div style={{ padding: "0 20px", borderBottom: "1px solid #111" }}>
								<h5>Thời gian</h5>
							</div>
						</div>
						{
							statusHistory?.data?.map(item => (
								<div style={{ display: "flex" }} key={item.id}>
									<div key={item.id} style={css.item}>
										<p>{item.from_status_desc}</p>
										<Icon>arrow_right_alt</Icon>
										<p>{item.to_status_desc}</p>
									</div>
									<div style={{
										padding: "0 20px", borderBottom: "1px solid #ccc",
									}}>
										<p>
											{item.change_at_day + "/" + item.change_at_month + "/" + item.change_at_year}
										</p>
									</div>
								</div>
							))
						}
					</div>
				</Box>
			</DialogContent>
			<DialogActions>

			</DialogActions>
		</DialogWrapper>
	)
}

export default StatusHistory
