import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import DialogWrapper from 'components/shared/DialogWrapper';
import { DialogContent, DialogActions, Box, Button } from '@material-ui/core';
import { useReleaseAssignLead } from './services';

const ReleaseLead = () => {
	const { state } = useLocation();
	const history = useHistory();
	const { mutateAsync, isLoading } = useReleaseAssignLead();
	const handleClick = () => {
		if (state.telesale_agent_id && state.id)
			mutateAsync({
				agent_id: state.telesale_agent_id,
				lead_id: state.id,
			}).then(() => history.goBack());
	};
	return (
		<DialogWrapper title="Thu hồi lead" history={history}>
			<DialogContent>
				<div>
					<h1>Bạn muốn thu hồi lead này</h1>
					<p>Thông tin lead</p>
				</div>
				<Box>
					<table>
						<tbody>
							<tr>
								<td>Agent id:</td>
								<td>{state.telesale_agent_id}</td>
							</tr>
							<tr>
								<td>Tên Agent:</td>
								<td>{state.telesale_agent_name}</td>
							</tr>
							<tr>
								<td>Lead id:</td>
								<td>{state.id}</td>
							</tr>
							<tr>
								<td>Tên lead:</td>
								<td>{state.full_name}</td>
							</tr>
						</tbody>
					</table>
				</Box>
				<DialogActions>
					<Button color="danger" onClick={() => history.goBack()}>
						Huỷ
					</Button>
					<Button
						color="primary"
						disabled={isLoading}
						variant="contained"
						onClick={() => handleClick()}
					>
						Xác nhận
					</Button>
				</DialogActions>
			</DialogContent>
		</DialogWrapper>
	);
};

export default ReleaseLead;
