import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { DropzoneArea } from 'material-ui-dropzone';
import { useHistory, useParams } from 'react-router-dom';
import {
	Typography,
	Box,
	FormLabel,
	TextareaAutosize,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import Editor from 'components/shared/Edittor';
import { useNewsLeader, useNewsProduct } from 'hooks/news';
import { useNewsDetail, useUpdateNews } from './services';
import DialogWrapper from 'components/shared/DialogWrapper';

const UpdateNew = () => {
	const history = useHistory();
	const routerParams = useParams();
	const [thumbnail, setThumbnail] = useState(null);
	const { mutateAsync: updateNews } = useUpdateNews();
	const [news, setNews] = useState({
		id: '',
		title: '',
		description: '',
		category: '',
		sub_category: '',
		priority: '',
		article: '',
		like_able: '',
		comment_able: '',
		team_name: '',
		mc_product_code: '',
		content: '',
	});
	const { data: leads } = useNewsLeader();
	const { data: products } = useNewsProduct();
	const { data, isSuccess } = useNewsDetail({ id: routerParams.id });

	useEffect(() => {
		if (isSuccess)
			setNews({
				code: data?.data.code,
				title: data?.data.title,
				description: data?.data.description,
				thumbnail_url: data?.data.thumbnail_url,
				content: data?.data.content,
				/* 			/* content_text: contentText, */
				/* /* content_img: contentImg, */
				active: data?.data.active,
				priority: data?.data.priority,
				like_able: data?.data.like_able === 1,
				comment_able: data?.data.comment_able === 1,
				team_name: data?.data.team_name,
				mc_product_code: data?.data.mc_product_code,
				id: data?.data.id,
			});
	}, [isSuccess]);

	const handleDialogAgree = () => {
		if (!news?.title || !news.description) {
			window.alert('Please input information !');
			return;
		}
		if (news?.priority < 0 || news.priority > 10) {
			window.alert('Incorrect priority value !');
			return;
		}
		const formData = new FormData();
		formData.append('id', news?.id);
		formData.append('title', news?.title);
		formData.append('description', news?.description);
		formData.append('category', news?.category);
		formData.append('sub_category', news?.sub_category);
		formData.append('priority', news?.priority);
		formData.append('article', thumbnail);
		formData.append('like_able', news?.like_able ? 1 : 0);
		formData.append('comment_able', news?.comment_able ? 1 : 0);
		formData.append('team_name', news?.team_name);
		formData.append('mc_product_code', news?.mc_product_code);
		formData.append('content', news.content);

		updateNews(formData).then(() => {
			history.goBack();
		});
	};

	const handleChange = (name) => (event) => {
		if (name == 'active') {
			setNews({ ...news, active: event.target.checked });
		} else {
			setNews({
				...news,
				[name]: event.target.value,
			});
		}
	};

	return (
		<DialogWrapper
			fullSreen
			width="100vw"
			history={history}
			title={'Cập nhật bản tin'}
		>
			<DialogContent>
				<Grid>
					<Grid container style={{ gap: '10px', flexWrap: 'nowrap' }}>
						<Grid xs={9} fullWidth>
							<FormControl fullWidth>
								<TextField
									fullWidth
									autoFocus
									margin="dense"
									id="edit_title"
									required={true}
									value={news?.title}
									onChange={handleChange('title')}
									label="Tiêu đề"
									type="name"
								/>
							</FormControl>
							<FormControl
								fullWidth
								style={{
									marginTop: 20,
								}}
							>
								<FormLabel>Mô tả</FormLabel>
								<Editor
									value={news?.description}
									onChange={(e) => {
										setNews({ ...news, description: e });
									}}
								/>
							</FormControl>
							<FormControl
								fullWidth
								style={{
									marginTop: 20,
								}}
							>
								<FormLabel>Nội dung bài viết:</FormLabel>
								<Editor
									value={news?.content}
									onChange={(e) => {
										setNews({ ...news, content: e });
									}}
								/>
							</FormControl>
						</Grid>
						<Grid>
							<FormControl fullWidth>
								<InputLabel htmlFor="merchantId">Team name (*)</InputLabel>
								<Select
									value={news.team_name}
									onChange={handleChange('team_name')}
								>
									{leads?.data.map((item) => (
										<MenuItem value={item.name}>{item.name}</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl fullWidth>
								<InputLabel htmlFor="merchantId">Sản phẩm (*)</InputLabel>
								<Select
									value={news?.mc_product_code}
									onChange={handleChange('mc_product_code')}
								>
									{products?.data.map((item) => (
										<MenuItem value={item.code}>{item.name}</MenuItem>
									))}
								</Select>
							</FormControl>
							<TextField
								fullWidth
								autoFocus
								margin="dense"
								id="priority"
								required={true}
								value={news?.priority}
								onChange={handleChange('priority')}
								label="Ưu tiên"
								type="number"
								InputProps={{ inputProps: { min: 0, max: 10 } }}
							/>

							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="flex-start"
								spacing={4}
							>
								<Grid item xs={6}>
									<div>
										Like
										<Switch
											checked={news?.like_able === 0 ? false : true}
											value={news?.like_able}
											onChange={handleChange('like_able')}
											color="primary"
										/>
										{news?.like_able ? 'Có' : 'Không'}
									</div>
								</Grid>

								<Grid item xs={6}>
									<div>
										Comment
										<Switch
											checked={news?.comment_able === 0 ? false : true}
											value={news?.comment_able}
											onChange={handleChange('comment_able')}
											color="primary"
										/>
										{news?.comment_able ? 'Có' : 'Không'}
									</div>
								</Grid>
							</Grid>

							<Typography variant="h6">Ảnh đại diện</Typography>
							<img src={`${news?.thumbnail_url}`} style={{ maxWidth: 300 }} />
							<Grid item>
								<DropzoneArea
									acceptedFiles={['image/*']}
									dropzoneText={
										thumbnail
											? thumbnail.name.slice(0, 5)
											: 'Upload file ảnh bài viết (*)'
									}
									maxFileSize={50000000}
									filesLimit={1}
									showAlerts={false}
									showPreviewsInDropzone={false}
									onChange={(files) => setThumbnail(files[0])}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Box display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="10px">
					<Button onClick={history.goBack} color="secondary" fullWidth>
						Hủy
					</Button>
					<Button
						onClick={handleDialogAgree}
						color="primary"
						variant="contained"
						fullWidth
					>
						Cập nhật
					</Button>
				</Box>
			</DialogActions>
		</DialogWrapper>
	);
};

export default UpdateNew;
