import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'const.js';
import moment from 'moment';
import React from 'react';
import Button from '@material-ui/core/Button';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { blue, green } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';

import DataTable from 'components/shared/DataTable';
import { getRole } from 'service';

const URL = cs.BaseURL + '/telesale/inventory/list/out';
const leaderURL = cs.BaseURL + '/telesale/inventory/leader';
const approveURL = cs.BaseURL + '/telesale/inventory/out/approve';

const activeStatus = [
	'Chưa phê duyệt',
	'Phê duyệt',
	'',
	'Yêu cầu sửa lại',
	'Tắt',
];

function formatValue(value) {
	// format display of time
	if (value && value.update_time)
		value.update_time = moment(value.update_time).format('DD/MM/YYYY HH:mm');

	// format short forms
	if (value && value.current_province)
		value.current_province = value.current_province
			.replace('Thành phố', 'TP')
			.replace('Tỉnh', '');

	if (value && value.active !== null) {
		value.active = activeStatus[value.active];
	}

	if (value && value.selling_price) {
		value.selling_price = value.selling_price
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return value;
}

const isTelesale_Merchant_Manager = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	var userrole = JSON.parse(String(user)).role;
	return userrole === cs.Role_Telesale_Merchant_Manager;
};

class InventoryOutputList extends React.Component {
	constructor(props) {
		super(props);

		var username = '';
		var user = localStorage.getItem(cs.System_Code + '-general-user');
		if (user) username = JSON.parse(String(user)).name;

		this.state = {
			columns: [
				{ name: 'action', title: 'Phê duyệt' },
				{ name: 'mc_code', title: 'Đối tác' },
				{ name: 'product_name', title: 'Sản phẩm' },
				{ name: 'product_code', title: 'Mã sản phẩm' },
				{ name: 'quantity', title: 'Số lượng' },
				{ name: 'selling_price', title: 'Giá' },
				{ name: 'telesale_agent_fullname', title: 'Agent' },
				{ name: 'telesale_agent_team', title: 'Team' },
				{ name: 'lead_full_name', title: 'Khách hàng' },
				{ name: 'update_time', title: 'Ngày' },
				{ name: 'note', title: 'Ghi chú' },
			],
			rows: [],
			selection: [],
			tableColumnExtensions: [
				{ columnName: 'action', align: 'center', width: 150 },
				{ columnName: 'mc_code', align: 'center', width: 150 },
				{ columnName: 'product_name', align: 'center', width: 250 },
				{ columnName: 'product_code', align: 'center', width: 250 },
				{ columnName: 'quantity', align: 'right', width: 150 },
				{ columnName: 'selling_price', align: 'right', width: 150 },
				{ columnName: 'telesale_agent_fullname', align: 'center', width: 250 },
				{ columnName: 'telesale_agent_team', align: 'center', width: 150 },
				{ columnName: 'lead_full_name', align: 'center', width: 250 },
				{ columnName: 'update_time', align: 'center', width: 150 },
				{ columnName: 'update_by', align: 'center', width: 150 },
				{ columnName: 'note', align: 'center', width: 150 },
			],
			// pageSizes: [5, 10, 20, 30, 60],
			totalCount: 0,
			pageSize: 15,
			currentPage: 0,
			loading: true,
			sorting: [{ columnName: 'lead_created_at_unix', direction: 'desc' }],
			leaders: [],
			search_agent_name: '',
			search_lead_name: '',
			search_team_name: '',
			search_product_name: '',
		};

		this.changeCurrentPage = this.changeCurrentPage.bind(this);
		this.changePageSize = this.changePageSize.bind(this);
		this.loadLeader();

		this.loadData();
	}

	loadLeader() {
		let queryString = `${leaderURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					this.setState({
						leaders: data.data,
					});
				} else {
					this.setState({
						leaders: [],
					});

					if (
						data &&
						(data.code == cs.erAuthenticationFailed.code ||
							data.code == cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					leaders: [],
				});
			});
	}

	changeCurrentPage(currentPage) {
		this.setState(
			{
				loading: true,
				currentPage: currentPage,
			},
			() => {
				this.loadData();
			}
		);
	}

	changePageSize(pageSize) {
		this.setState(
			{
				loading: true,
				pageSize: pageSize,
				currentPage: 0,
			},
			() => {
				this.loadData();
			}
		);
	}

	queryString() {
		const {
			pageSize,
			currentPage,
			sorting,

			search_agent_name,
			search_lead_name,
			search_team_name,
			search_product_name,
		} = this.state;

		let queryString = `${URL}?pagesize=${pageSize}&page=${currentPage + 1}`;

		const columnSorting = sorting[0];

		if (search_agent_name && search_agent_name !== '')
			queryString = `${queryString}&telesale_agent_fullname=${search_agent_name}`;
		if (search_lead_name && search_lead_name !== '')
			queryString = `${queryString}&lead_full_name=${search_lead_name}`;
		if (search_team_name && search_team_name !== '')
			queryString = `${queryString}&telesale_agent_team=${search_team_name}`;

		if (search_product_name && search_product_name !== '')
			queryString = `${queryString}&product_name=${search_product_name}`;

		// if (Number.isInteger(search_active) && search_active >= 0) queryString = `${queryString}&active=${search_active}`;

		// queryString = `${queryString}&deleted=${search_deleted ? 1 : 0}`
		return queryString;
	}

	loadData = (conditions) => {
		const queryString = this.queryString();

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					for (var i = 0; i < data.data.length; i++) {
						data.data[i] = formatValue(data.data[i]);
					}

					this.setState({
						rows: data.data,
						totalCount: data.total_count,
						loading: false,
					});
				} else {
					this.setState({
						rows: [],
						totalCount: 0,
						loading: false,
					});

					// prevent unauthorized access
					if (
						data &&
						(data.code === cs.erAuthenticationFailed.code ||
							data.code === cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	};

	handleChange = (name) => (event) => {
		if (name == 'search_deleted') {
			this.setState(
				{
					[name]: event.target.checked,
				},
				() => {
					this.setState({ currentPage: 0 });
					this.loadData();
				}
			);
		} else {
			this.setState(
				{
					[name]: event.target.value,
				},
				() => {
					this.setState({ currentPage: 0 });
					this.loadData();
				}
			);
		}
	};

	render() {
		const { classes } = this.props;

		// get value from state
		const {
			rows,
			columns,
			tableColumnExtensions,
			pageSizes,
			pageSize,
			currentPage,
			totalCount,
			loading,
			sorting,
		} = this.state;

		return (
			<div>
				<ExpansionPanel>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Typography className={classes.heading}>Tìm kiếm ...</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<form
							className={classes.search_container}
							noValidate
							autoComplete="off"
						>
							<TextField
								id="agent_name"
								label="Agent"
								type="search"
								className={classes.search_field}
								value={this.state.search_agent_name}
								onChange={this.handleChange('search_agent_name')}
								margin="normal"
							/>

							<TextField
								id="search_lead_name"
								label="Lead"
								type="search"
								className={classes.search_field}
								value={this.state.search_lead_name}
								onChange={this.handleChange('search_lead_name')}
								margin="normal"
							/>

							{/* <TextField
                                id="search_team_name"
                                label="Team"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_team_name}
                                onChange={this.handleChange('search_team_name')}
                                margin="normal"
                            /> */}
							{isTelesale_Merchant_Manager() && (
								<FormControl className={classes.search_field}>
									<InputLabel htmlFor="leader_name">Team</InputLabel>
									<Select
										value={this.state.search_team_name}
										onChange={this.handleChange('search_team_name')}
										inputProps={{
											name: 'leader_name',
											id: 'id',
										}}
									>
										<MenuItem value=""></MenuItem>
										{this.state.leaders.map((item) => (
											<MenuItem value={item.name}>{item.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							)}

							<TextField
								id="search_product_name"
								label="Sản phẩm"
								type="search"
								className={classes.search_field}
								value={this.state.search_product_name}
								onChange={this.handleChange('search_product_name')}
								margin="normal"
							/>
						</form>
					</ExpansionPanelDetails>
				</ExpansionPanel>

				<Button color="inherit" aria-label="Refresh" onClick={this.loadData}>
					<Icon>refresh</Icon>
					<span style={{ marginLeft: 10 }}>Làm mới</span>
				</Button>

				<DataTable
					rows={rows}
					columns={columns}
					columnWidths={tableColumnExtensions}
					pageSizes={pageSizes}
					pageSize={pageSize}
					currentPage={currentPage}
					loading={loading}
					sorting={sorting}
					totalCount={totalCount}
					changeCurrentPage={this.changeCurrentPage}
					changePageSize={this.changePageSize}
					changeSorting={this.changeSorting}
					CellComponent={CellComponent}
					autoLoadData={this.loadData}
				/>

			</div>
		);
	}
}

InventoryOutputList.propTypes = {
	classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
	grid_Container: {
		width: '100%',
		marginTop: theme.spacing(2),
		overflowX: 'auto',
	},
	tableStriped: {
		'& tbody tr:nth-of-type(odd)': {
			backgroundColor: 'rgb(186, 207, 255)',
		},
		'& tbody tr:nth-of-type(even)': {
			backgroundColor: 'rgb(217, 226, 244)',
		},
	},
	searchPanel: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	search_container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	search_field: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: 200,
		minWidth: 120,
	},
	search_select_menu: {
		width: 200,
	},
	grid_header: {
		backgroundColor: green,
	},
	gender_button: {
		marginTop: 20,
		marginLeft: 40,
	},
});

function ConfirmDialog(props) {
	const handleSubmit = () => {
		console.log(props.inventory_id);
		const data = {
			id: props.inventory_id,
		};
		fetch(approveURL, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (
					data &&
					(data.code == cs.erAuthenticationFailed.code ||
						data.code == cs.erAccountIsLocked.code)
				) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}
				if (data && data.error && data.code != cs.Succeed.code) {
					alert(data.error);
				} else {
					window.location.reload();
				}
			})
			.catch(() => { });
	};

	return (
		<>
			<Dialog
				open={props.dialog_open}
				aria-labelledby="draggable-dialog-title"
				onClose={() => props.handleClose()}
			>
				<DialogTitle>Xác nhận</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Xác nhận telesale agent {props.telesale_agent_fullname} đã bán{' '}
						<b>{props.quantity}</b> sản phẩm {props.product_name} với giá{' '}
						{props.selling_price} đồng!
          </DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => props.handleClose()}
						autoFocus
						color="secondary"
					>
						Hủy
          </Button>
					<Button onClick={handleSubmit} color="primary">
						Xác nhận
          </Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

class ActionCell extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			inventory_id: '',
			dialog_open: false,
		};
	}

	handleOpen = () => {
		this.setState({
			inventory_id: this.props.row.id,
			dialog_open: true,
		});
	};

	handleClose = () => {
		this.setState({
			inventory_id: null,
			dialog_open: false,
		});
	};

	render() {
		return (
			<>
				<span>
					<Checkbox
						checked={this.props.row.is_verified}
						name="checkedB"
						color="primary"
						onChange={!this.props.row.is_verified && this.handleOpen}
						disabled={this.props.row.is_verified}
					/>
				</span>
				<ConfirmDialog
					handleClose={this.handleClose}
					dialog_open={this.state.dialog_open}
					inventory_id={this.state.inventory_id}
					product_name={this.props.row.product_name}
					telesale_agent_fullname={this.props.row.telesale_agent_fullname}
					selling_price={this.props.row.selling_price}
					quantity={this.props.row.quantity}
				/>
			</>
		);
	}
}

const CellComponent = (props) => {
	const { column } = props;
	if (
		column.name === 'action' &&
		getRole() == cs.Role_Mc_Board_Of_Control_Member
	) {
		return (
			<Table.Cell
				{...props}
				style={{
					display: 'flex',
					padding: 1,
					justifyContent: 'center',
					fontSize: '12px',
				}}
			>
				<ActionCell {...props} />
			</Table.Cell>
		);
	}

	return (
		<Table.Cell
			{...props}
			style={{
				padding: 2,
				// color: '#81557a',
				fontSize: '12px',
			}}
		/>
	);
};
export default withStyles(styles)(InventoryOutputList);
