import React, { Component } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';

export default class Container extends Component {
	constructor(props) {
		super(props);
		this.state = { data: props.data };
	}

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.data != this.state.data) {
			this.setState({ data: nextProps.data });
		}
	};

	shouldComponentUpdate = (nextProps) => {
		return nextProps.data != this.state.data;
	};

	render() {
		const { data, ...rest } = this.props;
		return (
			<DropdownTreeSelect
				data={this.state.data}
				{...rest}
				showDropdown="always"
			/>
		);
	}
}
