import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { green } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import AssignTeam from './AssignTeam.js';
import DataTable from 'components/shared/DataTable.jsx';
import { useStates, useDistricts, useWards } from 'hooks/address';
import { useAgentLead } from 'hooks/leadAgent';
import { pageSizes } from 'utils/datatable.js';
import moment from 'moment';

const tableConfig = {
	columns: [
		{ name: 'action', title: 'Thao tác' },
		{ name: 'name', title: 'Họ và tên' },
		{ name: 'branch_name', title: 'Tên chi nhánh' },
		{ name: 'team_name', title: 'Trưởng phòng' },
		{ name: 'gender', title: 'Giới tính' },
		{ name: 'phone', title: 'Số điện thoại' },
		{ name: 'birthday', title: 'Ngày sinh' },
		{ name: 'adr_state', title: 'Tỉnh / Thành phố' },
		{ name: 'adr_district', title: 'Huyện / Quận' },
		{ name: 'adr_ward', title: 'Xã' },
		{ name: 'has_logged_in', title: 'Đã login' },
	],
	tableColumnExtensions: [
		{ columnName: 'action', align: 'center', width: 200 },
		{ columnName: 'name', align: 'center', width: 200 },
		{ columnName: 'branch_name', align: 'center', width: 200 },
		{ columnName: 'team_name', align: 'center', width: 200 },
		{ columnName: 'gender', align: 'center', width: 150 },
		{ columnName: 'phone', align: 'center', width: 200 },
		{ columnName: 'birthday', align: 'center', width: 150 },
		{ columnName: 'adr_state', align: 'center', width: 200 },
		{ columnName: 'adr_district', align: 'center', width: 200 },
		{ columnName: 'adr_ward', align: 'center', width: 200 },
		{ columnName: 'has_logged_in', align: 'center', width: 200 },
	],
};

const ManagingAgent = ({ classes }) => {
	const [params, setParams] = useState({
		pagesize: 15,
		page: 1,
		name: '',
		code: '',
		phone: '',
		adr_state_code: '',
		adr_district_code: '',
		adr_ward_code: '',
		team_code: '',
		team_name: '',
	});
	const { data: states } = useStates({});
	const { data: districts } = useDistricts({ state_code: '' });
	const { data: wards } = useWards({ district_code: '' });
	const { data, isLoading, refetch } = useAgentLead(params);

	return (
		<div>
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography className={classes.heading}>Tìm kiếm ...</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<form
						className={classes.search_container}
						noValidate
						autoComplete="off"
					>
						<TextField
							id="agent_name"
							label="Họ & Tên"
							type="search"
							className={classes.search_field}
							value={params.name}
							onChange={(e) => setParams({ ...params, name: e.target.value })}
							margin="normal"
						/>

						<TextField
							id="agent_code"
							label="Code"
							type="search"
							className={classes.search_field}
							value={params.code}
							onChange={(e) => setParams({ ...params, code: e.target.value })}
							margin="normal"
						/>

						<TextField
							id="agent_phone"
							label="Số điện thoại"
							type="search"
							className={classes.search_field}
							value={params.phone}
							onChange={(e) => setParams({ ...params, phone: e.target.value })}
							margin="normal"
						/>

						<FormControl className={classes.search_field}>
							<InputLabel htmlFor="search_adr_state">
								Tỉnh/thành phố (*)
              </InputLabel>
							<Select
								value={params.adr_state_code}
								onChange={(e) =>
									setParams({ ...params, adr_state_code: e.target.value })
								}
								inputProps={{
									name: 'adr_state_code',
									id: 'adr_state_id',
								}}
							>
								{states?.data?.map((pro) => (
									<MenuItem value={pro.state_code}>{pro.state_name}</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl className={classes.search_field}>
							<InputLabel htmlFor="search_adr_district">
								Quận / huyện (*)
              </InputLabel>
							<Select
								value={params.adr_district_code}
								onChange={(e) =>
									setParams({ ...params, adr_district_code: e.target.value })
								}
								inputProps={{
									name: 'adr_district_code',
									id: 'adr_district_id',
								}}
							>
								{districts?.data?.map((pro) => (
									<MenuItem value={pro.district_code}>
										{pro.district_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl className={classes.search_field}>
							<InputLabel htmlFor="search_adr_ward">Xã/Phường</InputLabel>
							<Select
								value={params.adr_ward_code}
								onChange={(e) =>
									setParams({ ...params, adr_ward_code: e.target.value })
								}
								inputProps={{
									name: 'adr_ward_code',
									id: 'adr_ward_id',
								}}
							>
								{wards?.data?.map((pro) => (
									<MenuItem value={pro.ward_code}>{pro.ward_name}</MenuItem>
								))}
							</Select>
						</FormControl>

						<TextField
							id="team_name"
							label="Tên trưởng phòng"
							type="search"
							className={classes.search_field}
							value={params.team_name}
							onChange={(e) =>
								setParams({ ...params, team_name: e.target.value })
							}
							margin="normal"
						/>

						<TextField
							id="team_code"
							label="Mã trưởng phòng"
							type="search"
							className={classes.search_field}
							value={params.team_code}
							onChange={(e) =>
								setParams({ ...params, team_code: e.target.value })
							}
							margin="normal"
						/>
					</form>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<Button color="inherit" aria-label="Refresh" onClick={refetch}>
				<Icon>refresh</Icon>
				<span style={{ marginLeft: 10 }}>Làm mới</span>
			</Button>

			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.tableColumnExtensions}
				pageSizes={pageSizes}
				pageSize={params.pagesize}
				currentPage={params.page - 1}
				loading={isLoading}
				/* sorting={sorting} */
				totalCount={data?.total_count}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				changePageSize={(p) => setParams({ ...params, pagesize: p })}
				CellComponent={CellComponent}
			/>

			<Route exact path="/managing-agent/assign/:id" component={AssignTeam} />
		</div>
	);
};

ManagingAgent.propTypes = {
	classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
	grid_Container: {
		width: '100%',
		marginTop: theme.spacing(2),
		overflowX: 'auto',
	},
	tableStriped: {
		'& tbody tr:nth-of-type(odd)': {
			backgroundColor: 'rgb(186, 207, 255)',
		},
		'& tbody tr:nth-of-type(even)': {
			backgroundColor: 'rgb(217, 226, 244)',
		},
	},
	searchPanel: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	search_container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	search_field: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: 200,
		minWidth: 120,
	},
	search_select_menu: {
		width: 200,
	},
	grid_header: {
		backgroundColor: green,
	},
});

const ActionCell = (props) => {
	return (
		<Table.Cell
			style={{
				padding: 1,
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					fontSize: '12px',
				}}
			>
				<Button
					color="primary"
					component={Link}
					to={{
						pathname: '/managing-agent/assign/' + props.row.id,
						state: { previous: '/managing-agent' },
					}}
				>
					Phân bổ nhân viên
        </Button>
			</div>
		</Table.Cell>
	);
};

const CellComponent = (props) => {
	const { column, row } = props;
	switch (column.name) {
		case 'action':
			return <ActionCell {...props} />;
		case 'phone':
			return (
				<Table.Cell>{row.phone?.replace(/\d(?!\d{0,3}$)/g, '*')}</Table.Cell>
			);
		case 'gender':
			return <Table.Cell> {row.gender === 0 ? 'Nam' : 'Nữ'}</Table.Cell>;

		case 'has_logged_in':
			return <Table.Cell>{row.has_logged_in ? 'Đã login' : 'Chưa'}</Table.Cell>;
				case 'birthday':
			return (
				<Table.Cell> {moment(row.birthday * 1000).format('DD/MM/YYYY')}</Table.Cell>
			);

		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						// color: '#81557a',
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default withRouter(withStyles(styles)(ManagingAgent));
