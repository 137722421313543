import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import DataTable from 'components/shared/DataTable';
import { pageSizes } from 'utils/datatable';
import { useLeader, useMerchantLeader } from 'hooks/lead';
import {
	Box,
	DialogTitle,
	DialogContent,
	Dialog,
	DialogActions,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Button,
} from '@material-ui/core';
import { useAssignLeader } from './services';

const tableConfig = {
	columns: [
		{ name: 'leader', title: 'Trưởng phòng' },
		{ name: 'leads', title: 'Số lượng lead chưa phân bổ' },
	],
	tableColumnExtensions: [
		{ columnName: 'leader', align: 'center', width: 500 },
		{ columnName: 'leads', align: 'center', width: 500 },
	],
};
const AssignLeadsToLeader = () => {
	const [params, setParams] = useState({
		page: 0,
		pagesize: 15,
	});
	const [bodyParams, setBodyParams] = useState({
		number: 0,
		leader_id: '',
	});
	const [open, setOpen] = useState(false);
	const { data, isLoading, refetch } = useLeader(params);
	const { data: merchantLeader } = useMerchantLeader({});

	const { mutateAsync: assignLeader } = useAssignLeader();
	const handleAssignLeader = () => {
		if (parseInt(bodyParams.number) === 0) {
			window.alert('Vui lòng nhập số lượng hồ sơ cần phân bổ cho trưởng phòng');
			return;
		} else if (bodyParams.leader == '') {
			window.alert('Vui lòng chọn trưởng phòng');
			return;
		} else
			assignLeader(bodyParams).then(() => {
				setOpen(false);
			});
	};

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box>
					<Button color="primary" onClick={() => setOpen(true)}>
						Phân bổ lead cho trưởng phòng
					</Button>
					<Button onClick={refetch}>Refetch</Button>
				</Box>
			</div>

			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.tableColumnExtensions}
				pageSizes={pageSizes}
				pageSize={params.pagesize}
				currentPage={params.page - 1}
				loading={isLoading}
				/* sorting={sorting} */
				totalCount={data?.total_count}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				changePageSize={(p) => setParams({ ...params, pagesize: p })}
				CellComponent={CellComponent}
			/>

			<Dialog
				open={open}
				fullWidth
				maxWidth="sm"
				onClose={() => setOpen(false)}
			>
				<DialogTitle id="from-dialog-title">
					Phân bổ lead về cho trưởng phòng
				</DialogTitle>
				<DialogContent>
					<h2 style={{ textAlign: 'center' }}>Số lượng Lead chưa phân bổ: </h2>
					<div>
						<FormControl fullWidth>
							<InputLabel htmlFor="leader">Trưởng phòng</InputLabel>
							<Select
								value={bodyParams.leader_id}
								onChange={(e) =>
									setBodyParams({ ...bodyParams, leader_id: e.target.value })
								}
								inputProps={{
									name: 'leader',
									id: 'leader',
								}}
							>
								{merchantLeader?.data.map((item) => (
									<MenuItem value={item.id}>{item.fullname}</MenuItem>
								))}
							</Select>
						</FormControl>
						<TextField
							fullWidth
							autoFocus
							margin="dense"
							id="number"
							required={true}
							value={bodyParams.number}
							onChange={(e) =>
								e.target.value !== '' &&
								setBodyParams({
									...bodyParams,
									number: parseInt(e.target.value),
								})
							}
							label="Số lượng lead"
							type="number"
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)} color="primary">
						Hủy
					</Button>
					<Button onClick={handleAssignLeader} color="primary">
						Phân bổ
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

AssignLeadsToLeader.propTypes = {
	classes: PropTypes.object.isRequired,
};

const CellComponent = (props) => {
	return (
		<Table.Cell
			{...props}
			style={{
				padding: 2,
				fontSize: '12px',
			}}
		/>
	);
};

export default withRouter(AssignLeadsToLeader);
