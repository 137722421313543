import React, { useState, useEffect } from 'react';
import GetApp from '@material-ui/icons/GetApp';
import {
	MenuItem,
	Select,
	InputLabel,
	TextField,
	Button,
	Dialog,
	Icon,
	Box,
	FormGroup,
} from '@material-ui/core';
import moment from 'moment';
import TooltipHelper from './ToolTipHelper';
import { useBranch } from 'hooks/lead';
import Export from './Export';
import { useProduct } from 'hooks/products';
import { useTeam } from 'hooks/lead';
import { useLevels } from 'hooks/level';
import Table from './Table';
import FormSearchMerchantLevel from './FormSearchMerchantLevel';
import { returnDropDown } from '../helperForHlm';
import './style.css';
import { useSearchBatchId } from './services';

const noteList = [
	{ title: 'Tốt', color: '#57be38' },
	{ title: 'Cố lên', color: '#ffeb3b' },
	{ title: 'Cảnh báo', color: '#f44336' },
];

const ReportSummary = () => {
	const { data: levels, isLoading: levelLoading } = useLevels();
	const [url, setUrl] = useState('realtime-summary-agent');
	const [params, setParams] = useState({
		new: true,
		product_code: 'ALL',

		report_date_from: moment()
			.subtract(14, 'day')
			.startOf('day')
			.format('YYYY-MM-DD hh:mm'),
		report_date_to: moment().endOf('day').format('YYYY-MM-DD hh:mm'),
		lead_update_date_from: moment()
			.subtract(14, 'day')
			.startOf('day')
			.format('YYYY-MM-DD hh:mm'),
		lead_update_date_to: moment().endOf('day').format('YYYY-MM-DD hh:mm'),
		batch_id: 'ALL',
		team_name: 'ALL',
		branch_name: 'ALL',
		...levels?.data
			?.sort((a, b) => a.level_id - b.level_id)
			.slice(1, -1)
			.filter((item) => item.level_id <= url.split('=')[1])
			.map((item) => {
				return { ['sp_name_lv' + item.level_id]: 'ALL' };
			}),
	});
	const [types, setTypes] = useState([]);
	const [selectedDropDownIndex, setSelectedDropDownIndex] = useState(0);
	const [openDialogExport, setOpenDialogExport] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);

	const [search, setSearch] = useState(true);

	const { data: products } = useProduct({}, 'merchant-product');
	const { data: branchs } = useBranch({});
	const { data: teams } = useTeam({
		branch_name: params.branch_name !== 'ALL' ? params.branch_name : '',
	});
	const { data: batchData } = useSearchBatchId();

	useEffect(() => {
		setTypes(returnDropDown({ level: levels?.data, type: 'realtime' }));
	}, [levels]);

	useEffect(() => {
		if (url === 'realtime-summary-branch') {
			setParams({ ...params, team_name: '', branch_name: '' });
		} else if (url === 'realtime-summary-team') {
			setParams({ ...params, branch_name: '' });
		}
	}, [url]);

	const handleChange = (name) => (event) => {
		setParams({
			...params,
			[name]: event.target.value,
		});
	};

	return (
		<div className="report-summary">
			<Box margin="0 auto" marginTop="40px">
				<form className="report-summary-form-search">
					<div className="lead-form-datetime">
						<span
							onClick={() => setSearch(!search)}
							className={`${search && 'active-search'} action-search`}
						>
							Ngày phân bổ:
						</span>
						<TextField
							label="Từ ngày"
							value={params.report_date_from}
							onChange={handleChange('report_date_from')}
							type="datetime-local"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!search}
						/>

						<TextField
							label="Đến ngày"
							value={params.report_date_to}
							onChange={handleChange('report_date_to')}
							type="datetime-local"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!search}
						/>
					</div>
					<div className="lead-form-datetime">
						<span
							onClick={() => setSearch(!search)}
							className={`${!search && 'active-search'} action-search`}
						>
							Ngày cập nhật trạng thái:
						</span>
						<TextField
							label="Từ ngày"
							value={params.lead_update_date_from}
							onChange={handleChange('lead_update_date_from')}
							type="datetime-local"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={search}
						/>

						<TextField
							label="Đến ngày"
							value={params.lead_update_date_to}
							onChange={handleChange('lead_update_date_to')}
							type="datetime-local"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={search}
						/>
					</div>

					<FormGroup>
						<InputLabel htmlFor="url">Loại</InputLabel>
						<Select
							value={url}
							onChange={(e) => {
								setUrl(e.target.value);
								setSelectedDropDownIndex(parseInt(e.nativeEvent.target.id));
							}}
						>
							{types.map((item, index) => (
								<MenuItem
									value={item.url}
									name={item.name}
									key={item.name}
									id={index}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
					<FormGroup>
						<InputLabel htmlFor="product_code">Sản phẩm</InputLabel>
						<Select
							value={params.product_code}
							onChange={(e) =>
								setParams({
									...params,
									product_code: e.target.value,
									page: 1,
								})
							}
							inputProps={{
								name: 'name',
								id: 'code',
							}}
						>
							<MenuItem value="ALL">Tất cả</MenuItem>
							{products?.data?.map((pro) => (
								<MenuItem value={pro.code} key={pro.code}>
									{pro.code && <div>{pro.name}</div>}
									{!pro.code && <div>{pro.name}</div>}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
					{(url === 'realtime-summary-agent' ||
						url === 'realtime-summary-team') && (
						<FormGroup>
							<InputLabel id="">Phòng (AD)</InputLabel>
							<Select
								labelId=""
								id="demo-simple-select"
								value={params.team_name}
								label="Phòng (AD)"
								onChange={(e) =>
									setParams({ ...params, team_name: e.target.value, page: 1 })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{teams?.data?.map((item, index) => (
									<MenuItem key={index} value={item.fullname}>
										{item.fullname}
									</MenuItem>
								))}
							</Select>
						</FormGroup>
					)}
					{(url === 'realtime-summary-agent' ||
						url === 'realtime-summary-branch' ||
						url === 'realtime-summary-team') && (
						<FormGroup>
							<InputLabel id="">
								Chi nhánh (SD)
							</InputLabel>
							<Select
								labelId=""
								id="demo-simple-select"
								value={params.branch_name}
								label="Chi nhánh (SD)"
								onChange={(e) =>
									setParams({
										...params,
										branch_name: e.target.value,
										page: 1,
									})
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{branchs?.data?.map((item, index) => (
									<MenuItem value={item.fullname} key={index}>
										{item.fullname}
									</MenuItem>
								))}
							</Select>
						</FormGroup>
					)}

					{levels?.data
						?.sort((a, b) => a.level_id - b.level_id)
						.slice(1, -1)
						.filter(
							(item) =>
								item.level_id <= url.split('=')[1] ||
								url === 'realtime-summary-agent' ||
								url === 'realtime-summary-team' ||
								url === 'realtime-summary-branch'
						)
						.map((item, index) => (
							<FormSearchMerchantLevel
								level={item.level_id}
								params={params}
								setParams={setParams}
								title={item.level_name}
								key={index}
							/>
						))}
						<FormGroup>
							<InputLabel id="">
								Batch id
							</InputLabel>
							<Select
								labelId=""
								value={params.batch_id}
								label="Batch id"
								onChange={(e) =>
									setParams({
										...params,
										batch_id: e.target.value,
										page: 1,
									})
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{batchData?.data?.map((item, index) => (
									<MenuItem value={item.id} key={index}>
										{item.batch_name}
									</MenuItem>
								))}
							</Select>
						</FormGroup>
				</form>
			</Box>
			<Box display="flex" justifyContent="end" margin="5px 0" alignItems="end">
				<Button onClick={() => setOpenTooltip(true)}>
					<Icon color="primary">help</Icon>
				</Button>
				<table
					style={{
						borderCollapse: 'collapse',
						borderBottom: '1px solid #222',
					}}
				>
					<caption style={{ fontWeight: 'bold' }}>Chú thích màu</caption>
					<thead style={{ border: '1px solid #222' }}>
						<tr>
							<td style={{ fontWeight: 'bold' }}>Màu</td>
							<td style={{ fontWeight: 'bold' }}>Mô tả</td>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid #222' }}>
						{noteList.map((item, index) => (
							<tr key={index} style={{ borderBottom: '1px solid #222' }}>
								<td style={{ padding: '2px 5px' }}>{item.title}</td>
								<td
									style={{ backgroundColor: item.color, padding: '2px 5px' }}
								></td>
							</tr>
						))}
					</tbody>
				</table>
			</Box>
			{!levelLoading &&
				types
					.slice(0, 1 + selectedDropDownIndex)
					.reverse()
					.map((item, index) => (
						<Table
							url={item.url}
							levels={levels}
							params={params}
							setParams={setParams}
							title={item.name}
							key={index}
							index={index}
							search={search}
						/>
					))}
			<div
				style={{
					position: 'fixed',
					bottom: '40px',
					right: '30px',
					zIndex: 501,
					backgroundColor: '#fff',
					borderRadius: '50%',
					width: '60px',
					height: '60px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px',
					cursor: 'pointer',
				}}
				onClick={() => setOpenDialogExport(true)}
			>
				<GetApp color="primary" />
			</div>
			<Dialog
				open={openDialogExport}
				onClose={() => setOpenDialogExport(false)}
			>
				<Export
					onClose={() => setOpenDialogExport(false)}
					params={params}
					type={url}
					search={search}
				/>
			</Dialog>
			<Dialog
				open={openTooltip}
				onClose={() => setOpenTooltip(false)}
				maxWidth="lg"
			>
				<TooltipHelper />
			</Dialog>
		</div>
	);
};

export default ReportSummary;
