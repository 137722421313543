import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from 'const.js';
const createPackageURL = cs.BaseURL + '/telesale/package/info/create';
const styles = theme => ({});
class CreatePackage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			package_name: '',
			package_type: '',
			package_types: ["analysis", "marketing", "marketing"],
			amount: 0
		};


		this.handleDialogAgree = this.handleDialogAgree.bind(this);

		//this.loadMerchants();
	}

	handleDialogAgree = () => {

		if (!this.state.package_name || !this.state.package_type || !this.state.amount) {
			window.alert('Please input information !');
			return;
		}

		if (this.state.amount && isNaN(this.state.amount)) {
			window.alert('Incorrect amount !');
			return;
		}
		if (this.state.amount < 0) {
			window.alert('Amount number must be positive!');
			return;
		}
		let queryString = `${createPackageURL}`;
		let requestBody = {

			package_name: this.state.package_name,
			package_type: this.state.package_type,
			amount: this.state.amount,

		};

		fetch(queryString, {
			method: 'POST',
			body: JSON.stringify(requestBody),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}

				if (data && data.error && data.code != cs.Succeed) {
					this.props.toastManager.add(JSON.stringify(data.error), {
						appearance: 'error',
						autoDismiss: true,
						pauseOnHover: true
					});
				} else {
					this.props.toastManager.add('Product is created !', {
						appearance: 'success',
						autoDismiss: true,
						pauseOnHover: true
					});
					window.location.href = "/package-info";

				}
			})
			.catch(() => { });
	};

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};



	render() {
		const { classes } = this.props;

		return (
			<div>
				<Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
					<DialogTitle id="create_merchant_form-dialog-title">Create Package</DialogTitle>
					<DialogContent>
						<FormControl fullWidth>
							<InputLabel htmlFor="merchantId">Package Type (*)</InputLabel>
							<Select
								value={this.state.package_type}
								onChange={this.handleChange('package_type')}
								inputProps={{
									name: 'package_type',
									id: 'package_type'
								}}
							>
								{this.state.package_types.map((item, index)=> (
									<MenuItem value={item} key={index}>{item}</MenuItem>
								))}
							</Select>
						</FormControl>
						<TextField
							fullWidth
							autoFocus
							margin="dense"
							id="campaign_name"
							required={true}
							value={this.state.package_name}
							onChange={this.handleChange('package_name')}
							label="Package name "
							type="name"
						/>

						<TextField
							fullWidth
							autoFocus
							margin="dense"
							id="amount"
							required={true}
							value={this.state.amount}
							onChange={this.handleChange('amount')}
							label="amount"
							type="number"
							InputProps={{ inputProps: { min: 0 } }}
						/>
						<Grid
							container
							direction="column"
							justify="space-between"
							alignItems="stretch"
							spacing={1}
							className={classes.gridContainer}
						>
							<Grid item>
								<div></div>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.history.goBack} color="primary">
							Cancel
                        </Button>
						<Button onClick={this.handleDialogAgree} color="primary">
							Create New
                        </Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

CreatePackage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreatePackage);
