import { useQuery } from '@tanstack/react-query';
import newsApi from 'api/news.api';

export const useNews = (params) => {
	return useQuery(['news', params], () => newsApi.newList(params));
};

export const useNewsLeader = (params) => {
	return useQuery(['newsLeader', params], () => newsApi.newLeaders(params));
};

export const useNewsProduct = (params) => {
	return useQuery(['newsProduct', params], () => newsApi.newsProducts(params));
};
