import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import cs from "const.js";

const URL = cs.BaseURL + '/telesale/lead/agent-product/list';
const deleteAgentProductURL = cs.BaseURL + "/telesale/lead/agent-product/delete";

class DeleteAgentProduct extends React.Component {
    state = {
        confirm_code: "",
        agent_name: "",
        mc_product_name: ""
    };

    componentDidMount() {
        this.loadData();
    }

    handleDialogAgree = () => {
        if (!this.props.match.params.id) {
            window.alert("Wrong information !");
            this.props.history.push({
                pathname: '/agent-product',
                state: { reload: true }
            });
            return;
        }

        var id = this.props.match.params.id;
        let queryString = `${deleteAgentProductURL}`;

        fetch(queryString, {
            method: "POST",
            body: JSON.stringify({
                id: id
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem(cs.System_Code + "-general-token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code) ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: "error",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add("Package info is deleted !", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/agent-product',
                    state: { reload: true }
                });
            })
            .catch(() => { });
    };

    loadData() {
        const queryString = `${URL}?id=${this.props.match.params.id}`;
        fetch(queryString, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: localStorage.getItem(cs.System_Code + "-general-token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.data);
                if (data && data.data[0]) {
                    let tmp = data.data[0];
                    this.setState({
                        agent_id: tmp.agent_id,
                        agent_name: tmp.agent_name,
                        agent_phone: tmp.agent_phone,
                        agent_mc_ref_code: tmp.agent_mc_ref_code,
                        mc_product_name: tmp.mc_product_name,
                        mc_product_code: tmp.mc_product_code,
                        max_order_qty: tmp.max_order_qty,
                    });

                    console.log("state", this.state)
                } else {
                    this.setState({
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleFieldChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => { }
        );
    };
    render() {
        return (
            <div>
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Bạn có muốn xóa thông tin agent theo sản phẩm này không?`}
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            onClick={this.props.history.goBack}
                            color="primary"
                        >
                            Hủy
                        </Button>
                        <Button
                            onClick={this.handleDialogAgree}
                            color="primary"
                            autoFocus
                        >
                            Xóa
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


export default DeleteAgentProduct;
