import { authAxiosV2 } from 'utils/api';
import { useQuery, useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { toast } from 'react-toastify';

export const useSearchTask = (params, pathname) => {
	const regex = /agent/.test(pathname);
	return useQuery(
		[
			'task',
			params.page,
			params.size,
			params.sort_by,
			params.sort_type,
			params.type,
		],
		async () => {
			const fix = { ...params };
			for (const key in fix) {
				if (fix[key] === 'ALL') fix[key] = undefined;
			}
			if (fix.due_date)
				fix.due_date = moment(fix.due_date).format('YYYY/MM/DD')
			if (!regex) fix.assignee_is_agent = false;

			const { data } = await authAxiosV2({
				url: 'api/task/list',
				method: 'GET',
				params: fix,
			});
			return data;
		}
	);
};

export const useAssigne = ({ pathname }) => {
	const regex = /agent/.test(pathname);
	if (regex) {
		return useQuery(['task_assigne_agent'], async () => {
			const { data } = await authAxiosV2({
				url: 'api/task/assignee/agents',
				method: 'GET',
			});
			return data;
		});
	} else
		return useQuery(['task_assigne_agent'], async () => {
			const { data } = await authAxiosV2({
				url: 'api/task/assignee/users',
				method: 'GET',
			});
			return data;
		});
};

export const useTaskConfig = () => {
	return useQuery(['task_config'], async () => {
		const { data } = await authAxiosV2({
			url: 'api/task/config',
			method: 'GET',
		});
		return data;
	});
};

export const useTaskDetail = (params) => {
	return useQuery(['task_detail', params], async () => {
		const { data } = await authAxiosV2({
			url: 'api/task/detail',
			method: 'GET',
			params: params,
		});
		return data;
	});
};

export const useCreateTask = () => {
	return useMutation(
		async (params) => {
			const fix = { ...params };
			for (const key in fix) {
				if (fix[key] === 'ALL') fix[key] = undefined;
			}

			const { data } = await authAxiosV2({
				url: 'api/task/create',
				method: 'POST',
				data: fix,
			});
			return data;
		},
		{
			onSuccess: () => { },
			onError: () => { },
		}
	);
};

export const useEditTask = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxiosV2({
				url: 'api/task/update',
				method: 'PUT',
				data: params,
			});
			return data;
		},

		{
			onSuccess: (data) => {
				if (data.status === 0) {
					toast.success('Cập nhật task thành công');
				} else {
					toast.error('Có lỗi xảy ra');
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useComment = (params) => {
	return useQuery(['task-comment'], async () => {
		const { data } = await authAxiosV2({
			url: 'api/task/comments',
			method: 'GET',
			params: params,
		});
		return data;
	});
};

export const useCreateComment = () => {
	return useMutation(
		async (params) => {
			const fix = { ...params };
			for (const key in fix) {
				if (fix[key] === 'ALL') fix[key] = undefined;
			}

			const { data } = await authAxiosV2({
				url: 'api/task/comment/add',
				method: 'POST',
				data: fix,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.status === 0) {
					toast.success('Thêm comment thành công');
				} else {
					toast.error('Có lỗi xảy ra');
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};
export const useUpdateComment = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxiosV2({
				url: 'api/task/comment/update',
				method: 'PUT',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.status === 0) {
					toast.success('Chỉnh sửa comment thành công');
				} else {
					toast.error('Có lỗi xảy ra');
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useTaskActiviti = (params) => {
	return useQuery(['task-activiti'], async () => {
		const { data } = await authAxiosV2({
			url: 'api/task/activities/' + params.id,
			method: 'GET',
		});
		return data;
	});
};

export const useMonitors = (params) => {
	return useQuery(['task-monitor'], async () => {
		const { data } = await authAxiosV2({
			url: 'api/task/monitors/' + params.id,
			method: 'GET',
		});
		return data;
	});
};

export const usePeopleForAddMonitor = () => {
	return useQuery(['people-add-monitor'], async () => {
		const { data } = await authAxiosV2({
			url: '/api/task/monitor/people',
			method: 'GET',
		});
		return data;
	});
};

export const useAddMonitor = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxiosV2({
				url: 'api/task/monitor/add',
				method: 'POST',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.status === 0) {
					toast.success('Thêm người giám sát thành công');
				} else {
					toast.error('Có lỗi xảy ra');
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};
