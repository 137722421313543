import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import cs from 'const.js';
import DialogWrapper from 'components/shared/DialogWrapper';
import { FormControlLabel, FormLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const createAgentProductURL = cs.BaseURL + '/telesale/lead/agent-product/create';
const agentURL = cs.BaseURL + '/telesale/lead/agent/list';
const productURL = cs.BaseURL + '/telesale/lead/merchant-product/list';

class CreateAgentProduct extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			agent_id: '',
			agent_name: '',
			agent_phone: '',
			agent_mc_ref_code: '',
			mc_product_name: [],
			mc_product_code: [],
			max_order_qty: '',
			agents: [],
			products: []
		};

		this.handleDialogAgree = this.handleDialogAgree.bind(this);
		this.loadAgents();
		this.loadProducts();
		//this.loadMerchants();
	}

	loadAgents() {
		let queryString = `${agentURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					//data.data.unshift({ adr_state: '', adr_state_code: null });
					this.setState({
						agents: data.data
					});
				} else {
					this.setState({
						agents: []
					});
					if (
						data &&
						(data.code == cs.erAuthenticationFailed.code ||
							data.code == cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					agents: []
				});
			});
	}

	loadProducts() {
		let queryString = `${productURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					data.data.unshift({ adr_state: '', adr_state_code: null });
					this.setState({
						products: data.data
					});
				} else {
					this.setState({
						products: []
					});
					if (
						data &&
						(data.code == cs.erAuthenticationFailed.code ||
							data.code == cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					products: []
				});
			});
	}

	handleDialogAgree = () => {
		if (
			!this.state.agent_id ||
			!this.state.agent_name ||
			!this.state.agent_phone ||
			this.state.mc_product_name.length === 0 ||
			this.state.mc_product_code.length === 0 ||
			!this.state.max_order_qty
		) {
			window.alert('Please input information !');
			return;
		}

		if (this.state.max_order_qty && isNaN(this.state.max_order_qty)) {
			window.alert('Incorrect amount !');
			return;
		}

		if (this.state.max_order_qty < 0) {
			window.alert('Number must be positive!');
			return;
		}
		let queryString = `${createAgentProductURL}`;
		let requestBody = {
			agent_id: this.state.agent_id,
			agent_name: this.state.agent_name,
			agent_phone: this.state.agent_phone,
			agent_mc_ref_code: this.state.agent_mc_ref_code,
			mc_product_name_list: this.state.mc_product_name,
			mc_product_code_list: this.state.mc_product_code,
			max_order_qty: this.state.max_order_qty
		};

		fetch(queryString, {
			method: 'POST',
			body: JSON.stringify(requestBody),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then((response) => response.json())
			.then((data) => {
				if (
					data &&
					(data.code == cs.erAuthenticationFailed.code ||
						data.code == cs.erAccountIsLocked.code)
				) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}

				if (data && data.error && data.code != cs.Succeed) {
					this.props.toastManager.add(JSON.stringify(data.error), {
						appearance: 'error',
						autoDismiss: true,
						pauseOnHover: true
					});
				} else if (data.data.errors.length > 0) {
					this.props.toastManager.add(
						'Product ' + data.data.errors + ' had been assigned',
						{
							appearance: 'error',
							autoDismiss: true,
							pauseOnHover: true
						}
					);
					this.props.history.push({
						pathname: '/agent-product',
						state: { reload: true }
					});
				} else {
					this.props.toastManager.add('AgentProduct is created !', {
						appearance: 'success',
						autoDismiss: true,
						pauseOnHover: true
					});
					this.props.history.push({
						pathname: '/agent-product',
						state: { reload: true }
					});
					//                    window.location.href="/package-info;
				}
			})
			.catch(() => { });
	};

	selectionRenderer = (values) => {
		// change the default comma separated rendering
		return <span>{values.join(' + ')}</span>;
	};
	handleChange = (name) => (event) => {
		if (name == 'mc_product_code') {
			var val = this.state.mc_product_code;
			if (event.target.checked === true) {
				val.push(event.target.value);
			} else {
				var a = val.length;
				while (a) {
					if (val[--a] === event.target.value) {
						val.splice(a, 1);
					}
				}
			}
			var val2 = [];
			for (let i = 0; i < val.length; i++) {
				let product = this.state.products.filter((el) => el.code === val[i]);
				val2.push(product[0].name);
			}
			this.setState({
				mc_product_code: val,
				mc_product_name: val2
			});
		} else
			this.setState(
				{
					[name]: event.target.value
				},
				() => {
					if (name == 'adr_state_code') this.loadDistricts();
					if (name == 'adr_district_code') this.loadWards();
					//agent_id
					if (name == 'agent_id') {
						let agent = this.state.agents.filter((el) => el.id === event.target.value);
						this.setState({
							agent_phone: agent[0].phone,
							agent_name: agent[0].name
						});
					}
				}
			);
	};

	render() {
		return (
			<div>
				<DialogWrapper title={'Gán sản phẩm - Thêm mới'} {...this.props}>
					<DialogContent>
						<FormControl fullWidth>
							<Autocomplete
								options={this.state.agents}
								getOptionLabel={op => op.code ? op.code :"null"}
								renderInput={item => <TextField {...item} label="Agent (*)" />}
								onChange={(e, v) => this.setState({
									agent_phone: v.phone,
									agent_name: v.name,
									agent_id: v.id
								})}
							/>
						</FormControl>

						<TextField
							disabled
							fullWidth
							autoFocus
							margin="dense"
							id="agent_name"
							required={true}
							value={this.state.agent_name}
							onChange={this.handleChange('agent_name')}
							label="Tên"
							type="name"
						/>
						<TextField
							disabled
							fullWidth
							autoFocus
							margin="dense"
							id="agent_phone"
							required={true}
							value={this.state.agent_phone}
							onChange={this.handleChange('agent_phone')}
							label="SĐT"
							type="name"
						/>

						<FormControl fullWidth>
							<FormLabel>Mã sản phẩm (*)</FormLabel>
							{this.state.products.map((item) => {
								if (item.code) {
									return (
										<FormControlLabel
											value={item.code}
											control={<Checkbox color="primary" />}
											label={item.code}
											labelPlacement="end"
											onChange={this.handleChange('mc_product_code')}
										/>
									);
								}
							})}
						</FormControl>

						<TextField
							disabled
							fullWidth
							autoFocus
							margin="dense"
							id="mc_product_name"
							required={true}
							value={this.state.mc_product_name}
							onChange={this.handleChange('mc_product_name')}
							label="Tên sản phẩm"
							type="name"
						/>

						<TextField
							fullWidth
							autoFocus
							margin="dense"
							id="max_order_qty"
							required={true}
							value={this.state.max_order_qty}
							onChange={this.handleChange('max_order_qty')}
							label="Số lượng lead tối đa"
							type="number"
							InputProps={{ inputProps: { min: 0 } }}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.history.goBack} color="primary">
							Hủy
						</Button>
						<Button onClick={this.handleDialogAgree} color="primary">
							Tạo mới
						</Button>
					</DialogActions>
				</DialogWrapper>
			</div>
		);
	}
}


export default CreateAgentProduct;
