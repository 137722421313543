import React from 'react';
import {
	Dialog,
	Divider,
	Typography,
	IconButton,
	DialogTitle as MuiDialogTitle,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';

const DialogTitle = (props) => {
	const { children, onClose } = props;
	return (
		<MuiDialogTitle>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant="h6">{children}</Typography>
				{onClose ? (
					<IconButton aria-label="close" onClick={onClose}>
						<AiOutlineClose />
					</IconButton>
				) : null}
			</div>
		</MuiDialogTitle>
	);
};

const DialogWrapper = ({ children, title, width, fullScreen }) => {
	const history = useHistory();
	return (
		<Dialog
			onClose={() => history.goBack()}
			open={true}
			maxWidth={width || 'sm'}
			fullScreen={fullScreen || false}
			PaperProps={{ style: { width: '100%' } }}
		>
			{title && (
				<>
					<DialogTitle onClose={() => history.goBack()}>{title}</DialogTitle>
					<Divider />
				</>
			)}
			{children}
		</Dialog>
	);
};

export default DialogWrapper;
