import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useChangePassword, useLogout } from './services';

const ChangePassword = ({ open, onClose }) => {
	const [user, setUser] = useState({
		current_password: '',
		new_password: '',
		confirm_password: '',
	});

	const { mutateAsync } = useChangePassword();
	const { mutateAsync: logout } = useLogout();

	const handleDialogAgree = () => {
		if (
			!user.new_password.match(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
			)
		) {
			window.alert(
				'Mật khẩu phải bao gồm ít nhất 1 chữ cái viết hoa, 1 chữ cái viết thường, 1 số, 1 kí tự đặc biệt và độ dài từ 8 kí tự trở lên'
			);
			return;
		}

		if (user.new_password !== user.confirm_password) {
			window.alert('Mật khẩu chưa trùng khớp');
			return;
		}
		mutateAsync({
			new_password: user.new_password,
			current_password: user.current_password,
		}).then(() => onClose());
	};
	const handleLogOut = () => {
		logout();
	};

	return (
		<Dialog
			open={open}
			aria-labelledby="form-dialog-title"
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>Đổi mật khẩu</DialogTitle>
			<DialogContent>
				<TextField
					margin="dense"
					id="current_password"
					required={true}
					value={user.current_password}
					onChange={(e) =>
						setUser({ ...user, current_password: e.target.value })
					}
					label="Mật khẩu hiện tại"
					type="password"
					fullWidth
				/>
				<TextField
					margin="dense"
					id="new_password"
					required={true}
					value={user.new_password}
					onChange={(e) => setUser({ ...user, new_password: e.target.value })}
					label="Mật khẩu mới"
					type="password"
					fullWidth
				/>
				<TextField
					autoFocus
					margin="dense"
					id="confirm_password"
					required={true}
					value={user.confirm_password}
					onChange={(e) =>
						setUser({ ...user, confirm_password: e.target.value })
					}
					label="Xác nhận mật khẩu "
					type="password"
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleLogOut}>Đăng nhập tài khoản khác</Button>
				<Button onClick={handleDialogAgree} variant="contained" color="primary">
					Xác nhận
        </Button>
			</DialogActions>
		</Dialog>
	);
};

export default ChangePassword;
