import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { authAxios } from 'utils/api';
import moment from 'moment';
import fileDownload from 'js-file-download';

export const useLead = (params) => {
	const query = useQuery(
		[
			'lead',
			{ page: params.page, size: params.pagesize, status: params.status },
		],
		async () => {
			const fix = {};

			for (const key in params) {
				if (params[key] === 'ALL' || params[key] === 'all') fix[key] = '';
				else fix[key] = params[key];
			}
			if (fix.search) {
				fix.search_submitted_start = moment(
					new Date(fix.search_submitted_start)
				)
					.startOf('day')
					.unix()
					.toString();
				fix.search_submitted_end = moment(new Date(fix.search_submitted_end))
					.endOf('day')
					.unix()
					.toString();
				delete fix.lead_update_date_start;
				delete fix.lead_update_date_end;
			} else {
				fix.lead_update_date_start = moment(
					new Date(fix.lead_update_date_start)
				)
					.startOf('day')
					.unix()
					.toString();
				fix.lead_update_date_end = moment(new Date(fix.lead_update_date_end))
					.endOf('day')
					.unix()
					.toString();
				delete fix.search_submitted_end;
				delete fix.search_submitted_start;
			}
			delete fix.search;
			const { data } = await authAxios({
				url: '/telesale/lead/list',
				params: fix,
				method: 'GET',
			});
			return data;
		}
	);
	return query;
};

/*
 * @params {lead_tel}
 */
export const useViewContract = (params) => {
	return useQuery(['contract', params], async () => {
		const { data } = await authAxios({
			url: '/telesale/lead/ma-hop-dong',
			method: 'GET',
			params: params,
		});
		return data;
	});
};

export const useAssignLeader = () =>
	useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/auto-assign-leader',
				data: params,
				method: 'POST',
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.code !== 100) {
					toast.error(data.error);
				} else {
					toast.success('Phân bổ lead thành công');
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);

export const useReAssignLead = () => {
	return useMutation(
		async (params) => {
			const url =
				params.status == 0
					? 'telesale/lead/agent/assign-lead'
					: 'telesale/lead/agent/reassign-lead';
			const { data } = await authAxios({
				url: url,
				data: params,
				method: 'POST',
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.code !== 100) {
					toast.error(data.error);
				} else {
					toast.success('Phân bổ lead thành công');
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useReleaseAssignLead = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: 'telesale/lead/agent/releaseAgentLead',
				data: params,
				method: 'POST',
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.code !== 100) {
					toast.error(data.error);
				} else {
					toast.success('Thu hồi lead thành công');
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useStatusHistory = (params) => {
	return useQuery(['status-history'], async () => {
		const { data } = await authAxios({
			url: 'telesale/lead/lead-status/history',
			params: params,
			method: 'GET',
		});
		return data;
	});
};

export const useExportData = () => {
	return useMutation(
		async (params) => {
			const fix = {};
			for (const key in params) {
				if (params[key] === 'ALL') fix[key] = '';
				else fix[key] = params[key];
			}
			if (fix.search) {
				fix.search_submitted_start = moment(
					new Date(fix.search_submitted_start)
				)
					.startOf('day')
					.unix()
					.toString();
				fix.search_submitted_end = moment(new Date(fix.search_submitted_end))
					.endOf('day')
					.unix()
					.toString();
				delete fix.lead_update_date_start;
				delete fix.lead_update_date_end;
			} else {
				fix.lead_update_date_start = moment(
					new Date(fix.lead_update_date_start)
				)
					.startOf('day')
					.unix()
					.toString();
				fix.lead_update_date_end = moment(new Date(fix.lead_update_date_end))
					.endOf('day')
					.unix()
					.toString();
				delete fix.search_submitted_end;
				delete fix.search_submitted_start;
			}
			delete fix.search;
			const { data } = await authAxios({
				url: 'telesale/lead/export-purchased',
				params: fix,
				method: 'GET',
				responseType: 'blob',
			});
			fileDownload(data, 'List-Da-Mua-Hang.zip');
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code) toast.error(data.content);
				else toast.success('Export file thành công');
			},
			onError: () => {
				toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);
};
