import React, { useState, useEffect } from 'react'
import Editor from "ckeditor5-custom-build/build/ckeditor"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import cs from 'const.js';
import Images from './Images';
import { useSavePost, useEditPost } from 'hooks/dc_article';
import { useParams, useLocation, useHistory } from "react-router-dom"
import { BiArrowBack } from "react-icons/bi"
import { Button, Grid, TextField, } from "@material-ui/core"

const NewPost = () => {
	const routerParams = useParams()
	const history = useHistory()
	const { state } = useLocation()
	const [post, setPost] = useState({
		"product_id": -1,
		"category_id": -1,
		"title": "",
		"description": "",
		"content": "",
		"media": "",
		"author_name": "",
		"source_name": "",
		"source_url": ""
	})

	const [media, setMedia] = useState({
		media: [],
		thumbnail: {
			id: -1,
			file_path: ""
		}
	})

	useEffect(() => {
		if (state) {
			setPost({
				id: state.id,
				"product_id": state.product_id,
				"category_id": state.category_id,
				"title": state.title,
				"description": state.description,
				"content": state.content,
				"media": state.media,
				"author_name": state.author_name,
				"source_name": state.source_name,
				"source_url": state.source_url,
				media_path: state.media_path
			})
			setMedia({ ...media, media: state.media.split(",").filter(item => item != "") })
		}
	}, [])

	const { mutateAsync: savePost } = useSavePost()
	const { mutateAsync: editPost } = useEditPost()

	const handleClickSavePost = () => {
		if (post.product_id === -1) {
			window.alert("Vui lòng chọn sản phẩm")
		}
		const medi = [media.thumbnail.id, ...media.media.filter(item => item != media.thumbnail.id)]
		if (routerParams.id)
			editPost({ ...post, media: medi.join(",") })
		else
			savePost({ ...post, media: medi.join(",") })

		history.goBack()
	}
	return (
		<div>
			<Button onClick={() => history.goBack()}><BiArrowBack /> Trở lại</Button>
			<Grid container>
				<Grid item xs={10}>
					<div style={{ display: "flex", flexFlow: "column", gap: "10px", marginTop: "10px" }}>
						<div>

							<p>
								Tiêu đề
						</p>
							<TextField
								value={post.title}
								onChange={(e) => setPost({ ...post, title: e.target.value })}
								variant="outlined" fullWidth />
						</div>
						<div>
							<p>Mô tả</p>
							<TextField
								value={post.description}
								onChange={(e) => setPost({ ...post, description: e.target.value })}
								variant="outlined"
								fullWidth
								multiline
								rows={4} />
						</div>
						<div>
							<p>Nội dung</p>
							<CKEditor
								editor={Editor}
								data={post.content}
								onChange={(event, editor) => {
									const data = editor.getData();
									setPost({ ...post, content: data })
								}}
								config={{
									simpleUpload: {
										uploadUrl: process.env.REACT_APP_API_GENERAL_URL + "/telesale/dc-article/article/media/upload",
										headers: {
											Authorization: localStorage.getItem(cs.System_Code + '-general-token')
										},
									},
									image: {
										"media-file": [".jpg", ".png", ".gif", ".jpeg"]
									},
									mediaEmbed: {
									}
								}}
							/>
						</div>
						<div>
							<p>
								Nguồn liên kết
							</p>
							<TextField width="100%" value={post.source_url} onChange={(e) => setPost({ ...post, source_url: e.target.value })} variant="outlined" fullWidth />
						</div>
						<div>
							<p>Tên nguồn</p>
							<TextField value={post.source_name} onChange={(e) => setPost({ ...post, source_name: e.target.value })} variant="outlined" fullWidth />
						</div>
						<div>
							<p>Tác giả</p>
							<TextField onChange={(e) => setPost({ ...post, author_name: e.target.value })} value={post.author_name} variant="outlined" fullWidth />
						</div>
					</div>
					<div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
						<Button onClick={handleClickSavePost} type="primary">Lưu</Button>
					</div>

				</Grid>
				<Grid item xs={2}>
					<Images post={post} setPost={setPost} media={media} setMedia={setMedia} />
				</Grid>
			</Grid>
		</div>
	)
}

export default NewPost
