export const statusOptions = [
	{
		value: 0,
		label: 'Chưa phê duyệt',
	},
	{
		value: 1,
		label: 'Đã duyệt',
	},
	{
		value: 11,
		label: 'Thu thập F1 thất bại',
	},
	{
		value: 12,
		label: 'Đang thu thập F1',
	},
	{
		value: 13,
		label: 'Thu thập f1 thành công',
	},
	{
		value: 2,
		label: 'Danh sách bạn bè đóng',
	},
	{
		value: 21,
		label: 'Đang tạo lead',
	},
	{
		value: 22,
		label: 'Tạo lead f1 thất bại',
	},
	{
		value: 23,
		label: 'Tạo lead f1 thành công',
	},
	{
		value: 31,
		label: 'Phân bổ lead f1 thất bại',
	},
	{
		value: 3,
		label: 'Phân bổ lead f1 thành công',
	},
	{
		value: 32,
		label: 'Cần đổi sang F0',
	},
];
