import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {
	DialogActions,
	DialogContent,
	FormControl,
	TextField,
} from '@material-ui/core';
import DialogWrapper from 'components/shared/DialogWrapper';
import { useBranchDirector } from 'hooks/lead';
import { useParams, useHistory } from 'react-router-dom';
import { useAssign } from './services';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AssignLeader = () => {
	const history = useHistory();
	const routerParams = useParams();

	const { data: branchDirectors } = useBranchDirector({});
	const [params, setParams] = useState({
		leader_id: parseInt(routerParams.id),
		branch_id: '',
	});
	const { mutateAsync } = useAssign();
	const handleDialogAgree = () => {
		mutateAsync(params).then(() => {
			history.goBack();
		});
	};

	return (
		<div>
			<DialogWrapper title="Phân bổ trưởng phòng " history={history}>
				<DialogContent>
					<FormControl fullWidth>
						<Autocomplete
							onChange={(e, v) =>
								v && setParams({ ...params, branch_id: v.id})
							}
							options={branchDirectors?.data}
							getOptionLabel={(option) => option.code || option.fullname}
							renderInput={(params) => (
								<TextField {...params} label="Chi nhánh" margin="normal" />
							)}
						/>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={history.goBack} color="primary">
						Hủy
          </Button>
					<Button onClick={handleDialogAgree} color="primary">
						Phân bổ
          </Button>
				</DialogActions>
			</DialogWrapper>
		</div>
	);
};

export default AssignLeader;
