import React, { useState } from "react"
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	TextField,
	Button
}
	from "@material-ui/core"
import { useUpdateButton } from "hooks/dc_article"
const UpdateButton = ({ open, onClose }) => {
	const [btn, setBtn] = useState({
		btn_title: "",
		message: ""
	})

	const {mutateAsync}  = useUpdateButton()

	const handleSubmitForm = ()=>{
		mutateAsync(btn)
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Chỉnh sửa nội dung nút và dòng tin nhắn</DialogTitle>
			<DialogContent>
				<div style={{display:"grid", gap:10}}>

				<FormControl>
					<TextField label="Nội dung nút" onChange={e => setBtn({ ...btn, title: e.target.value })} />
				</FormControl>

				<FormControl>
					<TextField label="Nội dung tin nhắn" onChange={e => setBtn({ ...btn, message: e.target.value })} />
				</FormControl>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={onClose}>Huỷ</Button>
				<Button variant="contained" color="primary" onClick={handleSubmitForm}>Lưu</Button>
			</DialogActions>
		</Dialog>
	)
}
export default UpdateButton
