import moment from 'moment';
const agentStatus = [
	{ value: 0, label: 'Đang hoạt động' },
	{ value: 1, label: 'Không muốn tham gia' },
	{ value: 2, label: 'Đã nghỉ việc' },
];

export const pageSizes = [15, 30, 60];
export const hubs = [
	{ code: 1, name: 'Đồng bằng Bắc Bộ' },
	{ code: 2, name: 'Đông Bắc Bộ' },
	{ code: 3, name: 'Tây Bắc Bộ' },
	{ code: 4, name: 'Bắc Trung Bộ' },
	{ code: 5, name: 'Nam Trung Bộ' },
	{ code: 6, name: 'Tây Nguyên' },
	{ code: 7, name: 'Đông Nam Bộ' },
	{ code: 8, name: 'Đồng bằng Sông Cửu Long' },
	{ code: 9, name: 'Hà Nội' },
	{ code: 10, name: 'TP.HCM' },
];
