import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Button } from '@material-ui/core';
import { useComment, useCreateComment, useUpdateComment } from './services';
import moment from 'moment';
import './comment.css';
import { AiTwotoneEdit } from 'react-icons/ai';
import { MdOutlineCancel, MdOutlineRefresh } from 'react-icons/md';
import { BsFillReplyFill } from 'react-icons/bs';

const CommentTask = () => {
	const { id } = useParams();

	const [paramsComment, setParamsComment] = useState({
		task_id: parseInt(id),
		replied_to: '',
		page: 0,
		size: 100,
	});
	const [newComment, setNewComment] = useState({
		taskId: parseInt(id),
		comment: '',
		repliedTo: null,
		id: null,
	});
	const [comment, setComment] = useState({});
	// edit | replie | none
	const [modeCommentInteract, setModeCommentInteract] = useState(null);
	const { data: comments, refetch } = useComment(paramsComment);
	const { mutateAsync } = useCreateComment();
	const { mutateAsync: updateComment } = useUpdateComment();

	const handleSubmit = () => {
		if (modeCommentInteract === 'edit') {
			updateComment(newComment).then(() => {
				setNewComment({ ...newComment, comment: '' });
				refetch();
			});
		} else {
			mutateAsync(newComment).then(() => {
				setNewComment({ ...newComment, comment: '' });
				refetch();
			});
		}
	};

	const handleClickEditComment = (cmt) => {
		setComment(cmt);
		setModeCommentInteract('edit');
		setNewComment({ ...newComment, id: cmt.id });
	};

	const handleClickReplieComment = (cmt) => {
		setComment(cmt);
		setModeCommentInteract('replie');
		setNewComment({ ...newComment, repliedTo: cmt.id });
	};

	const handleCancelCommentInteract = () => {
		setComment({});
		setModeCommentInteract(null);
	};
	return (
		<div className="comment">
			<h2 className="title">
				Comment <MdOutlineRefresh style={{ color: '#fff' }} onClick={refetch} />
			</h2>
			<table className="comment-list">
				<tbody>
					{comments?.data.map((item) => (
						<>
							<tr className="comment-item" key={item.id}>
								<td>
									<div className="comment-item-action">
										{/* <AiTwotoneEdit */}
										{/* 	onClick={() => handleClickEditComment(item)} */}
										{/* /> */}
										<BsFillReplyFill
											onClick={() => handleClickReplieComment(item)}
										/>
									</div>
								</td>
								<td className="comment-content">
									<span className="comment-author">
										<h3>{item.author.name}</h3>
										<span className="comment-date">
											{moment(item.updatedDate).format('YYYY-MM-DD hh:mm:ss')}
										</span>
									</span>
									<span className="comment-desc">{item.comment}</span>
								</td>
							</tr>
							{item.replies &&
								item.replies.map((i) => (
									<tr className="comment-item " key={i.id}>
										<td>
											<div className="comment-item-action"></div>
										</td>
										<td className="comment-content cmt-reply">
											<span className="comment-author">
												<h3>{i.author.name}</h3>
												<span className="comment-date">
													{moment(i.updatedDate).format('YYYY-MM-DD hh:mm:ss')}
												</span>
											</span>
											<span className="comment-desc">{i.comment}</span>
										</td>
									</tr>
								))}
						</>
					))}
					<tr>
						<td colSpan={2}>
							<div className="form-new-comment">
								<div>
									{modeCommentInteract && (
										<span className="edit-comment">
											<span>
												<b>
													{modeCommentInteract === 'edit' && 'Chỉnh sửa:'}
													{modeCommentInteract === 'replie' && 'Trả lời'}{' '}
												</b>
												{comment.comment}
											</span>
											<span className="cancel-edit-comment">
												<MdOutlineCancel
													onClick={handleCancelCommentInteract}
												/>
											</span>
										</span>
									)}
									<TextField
										placeholder="Bình luận"
										value={newComment.comment}
										onChange={(e) =>
											setNewComment({ ...newComment, comment: e.target.value })
										}
										size="small"
										variant="outlined"
										multiline
										fullWidth
										minRows={4}
									/>
								</div>
								<Button
									onClick={handleSubmit}
									variant="contained"
									color="primary"
									size="small"
								>
									Gửi
                </Button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default CommentTask;
