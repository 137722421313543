import React from 'react';
import { DialogContent } from '@material-ui/core';

const ToolTipHelper = () => {
	return (
		<>
			<p style={{ textAlign: 'center', fontWeight: 700, fontSize: 20 }}>
				Tô màu thống kê chi tiết:
			</p>
			<DialogContent>
				<ul style={{ fontSize: 16 }}>
					<li>Trạng thái Chưa gọi:</li>
					<ul>
						<li>
							{' '}
							Gọi hết số lượng được phân bổ trong vòng 24h:{' '}
							<span style={{ color: 'green' }}>Xanh</span>;{' '}
						</li>
						<li>
							{' '}
							Gọi hết số lượng phân bổ trong vòng 48h:{' '}
							<span style={{ color: '#e8d638' }}>Vàng</span>;
						</li>
						<li>
							{' '}
							Nếu không thì là <span style={{ color: 'red' }}>Đỏ</span>
						</li>
					</ul>
					<li> Trạng thái Gọi lại sau:</li>
					<ul>
						<li>
							{' '}
							Luôn luôn gọi lại trong vòng 72h:{' '}
							<span style={{ color: 'green' }}>Xanh</span>;
						</li>
						<li>
							{' '}
							Gọi lại trong vòng 144h:{' '}
							<span style={{ color: '#e8d638' }}>Vàng</span>;
						</li>
						<li>
							{' '}
							Nếu không thì là <span style={{ color: 'red' }}>Đỏ</span>
						</li>
					</ul>
					<li>
						Trạng thái Quan tâm: x =&gt; 10%:{' '}
						<span style={{ color: 'green' }}>Xanh</span>; 8&lt;= x &lt;10%:{' '}
						<span style={{ color: '#e8d638' }}>Vàng</span>; x &lt; 8:{' '}
						<span style={{ color: 'red' }}>Đỏ</span>
					</li>
					(trong đó x là tỉ lệ phần trăm của Tổng số quan tâm, Xin được hẹn, Đã
					gặp, Đã tìm hiểu nhu cầu KH, Đã trình bày giải pháp, Đã mua trên Tống
					số cuộc gọi được)
					<li>
						Trạng thái Xin được hẹn: x &gt;=7 %:{' '}
						<span style={{ color: 'green' }}>Xanh</span>; 7% &gt; x &gt;=6%:{' '}
						<span style={{ color: '#e8d638' }}>Vàng</span>; x &lt; 6%:{' '}
						<span style={{ color: 'red' }}>Đỏ</span>
					</li>
					(trong đó x là tỉ lệ phần trăm của Tổng số Xin được hẹn, Đã gặp, Đã
					tìm hiểu nhu cầu KH, Đã trình bày giải pháp, Đã mua trên Tổng số cuộc
					gọi được)
					<li>
						Trạng thái Đã gặp: x&gt;=5%:{' '}
						<span style={{ color: 'green' }}>Xanh</span>; 5&gt; x&gt;=4%:{' '}
						<span style={{ color: '#e8d638' }}>Vàng</span>; x &lt;4%:{' '}
						<span style={{ color: 'red' }}>Đỏ</span>
					</li>
					(trong đó x là tỉ lệ phần trăm của Tổng số Đã gặp, Đã tìm hiểu nhu cầu
					KH, Đã trình bày giải pháp, Đã mua trên Tổng số cuộc gọi được)
					<li>
						Trạng thái Đã tìm hiểu nhu cầu khách hàng: x &gt;=5%:{' '}
						<span style={{ color: 'green' }}>Xanh</span>; 5&gt;x &gt;=4% :
						<span style={{ color: '#e8d638' }}>Vàng</span>; x &lt; 4%:{' '}
						<span style={{ color: 'red' }}>Đỏ</span>
					</li>
					(trong đó x là tỉ lệ phần trăm của Tổng số Đã tìm hiểu nhu cầu KH, Đã
					trình bày giải pháp, Đã mua trên Tổng số cuộc gọi được)
					<li>
						Trạng thái Đã trình bày giải pháp: x &gt;=3%:{' '}
						<span style={{ color: 'green' }}>Xanh</span>; 3&gt;x &gt; 2%:{' '}
						<span style={{ color: '#e8d638' }}>Vàng</span>; x &lt; 1%:{' '}
						<span style={{ color: 'red' }}>Đỏ</span>
					</li>
					(trong đó x là tỉ lệ phần trăm của Tổng số Đã trình bày giải pháp và
					Đã mua trên Tổng số cuộc gọi được)
					<li>
						Trạng thái Đã mua: x &gt;=1%:{' '}
						<span style={{ color: 'green' }}>Xanh</span>; 1 &gt; x &gt;= 0.9%:{' '}
						<span style={{ color: '#e8d638' }}>Vàng</span>; x &lt; 0.9%:{' '}
						<span style={{ color: 'red' }}>Đỏ</span>
					</li>
					(trong đó x là tỉ lệ phần trăm của Tổng số Đã mua trên Tổng số cuộc
					gọi được)
					<li>Các trạng thái khác màu xanh lá cây."</li>
				</ul>
			</DialogContent>
		</>
	);
};

export default ToolTipHelper;
