import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import {
	Chart,
	ArgumentAxis,
	ValueAxis,
	BarSeries,
	Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';
import cs from 'const.js';
import moment from 'moment';
import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { isTelesaleMerchant } from '../../service';
import DataTable from 'components/shared/DataTable';

const productsURL = cs.BaseURL + '/telesale/lead/product/list';
const merchantURL = cs.BaseURL + '/telesale/lead//merchant-product/list';
const URL = cs.BaseURL + '/telesale/lead/monthly-summary';

function formatGridValue(value) {
	// if (value && value.report_date)
	if (value.unassigned_qty == 0 || value.unassigned_qty == '0')
		value.unassigned_qty = 0;
	if (value.assigned_qty == 0 || value.assigned_qty == '0')
		value.assigned_qty = 0;

	if (value.wrong_phone_qty == 0 || value.wrong_phone_qty == '0')
		value.wrong_phone_qty = 0;
	if (value.busy_qty == 0 || value.busy_qty == '0') value.busy_qty = 0;
	if (value.wrong_info_qty == 0 || value.wrong_info_qty == '0')
		value.wrong_info_qty = 0;
	if (value.reject_qty == 0 || value.reject_qty == '0') value.reject_qty = 0;
	if (value.call_again_qty == 0 || value.call_again_qty == '0')
		value.call_again_qty = 0;
	if (value.interested_qty == 0 || value.interested_qty == '0')
		value.interested_qty = 0;
	if (value.purchased_qty == 0 || value.purchased_qty == '0')
		value.purchased_qty = 0;

	value.called_qty =
		value.wrong_phone_qty +
		value.busy_qty +
		value.wrong_info_qty +
		value.reject_qty +
		value.call_again_qty +
		value.interested_qty +
		value.purchased_qty;

	value.total_qty =
		value.unassigned_qty + value.assigned_qty + value.called_qty;

	let wrong_phone_qty_pct =
		value.called_qty === 0
			? 0
			: ((value.wrong_phone_qty * 100) / value.called_qty).toFixed(1);
	let busy_qty_pct =
		value.called_qty === 0
			? 0
			: ((value.busy_qty * 100) / value.called_qty).toFixed(1);
	let wrong_info_qty_pct =
		value.called_qty === 0
			? 0
			: ((value.wrong_info_qty * 100) / value.called_qty).toFixed(1);
	let reject_qty_pct =
		value.called_qty === 0
			? 0
			: ((value.reject_qty * 100) / value.called_qty).toFixed(1);
	let call_again_qty_pct =
		value.called_qty === 0
			? 0
			: ((value.call_again_qty * 100) / value.called_qty).toFixed(1);
	let interested_qty_pct =
		value.called_qty === 0
			? 0
			: ((value.interested_qty * 100) / value.called_qty).toFixed(1);

	let purchased_qty_pct =
		value.called_qty === 0
			? 0
			: 100 -
			interested_qty_pct -
			call_again_qty_pct -
			reject_qty_pct -
			wrong_info_qty_pct -
			busy_qty_pct -
			wrong_phone_qty_pct;
	value.called_qty_data = `${value.called_qty} (100%)`;

	value.wrong_phone_qty_data = `${value.wrong_phone_qty} (${wrong_phone_qty_pct}%)`;
	value.busy_qty_data = `${value.busy_qty} (${busy_qty_pct}%)`;

	value.wrong_info_qty_data = `${value.wrong_info_qty} (${wrong_info_qty_pct}%)`;
	value.reject_qty_data = `${value.reject_qty} (${reject_qty_pct}%)`;

	value.call_again_qty_data = `${value.call_again_qty} (${call_again_qty_pct}%)`;
	value.interested_qty_data = `${value.interested_qty} (${interested_qty_pct}%)`;
	value.purchased_qty_data = `${value.purchased_qty} (${purchased_qty_pct}%) ;`;
	return value;
}

function formatChartValue(value) {
	let reportDate = '';
	if (value && value.month && value.year)
		reportDate = value.month + '/' + value.year;
	value['report_date'] = reportDate;
	return value;
}

class MonthlySummaryByDate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			merchants: [],
			products: [],
			start_months: [],
			end_months: [],
			chartData: [],

			grid2Data: [],
			grid2Columns: [
				{ name: 'report_date', title: 'Ngày' },
				{ name: 'product_code', title: 'Sản phẩm' },
				{ name: 'total_qty', title: 'Tổng số' },
				{ name: 'unassigned_qty', title: 'Chưa phân bổ' },
				{ name: 'assigned_qty', title: 'Chưa gọi' },
				{ name: 'called_qty_data', title: 'Đã gọi' },

				{ name: 'wrong_phone_qty_data', title: 'Không có/ Tắt máy' },
				{ name: 'busy_qty_data', title: 'Không nghe máy' },
				{ name: 'wrong_info_qty_data', title: 'Sai số/ sai tên' },
				{ name: 'reject_qty_data', title: 'Từ chối' },
				{ name: 'call_again_qty_data', title: 'Hẹn gọi lại' },
				{ name: 'interested_qty_data', title: 'Quan tâm/hẹn gặp' },
				{ name: 'purchased_qty_data', title: 'Đã mua hàng' },
			],
			grid2ColumnConfig: [
				{ columnName: 'report_date', align: 'center', width: 200 },
				{ columnName: 'product_code', align: 'center', width: 200 },
				{ columnName: 'total_qty', align: 'center', width: 200 },
				{ columnName: 'unassigned_qty', align: 'center', width: 200 },
				{ columnName: 'assigned_qty', align: 'center', width: 200 },
				{ columnName: 'called_qty_data', align: 'center', width: 200 },
				{ columnName: 'wrong_phone_qty_data', align: 'center', width: 200 },
				{ columnName: 'busy_qty_data', align: 'center', width: 200 },
				{ columnName: 'wrong_info_qty_data', align: 'center', width: 200 },
				{ columnName: 'reject_qty_data', align: 'center', width: 200 },
				{ columnName: 'call_again_qty_data', align: 'center', width: 200 },
				{ columnName: 'interested_qty_data', align: 'center', width: 200 },
				{ columnName: 'purchased_qty_data', align: 'center', width: 200 },
			],
			grid2TotalCount: 0,
			grid2CurrentPage: 0,

			pageSize: 15,
			loading: true,
			search_date_start: moment()
				.subtract(12, 'month')
				.startOf('month')
				//.format('YYYY-MM-DD'),
				.unix(),
			// get the last day of the month
			search_date_end: moment().endOf('month').unix(),
			searchProduct: 'all',
			//.format('YYYY-MM-DD')
		};

		this.changeGrid2CurrentPage = this.changeGrid2CurrentPage.bind(this);
		this.loadProducts = this.loadProducts.bind(this);
		this.loadMonths = this.loadMonths.bind(this);

		this.loadProducts();
		this.loadMonths();
		this.loadData();
	}

	loadMonths() {
		var start_months = [];
		var end_months = [];

		// start_months.push({ value: null, name: ' ' });
		// end_months.push({ value: null, name: ' ' });

		for (var i = -12; i <= 0; i++) {
			var current = null;

			current = moment().add(i, 'month').startOf('month');

			start_months.push({
				value: current.unix(),
				display: current.format('MM/YYYY'),
			});

			current = moment().add(i, 'month').endOf('month');
			end_months.push({
				value: current.unix(),
				display: current.format('MM/YYYY'),
			});
		}
		this.setState({
			start_months: start_months,
			end_months: end_months,
		});
	}

	loadProducts() {
		let queryString;
		isTelesaleMerchant() == true
			? (queryString = `${merchantURL}`)
			: (queryString = `${productsURL}`);

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					data.data.unshift({ adr_state: '', adr_state_code: null });
					this.setState({
						products: data.data,
					});
				} else {
					this.setState({
						products: [],
					});
					if (
						data &&
						(data.code == cs.erAuthenticationFailed.code ||
							data.code == cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					products: [],
				});
			});
	}

	changeGrid2CurrentPage(page) {
		this.setState(
			{
				loading: true,
				grid2CurrentPage: page,
			},
			() => {
				this.loadGrid2Data();
			}
		);
	}

	queryChartString() {
		const { search_date_start, search_date_end, searchProduct } = this.state;

		let queryString = `${URL}?new=true&orderby=report_date`;

		if (searchProduct && searchProduct !== 'all')
			queryString = `${queryString}&product_code=${searchProduct}`;

		if (search_date_start)
			queryString = `${queryString}&report_date_from=${moment
				.unix(search_date_start)
				.startOf('month')
				.unix()}`;
		if (search_date_end)
			queryString = `${queryString}&report_date_to=${moment
				.unix(search_date_end)
				.endOf('month')
				.unix()}`;

		return queryString;
	}

	loadChartData = (conditions) => {
		const queryString = this.queryChartString();
		let sum_lead_total = 0;
		let sum_lead_unassigned_qty = 0;
		let sum_lead_assigned_qty = 0;
		let sum_lead_wrong_phone_qty = 0;
		let sum_lead_busy_qty = 0;
		let sum_lead_wrong_info_qty = 0;
		let sum_lead_reject_qty = 0;
		let sum_lead_call_again_qty = 0;
		let sum_lead_interested_qty = 0;
		let sum_lead_purchased_qty = 0;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					for (var i = 0; i < data.data.length; i++) {
						data.data[i] = formatChartValue(data.data[i]);
						sum_lead_unassigned_qty += parseInt(data.data[i].unassigned_qty);
						sum_lead_assigned_qty += parseInt(data.data[i].assigned_qty);
						sum_lead_wrong_phone_qty += parseInt(data.data[i].wrong_phone_qty);
						sum_lead_busy_qty += parseInt(data.data[i].busy_qty);
						sum_lead_wrong_info_qty += parseInt(data.data[i].wrong_info_qty);
						sum_lead_reject_qty += parseInt(data.data[i].reject_qty);
						sum_lead_call_again_qty += parseInt(data.data[i].call_again_qty);
						sum_lead_interested_qty += parseInt(data.data[i].interested_qty);
						sum_lead_purchased_qty += parseInt(data.data[i].purchased_qty);
					}

					sum_lead_total =
						sum_lead_unassigned_qty +
						sum_lead_assigned_qty +
						sum_lead_wrong_phone_qty +
						sum_lead_busy_qty +
						sum_lead_wrong_info_qty +
						sum_lead_reject_qty +
						sum_lead_call_again_qty +
						sum_lead_interested_qty +
						sum_lead_purchased_qty;
					this.setState({
						chartData: [
							{
								report_date:
									this.state.search_date_start === this.state.search_date_end
										? moment
											.unix(this.state.search_date_start)
											.format('YYYY-MM')
										: 'Thống kê số lượng theo từng trạng thái từ ' +
										moment
											.unix(this.state.search_date_start)
											.format('YYYY-MM') +
										' đến ' +
										moment.unix(this.state.search_date_end).format('YYYY-MM'),
								sum_lead_total: sum_lead_total,
								sum_lead_unassigned_qty: sum_lead_unassigned_qty,
								sum_lead_assigned_qty: sum_lead_assigned_qty,
								sum_lead_wrong_phone_qty: sum_lead_wrong_phone_qty,
								sum_lead_busy_qty: sum_lead_busy_qty,
								sum_lead_wrong_info_qty: sum_lead_wrong_info_qty,
								sum_lead_reject_qty: sum_lead_reject_qty,
								sum_lead_call_again_qty: sum_lead_call_again_qty,
								sum_lead_interested_qty: sum_lead_interested_qty,
								sum_lead_purchased_qty: sum_lead_purchased_qty,
							},
						],
						loading: false,
					});
				} else {
					this.setState({
						chartData: [],
						loading: false,
					});
				}
			})
			.catch(() => this.setState({ loading: false }));
	};

	queryGrid2String() {
		const {
			pageSize,
			grid2CurrentPage,
			search_date_start,
			search_date_end,
			searchProduct,
		} = this.state;
		let queryString = `${URL}?pagesize=${pageSize}&page=${grid2CurrentPage + 1
			}&orderby=report_date&asc=false&new=true`;

		if (searchProduct && searchProduct !== 'all')
			queryString = `${queryString}&product_code=${searchProduct}`;

		if (search_date_start)
			queryString = `${queryString}&report_date_from=${moment
				.unix(search_date_start)
				.startOf('month')
				.unix()}`;
		if (search_date_end)
			queryString = `${queryString}&report_date_to=${moment
				.unix(search_date_end)
				.endOf('month')
				.unix()}`;

		return queryString;
	}

	loadGrid2Data = (conditions) => {
		const queryString = this.queryGrid2String();
		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data.length) {
					for (var i = 0; i < data.data.length; i++) {
						data.data[i] = formatGridValue(data.data[i]);
					}

					this.setState({
						grid2Data: data.data,
						grid2TotalCount: data.total_count,
						loading: false,
					});
				} else {
					this.setState({
						grid2Data: [],
						grid2TotalCount: 0,
						loading: false,
					});
				}
			})
			.catch(() => this.setState({ loading: false }));
	};

	loadData = (conditions) => {
		this.loadChartData();
		this.loadGrid2Data();
	};

	handleChange = (name) => (event) => {
		this.setState(
			{
				[name]: event.target.value,
			},
			() => {
				if (
					name === 'search_date_start' ||
					name === 'search_date_end' ||
					name === 'searchProduct'
				) {
					this.loadData();
				}
			}
		);
	};

	CellComponent = (props) => {
		return (
			<Table.Cell
				{...props}
				style={{
					padding: 2,
				}}
			/>
		);
	};

	render() {
		const { classes } = this.props;

		const {
			chartData,

			grid2Data,
			grid2Columns,
			grid2ColumnConfig,
			grid2CurrentPage,
			grid2TotalCount,

			pageSize,
			loading,
		} = this.state;

		return (
			<div>
				<form
					className={classes.search_container}
					noValidate
					autoComplete="off"
				>
					<FormControl className={classes.search_field}>
						<InputLabel htmlFor="productId">Từ tháng</InputLabel>
						<Select
							value={this.state.search_date_start}
							onChange={this.handleChange('search_date_start')}
							inputProps={{
								name: 'date_start',
								id: 'date_startId',
							}}
						>
							{this.state.start_months.map((item) => (
								<MenuItem value={item.value}>
									{item.value && <div>{item.display}</div>}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl className={classes.search_field}>
						<InputLabel htmlFor="productId">Đến tháng</InputLabel>
						<Select
							value={this.state.search_date_end}
							onChange={this.handleChange('search_date_end')}
							inputProps={{
								name: 'date_end',
								id: 'date_endId',
							}}
						>
							{this.state.end_months.map((item) => (
								<MenuItem value={item.value}>
									{item.value && <div>{item.display}</div>}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl className={classes.search_product_field}>
						<InputLabel htmlFor="productId">Sản phẩm</InputLabel>
						<Select
							value={this.state.searchProduct}
							onChange={this.handleChange('searchProduct')}
							inputProps={{
								name: 'product',
								id: 'productId',
							}}
						>
							{this.state.products.map((pro) => (
								<MenuItem value={pro.code}>
									{pro.code && (
										<div>
											{pro.name}
											{/* {pro.mc_code} - {pro.name} */}
										</div>
									)}
									{!pro.code && <div>{pro.name}</div>}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</form>

				<Chart data={chartData} className={classes.chart} height="300">
					<ArgumentAxis />
					<ArgumentScale />
					<ValueAxis />
					<Legend
						position="bottom"
						rootComponent={LegendRoot}
						labelComponent={LegendLabel}
					/>

					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_total"
						name="Tổng số"
						color="#FF0000"
					// pointComponent={BarWithLabel}
					/>
					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_unassigned_qty"
						name="Chưa Phân Bổ"
						color="#FFC0CB"
					// pointComponent={BarWithLabel}
					/>
					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_assigned_qty"
						name="Chưa gọi"
						color="#FFFF00"
					// pointComponent={BarWithLabel}
					/>
					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_wrong_phone_qty"
						name="Không có/Tắt máy"
						color="#EE82EE"
					// pointComponent={BarWithLabel}
					/>
					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_busy_qty"
						name="Không nghe máy"
						color="#00FF00"
					// pointComponent={BarWithLabel}
					/>
					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_wrong_info_qty"
						name="Sai số/sai tên"
						color="#00FFFF"
					// pointComponent={BarWithLabel}
					/>
					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_reject_qty"
						name="Từ chối"
						color="#A52A2A"
					// pointComponent={BarWithLabel}
					/>
					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_call_again_qty"
						name="Hẹn Gọi Lại"
						color="#00BFFF"
					// pointComponent={BarWithLabel}
					/>
					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_interested_qty"
						name="Quan tâm/hẹn gặp"
						color="#008000"
					// pointComponent={BarWithLabel}
					/>
					<BarSeries
						argumentField="report_date"
						valueField="sum_lead_purchased_qty"
						name="Đã mua hàng"
						color="#000000"
					// pointComponent={BarWithLabel}
					/>
					<Stack />
				</Chart>

				<DataTable
					rows={grid2Data}
					columns={grid2Columns}
					columnWidths={grid2ColumnConfig}
					pageSize={pageSize}
					currentPage={grid2CurrentPage}
					loading={loading}
					totalCount={grid2TotalCount}
					CellComponent={this.CellComponent}
				/>
			</div>
		);
	}
}

const rootStyles = {
	display: 'grid',
	gridTemplateColumns: 'auto auto auto',
	margin: 'auto',
};
const LegendRoot = (props) => <Legend.Root {...props} style={rootStyles} />;

const defaultLabelStyles = {
	marginLeft: '10px',
	whiteSpace: 'nowrap',
	fontSize: '14px',
};
const LegendLabel = (props) => (
	<div {...props} style={defaultLabelStyles}>
		{props.text}
	</div>
);

// const getGrid2RowId = row => row.report_date + row.lead_status;
MonthlySummaryByDate.propTypes = {
	classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
	grid_Container: {
		width: '100%',
		marginTop: theme.spacing(2),
		overflowX: 'auto',
	},
	tableStriped: {
		'& tbody tr:nth-of-type(odd)': {
			backgroundColor: 'rgb(186, 207, 255)',
		},
		'& tbody tr:nth-of-type(even)': {
			backgroundColor: 'rgb(217, 226, 244)',
		},
	},
	searchPanel: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	search_container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	search_field: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: 200,
		minWidth: 120,
	},
	search_product_field: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: 400,
		minWidth: 300,
	},
	search_select_menu: {
		width: 200,
	},
	grid_header: {
		// backgroundColor: blue
	},
	chart: {
		marginTop: 30,
	},
});

export default withStyles(styles)(MonthlySummaryByDate);
