import React, { useState } from 'react';
import DialogWrapper from 'components/shared/DialogWrapper';
import { useHistory, useLocation } from 'react-router-dom';
import {
	DialogContent,
	DialogActions,
	Button,
	Switch,
} from '@material-ui/core';
import { useResetSub } from './services';

const ResetSubLead = () => {
	const history = useHistory();
	const { state } = useLocation();
	const [params, setParams] = useState({
		force_crawl_friend: true,
	});
	const { mutateAsync: reset } = useResetSub();
	const onSubmit = () => {
		reset({ id: state.id, ...params }).then(() => history.goBack());
	};
	return (
		<DialogWrapper
			history={history}
			title="Cập nhật trạng thái đăng ký nhận lead"
		>
			<DialogContent>
				<p>Bạn có muốn cập nhật trạngt thái của lead này ?</p>
				<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
					<p>Buộc thu thập thông tin bạn bè:</p>
					<Switch
						checked={(params.force_crawl_friend)}
						onChange={(e) =>
							setParams({ ...params, force_crawl_friend: e.target.checked })
						}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => history.goBack()}>Huỷ</Button>
				<Button onClick={onSubmit} variant="contained" color="primary">
					Cập nhật
        </Button>
			</DialogActions>
		</DialogWrapper>
	);
};

export default ResetSubLead;
