import { useQuery, useMutation } from '@tanstack/react-query';
import leadApi from 'api/lead.api';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { authAxios } from 'utils/api';


export const useBranch = (params) => {
  const query = useQuery(['branch', params], () =>
    leadApi.searchBranch(params)
  );
  return query;
};

export const useTeam = (params) => {
  const query = useQuery(['team', params], async () => {
    const fix = {};
    for (const key in params) {
      if (params[key] === 'ALL') fix[key] = '';
      else fix[key] = params[key];
    }
    const { data } = await authAxios({
      url: '/telesale/lead/user/team-list',
      method: 'GET',
      params: fix,
    });
    return data;
  });
  return query;
};

export const useEmployee = (params) => {
  return useQuery(['employee', params], async () => {
    const { data } = await authAxios({
      url: '/telesale/lead/employee/list',
      method: 'GET',
      params: params,
    });
    return data;
  });
};

export const useMerchantLeader = (params) => {
  return useQuery(['merchanLead', params], async () => {
    const fix = {};
    for (const key in params) {
      if (params[key] === 'ALL') fix[key] = '';
      else fix[key] = params[key];
    }
    const { data } = await authAxios({
      url: '/telesale/lead/merchant-leader',
      params: fix,
      method: 'GET',
    });
    return data;
  });
};

export const useCurrentMerchant = (params) => {
  const query = useQuery(['currentMerchant', params], () =>
    leadApi.getCurrentMerchant(params)
  );
  return query;
};

export const useBranchDirector = (params) => {
  const query = useQuery(['branchDirector', params], () =>
    leadApi.searchBranchDirector(params)
  );
  return query;
};


export const useLeader = (params) => {
  const query = useQuery(['leader', params], () =>
    leadApi.searchLeader(params)
  );
  return query;
};

export const useBusinessReport = (params, url) => {
  const query = useQuery(['businessReport', params], () =>
    leadApi.businessReport(params, url)
  );
  return query;
};

export const useImportLead = () => {
  return useMutation((form) => leadApi.importLead(form), {
    onSuccess: (data) => {
      if (data?.code === 100) toast.success('Import lead thành công');
      else toast.error('Có lỗi xảy ra');
    },
    onError: () => {
      toast.error('Có lỗi xảy ra');
    },
  });
};

