import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import cs from 'const.js';
import DialogWrapper from 'components/shared/DialogWrapper';
import DataTable from 'components/shared/DataTable';

const EXECUTIVE_ORDER_DETAIL_URL = cs.MarketplaceURL + '/api/executive/order/detail';
const styles = (theme) => ({});

function formatValue(value) {
    console.log(value)
    if (value && value.originalPrice) {
        value.originalPrice = value.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    if (value && value.totalSale) {
        value.totalSale = value.totalSale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return value;
}

class OrderBookDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { name: 'maDonHang', title: 'Mã đơn hàng' },
                { name: 'productName', title: 'Sản phẩm' },
                { name: 'productVariationName', title: 'Phân loại' },
                { name: 'quantity', title: 'Số lượng' },
                { name: 'supplierName', title: 'Nhà cung cấp' },
                { name: 'mcCode', title: 'Công ty' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'maDonHang', align: 'center', width: 200 },
                { columnName: 'productName', align: 'center', width: 350 },
                { columnName: 'productVariationName', align: 'center', width: 200 },
                { columnName: 'quantity', align: 'center', width: 150 },
                { columnName: 'supplierName', align: 'center', width: 200 },
                { columnName: 'mcCode', align: 'center', width: 150 }
            ],
            // pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 10,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'update_time', direction: 'desc' }]
        };
        this.loadData();
    }

    loadData = () => {
        const queryString = `${EXECUTIVE_ORDER_DETAIL_URL}?maDonHang=${this.props.match.params.id}`;
        console.log(queryString);

        fetch(queryString, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-general-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    if (data.data.orderItems.length > 0) {
                        for (var i = 0; i < data.data.orderItems.length; i++) {
                            data.data.orderItems[i] = formatValue(data.data.orderItems[i]);
                        }
                        this.setState({
                            rows: data.data.orderItems,
                            totalCount: data.total_count,
                            loading: false
                        });
                    }
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    handleFieldChange = (name) => (event) => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {}
        );
    };
    render() {
        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;
        return (
            <div>
                <DialogWrapper title={'Chi tiết đơn hàng'} width={'md'} {...this.props}>
                    <DialogContent>
                        <DataTable
                            rows={rows}
                            columns={columns}
                            columnWidths={tableColumnExtensions}
                            pageSizes={pageSizes}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            loading={loading}
                            sorting={sorting}
                            totalCount={totalCount}
                            changeCurrentPage={this.changeCurrentPage}
                            changePageSize={this.changePageSize}
                            changeSorting={this.changeSorting}
                            autoLoadData={this.loadData}
                        />
                    </DialogContent>
                    <DialogActions></DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

OrderBookDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OrderBookDetail);
