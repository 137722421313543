import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import MonthlySummaryByDate from './MonthlySummaryByDate';


function TabContainer(props) {
	return <Paper style={{ padding: 8 * 2, minHeight: 350 }}>{props.children}</Paper>;
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
};

class MonthlyDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tabNum: 0
		};
	}

	handleTabChange = (event, value) => {
		this.setState({ tabNum: value });
	};

	render() {
		return (
			<div>
				<MonthlySummaryByDate />
			</div>
		);
	}
}

const styles = theme => ({});
export default withStyles(styles)(MonthlyDashboard);
