import axios from 'axios';
import cs from 'const.js';

const authAxios = axios.create({
	baseURL: process.env.REACT_APP_API_GENERAL_URL,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
		Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
	},
});

export default authAxios
