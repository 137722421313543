import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import {  useDeletPost } from 'hooks/dc_article';

const Delete = ({ open, item, onCancel }) => {

	const { mutateAsync: deletePost } = useDeletPost()
	const handleClickDeletePost = () => {
		deletePost({ id: item.id })
		onCancel()
	}
	return (
		<Dialog open={open}>
			<DialogTitle>Xoá</DialogTitle>
			<DialogContent>
				<p>Xoá bài viết này</p>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel}>Huỷ</Button>
				<Button onClick={handleClickDeletePost}>
					Xoá
							</Button>
			</DialogActions>
		</Dialog>

	)
}

export default Delete
