import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { authAxios } from 'utils/api';

export const useExportDetail = () => {
  return useMutation(
    async (params) => {
      const fix = {};
      for (const key in params) {
        if (params[key] === 'ALL') fix[key] = '';
        else fix[key] = params[key];
      }
      const { data } = await authAxios({
        url: '/telesale/lead/export',
        params: fix,
        method: 'GET',
        responseType: 'blob',
      });
      fileDownload(data, 'LeadReport.zip');
      return data;
    },
    {
      onSuccess: (data) => {
        if (data?.code) toast.error(data.content);
        else toast.success('Export file thành công');
      },
      onError: () => {
        toast.error('Có lỗi xảy ra, thử lại sau');
      },
    }
  );
};

export const useExportRaw = () => {
  return useMutation(
    async (params) => {
      const fix = {};
      for (const key in params) {
        if (params[key] === 'ALL') fix[key] = '';
        else fix[key] = params[key];
      }
      const { data } = await authAxios({
        url: '/telesale/lead/export2',
        params: fix,
        method: 'GET',
      }).then((res) => {
        if (res.data.code) return res;
        const date = new Date();
        fileDownload(res.data, `${date}export-raw.xlsx`);
        return res;
      });
      return data;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.code) {
          if (data?.data.code !== 100)
            return toast.error('Có lỗi xảy ra, thử lại sau');
          return toast.success('Export file thành công');
        }
        return toast.success('Export file thành công');
      },
      onError: () => {
        return toast.error('Có lỗi xảy ra, thử lại sau');
      },
    }
  );
};

export const useReportByDate = (params) => {
  return useQuery(['reportDate', params], async () => {
    const { data } = await authAxios({
      url: '/telesale/lead/daily-summary',
      params: params,
      method: 'GET',
    });
    return data;
  });
};
