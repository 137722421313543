import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from 'const.js';

const createBuyURL = cs.BaseURL + '/telesale/package/buy/create';
const userURL = cs.BaseURL + "/telesale/package/list/user";
const packageURL = cs.BaseURL + '/telesale/package/list/info';
const styles = theme => ({});

const isTelesaleOP = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	var userrole = JSON.parse(String(user)).role;
	return (
		userrole === cs.Role_Telesale_Hub_Leader
		|| userrole === cs.Role_Telesale_Hub_Manager
	);
}

class CreateBuy extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			package_name: '',
			user_id: '',
			users: [],
			packages: [],
			package_amount: 0
		};
		//this.users=[];
		this.handleDialogAgree = this.handleDialogAgree.bind(this);

		this.loadUsers();
		this.loadPackages();
	}

	loadUsers() {
		if (!isTelesaleOP()) {
			window.location.href = "/";
			return;
		}
		let queryString = `${userURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					this.setState({
						users: data.data
					});
				} else {
					this.setState({
						users: []
					});

					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					users: []
				});
			});
	}

	loadPackages() {

		let queryString = `${packageURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					this.setState({
						packages: data.data
					});
				} else {
					this.setState({
						packages: []
					});

					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					packages: []
				});
			});
	}

	handleDialogAgree = () => {
		if (!this.state.package_name || !this.state.user_id || !this.state.package_amount) {
			window.alert('Please input information !');
			return;
		}

		if (this.state.package_amount && isNaN(this.state.package_amount)) {
			window.alert('Incorrect amount !');
			return;
		}
		let queryString = `${createBuyURL}`;
		let requestBody = {

			package_name: this.state.package_name,
			user_id: this.state.user_id,
			package_amount: this.state.package_amount,

		};

		fetch(queryString, {
			method: 'POST',
			body: JSON.stringify(requestBody),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}

				if (data && data.error && data.code != cs.Succeed) {
					this.props.toastManager.add(JSON.stringify(data.error), {
						appearance: 'error',
						autoDismiss: true,
						pauseOnHover: true
					});
				} else {
					this.props.toastManager.add('Finished !', {
						appearance: 'success',
						autoDismiss: true,
						pauseOnHover: true
					});
					this.props.history.push({
						pathname: '/package-buy',
						state: { reload: true }
					});
				}
			})
			.catch(() => { });
	};

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		},
			() => {
				if (name == 'package_name') {
					let pkg = this.state.packages.filter((item => item.package_name === event.target.value));
					this.setState({ package_amount: pkg[0].amount });
				}
			}
		);
	};



	render() {
		const { classes } = this.props;

		return (
			<div>
				<Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
					<DialogTitle id="create_merchant_form-dialog-title">Create Buy Package</DialogTitle>
					<DialogContent>
						<FormControl fullWidth>
							<InputLabel htmlFor="merchantId">User (*)</InputLabel>
							<Select
								value={this.state.user_id}
								onChange={this.handleChange('user_id')}
								inputProps={{
									name: 'package_type',
									id: 'package_type'
								}}
							>
								{this.state.users.map(item => (
									<MenuItem value={item.id}>{item.name}</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl fullWidth>
							<InputLabel htmlFor="package_name">Package name (*)</InputLabel>
							<Select
								value={this.state.package_name}
								onChange={this.handleChange('package_name')}
								inputProps={{
									name: 'package_name',
									id: 'id'
								}}
							>
								{this.state.packages.map(item => (
									<MenuItem value={item.package_name}>{item.package_name}</MenuItem>
								))}
							</Select>
						</FormControl>
						<TextField
							disabled
							fullWidth
							autoFocus
							margin="dense"
							id="Package amount"
							required={true}
							value={this.state.package_amount}
							onChange={this.handleChange('package_amount')}
							label="Package amount"
							type="number"
						/>
						<Grid
							container
							direction="column"
							justify="space-between"
							alignItems="stretch"
							spacing={1}
							className={classes.gridContainer}
						>
							<Grid item>
								<div></div>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.history.goBack} color="primary">
							Cancel
                        </Button>
						<Button onClick={this.handleDialogAgree} color="primary">
							Create New
                        </Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

CreateBuy.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreateBuy);
