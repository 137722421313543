import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
	Button,
	TextField,
	DialogActions,
	DialogContent,
	FormControl,
} from '@material-ui/core';
import cs from 'const.js';
import DialogWrapper from 'components/shared/DialogWrapper';
import { getRole } from 'service';
import { useBranchDirector, useMerchantLeader } from 'hooks/lead';
import { useAgentLead } from 'hooks/leadAgent';
import { useAssign } from './services';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AssignTeam = (props) => {
	const history = useHistory();
	const routerParams = useParams();
	const [params, setParams] = useState({
		agent_id: routerParams?.id,
		branch_id: '',
		leader_id: '',
		leader_code: '',
	});
	const [branchName, setBranchName] = useState('');
	const { data: agents } = useAgentLead({ id: routerParams.id });
	const { data: branchDirectors } = useBranchDirector();
	const { data: leaders } = useMerchantLeader({ branch_name: branchName });

	const { mutateAsync: assignAgent } = useAssign();
	const handleDialogAgree = () => {
		assignAgent(params).then(() => {
			history.goBack();
		});
	};

	return (
		<div>
			<DialogWrapper
				title={'Phân bổ nhân viên ' + agents?.data[0]?.fullname}
				{...props}
			>
				<DialogContent>
					{getRole() === cs.Role_Telesale_Merchant_Manager && (
						<FormControl fullWidth>
							<Autocomplete
								onChange={(e, v) => {
									setParams({
										...params,
										branch_id: v.id,
										leader_code: '',
										leader_id: '',
									});
									setBranchName(v.name);
								}}
								options={branchDirectors?.data}
								getOptionLabel={(option) => option.code || option.fullname}
								renderInput={(params) => (
									<TextField {...params} label="Chi nhánh" margin="normal" />
								)}
							/>
						</FormControl>
					)}
					<FormControl fullWidth>
						<Autocomplete
							onChange={(e, v) => {
								if (v)
									setParams({
										...params,
										leader_id: v.id,
										leader_code: v.code,
									});
								else {
									setParams({ ...params, leader_id: '', leader_code: '' });
								}
							}}
							options={leaders?.data ? leaders.data : []}
							getOptionLabel={(option) => option.code || option.fullname}
							renderInput={(params) => (
								<TextField {...params} label="Trưởng phòng" margin="normal" />
							)}
						/>
					</FormControl>
					<FormControl fullWidth>
						<TextField
							disabled
							margin="dense"
							id="leader code"
							value={params.leader_code}
							label="Mã trưởng phòng"
						/>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={history.goBack} color="primary">
						Cancel
          </Button>
					<Button onClick={handleDialogAgree} color="primary">
						Set Team
          </Button>
				</DialogActions>
			</DialogWrapper>
		</div>
	);
};

export default AssignTeam;
