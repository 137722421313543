import React from 'react';
import {
	DialogTitle,
	DialogActions,
	Typography,
	Button,
	DialogContent,
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import DialogWrapper from 'components/shared/DialogWrapper';
import { useSendEmail, useSendEmailUserInfo } from './services';
import { toast } from 'react-toastify';
import './send_email.css';

const SendLogin = () => {
	const { state } = useLocation();
	const history = useHistory();
	const { mutateAsync: sendEmail } = useSendEmail();
	const { mutateAsync: sendEmailUserInfo } = useSendEmailUserInfo();
	const onSubmit = () => {
		if (state.id && state.code)
			sendEmail({
				id: state.id,
				code: state.code,
			});
		else {
			toast.error('Agent này không có đủ thông tin để gửi email(id và code)');
		}
	};

	const onSubmitUserInfo = () => {
		if (state.id && state.code)
			sendEmailUserInfo({
				id: state.id,
				code: state.code,
			});
		else {
			toast.error('Agent này không có đủ thông tin để gửi email(id và code)');
		}
	};
	return (
		<DialogWrapper open={true} {...history}>
			<DialogTitle>
				<Typography variant="h4" component="h4" align="center">
					Gửi email
        </Typography>
			</DialogTitle>
			<DialogContent>
				<div className="send-email">
					<div>
						<Button
							color="primary"
							variant="contained"
							onClick={() => onSubmit()}
						>
							Gửi email hướng dẫn sử dụng
            </Button>
					</div>

					<div>
						<Button
							color="primary"
							variant="contained"
							onClick={() => onSubmitUserInfo()}
						>
							Gửi email thông tin tài khoản
            </Button>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={() => history.goBack()}>
					Huỷ
        </Button>
			</DialogActions>
		</DialogWrapper>
	);
};

export default SendLogin;
