import React, { useState } from 'react';
import { Switch, DialogActions, Button, DialogContent } from '@material-ui/core';
import { useHistory, useLocation} from 'react-router-dom';
import { useSubcriptionApprove } from './services';
import DialogWrapper from 'components/shared/DialogWrapper';

const Approve = () => {
	const history = useHistory();
	const { state } = useLocation();
	const [switchChecked, setSwitchChecked] = useState(state.status);

	const onClose = () => {
		history.goBack();
	};
	const { mutateAsync: approve } = useSubcriptionApprove();

	const handleChangeSwitch = () => {
		setSwitchChecked((prev) => {
			approve({ id: state.id, status: !prev });
			return !prev;
		});
	};

	return (
		<DialogWrapper
			history={history}
			title={'Phê duyệt/huỷ đăng ký nhận lead bạn bè của KH'}
		>
			<DialogContent>

			<div>
				<div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
					<h2>Trạng thái:</h2>
					<Switch
						defaultChecked={switchChecked}
						onChange={handleChangeSwitch}
					/>
				</div>
			</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Đóng</Button>
			</DialogActions>
		</DialogWrapper>
	);
};

export default Approve;
