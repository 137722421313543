import React, { useState, useEffect } from 'react'
import { useGetAgentArticle, useGetLeadArticle, useGetLinkShareArticle } from 'hooks/dc_article';
import { Dialog, Select, DialogTitle, Tooltip, Button, DialogContent, DialogActions, Grid, MenuItem } from '@material-ui/core';

const GetLink = ({ open, onCancel, item }) => {
	const [paramsLead, setParamsLead] = useState({
		agent_id: '',
		name: "",
		phone: ""
	})
	const [paramsLink, setParamsLink] = useState({
		agent_id: "",
		lead_id: "",
		article_id: ""
	})
	useEffect(() => {
		if (item.id > 0)
			setParamsLink({
				...paramsLink,
				article_id: item.id
			})
		item.id
	}, [item])

	const { data: agents } = useGetAgentArticle()
	const { data: leads } = useGetLeadArticle(paramsLead);
	const { mutateAsync: getLinkShare, data } = useGetLinkShareArticle(paramsLink)

	const onOk = () => {
		getLinkShare(paramsLink)
	}
	const handleCancel = () => {
		setParamsLink({
			agent_id: "",
			lead_id: "",
			article_id: ""
		})
		setParamsLead({
			agent_id: "",
			name: "",
			phone: ""
		})
		onCancel()
	}
	return (
		<Dialog
			open={open}
		>
			<DialogTitle>
				Lấy link
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<p>Agent</p>
						<Select
							onChange={(e) => {
								setParamsLead({ ...paramsLead, agent_id: e.target.value })
								setParamsLink({ ...paramsLink, agent_id: e.target.value, lead_id: undefined })
							}}
							style={{ width: "100%" }}
						>
							<MenuItem value={""} >Không lấy</MenuItem>
							{
								agents?.data?.map(item => (

									<MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
								))
							}
						</Select>
					</Grid>
					{
						paramsLead.agent_id > 1 &&
						<Grid item xs={12}>
							<p>Lead</p>
							<Select
								onChange={(e) => setParamsLink({ ...paramsLink, lead_id: e.target.value })}
								style={{ width: "100%" }}
							>

								<MenuItem value={""} >Không lấy</MenuItem>
								{
									leads?.data.map(item => (

										<MenuItem value={item.id} key={item.id}>{item.full_name}</MenuItem>
									))
								}
							</Select>
						</Grid>
					}
					<Grid item xs={12}>
						{
							data?.data ? "Click để copy link" : "Hãy nhấn nút OK để lấy link"
						}
						{
							data?.data &&
							<Tooltip title={"Coppy link"} placement="topLeft">
								<div
									style={{
										cursor: "pointer",
										width: "470px",
										color: "#03b1fc",
										textDecoration: "underline"
									}}
									onClick={() => navigator.clipboard.writeText(data.data.url)}
								>
									{
										data.data.url
									}
								</div>
							</Tooltip>
						}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel}>Huỷ</Button>
				<Button onClick={onOk}>OK</Button>
			</DialogActions>
		</Dialog>
	)
}

export default GetLink
