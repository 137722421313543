import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'const.js';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { green } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import {
	Dialog,
	Switch,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core';
import DataTable from 'components/shared/DataTable.jsx';
import { withStyles } from '@material-ui/core/styles';
import UpdateNew from './UpdateNew';
import { useNews } from 'hooks/news';

const activeStatus = [
	'Chưa phê duyệt',
	'Phê duyệt',
	'',
	'Yêu cầu sửa lại',
	'Tắt',
];

const isTelesale_Merchant_Not_User = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	var userrole = JSON.parse(String(user)).role;
	return (
		userrole === cs.Role_Telesale_Merchant_Leader ||
		userrole === cs.Role_Telesale_Merchant_Manager
	);
};

const tableConfig = {
	columns: [
		{ name: 'code', title: 'Code' },
		{ name: 'title', title: 'Tiêu đề' },
		{ name: 'team_name', title: 'Team name' },
		{ name: 'created_by', title: 'Tạo bởi' },
		{ name: 'mc_product_code', title: 'Sản phẩm' },

		{ name: 'active', title: 'Trạng thái' },
		{ name: 'action', title: 'Thao tác' },
	],
	tableColumnExtensions: [
		{ columnName: 'title', align: 'center', width: 150 },
		{ columnName: 'team_name', align: 'center', width: 200 },
		{ columnName: 'created_by', align: 'center', width: 200 },
		{ columnName: 'mc_product_code', align: 'center', width: 150 },
		{ columnName: 'code', align: 'center', width: 150 },
		{ columnName: 'active', align: 'center', width: 160 },
		{ columnName: 'amount', align: 'center', width: 200 },
		{ columnName: 'action', align: 'center', width: 400 },
	],
};
const NewsList = ({ classes }) => {
	const [params, setParams] = useState({
		pageSize: 15,
		currentPage: 0,
		/* code: '', */
		/* title: '', */
		/* mc_product_code: '', */
		/* active: 0, */
		deleted: 0,
	});
	const [openDialogEdit, setOpenDialogEdit] = useState(false);
	const handleChange = (name) => (event) => {
		if (name == 'deleted') {
			setParams({
				...params,
				[name]: event.target.checked,
			});
		} else {
			setParams({
				...params,
				[name]: event.target.value,
			});
		}
	};
	const { data, refetch, isLoading } = useNews(params);

	return (
		<div>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography className={classes.heading}>Tìm kiếm ...</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<form className={classes.container} noValidate autoComplete="off">
						<TextField
							id="code"
							label="Code"
							type="search"
							className={classes.field}
							value={params.code}
							onChange={handleChange('code')}
							margin="normal"
						/>
						<TextField
							id="active"
							label="Tiêu đề"
							type="search"
							className={classes.field}
							value={params.title}
							onChange={handleChange('title')}
							margin="normal"
						/>

						<TextField
							id="mc_product_code"
							label="Sản phẩm"
							type="search"
							className={classes.field}
							value={params.mc_product_code}
							onChange={handleChange('mc_product_code')}
							margin="normal"
						/>

						<FormControl className={classes.field}>
							<InputLabel htmlFor="active">Trạng thái</InputLabel>
							<Select
								value={params.active}
								onChange={handleChange('active')}
								inputProps={{
									name: 'date_start',
									id: 'date_startId',
								}}
							>
								<MenuItem value="">
									<div></div>
								</MenuItem>
								<MenuItem value={0}>
									<div>{activeStatus[0]}</div>
								</MenuItem>
								<MenuItem value={1}>
									<div>{activeStatus[1]}</div>
								</MenuItem>

								<MenuItem value={3}>
									<div>{activeStatus[3]}</div>
								</MenuItem>

								<MenuItem value={4}>
									<div>{activeStatus[4]}</div>
								</MenuItem>
							</Select>
						</FormControl>
					</form>
				</AccordionDetails>
			</Accordion>
			<Button
				color="inherit"
				aria-label="Create News"
				component={Link}
				to={'/news/create'}
			>
				<Icon>person_add</Icon>
				<span style={{ marginLeft: 10 }}>Create News</span>
			</Button>
			<Button color="inherit" aria-label="Refresh" onClick={refetch}>
				<Icon>refresh</Icon>
				<span style={{ marginLeft: 10 }}>Làm mới</span>
			</Button>
			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.tableColumnExtensions}
				pageSize={params.pageSize}
				currentPage={params.currentPage}
				loading={isLoading}
				/* sorting={sorting} */
				totalCount={data?.total_count}
				changeCurrentPage={(p) => setParams({ ...params, currentPage: p })}
				/* changePageSize={changePageSize} */
				/* changeSorting={changeSorting} */
				CellComponent={CellComponent}
			/>
			<Dialog open={openDialogEdit} onClose={() => setOpenDialogEdit(false)}>
				<UpdateNew onClose={() => setOpenDialogEdit(false)} />
			</Dialog>
		</div>
	);
};

NewsList.propTypes = {
	classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
	grid_Container: {
		width: '100%',
		marginTop: theme.spacing(2),
		overflowX: 'auto',
	},
	tableStriped: {
		'& tbody tr:nth-of-type(odd)': {
			backgroundColor: 'rgb(186, 207, 255)',
		},
		'& tbody tr:nth-of-type(even)': {
			backgroundColor: 'rgb(217, 226, 244)',
		},
	},
	searchPanel: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	field: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: 200,
		minWidth: 120,
	},
	select_menu: {
		width: 200,
	},
	grid_header: {
		backgroundColor: green,
	},
	gender_button: {
		marginTop: 20,
		marginLeft: 40,
	},
});

const ActionCell = (props) => {
	return (
		<Table.Cell
			style={{
				padding: 1,
				justifyContent: 'center',
				fontSize: '12px',
			}}
		>
			<span>
				{isTelesale_Merchant_Not_User() && (
					<>
						<Button
							color="primary"
							style={{
								margin: 0,
								padding: 0,
								marginRight: 20,
							}}
							component={Link}
							to={'/news/edit/' + props.row.id}
						>
							YC sửa lại
						</Button>
						<Button
							color="primary"
							style={{
								margin: 0,
								padding: 0,
							}}
							component={Link}
							to={'/news/approve/' + props.row.id}
						>
							Duyệt
						</Button>
						<Button
							color="primary"
							style={{
								margin: 0,
								padding: 0,
							}}
							component={Link}
							to={'/news/cancel/' + props.row.id}
						>
							Tắt
						</Button>
					</>
				)}
				<Button
					color="primary"
					style={{
						margin: 0,
						padding: 0,
					}}
					component={Link}
					to={'/news/update/' + props.row.id}
				>
					<Icon>edit</Icon>
				</Button>
				<Button
					color="secondary"
					style={{
						margin: 0,
						padding: 0,
					}}
					component={Link}
					to={'/news/delete/' + props.row.id}
				>
					<Icon>delete_outline</Icon>
				</Button>
			</span>
		</Table.Cell>
	);
};
const CellComponent = (props) => {
	const { column } = props;
	if (column.name === 'action') {
		return <ActionCell {...props} />;
	}

	return (
		<Table.Cell
			{...props}
			style={{
				padding: 2,
				// color: '#81557a',
				fontSize: '12px',
			}}
		/>
	);
};

export default withStyles(styles)(NewsList);
