import React, { useState, useEffect } from 'react';
import DataTable from 'components/shared/DataTable';
import { pageSizes } from 'utils/datatable';
import {
	FormGroup,
	FormLabel,
	Select,
	MenuItem,
	Box,
	Input,
	Typography,
	Button,
} from '@material-ui/core';
import { useMerchantLevel } from 'hooks/level';
import { useLevels } from 'hooks/level';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Link, useParams, Route } from 'react-router-dom';
import AssignLevel from './AssignLevel';

const tableConfig = {
	columns: [
		{ name: 'action', title: 'Actions' },
		{ name: 'user_name', title: 'Tài khoản' },
		{ name: 'mc_code', title: 'Merchant code' },
		{ name: 'superior_user_name', title: 'Tên cấp trên' },
		{ name: 'level_name', title: 'Level' },
		{ name: 'gender', title: 'Giới tính' },
		{ name: 'phone', title: 'SĐT' },
		{ name: 'dob', title: 'Ngày sinh' },
		{ name: 'adr_state', title: 'Tỉnh/TP' },
		{ name: 'adr_district', title: 'Quận/Huyện' },
		{ name: 'adr_ward', title: 'Phường/Xã' },
		{ name: 'logged_in', title: 'Đã login' },
	],
	columnWidths: [
		{ columnName: 'action', align: 'center', width: 200 },
		{ columnName: 'user_name', align: 'center', width: 200 },
		{ columnName: 'mc_code', align: 'center', width: 200 },
		{ columnName: 'level', align: 'center', width: 200 },
		{ columnName: 'superior_id', align: 'center', width: 200 },
		{ columnName: 'superior_user_id', align: 'center', width: 200 },
		{ columnName: 'superior_user_name', align: 'center', width: 200 },
		{ columnName: 'level_name', align: 'center', width: 200 },
		{ columnName: 'user_code', align: 'center', width: 200 },
		{ columnName: 'gender', align: 'center', width: 200 },
		{ columnName: 'phone', align: 'center', width: 200 },
		{ columnName: 'dob', align: 'center', width: 200 },
		{ columnName: 'adr_state', align: 'center', width: 200 },
		{ columnName: 'adr_district', align: 'center', width: 200 },
		{ columnName: 'adr_ward', align: 'center', width: 200 },
		{ columnName: 'logged_in', align: 'center', width: 200 },
	],
};

const HighLevelManager = () => {
	const routerParams = useParams();
	const [params, setParams] = useState({
		page: 1,
		pagesize: 15,
		level: -1,
		keyword: '',
		orderby: 'level',
		asc: true,
	});
	const { data, isLoading, refetch } = useMerchantLevel(params);
	const { data: levels } = useLevels();
	useEffect(() => {
		refetch();
	}, [routerParams]);
	return (
		<div>
			<Box marginBottom="10px">
				<Typography variant="h5">Quản lý tài khoản theo cấp</Typography>
			</Box>
			<Box display="grid" gridGap="20px" gridTemplateColumns="repeat(5, 1fr)">
				<FormGroup>
					<FormLabel>Chọn cấp</FormLabel>
					<Select
						value={params.level}
						onChange={(e) =>
							setParams({ ...params, level: parseInt(e.target.value) })
						}
					>
						<MenuItem value={-1}>Tất cả</MenuItem>
						{levels?.data?.map((item) => (
							<MenuItem value={item.level_id} key={item.id}>
								{item.level_name}
							</MenuItem>
						))}
					</Select>
				</FormGroup>
				<FormGroup>
					<FormLabel>Từ khoá</FormLabel>
					<Input
						defaultValue={params.keyword}
						onChange={(e) => setParams({ ...params, keyword: e.target.value })}
					/>
				</FormGroup>
			</Box>
			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.columnWidths}
				currentPage={params.page - 1}
				totalCount={data?.total_count}
				pageSize={params.pagesize}
				pageSizes={pageSizes}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				loading={isLoading}
				CellComponent={CellComponent}
			/>

			<Route
				exact
				path="/managing-level/assign-level/:id"
				component={AssignLevel}
			/>
		</div>
	);
};

const ActionCell = (props) => {
	return (
		<Table.Cell
			style={{
				padding: 1,
			}}
		>
			<div
				style={{
					justifyContent: 'center',
					fontSize: '13px',
					display: 'flex',
				}}
			>
				<Button
					component={Link}
					to={{
						pathname: `/managing-level/assign-level/${props.row.user_id}`,
						state: props.row,
					}}
				>
					Chỉnh sửa
        </Button>
			</div>
		</Table.Cell>
	);
};

const CellComponent = (props) => {
	const { column, row } = props;
	switch (column.name) {
		case 'action':
			return <ActionCell {...props} />;
		case 'gender':
			return <Table.Cell> {row.gender === 0 ? 'Nam' : 'Nữ'}</Table.Cell>;
		case 'phone':
			return (
				<Table.Cell>{row.phone?.replace(/\d(?!\d{0,3}$)/g, '*')}</Table.Cell>
			);
		case 'logged_in':
			return <Table.Cell>{row.logged_in ? 'Đã login' : 'Chưa'}</Table.Cell>;

		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						// color: '#81557a',
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default HighLevelManager;
