import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTaskDetail, useTaskActiviti } from './services';
import moment from 'moment';
import EditTask from './edit';
import { Dialog, Button } from '@material-ui/core';
import './detail.css';
import CommentTask from './comment';
import NoData from 'components/Nodata';
import Moniter from "./moniter"
import { MdOutlineRefresh } from 'react-icons/md';
import { AiTwotoneEdit } from 'react-icons/ai';

const TaskDetail = () => {
	const { id } = useParams();
	const [openEdit, setOpenEdit] = useState({
		open: false,
		item: {},
	});
	const { data, refetch } = useTaskDetail({ id });

	return (
		<div className="task-detail">
			{data?.data ? (
				<>
					<div className="task-overview">
						<div className='base-info'>
							<h2 className="task-summary">{data?.data.summary}</h2>
							<p className="task-desc">{data?.data.description}</p>
						</div>
						<div className="actions">
							<Button
								color="primary"
								onClick={() => setOpenEdit({ item: data.data, open: true })}
								variant="contained"
							>
								<AiTwotoneEdit />
							</Button>
						</div>
					</div>
					<div className="task-info">
						<table className="task-info-item">
							<thead className="task-info-item-head">
								<tr>
									<th colSpan={2}>Trạng thái công việc</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<b>Trạng thái:</b>
									</td>
									<td>
										<span>{data?.data.status.name}</span>
										<div
											className="color"
											style={{ backgroundColor: data?.data.status.color }}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<b>Độ ưu tiên:</b>
									</td>
									<td>
										<span>{data?.data.priority.name} </span>
										<div
											className="color"
											style={{ backgroundColor: data?.data.priority.color }}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<b>Thời gian giao:</b>
									</td>
									<td>
										<span>
											{data?.data.submittedDate &&
												moment(data?.data.submittedDate).format(
													'hh:mm DD/MM/YYYY'
												)}
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<b>Deadline:</b>
									</td>
									<td>
										<span>
											{data?.data.dueDate &&
												moment(data?.data.dueDate).format('hh:mm DD/MM/YYYY')}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<table className="task-info-item">
							<thead className="task-info-item-head">
								<tr>
									<th colSpan={2}>Người nhận việc</th>
								</tr>
							</thead>
							<tbody>
								{data.data.assignee ? (
									<>
										<tr>
											<td>
												<b>Tên:</b>
											</td>
											<td>
												<span>{data?.data.assignee.name}</span>
											</td>
										</tr>
										<tr>
											<td>
												<b>SĐT:</b>
											</td>
											<td>
												<span>{data?.data.assignee.phone}</span>
											</td>
										</tr>
										<tr>
											<td>
												<b>Role:</b>
											</td>
											<td>
												<span>{data?.data.assignee.roleName}</span>
											</td>
										</tr>
									</>
								) : (
										<tr>
											<td colSpan={2}>
												<NoData />
											</td>
										</tr>
									)}
							</tbody>
						</table>

						<table className="task-info-item">
							<thead className="task-info-item-head">
								<tr>
									<th colSpan={2}>Người đề xuất</th>
								</tr>
							</thead>
							<tbody>
								{data.data.reporter ? (
									<>
										<tr>
											<td>
												<b>Tên:</b>
											</td>
											<td>
												<span>{data?.data.reporter.name}</span>
											</td>
										</tr>
										<tr>
											<td>
												<b>SĐT:</b>
											</td>
											<td>
												<span>{data?.data.reporter.phone}</span>
											</td>
										</tr>
										<tr>
											<td>
												<b>Role:</b>
											</td>
											<td>
												<span>{data?.data.reporter.roleName}</span>
											</td>
										</tr>
									</>
								) : (
										<tr>
											<td colSpan={2}>
												<NoData />
											</td>
										</tr>
									)}
							</tbody>
						</table>
						<table className="task-info-item">
							<thead className="task-info-item-head">
								<tr>
									<th colSpan={2}>Thông tin khác</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<b>Merchant code: </b>
									</td>
									<td>
										<span>{data?.data.mcCode}</span>
									</td>
								</tr>
								<tr>
									<td>
										<b>Sub merchant code: </b>
									</td>
									<td>
										<span>{data?.data.subMcCode}</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="other-info">
						<Moniter item={data?.data}/>
						<TaskActiviti />
						<CommentTask />
					</div>
					<Dialog
						maxWidth="md"
						fullWidth
						open={openEdit.open}
						onClose={() => setOpenEdit({ open: false, item: {} })}
					>
						<EditTask
							item={openEdit.item}
							onClose={() => setOpenEdit({ open: false, item: {} })}
							refetch={refetch}
						/>
					</Dialog>
				</>
			) : (
					<NoData />
				)}
		</div>
	);
};

const TaskActiviti = () => {
	const { id } = useParams();
	const { data, refetch } = useTaskActiviti({
		id,
	});
	return (
		<div className="task-activiti">
			<h2 className="title">
				Lịch sử hoạt động <MdOutlineRefresh onClick={refetch} />
			</h2>
			<table>
				<thead>
					<tr>
						<th>ID</th>
						<th style={{ maxWidth: 40 }}>Thời gian</th>
						<th>Lịch sử hoạt động</th>
					</tr>
				</thead>
				<tbody>
					{data?.data.map((item) => (
						<tr key={item.id}>
							<td>{item.id}</td>
							<td style={{ maxWidth: 40 }}>
								{moment(item.modifiedDate).format('YYYY-MM-DD hh:mm')}
							</td>
							<td>{item.activities}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TaskDetail;
