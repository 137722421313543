import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { useAgentLead } from 'hooks/leadAgent';
import { useProduct } from 'hooks/products';
import React, { useState } from 'react';
import { isTelesaleMerchant } from 'service';
import { useCreateRevenueKpi } from './services';

const Create = ({ onClose, refetch }) => {
	const date = new Date();
	const [params, setParams] = useState({
		year: date.getFullYear(),
		month: date.getMonth(),
		agent_code: '',
		agent_name: '',
		agent_phone: '',
		mc_product_code: '',
		mc_product_name: '',
		team_name: '',
		sub_mc_id: 0,
		sub_mc_code: '',
		revenue_plan: '',
	});

	const { data: agents } = useAgentLead({});
	const { data: products } = useProduct(
		{},
		isTelesaleMerchant() ? 'merchant-product' : 'product'
	);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setParams({ ...params, [name]: value });
	};

	const handleChangeAtribute = (event) => {
		const atribute = event.nativeEvent.target.dataset;
		delete atribute.value;
		setParams({
			...params,
			...atribute,
		});
	};
	const { mutateAsync } = useCreateRevenueKpi();

	const handleSubmit = () => {
		mutateAsync(params).then(()=>{
			onClose();
			refetch()
		});
	};

	return (
		<>
			<DialogTitle>Tạo KPI</DialogTitle>
			<DialogContent>
				<form className="form-create-revenue-kpi">
					<TextField
						label="Năm"
						onChange={(e) =>
							setParams({ ...params, year: parseInt(e.target.value) })
						}
						value={params.year}
						type="number"
					/>
					<FormControl>
						<InputLabel>Tháng</InputLabel>
						<Select name="month" onChange={handleChange} value={params.month}>
							{Array.from({ length: 12 }, (_, i) => (
								<MenuItem value={i + 1} key={i}>
									{i + 1}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Agent</InputLabel>
						<Select
							name="agent_code"
							value={params.agent_code}
							onChange={handleChangeAtribute}
						>
							{agents?.data.map((item) => (
								<MenuItem
									value={item.code}
									key={item.code}
									data-agent_code={item.code}
									data-agent_phone={item.phone}
									data-agent_name={item.name}
									data-sub_mc_id={item.sub_mc_id}
									data-sub_mc_code={item.sub_mc_code}
									data-team_name={item.team_name}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Product</InputLabel>
						<Select
							name="mc_product_code"
							value={params.mc_product_code}
							onChange={handleChangeAtribute}
						>
							{products?.data.map((item) => (
								<MenuItem
									value={item.code}
									data-mc_product_code={item.code}
									key={item.code}
									data-mc_product_name={item.name}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						value={params.revenue_plan}
						onChange={handleChange}
						label="Plan (triệu đồng)"
						name="revenue_plan"
						type="number"
					/>
				</form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Huỷ</Button>
				<Button color="primary" variant="contained" onClick={handleSubmit}>
					Lưu
				</Button>
			</DialogActions>
		</>
	);
};

export default Create;
