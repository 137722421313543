import React from 'react';
import {
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import JSZip from 'jszip';
import fileDownload from 'js-file-download';
import cs from 'const.js';
import moment from 'moment';
import { useExportRaw, useExportData } from './services';

async function creatPdf({ zip, elements }) {
	var img = zip.folder('images');

	for (let i = 0; i < elements.length; i++) {
		const el = elements.item(i);

		var dataURL = el.toDataURL('image/png');
		const rl = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');

		img.file(`file ${i}.png`, rl, { base64: true });
	}
}

const Export = ({ onClose, params, type, search }) => {
	const handleExportAllImage = async () => {
		const zip = new JSZip();

		const elements = document.getElementsByClassName('custom-chart');

		await creatPdf({ zip, elements });

		zip.generateAsync({ type: 'blob' }).then(function (content) {
			fileDownload(content, 'edm8.zip');
		});
	};

	const { mutateAsync: exportRaw } = useExportRaw();
	const { mutateAsync: exportData } = useExportData();

	const handleExportRaw = async () => {
		exportRaw({
			...params,
			reportType: type.split('?')[0].split('-')[2],
			level: type.split('=')[1] ? type.split('=')[1] : '',
			search,
		});
	};

	const handleExportData = async () => {
		exportData({ ...params, search });
	};
	return (
		<>
			<DialogTitle>Export dữ liệu</DialogTitle>
			<DialogContent>
				<div>
					<div>
						<h3>Export biểu đồ:</h3>
						<Button
							onClick={handleExportAllImage}
							variant="contained"
							color="primary"
						>
							Export tất cả biểu đồ
						</Button>
					</div>

					<div>
						<h3>Export dữ liệu:</h3>
						<Button
							onClick={handleExportData}
							variant="contained"
							color="primary"
						>
							Export dữ liệu
						</Button>
					</div>

					<div>
						<h3>Export raw:</h3>
						<Button
							onClick={handleExportRaw}
							variant="contained"
							color="primary"
						>
							Export Raw
						</Button>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="error" onClick={onClose}>
					Huỷ
				</Button>
			</DialogActions>
		</>
	);
};

export default Export;
