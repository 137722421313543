import { useQuery } from '@tanstack/react-query';
import { authAxios } from 'utils/api';

export const useTimeTable = (params) => {
	return useQuery(
		['timetable', { page: params.page, size: params.pagesize }],
		async () => {
			const fix = {};
			for (const key in params) {
				if (params[key] === 'ALL') fix[key] = '';
				else fix[key] = params[key];
			}
			if (params.searchDateType === 'date') {
				delete fix.from_date;
				delete fix.to_date;
			} else if (params.searchDateType === 'range') {
				delete fix.date;
			} else {
				delete fix.from_date;
				delete fix.to_date;
				delete fix.date;
			}
			delete fix.searchDateType;
			const { data } = await authAxios({
				url: '/telesale/agent/list/timetable',
				method: 'GET',
				params: fix,
			});
			return data;
		}
	);
};

export const useTimeTableConfig = () => {
	return useQuery(['timetable-config'], async () => {
		const { data } = await authAxios({
			url: '/telesale/agent/timetable/config',
			method: 'GET',
		});
		return data;
	});
};
