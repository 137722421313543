import productsApi from "../api/products.api"
import { useQuery } from "@tanstack/react-query"

export const useProduct = (params, url) => {
	const query = useQuery(["products", params], ({ queryKey }) => productsApi.searchProduct(queryKey, url))
	return query
}

export const useInventoryProduct = (params) => {
	const query = useQuery(["inventoryPro", params], ({ queryKey }) => productsApi.searchInventoryProduct(queryKey[1]))
	return query
}
