import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { authAxios } from 'utils/api';

export const useUpdateNews = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/news/update',
				method: 'POST',
				data: params,
				headers: { 'Content-Type': 'multipart/form-data' },
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.code === 100) {
					toast.success('Update new thành công');
				} else {
					toast.error(data.error);
				}
			},
		}
	);
};

export const useNewsDetail = (params) => {
	return useQuery(
		['news-detail', params],
		async () => {
			const { data } = await authAxios({
				url: '/telesale/news/detail',
				params: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.code === 100) {
					toast.success('Update new thành công');
				} else {
					toast.error(data.error);
				}
			},
		}
	);
};

export const useCreateNews = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/news/create',
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'Apmultipart/form-data',
				},
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data.inserted) {
					toast.success('Tạo new thành công');
				} else {
					toast.error(data.error);
				}
			},
		}
	);
};
