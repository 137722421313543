import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useCreateRevenueKpiTeam } from './services';
import { useTeam } from 'hooks/lead';

const CreateKpiTeam = ({ onClose, refetch }) => {
	const date = new Date();
	const [params, setParams] = useState({
		year: date.getFullYear(),
		month: date.getMonth(),
		team_name: '',
		revenue_plan: "",
	});

	const { data: teams } = useTeam({});

	const handleChange = (event) => {
		const { name, value } = event.target;
		setParams({ ...params, [name]: value });
	};

	const { mutateAsync } = useCreateRevenueKpiTeam();

	const handleSubmit = () => {
		mutateAsync(params).then(() => {
			onClose();
			refetch();
		});
	};

	return (
		<>
			<DialogTitle>Tạo KPI theo team</DialogTitle>
			<DialogContent>
				<form className="form-create-revenue-kpi">
					<TextField
						label="Năm"
						onChange={(e) =>
							setParams({ ...params, year: parseInt(e.target.value) })
						}
						value={params.year}
						type="number"
					/>
					<FormControl>
						<InputLabel>Tháng</InputLabel>
						<Select name="month" onChange={handleChange} value={params.month}>
							{Array.from({ length: 12 }, (_, i) => (
								<MenuItem value={i + 1} key={i}>
									{i + 1}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Team</InputLabel>
						<Select
							name="team_name"
							value={params.team_name}
							onChange={handleChange}
						>
							{teams?.data?.map((item, index) => (
								<MenuItem key={index} value={item.name}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						value={params.revenue_plan}
						onChange={(e) =>
							setParams({ ...params, revenue_plan: parseInt(e.target.value) })
						}
						label="Plan (VND)"
						name="revenue_plan"
						type="number"
					/>
				</form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Huỷ</Button>
				<Button color="primary" variant="contained" onClick={handleSubmit}>
					Lưu
				</Button>
			</DialogActions>
		</>
	);
};

export default CreateKpiTeam;
