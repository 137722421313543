import axios from './axios';

const newList = async (params) => {
	const { data } = await axios({
		url: '/telesale/news/list',
		params: params,
		method: 'GET',
	});
	return data;
};

const newLeaders = async (params) => {
	const { data } = await axios({
		url: '/telesale/news/leader',
		method: 'GET',
		params: params,
	});
	return data;
};

const newsProducts= async (params) => {
	const { data } = await axios({
		url: '/telesale/news/product',
		method: 'GET',
		params: params,
	});
	return data;
};

export default {
	newList,
	newLeaders,
	newsProducts
};
