import cs from './const';

export const getRole = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	if (!user) return null;
	var userrole = JSON.parse(String(user)).role;
	return userrole;
};

export const getFunctionRole = () => {
	const user = localStorage.getItem(cs.System_Code + '-general-user');
	if (!user) return null;
	const functionRoles = JSON.parse(String(user)).functionRoles;
	return functionRoles;
};

export const isValidFunctionRole = (validFunctionRoles) => {
	let valid = false;
	const functionRoles = getFunctionRole();
	const functionRoleIds = [];
	for (const item of functionRoles) {
		functionRoleIds.push(item.functionRoleId);
	}

	const checkRoleCondition = (role) => validFunctionRoles.includes(role);
	const checkRole = functionRoleIds.some(checkRoleCondition);
	if (checkRole) valid = true;
	return valid;
};

export const isSalesPlusMerchant = () => {
	const functionRoles = getFunctionRole();
	const functionRoleIds = [];
	for (const item of functionRoles) {
		functionRoleIds.push(item.functionRoleId);
	}

	const validFunctionRoles = [
		cs.Function_Role_MC_Executive_Warehouse,
		cs.Function_Role_MC_Order_Coordinator,
	];
	const checkRoleCondition = (role) => validFunctionRoles.includes(role);
	return functionRoleIds.some(checkRoleCondition);
};

export const isSupplierForEndUserOrder = () => {
	const functionRoles = getFunctionRole();
	const functionRoleIds = [];
	for (const item of functionRoles) {
		functionRoleIds.push(item.functionRoleId);
	}

	const validFunctionRoles = [
		cs.Function_Role_Supplier_General_Director_Level,
		cs.Function_Role_Supplier_Executive_Warehouse_For_End_User_Order,
		cs.Function_Role_Supplier_Sales_Coordinator_For_End_User_Order,
	];
	const checkRoleCondition = (role) => validFunctionRoles.includes(role);
	return functionRoleIds.some(checkRoleCondition);
};

export const isSupplierForBookingOfMerchant = () => {
	const functionRoles = getFunctionRole();
	const functionRoleIds = [];
	if (Array.isArray(functionRoles) && functionRoles.length > 0) {
		for (const item of functionRoles) {
			functionRoleIds.push(item.functionRoleId);
		}
		const validFunctionRoles = [
			cs.Function_Role_Supplier_Coordinator_For_Booking_Of_Mc,
			cs.Function_Role_Supplier_Warehouse_Executive_For_Booking_Of_Mc,
		];
		const checkRoleCondition = (role) => validFunctionRoles.includes(role);
		return functionRoleIds.some(checkRoleCondition);
	}
};

export const isTelesaleHub = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	if (!user) return false;
	var userrole = JSON.parse(String(user)).role;
	return (
		userrole === cs.Role_Telesale_Hub_User ||
		userrole === cs.Role_Telesale_Hub_Leader ||
		userrole === cs.Role_Telesale_Hub_Manager
	);
};

export const isTelesaleMerchantManager = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	if (!user) return false;
	var userrole = JSON.parse(String(user)).role;
	return userrole === cs.Role_Telesale_Merchant_Manager;
};

export const isTelesaleMerchant = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	if (!user) return false;
	var userrole = JSON.parse(String(user)).role;
	return (
		userrole === cs.Role_Telesale_Merchant_Leader ||
		userrole === cs.Role_Telesale_Merchant_Manager ||
		userrole === cs.Role_Telesale_Merchant_User ||
		userrole === cs.Role_Mc_Board_Of_Control_Member ||
		userrole === cs.Role_Telesale_Merchant_Branch_Director
	);
};

export const isTelesaleMc = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	if (!user) return false;
	var userrole = JSON.parse(String(user)).role;
	return (
		userrole === cs.Role_Telesale_Merchant_Leader ||
		userrole === cs.Role_Telesale_Merchant_Manager ||
		userrole === cs.Role_Telesale_Merchant_User
	);
};

const isTlsMerchantNonUser = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	if (!user) return false;
	var userrole = JSON.parse(String(user)).role;
	return (
		// userrole === cs.Role_Telesale_Hub_User ||
		userrole === cs.Role_Telesale_Merchant_Leader ||
		userrole === cs.Role_Telesale_Merchant_Manager ||
		userrole === cs.Role_Mc_Board_Of_Control_Member
	);
};

export const isMarketingMerchant = () => {
	var user = localStorage.getItem(cs.System_Code + '-card-user');
	if (!user) return false;
	var userrole = JSON.parse(String(user)).role;
	return (
		userrole === cs.Role_Marketing_Merchant_Leader ||
		userrole === cs.Role_Marketing_Merchant_Manager ||
		userrole === cs.Role_Marketing_Merchant_User
	);
};

export const isTelesaleOP = () => {
	var user = localStorage.getItem(cs.System_Code + '-card-user');
	var userrole = JSON.parse(String(user)).role;
	return (
		userrole === cs.Role_Telesale_Hub_Leader ||
		userrole === cs.Role_Telesale_Hub_Manager
	);
};
