import React from 'react';
import NoDataImg from './no_data.svg';
import "./style.css"

const NoData = () => {
	return (
		<div className="no-data">
			<img src={NoDataImg} alt="no data"  />
			<div className="">
				<h1 className=""> Không có dữ liệu</h1>
			</div>
		</div>
	);
};

export default NoData;
