import { useQuery } from "@tanstack/react-query"
import merchantApi from "api/merchant.api";

export const useMerchant = (params) => {
	return useQuery(['merchant', params], () => merchantApi.getMerchantList(params));
};

export const useMerchantProduct = (params) => {
	const query = useQuery(['merchant_product', params], () =>
		merchantApi.searchMerchantProduct(params)
	);
	return query;
};

