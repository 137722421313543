import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import cs from 'const.js';

const editNewsURL = cs.BaseURL + "/telesale/news/edit";
const styles = ()=> ({});


class RequestEditNews extends React.Component {
    state = {
        confirm_code: ""
    };
    handleDialogAgree = () => {
        if (!this.props.match.params.id) {
            window.alert("Wrong information !");
            this.props.history.push({
                pathname: '/news',
                state: { reload: true }
            });
            return;
        }

        var campaign_id = this.props.match.params.id;
        let queryString = `${editNewsURL}`;

        fetch(queryString, {
            method: "POST",
            body: JSON.stringify({
                id: campaign_id
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem(cs.System_Code + "-general-token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: "error",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add("News can be edited !", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/news',
                    state: { reload: true }
                });
                //window.location.href = "/news";
            })
            .catch(() => { });
    };

    handleFieldChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => { }
        );
    };
    render() {
        return (
            <div>
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Yêu cầu bài viết sửa lại?
                    </DialogTitle>{" "}
                    <DialogActions>
                        <Button
                            onClick={this.props.history.goBack}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleDialogAgree}
                            color="primary"
                            autoFocus
                        >
                            YES
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

RequestEditNews.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RequestEditNews);
