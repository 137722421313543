import { useQuery } from '@tanstack/react-query';
import { authAxios } from 'utils/api';

export const useKpi = (params) => {
	return useQuery(['kpi', params], async () => {
		const { data } = await authAxios({
			url: '/telesale/lead/kpi/list',
			method: 'GET',
			params: params,
		});
		return data;
	});
};
