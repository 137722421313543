import React, { useState } from 'react';
import {
	DialogActions,
	DialogTitle,
	DialogContent,
	Button,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Dialog,
} from '@material-ui/core';
import { usePeopleForAddMonitor, useMonitors, useAddMonitor } from './services';
import { useParams, useLocation } from 'react-router-dom';
import { getUserId } from 'utils/auth';
import './monito.css';

const userId = getUserId();

const Moniter = ({ item }) => {

	const { id } = useParams();
	const [openCreate, setOpenCreate] = useState(false);
	const { data, refetch } = useMonitors({ id });

	return (
		<div className="moniter">
			<h2 className="title">
				Người giám sát{' '}
				{item?.reporter.id === userId && (
					<Button
						onClick={() => setOpenCreate(true)}
						variant="outlined"
						color="inherit"
						size="small"
					>
						Thêm người giám sát
					</Button>
				)}
			</h2>

			<table className="moniter-list">
				<thead className="task-info-item-head">
					<tr>
						<th>ID</th>
						<th>Tên</th>
						<th>SĐT</th>
						<th>Role</th>
					</tr>
				</thead>
				<tbody>
					{data?.data.map((item) => (
						<>
							<tr>
								<td>
									<span>{item.person.id}</span>
								</td>
								<td>
									<span>{item.person.name}</span>
								</td>
								<td>
									<span>{item.person.phone}</span>
								</td>
								<td>
									<span>{item.person.roleName}</span>
								</td>
							</tr>
						</>
					))}
				</tbody>
			</table>

			<Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
				<Create onClose={() => setOpenCreate(false)} refetch={refetch} />
			</Dialog>
		</div>
	);
};

const Create = ({ onClose, refetch }) => {
	const { id } = useParams();
	const [params, setParams] = useState({
		taskId: parseInt(id),
		personId: 1,
		personIsAgent: true,
	});
	const { mutateAsync } = useAddMonitor();
	const { data } = usePeopleForAddMonitor();
	const handleSubmit = () => {
		mutateAsync(params).then(() => {
			onClose();
			refetch();
		});
	};
	const handleChangeAtribute = (event) => {
		const atribute = event.nativeEvent.target.dataset;

		setParams({
			...params,
			personIsAgent: JSON.parse(atribute.personIsAgent),
			personId: parseInt(atribute.personId),
		});
	};
	return (
		<>
			<DialogTitle>Thêm người giám sát</DialogTitle>
			<DialogContent>
				<form>
					<FormControl fullWidth>
						<InputLabel>Người giám sát</InputLabel>
						<Select onChange={handleChangeAtribute}>
							{data?.data.map((item) => (
								<MenuItem
									value={item.id}
									key={item.id}
									data-person-is-agent={item.isAgent}
									data-person-id={item.id}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Huỷ</Button>
				<Button onClick={handleSubmit} variant="contained" color="primary">
					Thêm
        </Button>
			</DialogActions>
		</>
	);
};

export default Moniter;
