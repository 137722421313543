import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogWrapper from 'components/shared/DialogWrapper';
import { useInventoryProduct } from 'hooks/products';
import { useMutation } from '@tanstack/react-query';
import inventoryApi from 'api/inventory.api';
import { useHistory } from "react-router-dom"

const styles = theme => ({});


const CreateInventoryIn = (props) => {
	const history = useHistory()
	const { data: inventoryProducts } = useInventoryProduct({})
	const [body, setBody] = useState({
		product_name: "",
		product_id: "",
		quantity: 0
	})

	const createInventory = useMutation(inventoryApi.createInventory * body)


	const handleDialogAgree = () => {
		if (body.quantity && isNaN(body.quantity)) {
			window.alert('Incorrect amount !');
			return;
		}

		if (!body.product_name || !body.quantity
		) {
			window.alert('Please input information !');
			return;
		}
		createInventory.mutate()
	};


	return (
		<DialogWrapper title={'Nhập kho'} {...props}>
			<DialogContent>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel htmlFor="merchantId">Sản phẩm</InputLabel>
						<Select
							value={body.product_id}
							onChange={(e) => setBody({ ...body, product_id: e.target.value })}
							inputProps={{
								name: 'package_type',
								id: 'package_type'
							}}
						>
							{inventoryProducts?.data?.map(item => (
								<MenuItem value={item.id}>{item.product_name}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<TextField
						InputProps={{
							readOnly: true,
						}}
						fullWidth
						margin="dense"
						id="priority"
						required={true}
						value={body.product_code}
						onChange={(e) => setBody({ ...body, product_id: e.target.value })}
						label="Mã sản phẩm"
						type='text'
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						fullWidth
						margin="dense"
						id="priority"
						required={true}
						value={body.quantity}
						onChange={(e) => setBody({ ...body, quantity: e.target.value })}
						label="Số lượng"
						type='number'
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={history.goBack} color="primary">
					Hủy
				</Button>
				<Button onClick={handleDialogAgree} color="primary">
					Nhập kho
				</Button>
			</DialogActions>
		</DialogWrapper>
	);
}


export default withStyles(styles)(CreateInventoryIn);
