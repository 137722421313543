import React, { useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import {
	Grid,
	Table,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	TableSelection,
	TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {
	PagingState,
	SortingState,
	CustomPaging,
	GroupingState,
	IntegratedGrouping,
	IntegratedSelection,
	SelectionState,
} from '@devexpress/dx-react-grid';
import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactDOM from 'react-dom';
import useInterval from 'components/shared/useInterval';
import cl from 'mycolor';
import { TableSettingContext } from 'contexts/TableSettingContext';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const SortingIcon = ({ direction }) => {
	return (
		<>
			{direction == 'asc' ? (
				<ArrowUpward style={{ fontSize: '18px' }} />
			) : (
				<ArrowDownward style={{ fontSize: '18px' }} />
			)}
		</>
	);
};
const SortLabel = ({ onSort, children, direction }) => (
	<div
		onClick={onSort}
		style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			cursor: 'pointer',
		}}
	>
		{children}
		{direction && <SortingIcon direction={direction} />}
	</div>
);
const styles = (theme) => ({
	grid_Container: {
		width: '100%',
		marginTop: theme.spacing(2),
		overflowX: 'auto',
		overflow: 'hidden',
	},
	tableStriped: {
		'& tbody tr:nth-of-type(odd)': {
			//backgroundColor: 'rgb(186, 207, 255)'
			backgroundColor: '#ffffff', // white
		},
		'& tbody tr:nth-of-type(even)': {
			backgroundColor: cl.grannyapple,
			color: '#fff',
		},
	},
	cell_button: {
		width: 100,
	},
});

function DataTable({
	rows = [],
	columns = [],
	sorting = [],
	currentPage = 0,
	pageSize = 10,
	pageSizes = [],
	loading = false,
	totalCount = 0,
	columnWidths = [],
	changeSorting = () => null,
	changeCurrentPage = () => null,
	changePageSize = () => null,
	ActionCell = null,
	CellComponent = null,
	autoLoadData = () => null,
	groupByColumn = '',
	onSelectionChange = () => null,
	isGroupRow = () => null,
	onExpandedGroupsChange = () => null,
	TableSelectionHeaderCell = () => null,
}) {
	const { tableheight, autoreload, intervaltime } =
		useContext(TableSettingContext);

	useInterval(() => {
		if (autoreload) {
			autoLoadData();
			const dom = ReactDOM.findDOMNode(document.body).children || [];
			for (let item of dom)
				if (item.className === '' && item.style.pointerEvents === 'all')
					item.style.pointerEvents = 'none';
		}
	}, intervaltime * 1000);

	const getRowId = (row) => row.id;

	const RowComponent = (props) => {
		return (
			<Table.Row
				{...props}
				style={{
					margin: 1,
					padding: 1,
					height: 40,
				}}
			/>
		);
	};

	const HeaderRowComponent = (props) => {
		return (
			<TableHeaderRow.Row
				{...props}
				style={{
					margin: 1,
					padding: 1,
					height: 46,
					//backgroundColor: 'rgb(63, 109, 193)'
					backgroundColor: cl.bluestone,
					left: 0,
					position: props.fill ? 'sticky' : 'static',
				}}
			/>
		);
	};

	const DefaultCellComponent = (props) => {
		const { column } = props;
		if (column.name === 'action') {
			return (
				<Table.Cell
					{...props}
					style={{
						display: 'flex',
						padding: 1,
						justifyContent: 'center',
						fontSize: '11px',
					}}
				>
					<ActionCell {...props} />
				</Table.Cell>
			);
		}
		// Để tô màu cho các ô thì ở data gửi vào kèm theo trường dataName_color với dataName là tên của ô cần tô màu
		const bg = props.row[props.column.name + '_color']
			? props.row[props.column.name + '_color']
			: 'white';
		const color =
			props.row[props.column.name + '_color'] === '#f44336' ? 'white' : 'black';
		return (
			<Table.Cell
				{...props}
				style={{
					padding: 1,
					color: color,
					backgroundColor: bg,
					fontSize: '14px',
					fontWeight: '900',
					left: 0,
					position: props.fill ? 'sticky' : 'static',
				}}
			/>
		);
	};

	const HeaderCellComponent = (props) => {
		return (
			<TableHeaderRow.Cell
				{...props}
				style={{
					//color: 'white',
					color: '#FFFFFF',
					fontSize: '14px',
					fontWeight: '900',
					textAlign: 'center',
				}}
			/>
		);
	};

	const tableMessages = {
		noData: 'Không có dữ liệu',
	};

	const pagingPanelMessages = {
		showAll: 'Hiển thị tất cả',
		rowsPerPage: 'Số dòng mỗi trang',
		info: '{from} đến {to} / Tổng : {count}',
	};

	return (
		<Paper
			style={{
				width: '100%',
				marginTop: '20px',
				overflowX: 'auto',
				position: 'relative',
				opacity: loading ? '0.5' : '',
			}}
		>
			<Grid rows={rows} columns={columns} getRowId={getRowId}>
				<SortingState sorting={sorting} onSortingChange={changeSorting} />
				<PagingState
					currentPage={currentPage}
					onCurrentPageChange={changeCurrentPage}
					onPageSizeChange={changePageSize}
					pageSize={pageSize}
				/>
				<CustomPaging totalCount={totalCount} />
				<Table
					columnExtensions={columnWidths}
					tableComponent={TableComponent}
					cellComponent={CellComponent ? CellComponent : DefaultCellComponent}
					rowComponent={RowComponent}
					messages={tableMessages}
					height={`${tableheight}px`}
					loading={loading}
				/>
				{columnWidths.length > 0 && (
					<TableColumnResizing defaultColumnWidths={columnWidths} />
				)}

				{groupByColumn != '' ? (
					<GroupingState
						defaultGrouping={[{ columnName: groupByColumn }]}
						expandedGroups={expandedGroups}
						onExpandedGroupsChange={onExpandedGroupsChange}
					/>
				) : (
					<GroupingState defaultGrouping={[]} />
				)}
				<IntegratedGrouping />
				<SelectionState onSelectionChange={onSelectionChange} />
				<IntegratedSelection isGroupRow={isGroupRow} />
				{groupByColumn != '' && (
					<Table
						columnExtensions={columnWidths}
						messages={tableMessages}
						cellComponent={CellComponent ? CellComponent : DefaultCellComponent}
					/>
				)}
				{groupByColumn != '' && (
					<TableSelection
						showSelectAll={true}
						selectByRowClick={true}
						highlightRow={true}
						headerCellComponent={TableSelectionHeaderCell}
					/>
				)}
				<TableHeaderRow
					showSortingControls
					sortLabelComponent={SortLabel}
					className={styles.grid_header}
					rowComponent={HeaderRowComponent}
					cellComponent={HeaderCellComponent}
				/>
				<TableGroupRow />
				<PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
				{loading && (
					<CircularProgress
						style={{
							position: 'absolute',
							left: '50%',
							top: '200px',
							zIndex: 999,
						}}
					/>
				)}
			</Grid>
		</Paper>
	);
}

export default DataTable;

const TableComponentBase = ({ classes, ...restProps }) => (
	<VirtualTable.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
	TableComponentBase
);
