import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

export const ThemeContext = React.createContext();

export function MyThemeProvider(props) {
	const [isDark, setDark] = useState(false);
	//Table
	const darkTheme = createTheme();

	const handleThemeChange = () => {
		localStorage.setItem('aidriven-general-theme', !isDark);
		setDark(!isDark);
	};
	useEffect(() => {
		const theme = localStorage.getItem('aidriven-general-theme');
		if (theme) setDark(JSON.parse(theme));
	}, []);

	return (
		<ThemeContext.Provider
			value={{
				handleThemeChange: handleThemeChange,
				isDark: isDark,
			}}
		>
			<ThemeProvider theme={darkTheme}>{props.children}</ThemeProvider>
		</ThemeContext.Provider>
	);
}

export default ThemeContext;
