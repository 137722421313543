import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ value, onChange }) => {
	return (
		<div className="text-editor">
			<ReactQuill theme="snow" value={value} onChange={(e) => onChange(e)} modules={Editor.modules} />
		</div>
	)
}

var toolbarOptions = [
	['bold', 'italic', 'underline', 'strike'],        // toggled buttons
	['blockquote'],
	[{ 'header': 1 }, { 'header': 2 }],               // custom button values
	[{ 'list': 'ordered' }, { 'list': 'bullet' }],
	[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
	[{ 'direction': 'rtl' }],                         // text direction
	[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	['link', 'image'],
	[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

	['clean']                                         // remove formatting button
];

Editor.modules = {
	toolbar: {
		container: toolbarOptions,
	},
};

/*
 * Quill editor formats
 * See http://quilljs.com/docs/formats/
 */
Editor.formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'color',
];

export default Editor
