import React, { useState, useEffect } from 'react';
import DataTable from 'components/shared/DataTable';
import {
	tableConfig,
	rateChartOption,
	optionsChart,
	mainChartData,
	rateChartData,
} from './helper';
import { useSummary } from './services';
import { pageSizes } from 'utils/datatable';
import { Box, Grid } from '@material-ui/core';
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController
);

const GridItem = (props) => (
	<Grid {...props} item xs={12} md={6} marginBottom={2} />
);

const Table = ({ url, levels, params, setParams, title, index, search }) => {
	const [mainChart, setMainChart] = useState({
		lebels: [],
		datasets: [],
	});
	const [rateChart, setRateChart] = useState({
		lebels: [],
		datasets: [],
	});
	const [currentParams, setCurrentParams] = useState({
		pagesize: 15,
		page: 1,
		orderby: '',
		asc: false,
	});
	const { data, isLoading, refetch } = useSummary(
		{
			...params,
			page: currentParams.page,
			orderby: currentParams.orderby,
			asc: currentParams.asc,
			pagesize: currentParams.pagesize,
			search,
		},
		url
	);

	const changeSorting = (e) => {
		setCurrentParams({
			...currentParams,
			orderby: e[0].columnName,
			asc: currentParams.asc ? false : true,
		});
	};

	useEffect(() => {
		if (data?.list) {
			setMainChart({ ...mainChartData(data.list) });
			setRateChart({ ...rateChartData(data.list) });
		}
	}, [data?.list]);

	return (
		<Box>
			<h2>{title}</h2>
			{index === 0 && (
				<>
					<h3
						style={{
							textAlign: 'center',
							textTransform: 'uppercase',
						}}
					>
						Thống kê số liệu
					</h3>
					{data?.summary && (
						<Grid container margin="0 auto">
							<GridItem>
								<table
									style={{
										margin: '0 auto',
										borderCollapse: 'collapse',
										borderBottom: '1px solid #ccc',
										width: '100%',
									}}
								>
									<tbody style={{ borderBottom: '1px solid #ccc' }}>
										{summaryData1.map((item) => (
											<tr key={item.name} style={{}}>
												<td
													style={{
														whiteSpace: 'nowrap',
														borderTop: '1px solid #ccc',
														borderLeft: '1px solid #ccc',
														padding: '3px 6px',
														fontSize: '14px',
														fontWeight: '700',
													}}
												>
													{item.title}
												</td>
												<td
													style={{
														whiteSpace: 'nowrap',
														borderTop: '1px solid #ccc',
														borderRight: '1px solid #ccc',
														padding: '3px 6px',
														borderLeft: '1px solid #ccc',
														fontSize: '14px',
														width: '100%',
													}}
												>
													{data.summary[item.name]
														? data.summary[item.name]
														: ''}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</GridItem>
							<GridItem>
								<table
									style={{
										margin: '0 auto',
										borderCollapse: 'collapse',
										borderBottom: '1px solid #ccc',
										width: '100%',
									}}
								>
									<tbody style={{ borderBottom: '1px solid #ccc' }}>
										{summaryData2.map((item) => (
											<tr key={item.name} style={{}}>
												<td
													style={{
														whiteSpace: 'nowrap',
														borderTop: '1px solid #ccc',
														borderLeft: '1px solid #ccc',
														padding: '3px 6px',
														fontSize: '14px',
														fontWeight: '700',
													}}
												>
													{item.title}
												</td>
												<td
													style={{
														whiteSpace: 'nowrap',
														borderTop: '1px solid #ccc',
														borderRight: '1px solid #ccc',
														padding: '3px 6px',
														borderLeft: '1px solid #ccc',
														fontSize: '14px',
														width: '100%',
													}}
												>
													{data.summary[item.name]
														? data.summary[item.name]
														: ''}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</GridItem>
						</Grid>
					)}
				</>
			)}

			<DataTable
				rows={data?.list}
				columns={tableConfig(url, levels).col}
				columnWidths={tableConfig(url, levels).config}
				totalCount={data?.total_count}
				currentPage={currentParams.page - 1}
				changeCurrentPage={(p) =>
					setCurrentParams({ ...currentParams, page: p + 1 })
				}
				pageSize={currentParams.pagesize}
				pageSizes={pageSizes}
				changeSorting={changeSorting}
				sorting={[
					{
						columnName: currentParams.orderby,
						direction: currentParams.asc ? 'asc' : 'desc',
					},
				]}
				loading={isLoading}
			/>

			<Box>
				<Box textAlign="center" marginBottom="30px">
					<h2 style={{ textTransform: 'uppercase' }}>Thống kê theo số lượng</h2>
				</Box>
				<Chart
					options={optionsChart}
					data={mainChart}
					className="custom-chart"
				/>
			</Box>
			<Box marginBottom="100px">
				<Box textAlign="center" marginBottom="30px">
					<h2 style={{ textTransform: 'uppercase' }}>
						Thống kê theo phần trăm
					</h2>
				</Box>
				<Chart
					options={rateChartOption}
					data={rateChart}
					className="custom-chart"
				/>
			</Box>
		</Box>
	);
};

const summaryData1 = [
	{ name: 'display_name', title: 'Hiển thị' },
	{ name: 'total_assigned_qty', title: 'Phân bổ' },
	{ name: 'assigned_qty', title: 'Chưa gọi' },
	{ name: 'total_called_qty', title: 'Đã gọi' },
	{ name: 'called_rate', title: '% gọi' },
	{ name: 'purchased_qty', title: 'Đã mua hàng' },
	{ name: 'purchased_rate', title: '% đã mua hàng' },
	{ name: 'solution_presented_qty', title: 'Đã trình bày giải pháp với KH' },
	{ name: 'solution_presented_rate', title: '% đã trình bày giải pháp với KH' },
	{ name: 'demand_determined_qty', title: 'Tìm hiểu nhu cầu KH' },
	{ name: 'demand_determined_rate', title: '% tìm hiểu nhu cầu KH' },
	{ name: 'have_met_qty', title: 'Đã gặp KH' },
	{ name: 'have_met_rate', title: '% đã gặp KH' },
];
const summaryData2 = [
	{ name: 'appointment_qty', title: 'Hẹn gặp' },
	{ name: 'sucessful_appointment_rate', title: '% hẹn gặp' },
	{ name: 'interested_qty', title: 'Quan tâm' },
	{ name: 'call_again_qty', title: 'Hẹn gọi lại' },
	{ name: 'wrong_phone_qty', title: 'Không có SĐT/Tắt máy' },
	{ name: 'busy_qty', title: 'Không nghe máy' },
	{ name: 'wrong_info_qty', title: 'Sai thông tin' },
	{ name: 'reject_qty', title: 'Từ chối' },
	{ name: 'buyed_reject_qty', title: 'Đang dùng sản phẩm' },
	{ name: 'is_seller_reject_qty', title: 'Là người bán' },
	{ name: 'wrong_location_qty', title: 'Sai số đia bàn' },
	{ name: 'messaged_qty', title: 'Đã nhắn tin' },
	{ name: 'processing_qty', title: 'Đang xử lý' },
	{ name: 'processing_rate', title: '% Đang xử lý' },
];

export default Table;
