import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import DataTable from 'components/shared/DataTable';
import { isTelesaleMerchantManager } from 'service';
import { useStates, useDistricts, useWards } from 'hooks/address';
import { useEmployee } from 'hooks/lead';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Icon,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Button,
} from '@material-ui/core';
import { styles } from 'style';
import { pageSizes } from 'utils/datatable';
import  moment  from 'moment';

const tableConfig = {
	columns: [
		{ name: 'name', title: 'Họ và tên' },
		{ name: 'user_role', title: 'Role' },
		{ name: 'code', title: 'Code' },
		{ name: 'team_code', title: 'Mã trưởng phòng' },
		{ name: 'team_name', title: 'Tên trưởng phòng' },
		{ name: 'gender', title: 'Giới tính' },
		{ name: 'phone', title: 'Số điện thoại' },
		{ name: 'birthday', title: 'Ngày sinh' },
		{ name: 'adr_state', title: 'Tỉnh / Thành phố' },
		{ name: 'adr_district', title: 'Huyện / Quận' },
		{ name: 'adr_ward', title: 'Xã' },
	],
	tableColumnExtensions: [
		{ columnName: 'name', align: 'center', width: 150 },
		{ columnName: 'user_role', align: 'center', width: 150 },
		{ columnName: 'team_code', align: 'center', width: 200 },
		{ columnName: 'team_name', align: 'center', width: 200 },
		{ columnName: 'code', align: 'center', width: 150 },
		{ columnName: 'gender', align: 'center', width: 150 },
		{ columnName: 'phone', align: 'center', width: 200 },
		{ columnName: 'birthday', align: 'center', width: 150 },
		{ columnName: 'adr_state', align: 'center', width: 200 },
		{ columnName: 'adr_district', align: 'center', width: 200 },
		{ columnName: 'adr_ward', align: 'center', width: 200 },
	],
};

const EmployeeList = ({ classes }) => {
	const [params, setParams] = useState({
		name: '',
		code: '',
		phone: '',
		adr_state_code: '',
		adr_district_code: '',
		adr_ward_code: '',
		team_code: '',
		team_name: '',
		pagesize: 15,
		page: 1,
	});

	const { data: states } = useStates({});
	const { data: districts } = useDistricts({
		state_code: params.adr_state_code,
	});
	const { data: wards } = useWards({ district_code: params.adr_district_code });
	const { data, isLoading } = useEmployee(params);

	return (
		<div>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography className={classes.heading}>Tìm kiếm ...</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<form
						className={classes.search_container}
						noValidate
						autoComplete="off"
					>
						<TextField
							id="agent_name"
							label="Họ & Tên"
							type="search"
							className={classes.search_field}
							defaultValue={params.name}
							onChange={(e) => setParams({ ...params, name: e.target.value })}
							margin="normal"
						/>

						<TextField
							id="agent_code"
							label="Code"
							type="search"
							className={classes.search_field}
							defaultValue={params.code}
							onChange={(e) => setParams({ ...params, code: e.target.value })}
							margin="normal"
						/>

						<TextField
							id="agent_phone"
							label="Phone"
							type="search"
							className={classes.search_field}
							defaultValue={params.phone}
							onChange={(e) => setParams({ ...params, phone: e.target.value })}
							margin="normal"
						/>

						<FormControl className={classes.search_field}>
							<InputLabel htmlFor="search_adr_state">
								Tỉnh/thành phố (*)
              </InputLabel>
							<Select
								defaultValue={params.adr_state_code}
								onChange={(e) =>
									setParams({ ...params, adr_state_code: e.target.value })
								}
								inputProps={{
									name: 'adr_state_code',
									id: 'adr_state_id',
								}}
							>
								{states?.data?.map((pro) => (
									<MenuItem value={pro.state_code} key={pro.state_code}>
										{pro.state_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl className={classes.search_field}>
							<InputLabel htmlFor="search_adr_district">
								Quận / huyện (*)
              </InputLabel>
							<Select
								defaultValue={params.adr_district_code}
								onChange={(e) =>
									setParams({ ...params, adr_district_code: e.target.value })
								}
								inputProps={{
									name: 'adr_district_code',
									id: 'adr_district_id',
								}}
							>
								{districts?.data?.map((pro) => (
									<MenuItem value={pro.district_code} key={pro.district_code}>
										{pro.district_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl className={classes.search_field}>
							<InputLabel htmlFor="search_adr_ward">Xã/Phường</InputLabel>
							<Select
								defaultValue={params.adr_ward_code}
								onChange={(e) =>
									setParams({ ...params, adr_ward_code: e.target.value })
								}
								inputProps={{
									name: 'adr_ward_code',
									id: 'adr_ward_id',
								}}
							>
								{wards?.data?.map((pro) => (
									<MenuItem value={pro.ward_code} key={pro.ward_code}>
										{pro.ward_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{isTelesaleMerchantManager() && (
							<>
								<TextField
									id="team_name"
									label="Team Name"
									type="search"
									className={classes.search_field}
									defaultValue={params.team_name}
									onChange={(e) =>
										setParams({ ...params, team_name: e.target.value })
									}
									margin="normal"
								/>
								<TextField
									id="team_code"
									label="Team Code"
									type="search"
									className={classes.search_field}
									defaultValue={params.team_code}
									onChange={(e) =>
										setParams({ ...params, team_code: e.target.value })
									}
									margin="normal"
								/>
							</>
						)}
					</form>
				</AccordionDetails>
			</Accordion>

			<Button
				color="inherit"
				aria-label="Refresh"
			/* onClick={this.loadData} */
			>
				<Icon>refresh</Icon>
				<span style={{ marginLeft: 10 }}>Làm mới</span>
			</Button>

			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.tableColumnExtensions}
				pageSizes={pageSizes}
				pageSize={params.pagesize}
				currentPage={params.page}
				loading={isLoading}
				/* sorting={sorting} */
				totalCount={data?.total_count}
				changeCurrentPage={(p) => setParams({ ...params, page: p })}
				changePageSize={(p) => setParams({ ...params, pagesize: p })}
				/* changeSorting={this.changeSorting} */
				CellComponent={CellComponent}
			/* autoLoadData={this.loadData} */
			/>
		</div>
	);
};

const ActionCell = (props) => {
	return (
		<Button
			color="primary"
			component={Link}
			to={{
				pathname: '/detail/' + props.row.order_code,
				state: { previous: '/waiting' },
			}}
		>
			Chi tiết
		</Button>
	);
};

const CellComponent = (props) => {
	const { column, row } = props;
	switch (column.name) {
		case 'action':
			return <ActionCell {...props} />;
		case 'birthday':
			return (
				<Table.Cell> {moment(row.birthday * 1000).format('DD/MM/YYYY')}</Table.Cell>
			);
		case 'phone':
			return (
				<Table.Cell>{row.phone?.replace(/\d(?!\d{0,3}$)/g, '*')}</Table.Cell>
			);
		case 'gender':
			return <Table.Cell> {row.gender === 0 ? 'Nam' : 'Nữ'}</Table.Cell>;
		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						// color: '#81557a',
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default withRouter(withStyles(styles)(EmployeeList));
