import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Button,
	Icon,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { Link, Route } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import CreateAgentLocation from './CreateAgentLocation.js';
import CreateAllAgentLocation from './CreateAllAgentLocation.js';
import EditAgentLocation from './EditAgentLocation.js';
import DeleteAgentLocation from './DeleteAgentLocation.js';
import DataTable from 'components/shared/DataTable.jsx';
import { useAgentLocation } from './services.js';
import { pageSizes, hubs } from 'utils/datatable.js';
import { useStates, useDistricts, useWards } from 'hooks/address.js';

const config = {
	columns: [
		{ name: 'agent_name', title: 'Họ và tên' },
		{ name: 'agent_phone', title: 'SĐT' },
		{ name: 'hub', title: 'Hub' },
		{ name: 'adr_state', title: 'Tỉnh / Thành phố' },
		{ name: 'adr_district', title: 'Huyện / Quận' },
		{ name: 'adr_ward', title: 'Xã' },
		{ name: 'mc_code', title: 'Mã đối tác' },
		{ name: 'action', title: 'Thao tác' },
	],
	tableColumnExtensions: [
		{ columnName: 'agent_name', align: 'center', width: 150 },
		{ columnName: 'agent_phone', align: 'center', width: 150 },
		{ columnName: 'hub', align: 'center', width: 200 },
		{ columnName: 'adr_state', align: 'center', width: 200 },
		{ columnName: 'adr_district', align: 'center', width: 200 },
		{ columnName: 'adr_ward', align: 'center', width: 150 },
		{ columnName: 'mc_code', align: 'center', width: 150 },
		{ columnName: 'action', align: 'center', width: 150 },
	],
};
const Location = () => {
	const [params, setParams] = useState({
		agent_name: '',
		hub: 'ALL',
		agent_phone: '',
		adr_state_code: 'ALL',
		adr_district_code: 'ALL',
		adr_ward_code: 'ALL',
		pagesize: 15,
		page: 1,
	});

	const { data, isLoading, refetch } = useAgentLocation(params);

	const { data: states } = useStates({});
	const { data: districts } = useDistricts({
		state_code: params.adr_state_code,
	});
	const { data: wards } = useWards({ district_code: params.adr_district_code });

	return (
		<div>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Tìm kiếm ...</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box
						display="grid"
						gridTemplateColumns="repeat(6, 1fr)"
						gridColumnGap={10}
						width="100%"
					>
						<TextField
							id="Telesale_agent"
							label="Họ & Tên"
							type="search"
							value={params.agent_name}
							onChange={(e) =>
								setParams({ ...params, agent_name: e.target.value })
							}
						/>

						<TextField
							label="Phone"
							type="search"
							value={params.agent_phone}
							onChange={(e) =>
								setParams({ ...params, agent_phone: e.target.value })
							}
						/>

						<FormControl>
							<InputLabel htmlFor="adr_ward_id">Hub</InputLabel>
							<Select
								value={params.hub}
								onChange={(e) => setParams({ ...params, hub: e.target.value })}
								inputProps={{
									name: 'adr_ward_code',
									id: 'adr_ward_id',
								}}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{hubs.map((item) => (
									<MenuItem value={item.code} key={item.code}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl>
							<InputLabel htmlFor="cus_adr_state_id">
								Tỉnh/thành phố (*)
              </InputLabel>
							<Select
								value={params.adr_state_code}
								onChange={(e) =>
									setParams({ ...params, adr_state_code: e.target.value })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{states?.data?.map((pro) => (
									<MenuItem value={pro.state_code} key={pro.state_code}>
										{pro.state_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl>
							<InputLabel htmlFor="adr_district_id">
								Quận / huyện (*)
              </InputLabel>
							<Select
								value={params.adr_district_code}
								onChange={(e) =>
									setParams({ ...params, adr_district_code: e.target.value })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{districts?.data?.map((pro) => (
									<MenuItem value={pro.district_code} key={pro.district_code}>
										{pro.district_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl>
							<InputLabel htmlFor="adr_ward_id">Xã/Phường</InputLabel>
							<Select
								value={params.adr_ward_code}
								onChange={(e) =>
									setParams({ ...params, adr_ward_code: e.target.value })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{wards?.data?.map((pro) => (
									<MenuItem value={pro.ward_code} key={pro.ward_code}>
										{pro.ward_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				</AccordionDetails>
			</Accordion>
			<Button
				color="inherit"
				aria-label="Create New Content"
				component={Link}
				to={'/agent-location/create'}
			>
				<Icon>person_add</Icon>
				<span style={{ marginLeft: 10 }}>Thêm mới</span>
			</Button>
			<Button color="inherit" aria-label="Refresh" onClick={refetch}>
				<Icon>refresh</Icon>
				<span style={{ marginLeft: 10 }}>Làm mới</span>
			</Button>
			<Button
				color="inherit"
				aria-label="Create New Content"
				component={Link}
				to={'/agent-location/createforall'}
			>
				<Icon>person_add</Icon>
				<span style={{ marginLeft: 10 }}>Câu hình nhiều địa bàn</span>
			</Button>
			<DataTable
				rows={data?.data}
				columns={config.columns}
				columnWidths={config.columnWidths}
				currentPage={params.page - 1}
				totalCount={data?.total_count}
				pageSize={params.pagesize}
				pageSizes={pageSizes}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				loading={isLoading}
				CellComponent={CellComponent}
			/>
			<Route
				exact
				path="/agent-location/create"
				component={CreateAgentLocation}
			/>
			<Route
				exact
				path="/agent-location/createforall"
				component={CreateAllAgentLocation}
			/>
			<Route
				exact
				path="/agent-location/edit/:id"
				component={EditAgentLocation}
			/>
			<Route
				exact
				path="/agent-location/delete/:id"
				component={DeleteAgentLocation}
			/>
		</div>
	);
};

class ActionCell extends React.Component {
	render() {
		return (
			<Table.Cell
				style={{
					padding: 1,
					justifyContent: 'center',
					fontSize: '12px',
				}}
			>
				<span>
					<Button
						color="primary"
						style={{
							margin: 0,
							padding: 0,
						}}
						component={Link}
						to={'/agent-location/edit/' + this.props.row.id}
					>
						<Icon>edit</Icon>
					</Button>
					<Button
						color="secondary"
						style={{
							margin: 0,
							padding: 0,
						}}
						component={Link}
						to={'/agent-location/delete/' + this.props.row.id}
					>
						<Icon>remove_circle</Icon>
					</Button>
				</span>
			</Table.Cell>
		);
	}
}

const CellComponent = (props) => {
	const { column,row } = props;
	switch (column.name) {
		case 'action':
			return <ActionCell {...props} />;
		case 'agent_phone':
			return (
				<Table.Cell>
					{row.agent_phone?.replace(/\d(?!\d{0,3}$)/g, '*')}
				</Table.Cell>
			);

		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						//color: '#81557a',
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default Location;
