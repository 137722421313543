import React, { useState } from 'react';
import { FormGroup, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useMerchantLevel } from 'hooks/level';

const FormSearchMerchantLevel = ({ params, setParams, title, level }) => {
	const [searchMerchantLevelParams] = useState({
		page: 1,
		pagesize: 0,
		level: level,
		keyword: '',
		orderby: 'level',
		asc: true,
		/* exclude_user_id: id) */
	});

	const { data: merchants } = useMerchantLevel(searchMerchantLevelParams);
	return (
		<FormGroup>
			<InputLabel>{title}</InputLabel>
			<Select
				/* value={params[`sp_name_lv${level}`]} */
				defaultValue="ALL"
				label="Phòng (AD)"
				onChange={(e) =>
					setParams({
						...params,
						[`sp_name_lv${level}`]: e.target.value,
						page: 1,
					})
				}
			>
				<MenuItem value="ALL">Tất cả</MenuItem>
				{merchants?.data?.map((item) => (
					<MenuItem value={item.fullname} key={item.user_id}>
						{item.fullname}
					</MenuItem>
				))}
			</Select>
		</FormGroup>
	);
};

export default FormSearchMerchantLevel;
