import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import cs from 'const.js';
import DialogWrapper from 'components/shared/DialogWrapper';
// import { DropzoneArea } from 'material-ui-dropzone';
import Container from '../../components/DropdownContainer';
import dataLocation from './data.json';
import Autocomplete from '@material-ui/lab/Autocomplete';


const createAgentLocationURL = cs.BaseURL + '/telesale/lead/agent-location/create-multiple';
const agentURL = cs.BaseURL + '/telesale/lead/agent/list';
const statesURL = cs.BaseURL + '/telesale/address/states';
const districtsURL = cs.BaseURL + '/telesale/address/districts';
const wardsURL = cs.BaseURL + '/telesale/address/wards';

const hubName = ["Đồng bằng Bắc Bộ", "Đông Bắc Bộ", "Tây Bắc Bộ", "Bắc Trung Bộ", "Nam Trung Bộ", "Tây Nguyên", "Đông Nam Bộ", "Đồng bằng Sông Cửu Long", "Hà Nội", "TP.HCM"];


/** Validate phone number **/
function validatePhoneInput(phone) {
	const validHeadNums = /((086|096|097|098|032|033|034|035|036|037|038|039|088|091|094|083|084|085|081|082|092|056|058|089|090|093|070|079|077|076|078|099|059)+([0-9]{7})\b)/g;
	let validPhoneNumber = (String(phone).length === 10) && (validHeadNums.test(String(phone)));
	return (validPhoneNumber);
};

class CreateAgentLocation extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			// package_name: '',
			// package_type: '',
			// package_types: ["analysis", "marketing", "marketing"],
			// amount: 0

			agent_id: '',
			agent_name: '',
			hub: [],
			agent_phone: '',
			adr_state: [],
			adr_state_code: [],
			adr_district: [],
			adr_district_code: [],
			adr_ward: [],
			adr_ward_code: [],
			states: [],
			states_code: [],
			districts: [],
			districts_code: [],
			wards: [],
			wards_code: [],
			agents: [],
			locations: [],
			hubs: [],

			states_find: [],
			districts_find: [],
			wards_find: []
		};
		// if (!isTelesaleOP()) {
		//     window.location.href="/";
		//     return;
		// }
		this.loadStates = this.loadStates.bind(this);
		this.loadDistricts = this.loadDistricts.bind(this);
		this.loadWards = this.loadWards.bind(this);
		this.handleDialogAgree = this.handleDialogAgree.bind(this);

		this.loadAgents();

		//this.loadMerchants();
	}

	loadAgents() {
		let queryString = `${agentURL}`;
		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					this.setState({
						agents: data.data
					});
				} else {
					this.setState({
						states: []
					});
					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					states: []
				});
			});
	}

	loadStates() {
		this.setState({
			states_find: [],
			districts_find: [],
			wards_find: []
		})

		let queryString = `${statesURL}?hub=1`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					data.data.unshift({ adr_state: '', adr_state_code: null });
					this.setState({
						states_find: data.data
					});
				} else {
					this.setState({
						states_find: []
					});
					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					states_find: []
				});
			});
	}

	loadDistricts() {
		this.setState({
			districts_find: [],
			wards_find: []
		})
		let queryString = `${districtsURL}?state_code=${this.state.adr_state_code}`;
		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					data.data.unshift({ adr_district: '', adr_district_code: null });
					this.setState({
						districts_find: data.data
					});
				} else {
					this.setState({
						districts_find: []
					});
					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => { });
	}

	loadWards() {
		this.setState({
			wards_find: []
		})
		let queryString = `${wardsURL}?district_code=${this.state.adr_district_code}`;
		if (queryString === this.lastQueryWard) {
			return;
		}

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					this.setState({
						wards_find: data.data
					});
					this.lastQueryWard = queryString;
				} else {
					this.setState({
						wards_find: []
					});
					this.lastQueryWard = null;
					if (
						data &&
						(data.code == cs.erAuthenticationFailed.code ||
							data.code == cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.lastQueryWard = null;
			});
	}

	handleDialogAgree = () => {
		//tmpData = data.data.filter((el) => el.is_deleted_by_merchant === 0);
		if (!this.state.agent_id ||
			!this.state.agent_name ||
			!this.state.agent_phone
		) {
			window.alert('Please input information !');
			return;
		}

		if (this.state.agent_phone != '') {
			if (!validatePhoneInput(this.state.agent_phone)) {
				window.alert("Incorrect phone number !");
				return;
			}
		}
		if (this.state.hub.length == 0 && this.state.adr_district == 0 && this.state.adr_state.length == 0 && this.state.adr_ward == 0) {
			window.alert('Please fill location field !');
			return;
		}

		let queryString = `${createAgentLocationURL}`;
		let requestBody = {
			agent_id: this.state.agent_id,
			agent_name: this.state.agent_name,
			agent_phone: this.state.agent_phone,
			hub: this.state.hub,
			adr_state_code: this.state.adr_state_code,
			adr_district_code: this.state.adr_district_code,
			adr_ward_code: this.state.adr_ward_code,

			adr_state: this.state.adr_state,
			adr_district: this.state.adr_district,
			adr_ward: this.state.adr_ward
		};

		fetch(queryString, {
			method: 'POST',
			body: JSON.stringify(requestBody),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				console.log(data);
				if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
					sessionStorage.clear();
					window.location.reload();
				}

				if (data && data.error && data.code != cs.Succeed) {
					this.props.toastManager.add(JSON.stringify(data.error), {
						appearance: 'error',
						autoDismiss: true,
						pauseOnHover: true
					});
				} else if (data.data.errors.length > 0) {
					console.log(data.data.errors);
					this.props.toastManager.add('Location ' + data.data.errors + ' had been assigned', {
						appearance: 'error',
						autoDismiss: true,
						pauseOnHover: true
					});
					// this.props.history.push({
					//     pathname: '/agent-location',
					//     state: { reload: true }
					// });
				} else {
					this.props.toastManager.add('AgentLocation is created !', {
						appearance: 'success',
						autoDismiss: true,
						pauseOnHover: true
					});
					this.props.history.push({
						pathname: '/agent-location',
						state: { reload: true }
					});
				}

				//                    window.location.href="/package-info;


			})
			.catch(() => { });
	};

	handleChange = name => event => {
		if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
		else
			console.log(event.target.value);
		this.setState(
			{
				[name]: event.target.value
			},
			() => {
				if (name == 'hub') this.loadStates();
				if (name == 'adr_state_code') this.loadDistricts();
				if (name == 'adr_district_code') this.loadWards();
			}
		);
	};

	onChange = (currentNode, selectedNodes) => {
		// console.log("onChange::",selectedNodes);

		this.setState(
			{
				hub: [],
				adr_state_code: [],
				adr_state: [],
				adr_district_code: [],
				adr_district: [],
				adr_ward_code: [],
				adr_ward: [],
				hubs: [],
				states_code: [],
				states: [],
				districts_code: [],
				districts: [],
				wards_code: [],
				wards: [],
			}
		)
		if (selectedNodes.length > 0) {
			for (let i = 0; i < selectedNodes.length; i++) {
				if (selectedNodes[i]._depth == 0) {
					this.state.hubs.push(selectedNodes[i].value);
					this.setState({
						hub: this.state.hubs,
					})
				}
				if (selectedNodes[i]._depth == 1) {
					let nHub = parseInt(selectedNodes[i]._id.split('-')[1], 10) + 1;

					this.state.states_code.push((nHub) + "-" + selectedNodes[i].value);

					this.state.states.push(hubName[nHub - 1] + "-" + selectedNodes[i].label);
				}
				if (selectedNodes[i]._depth == 2) {
					let nHub = parseInt(selectedNodes[i]._id.split('-')[1], 10) + 1;
					let nState = parseInt(selectedNodes[i]._id.split('-')[2], 10);


					this.state.districts_code.push((nHub) + "-" + dataLocation[nHub - 1].children[nState].value + "-" + selectedNodes[i].value);
					this.state.districts.push(hubName[nHub - 1] + "-" + dataLocation[nHub - 1].children[nState].label + "-" + selectedNodes[i].label);
				}
				if (selectedNodes[i]._depth == 3) {
					let nHub = parseInt(selectedNodes[i]._id.split('-')[1], 10) + 1;
					let nState = parseInt(selectedNodes[i]._id.split('-')[2], 10);
					let nDistrict = parseInt(selectedNodes[i]._id.split('-')[3], 10);


					this.state.wards_code.push((nHub) + "-" + dataLocation[nHub - 1].children[nState].value + "-" + dataLocation[nHub - 1].children[nState].children[nDistrict].value + "-" + selectedNodes[i].value)
					this.state.wards.push(hubName[nHub - 1] + "-" + dataLocation[nHub - 1].children[nState].label + "-" + dataLocation[nHub - 1].children[nState].children[nDistrict].label + "-" + selectedNodes[i].label);
				}
			}
			this.setState(
				{
					hub: this.state.hubs,
					adr_state_code: this.state.states_code,
					adr_state: this.state.states,
					adr_district_code: this.state.districts_code,
					adr_district: this.state.districts,
					adr_ward_code: this.state.wards_code,
					adr_ward: this.state.wards

				}
			)

		}


	}

	render() {
		return (
			<div>
				<DialogWrapper title={'Gán nhiều địa bàn - Thêm mới'} {...this.props}>
					<DialogContent style={{ height: '550px' }}>
						<FormControl fullWidth>
							<InputLabel htmlFor="merchantId">Agent (*)</InputLabel>
							<Autocomplete
								options={this.state.agents}
								getOptionLabel={op => op.code ? op.code : "null"}
								renderInput={item => <TextField {...item} label="Agent (*)" />}
								onChange={(e, v) => this.setState({
									agent_phone: v.phone,
									agent_name: v.name,
									agent_id: v.id
								})}
							/>
						</FormControl>

						<TextField
							disabled
							fullWidth
							autoFocus
							margin="dense"
							id="agent_name"
							required={true}
							value={this.state.agent_name}
							onChange={this.handleChange('agent_name')}
							label="Tên"
							type="name"
						/>

						<TextField
							disabled
							fullWidth
							autoFocus
							margin="dense"
							id="agent_phone"
							required={true}
							value={this.state.agent_phone}
							onChange={this.handleChange('agent_phone')}
							label="SĐT"
							type="name"
						/>
						<FormControl fullWidth >
							<Container
								onChange={this.onChange}
								data={dataLocation}
								texts={{ placeholder: "Chọn địa bàn (*)" }}
								className="mdl-demo"
							/>
							{/* onAction={this.onAction} 
                            onNodeToggle={this.onNodeToggle} */}
						</FormControl>


					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.history.goBack} color="primary">
							Hủy
                        </Button>
						<Button onClick={this.handleDialogAgree} color="primary">
							Tạo mới
                        </Button>
					</DialogActions>
				</DialogWrapper>
			</div>
		);
	}
}


export default CreateAgentLocation

