import React from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	Button,
	TextField,
	InputLabel,
} from '@material-ui/core';
import './style.css';
import { useProduct } from 'hooks/products';
import { useStates } from 'hooks/address';
import { isTelesaleMerchant } from 'service';

const SearchPanel = ({ params, setParams, refetch, search, setSearch }) => {
	const { data: products } = useProduct(
		{},
		isTelesaleMerchant() ? 'merchant-product' : 'product'
	);
	const { data: states } = useStates({});
	const handleChange = (event) => {
		const { name, value } = event.target;
		setParams({
			...params,
			[name]: value,
			page: 1,
		});
	};

	return (
		<form className="lead-form-search">
			<TextField
				label="Người gọi"
				value={params.telesale_agent_name}
				onChange={handleChange}
				name="telesale_agent_name"
				type="text"
			/>
			<FormControl>
				<InputLabel>Sản phẩm</InputLabel>
				<Select
					value={params.mc_product_code}
					onChange={handleChange}
					name="mc_product_code"
				>
					<MenuItem value="ALL">Tẩt cả</MenuItem>
					{products?.data?.map((pro) => (
						<MenuItem value={pro.code} key={pro.code}>
							<div>{pro.name}</div>
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<TextField
				label="Khách hàng"
				value={params.search_content}
				onChange={handleChange}
				name="search_content"
				type="text"
			/>
			<FormControl>
				<InputLabel>Tỉnh/thành</InputLabel>
				<Select
					value={params.current_province}
					onChange={handleChange}
					name="current_province"
				>
					<MenuItem value="ALL">Tẩt cả</MenuItem>
					{states?.data?.map((pro) => (
						<MenuItem value={pro.state_name} key={pro.state_code}>
							{pro.state_name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl>
				<InputLabel>Loại lead</InputLabel>
				<Select
					onChange={handleChange}
					value={params.lead_type}
					name="lead_type"
				>
					<MenuItem value={-1}>Tất cả</MenuItem>
					<MenuItem value={0}>Auto</MenuItem>
					<MenuItem value={1}>F0/F1</MenuItem>
					<MenuItem value={2}>Tạo thủ công</MenuItem>
					<MenuItem value={3}>Danh bạ</MenuItem>
					<MenuItem value={4}>Web kế hoạch tài chính</MenuItem>
				</Select>
			</FormControl>
			<div className="lead-form-datetime">
				<span
					onClick={() => setSearch(!search)}
					className={`${search && 'active-search'} action-search`}
				>
					Ngày phân bổ:
        </span>
				<TextField
					label="Từ ngày"
					value={params.search_submitted_start}
					onChange={handleChange}
					name="search_submitted_start"
					type="datetime-local"
					InputLabelProps={{
						shrink: true,
					}}
					disabled={!search}
				/>

				<TextField
					label="Đến ngày"
					value={params.search_submitted_end}
					onChange={handleChange}
					name="search_submitted_end"
					type="datetime-local"
					InputLabelProps={{
						shrink: true,
					}}
					disabled={!search}
				/>
			</div>
			<div className="lead-form-datetime">
				<span
					onClick={() => setSearch(!search)}
					className={`${!search && 'active-search'} action-search`}
				>
					Ngày cập nhật trạng thái:
        </span>
				<TextField
					label="Từ ngày"
					value={params.lead_update_date_start}
					onChange={handleChange}
					name="lead_update_date_start"
					type="datetime-local"
					InputLabelProps={{
						shrink: true,
					}}
					disabled={search}
				/>

				<TextField
					label="Đến ngày"
					value={params.lead_update_date_end}
					onChange={handleChange}
					name="lead_update_date_end"
					type="datetime-local"
					InputLabelProps={{
						shrink: true,
					}}
					disabled={search}
				/>
			</div>
			<FormControl>
				<Button color="primary" variant="contained" onClick={() => refetch()}>
					Tìm
        </Button>
			</FormControl>
		</form>
	);
};

export default SearchPanel;
