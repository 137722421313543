import { useMutation } from '@tanstack/react-query';
import { authAxios } from 'utils/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import fileDownload from 'js-file-download';

export const useSendEmail = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/agent/send-login-guide ',
				method: 'POST',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code === 100) toast.success('Gửi email thành công');
				else {
					toast.error(data.error);
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useSendEmailUserInfo = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/agent/send-login-info ',
				method: 'POST',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code === 100) toast.success('Gửi email thành công');
				else {
					toast.error(data.error);
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useUpdateAgent = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: 'telesale/agent/update',
				method: 'POST',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code === 100)
					toast.success('Cập nhật thông tin agent thành công');
				else toast.error(data.error);
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useVerifyBeforeImport = () => {
	return useMutation(async (params) => {
		const form = new FormData();
		form.append('excel-file', params);
		const { data } = await authAxios({
			url: '/telesale/agent/verify_before_import',
			data: form,
			method: 'POST',
			headers: { 'Content-Type': 'multipart/form-data;' },
		});
		return data;
	});
};

export const useImportAgent = () => {
	return useMutation(
		async (body) => {
			const form = new FormData();

			form.append('excel-file', body);

			const { data } = await authAxios({
				url: '/telesale/agent/import',
				data: form,
				method: 'POST',
				headers: { 'Content-Type': 'multipart/form-data;' },
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code === 100) toast.success('Upload file thành công');
				else {
					toast.error(data.error);
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useImportFileUpdate = () => {
	return useMutation(
		async (body) => {
			const form = new FormData();

			form.append('excel-file', body);

			const { data } = await authAxios({
				url: '/telesale/agent/import-update',
				data: form,
				method: 'POST',
				headers: { 'Content-Type': 'multipart/form-data;' },
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code === 100) toast.success('Upload file thành công');
				else {
					toast.error(data.error);
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useExportAgent = () => {
	return useMutation(
		async (params) => {
			const fix = {};
			if (params.action) {
				fix.last_lead_action_to = moment(
					new Date(params.last_lead_action_to)
				).unix();
				fix.last_lead_action_from = moment(
					new Date(params.last_lead_action_from)
				).unix();
			}
			if (params.login) {
				fix.last_login_to = moment(new Date(params.last_login_to)).unix();
				fix.last_login_from = moment(new Date(params.last_login_from)).unix();
			}
			await authAxios({
				url: 'telesale/agent/export',
				method: 'GET',
				responseType: 'blob',
				params: fix,
			}).then((res) => {
				const date = new Date().toLocaleDateString();
				fileDownload(res.data, `${date}-agent-list.xlsx`);
			});
			return {
				code: 100,
			};
		},
		{
			onSuccess: (data) => {
				if (data?.code === 100) toast.success('Export file thành công');
				else {
					toast.error(data.error);
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};

export const useAssign = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/agent/assign',
				data: params,
				method: 'POST',
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code === 100) toast.success('Phân bổ thành công');
				else {
					toast.error('Có lỗi xảy ra ' + data.error);
				}
			},
			onError: () => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};
