import axios from './axios';
import moment from 'moment';

const subcriptionList = async (params) => {
	const fix = {};

	for (const key in params) {
		if (params[key] === 'ALL') fix[key] = '';
		else fix[key] = params[key];
	}
	fix.date_from = moment(new Date(params.date_from))
		.startOf('day')
		.unix()
		.toString();
	fix.date_to = moment(new Date(params.date_to)).endOf('day').unix().toString();
	const { data } = await axios({
		url: '/telesale/lead/subscription/list',
		method: 'GET',
		params: fix,
	});
	return data.data ? data : { ...data, data: [] };
};
const subscriptionApprove = async (params) => {
	if (params.status) {
		const { data } = await axios({
			url: '/telesale/lead/subscription/approve',
			method: 'POST',
			data: { id: params.id },
		});
		return data;
	} else {
		const { data } = await axios({
			url: '/telesale/lead/subscription/disapprove',
			method: 'POST',
			data: { id: params.id },
		});
		return data;
	}
};

const subcriptionRetry = async (params) => {
	const { data } = await axios({
		url: 'telesale/lead/subscription/retry',
		method: 'POST',
		data: params,
	});
	return data;
};

const convertF0 = async (params) => {
	const { data } = await axios({
		url: 'telesale/lead/convertToF0',
		method: 'POST',
		data: params,
	});
	return data;
};

const addFBUID = async (params) => {
	const { data } = await axios({
		url: 'telesale/lead/subscription/updateUUID',
		method: 'POST',
		data: params,
	});
	return data;
};

export default {
	subcriptionList,
	subscriptionApprove,
	subcriptionRetry,
	convertF0,
	addFBUID,
};
