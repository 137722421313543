import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogWrapper from 'components/shared/DialogWrapper';
import { useParams, useHistory } from 'react-router-dom';
import { useDeleteAgentLocation } from './services';

const DeleteAgentLocation = () => {
	const routerParams = useParams();
	const history = useHistory();
	const { mutateAsync: removeAgent } = useDeleteAgentLocation();
	const handleDialogAgree = () => {
		removeAgent({ id: routerParams.id }).then(() => {
			history.goBack();
		});
	};

	return (
		<div>
			<DialogWrapper
				title={'Bạn có muốn xóa thông tin agent theo địa bàn này không?'}
				history={history}
			>
				<DialogActions>
					<Button onClick={() => history.goBack()} color="primary">
						Hủy
          </Button>
					<Button onClick={handleDialogAgree} color="primary" autoFocus>
						Xóa
          </Button>
				</DialogActions>
			</DialogWrapper>
		</div>
	);
};

export default DeleteAgentLocation;
