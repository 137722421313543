import { green } from '@material-ui/core/colors';

export const styles = (theme) => ({
	grid_Container: {
		width: '100%',
		marginTop: theme.spacing(2),
		overflowX: 'auto'
	},
	tableStriped: {
		'& tbody tr:nth-of-type(odd)': {
			backgroundColor: 'rgb(186, 207, 255)'
		},
		'& tbody tr:nth-of-type(even)': {
			backgroundColor: 'rgb(217, 226, 244)'
		}
	},
	searchPanel: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2)
	},
	search_container: {
		display: 'flex',
		flexWrap: 'wrap',
		width:"100%", 
		gap:"10"
	},
	search_field: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: 200,
		minWidth: 120
	},
	search_select_menu: {
		width: 200
	},
	grid_header: {
		backgroundColor: green
	}
});
