import React, { useState } from 'react';
import { useRankExp, useRankRevenue, useSubmerchant } from './services';
import './style.css';
import { useAgentLead } from 'hooks/leadAgent';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	FormGroup,
	InputLabel,
	Select,
	MenuItem,
} from '@material-ui/core';
import NoData from 'components/Nodata';
import moment from 'moment';

const AgentInfo = ({ onClose, id }) => {
	const { data: agent } = useAgentLead({ id: id });
	return (
		<>
			<DialogTitle>Thông tin nhân viên</DialogTitle>
			<DialogContent>
				<div className="ranking-agent-info">
					{agent?.data.map((item) => (
						<>
							<div>
								<span>Tên:</span>
								<span>{item.name}</span>
							</div>
							<div>
								<span>Giới tính</span>
								<span>{item.gender ? 'Nữ' : 'Nam'}</span>
							</div>
							<div>
								<span>Số điện thoại:</span>
								<span>{item.phone}</span>
							</div>
							<div>
								<span>Email:</span>
								<span>{item.email}</span>
							</div>
							<div>
								<span>Tỉnh/TP:</span>
								<span>{item.adr_state}</span>
							</div>
							<div>
								<span>Quận/huyện:</span>
								<span>{item.adr_district}</span>
							</div>
							<div>
								<span>Phường/xã:</span>
								<span>{item.adr_ward}</span>
							</div>
							<div>
								<span>Phòng:</span>
								<span>{item.team_name}</span>
							</div>
							<div>
								<span>Chi nhánh:</span>
								<span>{item.branch_name}</span>
							</div>
							<div>
								<span>Ngày sinh:</span>
								<span>{moment(item.birthday).format('DD/MM/YYYY')}</span>
							</div>
						</>
					))}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Đóng</Button>
			</DialogActions>
		</>
	);
};

const topSelect = [
	{ value: 10, label: 'Top 10' },
	{ value: 20, label: 'Top 20' },
	{ value: 50, label: 'Top 50' },
];

const monthSelect = [
	{ value: 1, label: 'Tháng 1' },
	{ value: 2, label: 'Tháng 2' },
	{ value: 3, label: 'Tháng 3' },
	{ value: 4, label: 'Tháng 4' },
	{ value: 5, label: 'Tháng 5' },
	{ value: 6, label: 'Tháng 6' },
	{ value: 7, label: 'Tháng 7' },
	{ value: 8, label: 'Tháng 8' },
	{ value: 9, label: 'Tháng 9' },
	{ value: 10, label: 'Tháng 10' },
	{ value: 11, label: 'Tháng 11' },
	{ value: 12, label: 'Tháng 12' },
];
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const range = (start, stop, step) =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
const yearSelect = range(currentYear, currentYear - 3, -1);

const Ranking = () => {
	const [openAgentInfo, setOpenAgentInfo] = useState({
		open: false,
		id: '',
	});
	const [paramsExp, setParamsExp] = useState({
		top: 10,
		sub_mc_id: 0,
	});
	const [paramsRev, setParamsRev] = useState({
		top: 10,
		sub_mc_id: 0,
		month: currentMonth + 1,
		year: currentYear,
	});
	const { data: rankExp } = useRankExp(paramsExp);
	const { data: rankRevenue } = useRankRevenue(paramsRev);
	const { data: submerchants } = useSubmerchant();
	const handleChangeExpParams = (e) => {
		const { value, name } = e.target;
		setParamsExp({
			...paramsExp,
			[name]: value,
		});
	};

	const handleChangeRevParams = (e) => {
		const { value, name } = e.target;
		setParamsRev({
			...paramsRev,
			[name]: value,
		});
	};

	return (
		<div className="ranking">
			<div className="rank-exp">
				<div className="form-search">
					<FormGroup>
						<InputLabel>Top</InputLabel>
						<Select
							value={paramsExp.top}
							onChange={handleChangeExpParams}
							name="top"
						>
							{topSelect.map((item) => (
								<MenuItem value={item.value} key={item.value}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
					{submerchants?.length > 0 && (
						<FormGroup>
							<InputLabel>Sub Merchant</InputLabel>
							<Select
								value={paramsExp.sub_mc_id}
								onChange={handleChangeExpParams}
								name="sub_mc_id"
							>
								<MenuItem value={0}>Tất cả</MenuItem>
								{submerchants.map((item) => (
									<MenuItem value={item.id} key={item.sub_mc_code}>
										{item.sub_mc_name}
									</MenuItem>
								))}
							</Select>
						</FormGroup>
					)}
					<FormGroup></FormGroup>
					<FormGroup style={{ opacity: 0 }}>
						<InputLabel>None</InputLabel>
						<Select disabled={true} />
					</FormGroup>
				</div>
				<div>
					<h1>Bảng xếp hạng kinh nghiệm</h1>
				</div>
				<div className="rank-list">
					{rankExp?.data?.map((item, index) => (
						<div
							className="rank-item"
							key={item.id}
							onClick={() => setOpenAgentInfo({ open: true, id: item.id })}
						>
							<div className="rank-item-content">
								<span className="rank-number">{index + 1}</span>
								<span className="name">{item.name}</span>
								<span className="rank">{parseInt(item.exp).toLocaleString('en-US')}</span>
							</div>
						</div>
					))}

					{(!rankExp?.data || rankExp?.data?.length <= 0) && <NoData />}
				</div>
			</div>
			<div className="rank-revenue">
				<div className="form-search">
					<FormGroup>
						<InputLabel>Tháng</InputLabel>
						<Select
							value={paramsRev.month}
							onChange={handleChangeRevParams}
							name="month"
						>
							{monthSelect.map((item) => (
								<MenuItem value={item.value} key={item.value}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
					<FormGroup>
						<InputLabel>Năm</InputLabel>
						<Select
							value={paramsRev.year}
							onChange={handleChangeRevParams}
							name="year"
						>
							<MenuItem value={0}>Tẩt cả</MenuItem>
							{yearSelect.map((item) => (
								<MenuItem value={item} key={item}>
									{item}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
					<FormGroup>
						<InputLabel>Top</InputLabel>
						<Select
							name="top"
							value={paramsRev.top}
							onChange={handleChangeRevParams}
						>
							{topSelect.map((item) => (
								<MenuItem value={item.value} key={item.value}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
					{submerchants?.length > 0 && (
						<FormGroup>
							<InputLabel>Sub Merchant</InputLabel>
							<Select
								value={paramsRev.sub_mc_id}
								name="sub_mc_id"
								onChange={handleChangeRevParams}
							>
								<MenuItem value={0}>Tất cả</MenuItem>
								{submerchants.map((item) => (
									<MenuItem value={item.id} key={item.sub_mc_code}>
										{item.sub_mc_name}
									</MenuItem>
								))}
							</Select>
						</FormGroup>
					)}
				</div>
				<div>
					<h1>Bảng xếp hạng doanh thu</h1>
				</div>
				<div className="rank-list">
					{rankRevenue?.data?.map((item, index) => (
						<div
							className="rank-item"
							key={item.id}
							onClick={() => setOpenAgentInfo({ open: true, id: item.id })}
						>
							<div className="rank-item-content">
								<span className="rank-number">{index + 1}</span>
								<span>{item.name}</span>
								<span>{parseInt(item.revenue).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
							</div>
						</div>
					))}
					{(!rankRevenue?.data || rankRevenue?.data.length <= 0) && <NoData />}
				</div>
			</div>
			<Dialog
				open={openAgentInfo.open}
				onClose={() => setOpenAgentInfo({ ...openAgentInfo, open: false })}
				maxWidth="xs"
				fullWidth
			>
				<AgentInfo
					onClose={() => setOpenAgentInfo({ ...openAgentInfo, open: false })}
					id={openAgentInfo.id}
				/>
			</Dialog>
		</div>
	);
};

export default Ranking;
