import React, { useState } from 'react';
import {
	DialogTitle,
	DialogContent,
	DialogActions,
	Box,
	Typography,
	Button,
	List,
	ListItem,
	Link,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import file_mau from './file/template_import_dai_ly.xlsx';
import file_mau_update from './file/template_import_dai_ly_update.xlsx';
import {
	useVerifyBeforeImport,
	useImportAgent,
	useImportFileUpdate,
} from './services';

const DialogAssignAgent = ({ onClose }) => {
	const [fileCheck, setFileCheck] = useState(null);
	const [file, setFile] = useState(null);
	const [fileUpdate, setFileUpdate] = useState(null);
	const [errorList, setErrorList] = useState([]);

	const { mutateAsync: checkFileMutation } = useVerifyBeforeImport();
	const { mutateAsync: importFile, isLoading } = useImportAgent();
	const { mutateAsync: importFileUpdate } = useImportFileUpdate();

	const handleCheckFile = () => {
		checkFileMutation(fileCheck);
	};
	const handelImportFile = () => {
		importFile(file);
	};
	const handleImportFileUpdate = () => {
		importFileUpdate(fileUpdate);
	};
	return (
		<>
			<DialogTitle>
				<Typography variant="h4" component="h4" align="center">
					Phân bổ nhiều agent
        </Typography>
			</DialogTitle>
			<DialogContent>
				<Box gridTemplateColumns="1fr 1fr" display="grid" gridGap="10px">
					<Box>
						<Typography variant="h5" component="h5">
							Kiểm tra file
            </Typography>
						<Box>
							<DropzoneArea
								dropzoneText={
									fileCheck && fileCheck.name
										? fileCheck.name
										: 'Upload file exel theo mẫu'
								}
								maxFileSize={50000000}
								filesLimit={1}
								showAlerts={true}
								showPreviewsInDropzone={true}
								onChange={(e) => setFileCheck(e[0])}
							/>
							{errorList.length > 0 && (
								<List>
									{errorList.map((item) => (
										<ListItem key={item.code}>{item.error}</ListItem>
									))}
								</List>
							)}
						</Box>
						<Box
							display="flex"
							gridGap="10px"
							justifyContent="flex-end"
							marginTop={2}
						>
							<Button>
								<Link href={file_mau} download>
									File mẫu
                </Link>
							</Button>
							<Button
								color="primary"
								variant="contained"
								disabled={checkFileMutation.isLoading}
								onClick={handleCheckFile}
							>
								Kiểm tra
              </Button>
						</Box>
					</Box>
					<Box>
						<Typography variant="h5" component="h5">
							Upload file
            </Typography>
						<Box>
							<DropzoneArea
								dropzoneText={
									file && file.name ? file.name : 'Upload file exel theo mẫu'
								}
								showPreviewsInDropzone={false}
								maxFileSize={50000000}
								filesLimit={1}
								showAlerts={true}
								onChange={(e) => setFile(e[0])}
							/>
						</Box>
						<Box display="flex" justifyContent="flex-end" marginTop={2}>
							<Button
								color="primary"
								disabled={isLoading}
								variant="contained"
								onClick={handelImportFile}
							>
								Upload
              </Button>
						</Box>
					</Box>
				</Box>
				<div>
					<Typography variant="h5" component="h5">
						Upload file cập nhật thông tin
          </Typography>
					<Box>
						<DropzoneArea
							dropzoneText={
								fileUpdate && fileUpdate.name
									? fileUpdate.name
									: 'Upload file exel theo mẫu'
							}
							showPreviewsInDropzone={false}
							maxFileSize={50000000}
							filesLimit={1}
							showAlerts={true}
							onChange={(e) => setFileUpdate(e[0])}
						/>
					</Box>
					<Box display="flex" justifyContent="flex-end" marginTop={2}>
						<Button>
							<Link href={file_mau_update} download>
								File mẫu
              </Link>
						</Button>
						<Button
							color="primary"
							disabled={isLoading}
							variant="contained"
							onClick={handleImportFileUpdate}
						>
							Upload
            </Button>
					</Box>
				</div>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={onClose}>
					Huỷ
        </Button>
			</DialogActions>
		</>
	);
};

export default DialogAssignAgent;
