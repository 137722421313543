import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import cs from 'const.js';

const deletePackageURL = cs.BaseURL + "/telesale/package/info/delete";
const styles = theme => ({});

class DeletePackage extends React.Component {
	state = {
		confirm_code: ""
	};
	handleDialogAgree = () => {
		if (!this.props.match.params.id) {
			window.alert("Wrong information !");
			this.props.history.push({
				pathname: '/package-info',
				state: { reload: true }
			});
			return;
		}
		// if (this.state.confirm_code != "goldengate.biz.vn") {
		//     window.alert("Không có quyền truy cập !");
		//     this.props.history.push({
		//         pathname: "/campaign/all"
		//     });
		//     return;
		// }

		var campaign_id = this.props.match.params.id;
		let queryString = `${deletePackageURL}`;

		fetch(queryString, {
			method: "POST",
			body: JSON.stringify({
				id: campaign_id
			}),
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: localStorage.getItem(cs.System_Code + "-general-token")
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}

				if (data && data.error && data.code != cs.Succeed) {
					this.props.toastManager.add(JSON.stringify(data.error), {
						appearance: "error",
						autoDismiss: true,
						pauseOnHover: true
					});
				} else {
					this.props.toastManager.add("Package info is deleted !", {
						appearance: "success",
						autoDismiss: true,
						pauseOnHover: true
					});
				}
				// this.props.history.push({
				//     pathname: '/package-info',
				//     state: { reload: true }
				// });
				window.location.href = "/package-info";
			})
			.catch(() => { });
	};

	handleFieldChange = name => event => {
		this.setState(
			{
				[name]: event.target.value
			},
			() => { }
		);
	};
	render() {
		return (
			<div>
				<Dialog
					open={true}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Do you want to delete Package Info ?
                    </DialogTitle>{" "}
					<DialogActions>
						<Button
							onClick={this.props.history.goBack}
							color="primary"
						>
							Cancel
                        </Button>
						<Button
							onClick={this.handleDialogAgree}
							color="primary"
							autoFocus
						>
							Delete
                        </Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

DeletePackage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DeletePackage);
