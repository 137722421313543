import { useMutation } from '@tanstack/react-query';
import { authAxios } from 'utils/api';

export const useChangePassword = () => {
	return useMutation(async (params) => {
		const { data } = await authAxios({
			url: '/common/auth/change_password_first_time',
			method: 'POST',
			data: params,
		});
		return data;
	});
};

export const useLogout = () => {
	return useMutation(async () => {
		localStorage.clear();
		sessionStorage.clear();
		window.location.reload();
	});
};
