import React, { useState } from 'react';
import { usePosts, useCategory, useProduct } from 'hooks/dc_article';
import './post.css';
import { Link } from 'react-router-dom';
import GetLink from './GetLink';
import {
	Grid,
	Button,
	TextField,
	MenuItem,
	Select,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from '@material-ui/core';
import Approve from './Approve';
import HubReject from './HubReject';
import { getItemLocal, checkFuncRoleWithId } from 'utils/auth';
import Delete from './Delete';
import UpdateButton from './UpdateButton';

const status = (type) =>
	[
		{ value: 0, label: 'chưa duyệt' },
		{ value: 1, label: 'đã duyệt' },
		{ value: -1, label: 'đã xóa' },
	].find((item) => item.value === type)?.label;

const Posts = () => {
	const [params, setParams] = useState({
		product_id: '',
		category_id: '',
		title: '',
		description: '',
		status: 0,
		page: 1,
		pagesize: 25,
	});
	const funcRole = checkFuncRoleWithId(502);
	const role = getItemLocal('aidriven-general-user')?.role;
	const [open, setOpen] = useState({
		item: {},
		open: false,
	});
	const [openApprove, setOpenApprove] = useState({
		open: false,
		item: {},
	});
	const [openHubReject, setOpenHubReject] = useState({
		open: false,
		item: {},
	});
	const [openDelete, setOpenDelete] = useState({
		open: false,
		item: {},
	});
	const [openCustomButton, setOpenCustomButton] = useState(false);
	const { data: post, refetch } = usePosts(params);
	const { data: categories } = useCategory();
	const { data: products } = useProduct();

	return (
		<div>
			<Grid container style={{ gap: 4 }}>
				<div>
					<p>Tiêu đề</p>
					<TextField
						onChange={(e) => setParams({ ...params, title: e.target.value })}
					/>
				</div>
				<div>
					<p>Mô tả</p>
					<TextField
						onChange={(e) =>
							setParams({ ...params, description: e.target.value })
						}
					/>
				</div>
				<div>
					<p>Danh mục</p>
					<Select
						onChange={(e) =>
							setParams({ ...params, category_id: e.target.value })
						}
						defaultValue={params.category_id}
						style={{ width: 200 }}
					>
						<MenuItem value={''}>Tất cả</MenuItem>
						{categories?.data?.map((item) => (
							<MenuItem value={item.id} key={item.id}>
								{item.category_name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<div>
						<p>Sản phẩm</p>
					</div>
					<Select
						onChange={(e, v) =>
							setParams({ ...params, product_id: e.target.value })
						}
						defaultValue={params.product_id}
						style={{ width: 200 }}
					>
						<MenuItem value={''}>Tất cả</MenuItem>
						{products?.data?.map((item) => (
							<MenuItem value={item.id} key={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>
					<Select
						onChange={(e, v) =>
							setParams({ ...params, status: e.target.value })
						}
						defaultValue={params.status}
						style={{ width: 200 }}
					>
						<MenuItem value={''}>Tất cả</MenuItem>
						{[
							{ value: 1, label: 'Đã duyệt' },
							{ value: 0, label: 'Chưa duyệt' },
							{ value: -1, label: 'Đã xoá' },
						].map((item) => (
							<MenuItem value={item.value} key={item.value}>
								{item.label}
							</MenuItem>
						))}
					</Select>
				</div>
				<Button color="secondary" variant="contained" onClick={refetch}>
					Tìm
				</Button>
				<div style={{ flex: 1 }}></div>
				<div style={{ display: 'grid', gap: 4 }}>
					<Link to="/posts/new">
						<Button variant="contained" color="primary">
							Tạo bài mới
						</Button>
					</Link>
					<Button
						variant="contained"
						color="primary"
						onClick={() => setOpenCustomButton(true)}
					>
						Custom Button
					</Button>
				</div>
			</Grid>
			<Grid container style={{ marginTop: 50 }} spacing={2}>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>#</TableCell>
								<TableCell>Tiêu đề</TableCell>
								<TableCell>Trạng thái</TableCell>
								<TableCell>Ảnh bìa</TableCell>
								<TableCell>Tạo ngày</TableCell>
								<TableCell>Sửa ngày</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{post?.data?.map((item) => (
								<TableRow key={item.id}>
									<TableCell>
										<a
											target="_blank"
											href={
												process.env.REACT_APP_API_DC_APP + '/posts/' + item.id
											}
										>
											<Button>Xem</Button>
										</a>
										<Link
											to={{ pathname: `/posts/edit/${item.id}`, state: item }}
										>
											<Button>Chỉnh sửa</Button>
										</Link>
										<Button onClick={() => setOpen({ item: item, open: true })}>
											Lấy Link
										</Button>
										{(role === 91 ||
											role === 92 ||
											role === 93 ||
											((role === 95 || role === 96 || role === 97) &&
												funcRole)) && (
												<Button
													onClick={() =>
														setOpenApprove({ open: true, item: item })
													}
												>
													Phê duyệt
												</Button>
											)}

										{(role === 91 || role === 92 || role === 93) && (
											<Button
												onClick={() =>
													setOpenHubReject({ open: true, item: item })
												}
											>
												Từ chối
											</Button>
										)}
										<Button
											onClick={() => setOpenDelete({ item: item, open: true })}
										>
											Xoá
										</Button>
									</TableCell>
									<TableCell>{item.title}</TableCell>
									<TableCell>{status(item.status)}</TableCell>
									<TableCell>
										{item.media_path[0] ? (
											<img
												src={
													process.env.REACT_APP_MEDIA_PATH + item.media_path[0]
												}
												style={{ width: 100 }}
											/>
										) : (
											'Không có'
										)}
									</TableCell>
									<TableCell>{item.updated_at}</TableCell>
									<TableCell>{item.updated_at}</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
									colSpan={6}
									count={post?.total_count}
									rowsPerPage={params.pagesize}
									page={params.page - 1}
									SelectProps={{
										inputProps: { 'aria-label': '/' },
										native: true,
									}}
									onPageChange={(e, p) => setParams({ ...params, page: p + 1 })}
									onRowsPerPageChange={(e, p) =>
										setParams({ ...params, pagesize: p + 1 })
									}
								/>
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			</Grid>
			<GetLink
				open={open.open}
				onCancel={() => setOpen({ open: false, item: {} })}
				item={open.item}
			/>
			<Approve
				open={openApprove.open}
				onCancel={() => {
					setOpenApprove({ open: false, item: {} });
					refetch();
				}}
				item={openApprove.item}
			/>
			<HubReject
				open={openHubReject.open}
				onCancel={() => {
					setOpenHubReject({ open: false, item: {} });
					refetch();
				}}
				item={openHubReject.item}
			/>
			<Delete
				open={openDelete.open}
				item={openDelete.item}
				onCancel={() => {
					setOpenDelete({ open: false, item: {} });
					refetch();
				}}
			/>
			<UpdateButton
				open={openCustomButton}
				onClose={() => setOpenCustomButton(false)}
			/>
		</div>
	);
};

export default Posts;
