import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import cs from 'const.js';
import DialogWrapper from 'components/shared/DialogWrapper';


const createProductURL = cs.BaseURL + '/telesale/inventory/product/create';

const styles = theme => ({});


class CreateProduct extends React.Component {
    constructor(props) {
        super(props);
        const currentYear = (new Date()).getFullYear();

        this.state = {
            product_code: '',
            product_name: '',
            price: '',
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
    }

    handleDropZoneChange(files) {
        this.setState({
            file: files[0]
        });
    }

    handleDialogAgree = () => {
        console.log(this.state);
        let requestBody = {
            product_name: this.state.product_name,
            price: this.state.price,
            product_code: this.state.product_code,
        }
        console.log(requestBody)
        // console.log(this.state);

        if (isNaN(this.state.price)) {
            window.alert('Incorrect amount !');
            return;
        }

        if (!this.state.product_name) {
            window.alert('Please input information !');
            return;
        }

        let queryString = `${createProductURL}`;


        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify(requestBody),

            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-general-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed.code) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Product is created !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/product-inventory',
                    state: { reload: true }
                });
                //window.location.href = '/product-inventory';
            })
            .catch(() => { });
    };

    handleChange = name => event => {
        // this.setState({
        //     [name]: event.target.value
        // });
        console.log(event.target.value, event.target.checked);
        if (name == "like_able" || name == "comment_able") this.setState({ [name]: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name == "agent_code") {
                        let agent = this.state.agents.filter((el) => el.code === event.target.value)
                        console.log(agent);

                        this.setState({
                            agent_name: agent[0].name,
                            agent_phone: agent[0].phone,
                            team_name: agent[0].team_name || ""
                        })
                    }
                    //let merchant = this.state.merchants.filter((el) => el.code === tmp.mc_code)
                    if (name == "mc_code") {
                        let merchant = this.state.merchants.filter((el) => el.code === event.target.value)
                        this.setState({
                            mc_product_code: '',
                            mc_product_name: '',
                        })
                        this.loadProducts(merchant[0].id, merchant[0].code);
                    }
                    if (name == "mc_product_code") {
                        let product = this.state.products.filter((el) => el.code === event.target.value)
                        this.setState({ mc_product_name: product[0].name })
                    }

                }
            );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <DialogWrapper title={'Thêm sản phẩm'} {...this.props}>
                    <DialogContent>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="priority"
                                required={true}
                                value={this.state.product_name}
                                onChange={this.handleChange('product_name')}
                                label="Sản phẩm"
                                type='name'
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="priority"
                                required={true}
                                value={this.state.product_code}
                                onChange={this.handleChange('product_code')}
                                label="Mã sản phẩm"
                                type='name'
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="priority"
                                required={true}
                                value={this.state.price}
                                onChange={this.handleChange('price')}
                                label="Giá"
                                type='number'
                            //InputProps={{ inputProps: { min: 0, max: 10 } }}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Thêm sản phẩm
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

CreateProduct.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreateProduct)
