import { useQuery, useMutation } from '@tanstack/react-query';
import subcriptionApi from 'api/subcription.api';
import { toast } from 'react-toastify';

export const useSubcription = (params) =>
	useQuery(['subcription', params], () =>
		subcriptionApi.subcriptionList(params)
	);

export const useSubcriptionApprove = () =>
	useMutation((params) => subcriptionApi.subscriptionApprove(params), {
		onSuccess: (data) => {
			if (data.code === 100)
				toast.success('Cập nhật trạng thái nhận lead thành công');
			else {
				toast.error('Có lỗi xảy ra, vui lòng thử lại sau !!!');
			}
		},
		onError: () => {
			toast.error('Có lỗi xảy ra, vui lòng thử lại sau !!!');
		},
	});

export const useResetSub = () =>
	useMutation((params) => subcriptionApi.subcriptionRetry(params), {
		onSuccess: (data) => {
			if (data.code === 100)
				toast.success('Cập nhật trạng thái nhận lead thành công');
			else {
				toast.error(data.error);
			}
		},
		onError: () => {
			toast.error('Có lỗi xảy ra, vui lòng thử lại sau !!!');
		},
	});

export const useConvertLeadToF0 = () => {
	return useMutation((params) => subcriptionApi.convertF0(params), {
		onSuccess: (data) => {
			if (data?.code === 100) toast.success('Convert lead thành F0 thành công');
			else toast.error(data.error);
		},
		onError: () => {
			toast.error('Có lỗi xảy ra');
		},
	});
};

export const useAddFBUID= () => {
	return useMutation((params) => subcriptionApi.addFBUID(params), {
		onSuccess: (data) => {
			if (data?.code === 100) toast.success('Thêm facebook uid thành công');
			else toast.error(data.error);
		},
		onError: () => {
			toast.error('Có lỗi xảy ra');
		},
	});
};

