import React, { useState } from 'react';
import './create.css';
import {
	DialogTitle,
	DialogActions,
	DialogContent,
	Button,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@material-ui/core';
import moment from 'moment';
import {
	useCreateTask,
	useTaskConfig,
	useAssigne,
	useSearchTask,
} from './services';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateTask = ({ onClose, refetch }) => {
	const { pathname } = useLocation();

	const date = new Date();
	const [params, setParams] = useState({
		assignee: 0,
		assigneeIsAgent: false,
		summary: 'Tóm tắt công việc',
		description: 'Mô tả công viêc',
		priority: 1,
		status: 1,
		dueDate: moment(date).format('YYYY-MM-DD hh:mm:ss'),
		relationTask: 0,
		relationType: 0,
	});

	const { mutateAsync } = useCreateTask();
	const { data: taskConfig } = useTaskConfig();
	const { data: assigneeData } = useAssigne({ pathname });
	const { data: taskData } = useSearchTask(
		{ page: 1, size: 1000000000 },
		pathname
	);

	const handleSubmit = () => {
		if (params.relationTask === 0 && params.relationType !== 0)
			return toast.error('Cần chọn công việc liên quan');
		mutateAsync(params).then(() => {
			onClose();
			refetch();
		});
	};
	const handleChange = (event) => {
		const { name, value } = event.target;
		setParams({
			...params,
			[name]: value,
		});
	};
	const handleChangeDate = (event) => {
		const { value } = event.target;
		setParams({
			...params,
			dueDate: moment(value).format('YYYY-MM-DD hh:mm:ss'),
		});
	};

	const handleChangeAssignee = (event) => {
		const atribute = event.nativeEvent.target.dataset;

		setParams({
			...params,
			assigneeIsAgent: JSON.parse(atribute.assigneeIsAgent),
			assignee: parseInt(atribute.assignee),
		});
	};

	return (
		<>
			<DialogTitle>Tạo công việc mới</DialogTitle>
			<DialogContent>
				<form className="create-task">
					<TextField
						label="Tóm tắt"
						name="summary"
						value={params.summary}
						onChange={handleChange}
						variant="outlined"
					/>
					<TextField
						label="Mô tả"
						name="description"
						placeholder="Mô tả"
						multiline
						rows={4}
						value={params.description}
						onChange={handleChange}
						variant="outlined"
					/>
					<FormControl variant="outlined">
						<InputLabel>Giao việc cho</InputLabel>
						<Select
							value={params.assignee}
							name="assignee"
							onChange={handleChangeAssignee}
							label="Giao việc cho"
						>
							<MenuItem
								value={0}
								data-assignee-is-agent={false}
								data-assignee={0}
							>
								Không
              </MenuItem>
							{assigneeData?.data.map((item) => (
								<MenuItem
									value={item.id}
									data-assignee-is-agent={item.isAgent}
									data-assignee={item.id}
									key={item.id}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<div className="span-2">
						<TextField
							label="Hạn"
							name="dueDate"
							type="datetime-local"
							value={params.dueDate}
							onChange={handleChangeDate}
							variant="outlined"
						/>

						<FormControl variant="outlined">
							<InputLabel>Độ ưu tiên</InputLabel>
							<Select
								value={params.priority}
								name="priority"
								onChange={handleChange}
								label="Độ ưu tiên"
							>
								{taskConfig?.data
									.filter((item) => item.type === 'priority')
									.map((item) => (
										<MenuItem value={item.id} key={item.id}>
											{item.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</div>
					<FormControl variant="outlined">
						<InputLabel>Mối quan hệ</InputLabel>
						<Select
							value={params.relationType}
							name="relationType"
							onChange={handleChange}
							label="Mối quan hệ"
						>
							<MenuItem value={0}>Không</MenuItem>
							{taskConfig?.data
								.filter((item) => item.type === 'relation')
								.map((item) => (
									<MenuItem value={item.id} key={item.id}>
										{item.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<FormControl variant="outlined">
						<InputLabel>Công việc liên quan</InputLabel>
						<Select
							value={params.relationTask}
							name="relationTask"
							onChange={handleChange}
							label="Công việc liên quan"
						>
							<MenuItem value={0}>Không</MenuItem>
							{taskData?.data.map((item) => (
								<MenuItem value={item.id} key={item.id}>
									{item.summary}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Huỷ</Button>
				<Button color="primary" variant="contained" onClick={handleSubmit}>
					Thêm
        </Button>
			</DialogActions>
		</>
	);
};

export default CreateTask;
