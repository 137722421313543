import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import cs from "const.js";
import moment from "moment";
// import { DropzoneArea } from 'material-ui-dropzone';

const URL = cs.BaseURL + '/telesale/inventory/list';
const updateInventoryURL = cs.BaseURL + "/telesale/inventory/update";
const productURL = cs.BaseURL + '/telesale/inventory/list/product';

const styles = theme => ({
    locked_button: {
        marginTop: 10,
        marginLeft: 0
    },
    dropZone: {
        paddingTop: 10,
        minHeight: 200
    },
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },
});

function formatValue(value) {
    // format display of time
    if (value && value.created_at)
        value.created_at = moment
            .unix(value.created_at)
            .format('YYYY-MM-DD');

    if (value && value.ended_at)
        value.ended_at = moment
            .unix(value.ended_at)
            .format('YYYY-MM-DD');

    // format short forms
    if (value && value.current_province) value.current_province = value.current_province.replace('Thành phố', 'TP').replace('Tỉnh', '');

    return value;
}

class UpdateInventory extends React.Component {
    constructor(props) {
        super(props);
        const currentYear = (new Date()).getFullYear();

        this.state = {
            id: this.props.match.params.id,
            product_name: '',
            product_id: '',
            quantity: '',
            products: []
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        //this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
        this.loadProducts();
        this.loadData();
    }

    loadProducts() {
        let queryString = `${productURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-general-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    console.log(data.data)

                    var products = [];
                    products.push({ id: null, product_name: 'Tất cả' });
                    data.data.map((p) => {
                        const found = products.some((el) => el.id === p.id);
                        if (found) {
                            console.log("Found:", found)
                            return
                        };
                        products.push(p);
                    });
                    console.log(products)
                    this.setState({
                        products: products,

                    });
                } else {
                    this.setState({
                        products: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?id=${id}`;
        return queryString;
    }

    loadData() {
        // if (!isTelesaleOP()) {
        //     window.location.href="/";
        //     return;
        // }
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: localStorage.getItem(cs.System_Code + "-general-token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.data);
                if (data && data.data[0]) {
                    let tmp = formatValue(data.data[0]);
                    this.setState({
                        product_id: tmp.product_id,
                        product_name: tmp.product_name,
                        quantity: tmp.quantity,

                    }, () => {

                    });

                    console.log("state", this.state)
                } else {
                    this.setState({
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        // sessionStorage.clear();
                        // localStorage.clear();
                        // window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleDialogAgree = () => {
        console.log(this.state)
        if (isNaN(this.state.quantity)) {
            window.alert('Incorrect amount !');
            return;
        }

        if (!this.state.product_name) {
            window.alert('Please input information !');
            return;
        }

        let queryString = `${updateInventoryURL}`;
        let requestBody = {
            id: parseInt(this.state.id),
            product_name: this.state.product_name,
            product_id: this.state.product_id,
            quantity: this.state.quantity,
        };

        console.log(requestBody);
        fetch(queryString, {
            method: "POST",

            body: JSON.stringify(requestBody),
            headers: {
                Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code) ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed.code) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: "error",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add("Inventory is updated !", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/inventory',
                    state: { reload: true }
                });
                // window.location.href = "/kpi";
                //window.location.reload();
            })
            .catch(() => { });
    };

    handleChange = name => event => {
        // this.setState({
        //     [name]: event.target.value
        // });
        console.log(event.target.value, event.target.checked);
        if (name == "like_able" || name == "comment_able") this.setState({ [name]: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name == "agent_code") {
                        let agent = this.state.agents.filter((el) => el.code === event.target.value)
                        console.log(agent);
                        // this.loadProducts(agent[0].id);
                        this.setState({
                            // mc_product_code: '',
                            // mc_product_name: '',
                            agent_name: agent[0].name,
                            agent_phone: agent[0].phone,
                            team_name: agent[0].team_name || ""
                        })
                    }
                    //let merchant = this.state.merchants.filter((el) => el.code === tmp.mc_code)
                    if (name == "mc_code") {
                        let merchant = this.state.merchants.filter((el) => el.code === event.target.value)
                        this.setState({
                            mc_product_code: '',
                            mc_product_name: '',
                        })
                        this.loadProducts(merchant[0].id, merchant[0].code);
                    }
                    if (name == "product_id") {
                        let product = this.state.products.filter((el) => el.id === event.target.value)
                        this.setState({ product_name: product[0].product_name })
                    }
                }
            );
    };


    handleDropZoneChange(files) {
        this.setState({
            file: files[0]
        });
    }

    render() {
        const { classes } = this.props;
        const { merchant } = this.state;
        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                    <DialogTitle id="edit_merchant_form-dialog-title">
                        Sửa kho hàng
                    </DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <FormControl disabled fullWidth>
                                    <InputLabel htmlFor="merchantId">Sản phẩm</InputLabel>
                                    <Select
                                        value={this.state.product_id}
                                        onChange={this.handleChange('product_id')}
                                        inputProps={{
                                            name: 'package_type',
                                            id: 'package_type'
                                        }}
                                    >

                                        {this.state.products.map(item => (
                                            <MenuItem value={item.id}>{item.product_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="priority"
                                    required={true}
                                    value={this.state.quantity}
                                    onChange={this.handleChange('quantity')}
                                    label="Số lượng"
                                    type='number'
                                //InputProps={{ inputProps: { min: 0, max: 10 } }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.history.goBack}
                            color="primary"
                        >
                            Hủy
                        </Button>
                        <Button
                            onClick={this.handleDialogAgree}
                            color="primary"
                        >
                            Sửa
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

UpdateInventory.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UpdateInventory);
