import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import {
	hasAccess,
	hasNonAccess,
	hasOneOfFunctionRoles,
	hasLevel,
} from './utils';
import { Divider, Typography } from '@material-ui/core';

export default function NestedList({ menu }) {
	return menu.map((item, index) => {
		if (item.items) return <MultiLevel item={item} key={index} />;
		else return <SingleLevel item={item} key={index} />;
	});
}

const SingleLevel = ({ item }) => {
	const location = useLocation();
	if (
		!hasAccess(item) ||
		!hasOneOfFunctionRoles(item) ||
		!hasLevel(item) ||
		hasNonAccess(item)
	)
		return null;

	if (item.to) {
		return (
			<>
				<ListItem
					button
					component={Link}
					to={{ pathname: item.to, state: item.title }}
					style={{ whiteSpace: 'normal' }}
					selected={item.to === location.pathname}
				>
					<ListItemIcon>{item.icon}</ListItemIcon>
					<ListItemText primary={item.title} />
				</ListItem>
			</>
		);
	} else {
		return (
			<>
				<ListItem button style={{ whiteSpace: 'normal' }}>
					<ListItemIcon>{item.icon}</ListItemIcon>
					<ListItemText primary={item.title} />
				</ListItem>
			</>
		);
	}
};

const MultiLevel = ({ item }) => {
	if (
		!hasAccess(item) ||
		!hasOneOfFunctionRoles(item) ||
		!hasLevel(item) ||
		hasNonAccess(item)
	)
		return null;
	const [open, setOpen] = useState(false);
	const handleClick = () => {
		setOpen((prev) => !prev);
	};

	return (
		<React.Fragment>
			<ListItem button onClick={handleClick} style={{ whiteSpace: 'normal' }}>
				<ListItemIcon>{item.icon}</ListItemIcon>
				<ListItemText
					disableTypography
					primary={
						<Typography
							type="body2"
							style={{ fontWeight: 'bold', fontSize: 18 }}
						>
							{item.title}
						</Typography>
					}
				/>
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{item.items.map((child, key) => (
						<SingleLevel key={key} item={child} />
					))}
				</List>
			</Collapse>
			<Divider />
		</React.Fragment>
	);
};
