import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'const.js';
import React from 'react';
import Button from '@material-ui/core/Button';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { green } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DataTable from 'components/shared/DataTable';
import { getRole } from 'service';
import { isValidFunctionRole } from 'service';

const EXECUTIVE_ORDER_LIST_URL =
	cs.MarketplaceURL + '/api/executive/order/list';
// const EXECUTIVE_ORDER_LIST_URL = cs.BaseURL + '/telesale/external/executive/order/list';
const EXECUTIVE_ORDER_UPDATE_STATUS_URL =
	cs.MarketplaceURL + '/api/executive/order/update-status';
// const EXECUTIVE_ORDER_UPDATE_STATUS_URL = cs.BaseURL + '/telesale/external/executive/order/update-status';

function formatValue(value) {
	if (value && value.tongThanhToan) {
		value.tongThanhToan = value.tongThanhToan
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return value;
}

class OrderBeingShipped extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			columns: [
				{ name: 'action', title: 'Thao tác' },
				{ name: 'tongThanhToan', title: 'Tổng thanh toán' },
				{ name: 'maHoaDon', title: 'Mã hóa đơn' },
				{ name: 'supplierName', title: 'Nhà cung cấp' },
				{ name: 'maDonHang', title: 'Mã đơn hàng' },
				{ name: 'leadFullAddress', title: 'Địa chỉ khách hàng' },
				{ name: 'leadCommuneName', title: 'Phường/Xã' },
				{ name: 'leadDistrictName', title: 'Quận/Huyện' },
				{ name: 'leadProvinceName', title: 'Tỉnh/Thành phố' },
			],
			rows: [],
			tableColumnExtensions: [
				{ columnName: 'action', align: 'center', width: 200 },
				{ columnName: 'tongThanhToan', align: 'center', width: 200 },
				{ columnName: 'maHoaDon', align: 'center', width: 200 },
				{ columnName: 'supplierName', align: 'center', width: 200 },
				{ columnName: 'maDonHang', align: 'center', width: 200 },
				{ columnName: 'leadCommuneName', align: 'center', width: 200 },
				{ columnName: 'leadDistrictName', align: 'center', width: 200 },
				{ columnName: 'leadProvinceName', align: 'center', width: 200 },
				{ columnName: 'leadFullAddress', align: 'center', width: 300 },
			],
			// pageSizes: [5, 10, 20, 30, 60],
			totalCount: 0,
			pageSize: 10,
			currentPage: 1,
			loading: true,
			sorting: [{ columnName: 'update_time', direction: 'desc' }],
		};

		this.changeCurrentPage = this.changeCurrentPage.bind(this);
		this.changePageSize = this.changePageSize.bind(this);

		this.loadData();
	}

	changeCurrentPage(currentPage) {
		this.setState(
			{
				loading: true,
				currentPage: currentPage,
			},
			() => {
				this.loadData();
			}
		);
	}

	changePageSize(pageSize) {
		this.setState(
			{
				loading: true,
				pageSize: pageSize,
				currentPage: 0,
			},
			() => {
				this.loadData();
			}
		);
	}

	loadData = () => {
		const { currentPage, pageSize } = this.state;
		const body = {
			statusCategoryId: cs.Marketplace_OrderStatus_BeingShipped,
			page: currentPage,
			size: pageSize,
		};
		fetch(EXECUTIVE_ORDER_LIST_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
			body: JSON.stringify(body),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					for (var i = 0; i < data.data.length; i++) {
						data.data[i] = formatValue(data.data[i]);
					}
					this.setState({
						rows: data.data,
						totalCount: data.total_count,
						loading: false,
					});
				} else {
					this.setState({
						rows: [],
						totalCount: 0,
						loading: false,
					});
				}
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	};

	render() {
		// get value from state
		const {
			rows,
			columns,
			tableColumnExtensions,
			pageSizes,
			pageSize,
			currentPage,
			totalCount,
			loading,
			sorting,
		} = this.state;

		return (
			<div>
				<ExpansionPanel>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>Tìm kiếm ...</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails></ExpansionPanelDetails>
				</ExpansionPanel>

				<Button color="inherit" aria-label="Refresh" onClick={this.loadData}>
					<Icon>refresh</Icon>
					<span style={{ marginLeft: 10 }}>Làm mới</span>
				</Button>

				<DataTable
					rows={rows}
					columns={columns}
					columnWidths={tableColumnExtensions}
					pageSizes={pageSizes}
					pageSize={pageSize}
					currentPage={currentPage}
					loading={loading}
					sorting={sorting}
					totalCount={totalCount}
					changeCurrentPage={this.changeCurrentPage}
					changePageSize={this.changePageSize}
					changeSorting={this.changeSorting}
					CellComponent={CellComponent}
					autoLoadData={this.loadData}
				/>

			</div>
		);
	}
}

OrderBeingShipped.propTypes = {
	classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
	grid_Container: {
		width: '100%',
		marginTop: theme.spacing(2),
		overflowX: 'auto',
	},
	tableStriped: {
		'& tbody tr:nth-of-type(odd)': {
			backgroundColor: 'rgb(186, 207, 255)',
		},
		'& tbody tr:nth-of-type(even)': {
			backgroundColor: 'rgb(217, 226, 244)',
		},
	},
	searchPanel: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	search_container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	search_field: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: 200,
		minWidth: 120,
	},
	search_select_menu: {
		width: 200,
	},
	grid_header: {
		backgroundColor: green,
	},
	gender_button: {
		marginTop: 20,
		marginLeft: 40,
	},
});

function ConfirmDialog(props) {
	const handleSubmit = () => {
		const queryString = `${EXECUTIVE_ORDER_UPDATE_STATUS_URL}?maDonHang=${props.row.maDonHang}&statusId=${cs.Marketplace_OrderUpdateStatus_DeliveredToCustomer}`;

		fetch(queryString, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.error_code != 0) {
					props.handleClose();
				} else {
					props.handleClose();
				}
			})
			.catch(() => { });
	};

	return (
		<>
			<Dialog
				open={props.dialogOpen}
				aria-labelledby="draggable-dialog-title"
				onClose={() => props.handleClose()}
			>
				<DialogTitle>Cập nhật trạng thái đơn hàng</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Xác nhận đơn hàng đã giao thành công và đã đến tay khách hàng
          </DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => props.handleClose()}
						autoFocus
						color="secondary"
					>
						Hủy
          </Button>
					<Button onClick={handleSubmit} color="primary">
						Xác nhận
          </Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

class ActionCell extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dialogOpen: false,
		};
	}

	handleOpen = () => {
		this.setState({
			dialogOpen: true,
		});
	};

	handleClose = () => {
		this.setState({
			dialogOpen: false,
		});
	};

	render() {
		return (
			<span>
				<Button
					color="primary"
					style={{
						margin: 0,
						padding: 0,
					}}
					onClick={this.handleOpen}
				>
					Cập nhật
        </Button>
				<Button
					color="primary"
					style={{
						margin: 0,
						padding: 0,
					}}
					component={Link}
					to={'/order/detail/' + this.props.row.maDonHang}
				>
					<Icon>edit</Icon>
          Chi tiết
        </Button>
					<ConfirmDialog
						{...this.props}
						handleClose={this.handleClose}
						dialogOpen={this.state.dialogOpen}
						row={this.props.row}
					/>
			</span>
		);
	}
}

const validFunctionRoles = [
	cs.Function_Role_MC_Executive_Warehouse,
	cs.Function_Role_Supplier_Executive_Warehouse,
];

const CellComponent = (props) => {
	const { column } = props;
	if (
		column.name === 'action' &&
		(getRole() == cs.Role_Mc_Board_Of_Control_Member ||
			getRole() == cs.Role_Mc_Salesplus_CEO ||
			isValidFunctionRole(validFunctionRoles))
	) {
		return (
			<Table.Cell
				{...props}
				style={{
					padding: 2,
					fontSize: '11px',
				}}
			>
				<ActionCell {...props} />
			</Table.Cell>
		);
	}

	return (
		<Table.Cell
			{...props}
			style={{
				padding: 2,
				fontSize: '11px',
			}}
		/>
	);
};

export default OrderBeingShipped;
