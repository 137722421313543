import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import DialogWrapper from 'components/shared/DialogWrapper';
import {
	DialogContent,
	DialogActions,
	Box,
	Button,
	Switch,
	Divider,
} from '@material-ui/core';
import { useUpdateFunctionGetLead } from 'hooks/leadAgent';
import { useReleaseAssignLead } from '../../lead/services';

const ReleaseAllLead = () => {
	const { state } = useLocation();
	const history = useHistory();
	const { mutateAsync, isLoading } = useReleaseAssignLead();
	const {
		mutateAsync: updateFuntionGetLeadMutate,
		isLoading: loadinUpdateGetLead,
	} = useUpdateFunctionGetLead();
	const handleClick = () => {
		if (state.id)
			mutateAsync({ agent_id: state.id })
				.then(() => {
				})
				.catch(() => {
				});
		else {
		}
	};
	const handleChangeClickUpdateGetLead = () => {
		updateFuntionGetLeadMutate({
			id: state.id,
			code: state.code,
			status: state.auto_assign_ready,
		})
			.finally(() => history.goBack());
	};
	return (
		<DialogWrapper title="Thu hồi tất cả lead" history={history}>
			<DialogContent>
				<div>
					<h1>Bạn muốn thu hồi lead của nhân viên này</h1>
					<p>Thông tin nhân viên</p>
				</div>
				<Box>
					<table>
						<tbody>
							<tr>
								<td>Code:</td>
								<td>{state.code}</td>
							</tr>
							<tr>
								<td>Tên:</td>
								<td>{state.name}</td>
							</tr>
						</tbody>
					</table>
				</Box>
				<DialogActions>
					<Button color="secondary" onClick={() => history.goBack()}>
						Huỷ
					</Button>
					<Button
						color="primary"
						disabled={isLoading}
						variant="contained"
						onClick={() => handleClick()}
					>
						Xác nhận
					</Button>
				</DialogActions>
				<Divider />
				<Box style={{ display: 'flex', alignItems: 'center' }}>
					<h3>Bật/tắt nhận lead tự động cho nhân viên này</h3>
					<Switch
						checked={state.auto_assign_ready === 0 ? false : true}
						onChange={() => handleChangeClickUpdateGetLead()}
						disabled={loadinUpdateGetLead}
					/>
				</Box>
			</DialogContent>
		</DialogWrapper>
	);
};

export default ReleaseAllLead;
