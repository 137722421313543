import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogWrapper from 'components/shared/DialogWrapper';

const styles = (theme) => ({});

class NoteLooking extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div>
				<DialogWrapper
					title={
						'Ghi chú của khách hàng: ' + this.props.location.params.lead_name
					}
					{...this.props}
				>
					<DialogContent>
						<DialogContentText>
							{this.props.location.params.lead_note.length
								? this.props.location.params.lead_note
								: 'Không có'}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.props.history.goBack}
							color="primary"
							autofocus
						>
							OK
						</Button>
					</DialogActions>
				</DialogWrapper>
			</div>
		);
	}
}

NoteLooking.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoteLooking);
