export const mainChartData = (data) => {
	const colorList = ['#A93226', '#2471A3', '#138D75'];
	const list = [
		{ name: 'total_amount', title: 'Tổng', yaxis: 'A', type: 'bar' },
		{ name: 'total_order', title: 'Tổng đơn hàng', yaxis: 'A', type: 'bar' },
		{
			name: 'total_revenue_fix',
			title: 'Tổng doanh thu',
			yaxis: 'B',
			type: 'line',
		},
	];
	const bar = list.map((item, index) => ({
		type: item.type,
		label: item.title,
		backgroundColor: colorList[index],
		borderWidth: 1,
		borderColor: colorList[index],
		fill: false,
		data: data.map((i) => i[item.name]),
		yAxisID: item.yaxis,
	}));

	const labels = data.map((i) => i.display_name);
	return {
		labels: labels,
		datasets: bar,
	};
};

export const optionsChart = {
	scales: {
		A: {
			type: 'linear',
			position: 'left',
			title: {
				text: 'Số lượng',
				display: true,
				align: 'end',
			},
		},
		B: {
			type: 'linear',
			position: 'right',
			display: true,
			title: {
				text: 'Số lượng (*100,000,000)',
				display: true,
				align: 'end',
				color: '#138D75',
			},
		},
	},
	plugins: {
		legend: {
			position: 'bottom',
			maxWidth: 8,
		},
		title: {
			display: true,
			text: 'Hi',
		},
	},
};
