import React, { Component } from 'react';
import cs from 'const.js';
import Loadable from 'react-loadable';
import HomeTelesale from 'telesales/Home';
import FirstTime from 'FirstTime';
import { MyThemeProvider } from 'contexts/ThemeContext';
import { TableSettingProvider } from 'contexts/TableSettingContext';

const LazyLoadLogin = Loadable({
	loader: () => import('Login'),
	loading: () => <div>Loading...</div>,
});

class App extends Component {
	render() {
		var token = localStorage.getItem(cs.System_Code + '-general-token');

		var user = JSON.parse(
			localStorage.getItem(cs.System_Code + '-general-user')
		);

		if (!token || token === null || token === 'null' || token === undefined) {
			return <LazyLoadLogin />;
		} else {
			if (
				user.role === cs.Role_Telesale_Hub_User ||
				user.role === cs.Role_Telesale_Hub_Leader ||
				user.role === cs.Role_Telesale_Hub_Manager ||
				user.role === cs.Role_Telesale_Merchant_User ||
				user.role === cs.Role_Telesale_Merchant_Leader ||
				user.role === cs.Role_Telesale_Merchant_Manager ||
				user.role === cs.Role_Mc_Board_Of_Control_Member ||
				user.role === cs.Role_Telesale_Merchant_Branch_Director ||
				user.role === cs.Role_Mc_Salesplus_CEO
			) {
				return (
					<MyThemeProvider>
						<TableSettingProvider>
							<HomeTelesale />
						</TableSettingProvider>
					</MyThemeProvider>
				);
			}  else {
				return <FirstTime />;
			}
		}
	}
}

export default App;
