import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import cs from "const.js";
import moment from "moment";
import DialogWrapper from "components/shared/DialogWrapper";
// import { DropzoneArea } from 'material-ui-dropzone';

const URL = cs.BaseURL + '/telesale/lead/agent-product/list';
const updateAgentProductURL = cs.BaseURL + "/telesale/lead/agent-product/update";
const agentURL = cs.BaseURL + '/telesale/lead/agent/list';
const productURL = cs.BaseURL + '/telesale/lead/merchant-product/list';


function formatValue(value) {
	// format display of time
	if (value && value.created_at)
		value.created_at = moment
			.unix(value.created_at)
			.format('YYYY-MM-DD');

	if (value && value.ended_at)
		value.ended_at = moment
			.unix(value.ended_at)
			.format('YYYY-MM-DD');

	if (value && value.current_province) value.current_province = value.current_province.replace('Thành phố', 'TP').replace('Tỉnh', '');

	return value;
}

class EditAgentProduct extends React.Component {
	state = {
		id: this.props.match.params.id,
		agent_id: '',
		agent_name: '',
		agent_phone: '',
		agent_mc_ref_code: '',
		mc_product_name: '',
		mc_product_code: '',
		max_order_qty: '',
		agents: [],
		products: [],
		url:""
	};
	constructor(props) {
		super(props);

		this.handleDialogAgree = this.handleDialogAgree.bind(this);
		this.loadAgents();
		this.loadProducts();
		this.loadData();
	}

	loadAgents() {
		let queryString = `${agentURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					//data.data.unshift({ adr_state: '', adr_state_code: null });
					this.setState({
						agents: data.data
					});
				} else {
					this.setState({
						agents: []
					});
					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					agents: []
				});
			});
	}

	loadProducts() {
		let queryString = `${productURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					data.data.unshift({ adr_state: '', adr_state_code: null });
					this.setState({
						products: data.data
					});
				} else {
					this.setState({
						products: []
					});
					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					products: []
				});
			});
	}

	queryString() {
		const { id } = this.state;
		let queryString = `${URL}?id=${id}`;
		return queryString;
	}

	loadData() {
		this.setState({ id: this.props.match.params.id, loading: true });
		const queryString = this.queryString();
		fetch(queryString, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: localStorage.getItem(cs.System_Code + "-general-token")
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data[0]) {
					let tmp = formatValue(data.data[0]);
					this.setState({
						agent_id: tmp.agent_id,
						agent_name: tmp.agent_name,
						agent_phone: tmp.agent_phone,
						agent_mc_ref_code: tmp.agent_mc_ref_code,
						mc_product_name: tmp.mc_product_name,
						mc_product_code: tmp.mc_product_code,
						max_order_qty: tmp.max_order_qty,
					});

				} else {
					this.setState({
						loading: false
					});
					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						// sessionStorage.clear();
						// localStorage.clear();
						// window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	}

	handleDialogAgree = () => {
		if (!this.state.agent_id || !this.state.agent_name || !this.state.agent_phone
			|| !this.state.mc_product_name
			|| !this.state.mc_product_code || !this.state.max_order_qty
		) {
			this.props.toastManager.add('Please input information !', {
				appearance: "error",
				autoDismiss: true,
				pauseOnHover: true
			});
			return;
		}

		if (this.state.max_order_qty && isNaN(this.state.max_order_qty)) {
			this.props.toastManager.add('Incorrect amount !', {
				appearance: "error",
				autoDismiss: true,
				pauseOnHover: true
			});
			return;
		}

		if (this.state.max_order_qty < 0) {
			this.props.toastManager.add('Number must be positive!', {
				appearance: "error",
				autoDismiss: true,
				pauseOnHover: true
			});
			return;
		}

		let queryString = `${updateAgentProductURL}`;
		let requestBody = {
			id: parseInt(this.state.id),
			agent_id: this.state.agent_id,
			agent_name: this.state.agent_name,
			agent_phone: this.state.agent_phone,
			agent_mc_ref_code: this.state.agent_mc_ref_code,
			mc_product_name: this.state.mc_product_name,
			mc_product_code: this.state.mc_product_code,
			max_order_qty: this.state.max_order_qty,
		};

		fetch(queryString, {
			method: "POST",

			body: JSON.stringify(requestBody),
			headers: {
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}

				if (data && data.error && data.code != cs.Succeed) {
					this.props.toastManager.add(JSON.stringify(data.error), {
						appearance: "error",
						autoDismiss: true,
						pauseOnHover: true
					});
				} else {
					this.props.toastManager.add("AgentProduct is updated !", {
						appearance: "success",
						autoDismiss: true,
						pauseOnHover: true
					});
				}
				this.props.history.push({
					pathname: '/agent-product',
					state: { reload: true }
				});
				//window.location.reload();
			})
			.catch(() => { });
	};


	handleChange = name => event => {
		if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
		else
			this.setState(
				{
					[name]: event.target.value
				},
				() => {
					if (name == 'adr_state_code') this.loadDistricts();
					if (name == 'adr_district_code') this.loadWards();
					//agent_id
					if (name == 'agent_id') {
						let agent = this.state.agents.filter((el) => el.id === event.target.value)
						this.setState({
							agent_phone: agent[0].phone,
							agent_name: agent[0].name
						})
					}

					if (name == 'mc_product_code') {
						let product = this.state.products.filter((el) => el.code === event.target.value)
						this.setState({
							mc_product_name: product[0].name,

						})
					}
				}
			);
	};

	handleDropZoneChange(files) {
		this.setState({
			file: files[0]
		});
	}

	render() {
		return (
			<div>
				<DialogWrapper title={'Gán sản phẩm - Cập nhật'} {...this.props}>
					<DialogContent>
						<FormControl fullWidth>
							<InputLabel htmlFor="merchantId">Agent (*)</InputLabel>
							<Select
								disabled
								value={this.state.agent_id}
								onChange={this.handleChange('agent_id')}
								inputProps={{
									name: 'package_type',
									id: 'package_type'
								}}
							>
								{this.state.agents.map(item => (
									<MenuItem value={item.id}>{item.code}</MenuItem>
								))}
							</Select>
						</FormControl>


						<TextField
							disabled
							fullWidth
							autoFocus
							margin="dense"
							id="agent_name"
							required={true}
							value={this.state.agent_name}
							onChange={this.handleChange('agent_name')}
							label="Tên"
							type="name"
						/>
						<TextField
							disabled
							fullWidth
							autoFocus
							margin="dense"
							id="agent_phone"
							required={true}
							value={this.state.agent_phone}
							onChange={this.handleChange('agent_phone')}
							label="SĐT"
							type="name"
						/>
						<FormControl disabled fullWidth>
							<InputLabel htmlFor="merchantId">Mã sản phẩm (*)</InputLabel>
							<Select
								value={this.state.mc_product_code}
								onChange={this.handleChange('mc_product_code')}
								inputProps={{
									name: 'package_type',
									id: 'package_type'
								}}
							>
								{this.state.products.map(item => (
									<MenuItem value={item.code}>{item.code}</MenuItem>
								))}
							</Select>
						</FormControl>


						<TextField
							disabled
							fullWidth
							autoFocus
							margin="dense"
							id="mc_product_name"
							required={true}
							value={this.state.mc_product_name}
							onChange={this.handleChange('mc_product_name')}
							label="Tên sản phẩm"
							type="name"
						/>

						<TextField
							fullWidth
							autoFocus
							margin="dense"
							id="max_order_qty"
							required={true}
							value={this.state.max_order_qty}
							onChange={this.handleChange('max_order_qty')}
							label="Số lượng lead tối đa"
							type="number"
							InputProps={{ inputProps: { min: 0 } }}

						/>

						<TextField
							fullWidth
							autoFocus
							margin="dense"
							id="url"
							value={this.state.url}
							onChange={this.handleChange('url')}
							label="Link sản phẩm"
							type="text"
							InputProps={{ inputProps: { min: 0 } }}

						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.props.history.goBack}
							color="primary"
						>
							Hủy
						</Button>
						<Button
							onClick={this.handleDialogAgree}
							color="primary"
						>
							Cập nhật
						</Button>
					</DialogActions>
				</DialogWrapper>
			</div>
		);
	}
}

export default EditAgentProduct;
