import { useQuery } from '@tanstack/react-query';
import levelApi from 'api/level.api';
import { authAxios } from 'utils/api';

export const useLevels = (params) => {
	return useQuery(['levels', params], () => levelApi.getLevels(params));
};

export const useMerchantLevel = (params) => {
	return useQuery(['merchant', params], async () => {
		const fix = {};

		for (const key in params) {
			if (params[key] === 'ALL') fix[key] = '';
			else fix[key] = params[key];
		}

		const { data } = await authAxios({
			url: 'telesale/lead/merchant-hlm/search',
			method: 'get',
			params: fix,
		});
		return data;
	});
};
