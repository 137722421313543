import React, { useState } from 'react'
import { DialogContent, DialogActions, Button, Box, FormGroup, FormLabel, Select, MenuItem } from '@material-ui/core';
import DialogWrapper from 'components/shared/DialogWrapper';
import { useParams, useHistory, useLocation } from "react-router-dom"
import { useMutation } from '@tanstack/react-query';
import levelApi from 'api/level.api';
import { useMerchantLevel, useLevels } from 'hooks/level';
import { toast } from 'react-toastify';

const AssignLevel = (props) => {
	const routerParams = useParams()
	const history = useHistory()
	const { state } = useLocation()
	const [params, setParams] = useState({
		"user_id": parseInt(routerParams.id),
		"level_id": state.level,
		"superior_user_id": state.superior_user_id
	})

	const [searchMerchantLevelParams, setSearchMerchantLevelParams] = useState({
		page: 1,
		pagesize: 15,
		level: -1,
		keyword: '',
		orderby: "level",
		asc: true,
		exclude_user_id: parseInt(routerParams.id)
	})

	const assignLevelMutation = useMutation(() => {
		const fix = { ...params }
		if (fix.level_id === 0)
			delete fix.superior_user_id
		return levelApi.assignLevelForAgent(fix)
	}, {
		onSuccess: (data) => {
			if (data.code === 100) {
				return toast.success("Phân cấp thành công")
			}
			return toast.error(data.error)
		},
		onError: () => {
			return toast.error("Có lỗi xảy ra, thử lại sau")
		}
	})
	const { data: levels } = useLevels()
	const { data: superiorData, } = useMerchantLevel(searchMerchantLevelParams)

	const handleSubmit = () => {
		assignLevelMutation.mutateAsync()
		history.goBack()
	}

	return (
		<DialogWrapper title={`Chọn level`}  width="sm">
			<DialogContent>
				<Box>
					{
						state.level !== 4 &&
						<FormGroup>
							<FormLabel>
								Chọn cấp
							</FormLabel>
							<Select
								value={params.level_id}
								onChange={(e) => {
									setParams({ ...params, level_id: parseInt(e.target.value) })
									setSearchMerchantLevelParams({ ...searchMerchantLevelParams, level: parseInt(e.target.value) - 1 })
								}}
							>
								{
									levels?.data?.slice(0,-1).map(item => (
										<MenuItem value={item.level_id} key={item.id}>{item.level_name}</MenuItem>
									))
								}
							</Select>
						</FormGroup>
					}
					{
						params.level_id !== 0 &&
						<FormGroup style={{ marginTop: "10px" }}>
							<FormLabel>Chọn cấp trên</FormLabel>
							<Select value={params.superior_user_id}
								onChange={(e) => {
									setParams({ ...params, superior_user_id: parseInt(e.target.value) })
								}}
							>
								<MenuItem value="0" disabled>Chưa chọn</MenuItem>
								{
									superiorData?.data?.map(item => (
										<MenuItem value={item.user_id} key={item.user_id}>{item.user_name}</MenuItem>
									))
								}
							</Select>
						</FormGroup>
					}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={() => history.goBack()}>Huỷ</Button>
				<Button color="primary" variant="contained" onClick={handleSubmit}>OK</Button>
			</DialogActions>
		</DialogWrapper>
	)
}

export default AssignLevel
