import React from 'react';
import DialogWrapper from 'components/shared/DialogWrapper';
import { DialogContent, DialogActions, Button } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import userApi from 'api/user.api';
import { toast } from 'react-toastify';

const LockAgent = () => {
	const routerParams = useParams();
	const history = useHistory();
	const locked = routerParams.code?.split('.')[1];
	const code = routerParams.code.split('.')[0];
	const lockMutation = useMutation(
		() => userApi.lockUser({ id: routerParams.id, code: code }),
		{
			onSuccess: (data) => {
				if (data.code === 100)
					return toast.success('Khoá tài khoản thành công');
				return toast.error('Có lỗi xảy ra, thử lại sau');
			},
			onError: () => {
				return toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);
	const unLockMutation = useMutation(
		() => userApi.unLockUser({ id: routerParams.id, code: code }),
		{
			onSuccess: (data) => {
				if (data.code === 100)
					return toast.success('Mở khoá tài khoản thành công');
				return toast.error('Có lỗi xảy ra, thử lại sau');
			},
			onError: () => {
				return toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);

	const handleSubmit = () => {
		if (locked === '0') {
			lockMutation.mutateAsync();
		} else if (locked === '1') {
			unLockMutation.mutateAsync();
		}
		history.goBack();
	};
	return (
		<DialogWrapper
			title={`${locked === '1' ? 'Mở khoá' : 'Khoá'} tài khoản`}
			history={history}
		>
			<DialogContent>
				<h1>{`Bạn chắc chắn muốn ${
					locked === '1' ? 'Mở khoá' : 'Khoá'
					} tài khoản này`}</h1>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={() => history.goBack()}>
					Huỷ
        </Button>
				<Button color="primary" variant="contained" onClick={handleSubmit}>
					OK
        </Button>
			</DialogActions>
		</DialogWrapper>
	);
};

export default LockAgent;
