import { useQuery, useMutation } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { authAxios } from 'utils/api';
import moment from 'moment';

export const useSummary = (params, url) => {
	const query = useQuery(
		['reportSummary', [params, url]],
		async () => {
			const fix = {};
			for (const key in params) {
				if (params[key] === 'ALL') fix[key] = '';
				else fix[key] = params[key];
			}

			if (fix.search) {
				fix.lead_update_date_from = undefined;
				fix.lead_update_date_to = undefined;
				fix.report_date_to = moment(fix.report_date_to).endOf('day').unix();
				fix.report_date_from = moment(fix.report_date_from)
					.startOf('day')
					.unix();
			} else {
				fix.report_date_from = undefined;
				fix.report_date_to = undefined;
				fix.lead_update_date_from = moment(params.lead_update_date_from)
					.endOf('day')
					.unix();
				fix.lead_update_date_to = moment(params.lead_update_date_to)
					.startOf('day')
					.unix();
			}
			delete fix.search;

			const { data } = await authAxios({
				url: '/telesale/lead/' + url,
				method: 'GET',
				params: fix,
			});

			if (url) {
				const k = {
					list: [],
					total_count: 0,
					summary: [],
				};
				if (data.list?.data) {
					const returnData = data.list.data.map((item) => {
						const i = {
							...item,
							id: uuidv4(),
							have_met_rate_color: item.have_met_qty_color,
							sucessful_appointment_rate_color: item.appointment_qty_color,
							called_rate_color: item.assigned_qty_color,
							total_called_qty_color: item.assigned_qty_color,
							wrong_phone_qty_color: 'green',
							total_assigned_qty_color: 'green',
							reject_qty_color: 'green',
							buyed_reject_qty_color: 'green',
							sucessful_called_rate_color: 'green',
							total_successful_called_qty_color: 'green',
							wrong_info_qty_color: 'green',
							busy_qty_color: 'green',
							is_seller_reject_qty_color: 'green',
							purchased_rate_color: item.purchased_qty_color,
							demand_determined_rate_color: item.demand_determined_qty_color,
							solution_presented_rate_color: item.solution_presented_qty_color,
							total_exp_color_color: 'green',
							wrong_location_qty_color: 'green',
							messaged_qty_color: 'green',
						};
						return i;
					});
					k['list'] = returnData.map((item) => {
						const m = {};
						for (const [key, value] of Object.entries(item)) {
							if (value === 'green') m[key] = '#75fa4c';
							else if (value === 'yellow') m[key] = '#fffc54';
							else if (value === 'red') m[key] = '#f44336';
							else m[key] = value;
						}
						return m;
					});
					k['total_count'] = data.list.total_count;
				}
				if (data.summary?.data) {
					k['summary'] = data.summary.data[0];
				}
				return k;
			}
			return data;
		},
		{
			cacheTime: Infinity,
			refetchOnWindowFocus: false,
			staleTime: Infinity,
		}
	);
	return query;
};

export const useExportRaw = () => {
	return useMutation(
		async (params) => {
			const fix = {};
			for (const key in params) {
				if (params[key] === 'ALL') fix[key] = '';
				else fix[key] = params[key];
			}

			if (fix.search) {
				fix.lead_update_date_from = undefined;
				fix.lead_update_date_to = undefined;
				fix.report_date_to = moment(fix.report_date_to).endOf('day').unix();
				fix.report_date_from = moment(fix.report_date_from)
					.startOf('day')
					.unix();
			} else {
				fix.report_date_from = undefined;
				fix.report_date_to = undefined;
				fix.lead_update_date_from = moment(params.lead_update_date_from)
					.endOf('day')
					.unix();
				fix.lead_update_date_to = moment(params.lead_update_date_to)
					.startOf('day')
					.unix();
			}
			delete fix.search;

			const { data } = await authAxios({
				url: `telesale/lead/realtime-report-export-raw`,
				params: fix,
				method: 'GET',
				responseType: 'blob',
			}).then((res) => {
				if (res.data.code) return res;
				const date = new Date();
				fileDownload(res.data, `export-raw.xlsx`);
				return res;
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.data?.code) {
					if (data?.data.code !== 100)
						return toast.error('Có lỗi xảy ra, thử lại sau');
					return toast.success('Export file thành công');
				}
				return toast.success('Export file thành công');
			},
			onError: () => {
				return toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);
};

export const useExportData = () => {
	return useMutation(
		async (params) => {
			const fix = {};
			for (const key in params) {
				if (params[key] === 'ALL') fix[key] = '';
				else fix[key] = params[key];
			}

			if (fix.search) {
				fix.lead_update_date_from = undefined;
				fix.lead_update_date_to = undefined;
				fix.report_date_to = moment(fix.report_date_to).endOf('day').unix();
				fix.report_date_from = moment(fix.report_date_from)
					.startOf('day')
					.unix();
			} else {
				fix.report_date_from = undefined;
				fix.report_date_to = undefined;
				fix.lead_update_date_from = moment(params.lead_update_date_from)
					.endOf('day')
					.unix();
				fix.lead_update_date_to = moment(params.lead_update_date_to)
					.startOf('day')
					.unix();
			}
			delete fix.search;

			const { data } = await authAxios({
				url: `telesale/lead/realtime-report-export`,
				params: fix,
				method: 'GET',
				responseType: 'blob',
			}).then((res) => {
				if (res.data.code) return res;
				const date = new Date();
				fileDownload(res.data, `export-data.xlsx`);
				return res;
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.data?.code) {
					if (data?.data.code !== 100)
						return toast.error('Có lỗi xảy ra, thử lại sau');
					return toast.success('Export file thành công');
				}
				return toast.success('Export file thành công');
			},
			onError: () => {
				return toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);
};

export const useSearchBatchId = () => {
	return useQuery(['batch_id'], async () => {
		const { data } = await authAxios({
			url: '	telesale/lead/lead-batch/list',
			method: 'GET',
		});
		return data;
	});
};
