import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'const.js';

import moment from 'moment';

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';
import { blue, green } from '@material-ui/core/colors';
import DataTable from 'components/shared/DataTable';

const URL = cs.BaseURL + '/telesale/package/list/summary';

function formatValue(value) {
    // format display of time
    if (value && value.analysis_expiry_date)
        value.analysis_expiry_date = moment(value.analysis_expiry_date)
            .format('DD/MM/YYYY HH:mm');

    // format display of time
    if (value && value.LA_expiry_date)
        value.LA_expiry_date = moment(value.LA_expiry_date)
            .format('DD/MM/YYYY HH:mm');

    // format role
    if (value && value.user_role) {
        switch (value.user_role) {
            case cs.Role_Telesale_Merchant_User:
                value.user_role = "MERCHANT USER"
                break;
            case cs.Role_Telesale_Merchant_Manager:
                value.user_role = "MERCHANT MANAGER"
                break;
            case cs.Role_Telesale_Merchant_Leader:
                value.user_role = "MERCHANT LEADER";
                break;
            default:
                break;
        }
    }

    return value;
}

class PackageSummary extends React.Component {
    constructor(props) {
        super(props);

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-general-user');
        if (user) username = JSON.parse(String(user)).name;

        this.state = {
            columns: [
                { name: 'user_fullname', title: 'Họ và tên' },
                { name: 'user_name', title: 'Tên' },
                { name: 'user_type', title: 'Type' },
                { name: 'user_role', title: 'Role' },
                { name: 'user_mc_code', title: 'Mã đối tác' },
                { name: 'analysis_total_amount', title: 'Số lượng analysis' },
                { name: 'analysis_expiry_date', title: 'Hạn dùng analysis' },
                { name: 'LA_total_amount', title: 'Số lượng LA' },
                { name: 'LA_expiry_date', title: 'Hạn dùng LA' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'user_fullname', align: 'center', width: 150 },
                { columnName: 'user_name', align: 'center', width: 150 },
                { columnName: 'user_type', align: 'center', width: 100 },
                { columnName: 'user_role', align: 'center', width: 200 },
                { columnName: 'user_mc_code', align: 'center', width: 150 },
                { columnName: 'analysis_total_amount', align: 'center', width: 150 },
                { columnName: 'analysis_expiry_date', align: 'center', width: 200 },
                { columnName: "LA_total_amount", align: "center", width: 150 },
                { columnName: 'LA_expiry_date', align: 'center', width: 200 }
            ],
            // pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 15,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'lead_created_at_unix', direction: 'desc' }],
            user_fullname: '',
            user_name: '',
            user_type: '',
            user_role: '',
            user_mc_code: '',
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);

        this.loadData();
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const {
            pageSize,
            currentPage,
            sorting,
            searchStatus,
            user_fullname,
            user_name,
            user_type,
            user_role,
            user_mc_code,
        } = this.state;



        let queryString = `${URL}?pagesize=${pageSize}&page=${currentPage + 1}`;

        const columnSorting = sorting[0];
        // if (columnSorting) {
        //     queryString = `${queryString}&orderby=${columnSorting.columnName}`;

        //     if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
        //     else queryString = `${queryString}&asc=true`;
        // }



        if (user_fullname && user_fullname !== '') queryString = `${queryString}&user_fullname=${user_fullname}`;

        if (user_name && user_name !== '') queryString = `${queryString}&user_name=${user_name}`;

        if (user_type && user_type !== '') queryString = `${queryString}&user_type=${user_type}`;

        if (user_role && user_role !== '') queryString = `${queryString}&user_role=${user_role}`;

        if (user_mc_code && user_mc_code !== '') queryString = `${queryString}&user_mc_code=${user_mc_code}`;


        return queryString;
    }

    loadData = conditions => {
        const queryString = this.queryString();


        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-general-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });

                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });

                    // prevent unauthorized access
                    if (data && (data.code === cs.erAuthenticationFailed.code || data.code === cs.erAccountIsLocked.code)) {

                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {

                this.setState({ loading: false });
            });
    };

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {

                this.setState({ currentPage: 0 });
                this.loadData();
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;


        return (
            <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Tìm kiếm ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">

                            <TextField
                                id="Telesale_agent"
                                label="Họ & Tên"
                                type="search"
                                className={classes.search_field}
                                value={this.state.user_fullname}
                                onChange={this.handleChange('user_fullname')}
                                margin="normal"
                            />

                            <TextField
                                id="product_code"
                                label="Tên"
                                type="search"
                                className={classes.search_field}
                                value={this.state.user_name}
                                onChange={this.handleChange('user_name')}
                                margin="normal"
                            />
                            <TextField
                                id="cus_name"
                                label="Đối tác"
                                type="search"
                                className={classes.search_field}
                                value={this.state.user_mc_code}
                                onChange={this.handleChange('user_mc_code')}
                                margin="normal"
                            />


                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="package_type">Role</InputLabel>
                                <Select
                                    value={this.state.user_role}
                                    onChange={this.handleChange('user_role')}
                                    inputProps={{
                                        name: 'date_start',
                                        id: 'date_startId'
                                    }}
                                >
                                    <MenuItem value={cs.Role_Telesale_Merchant_User}>
                                        <div>Merchant User</div>
                                    </MenuItem>
                                    <MenuItem value={cs.Role_Telesale_Merchant_Manager}>
                                        <div>Merchant Manager</div>
                                    </MenuItem>
                                    <MenuItem value={cs.Role_Telesale_Merchant_Leader}>
                                        <div>Merchant Leader</div>
                                    </MenuItem>

                                    <MenuItem value="">
                                        <div></div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="package_type">Type</InputLabel>
                                <Select
                                    value={this.state.user_type}
                                    onChange={this.handleChange('user_type')}
                                    inputProps={{
                                        name: 'date_start',
                                        id: 'date_startId'
                                    }}
                                >
                                    <MenuItem value="Telesale">
                                        <div>Telesale</div>
                                    </MenuItem>
                                    <MenuItem value="Marketing">
                                        <div>Marketing</div>
                                    </MenuItem>

                                    <MenuItem value="">
                                        <div></div>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <DataTable
                    rows={rows}
                    columns={columns}
                    columnWidths={tableColumnExtensions}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    loading={loading}
                    sorting={sorting}
                    totalCount={totalCount}
                    changeCurrentPage={this.changeCurrentPage}
                    changePageSize={this.changePageSize}
                    changeSorting={this.changeSorting}
                    CellComponent={CellComponent}
                    autoLoadData={this.loadData}
                />
            </div>
        );
    }
}

PackageSummary.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing(2),
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 200,
        minWidth: 120
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        backgroundColor: green
    }
});

class ActionCell extends React.Component {
    render() {
        return (
            <Table.Cell style={{
                padding: 1,
                justifyContent: 'center',
                fontSize: '12px'
            }}>
                <span>
                    <Button
                        color="primary"
                        component={Link}
                        to={{ pathname: '/detail/' + this.props.row.order_code, state: { previous: '/waiting' } }}
                    >
                        Chi tiết
                    </Button>
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    return (
        <Table.Cell
            {...props}
            style={{
                padding: 2,
                //color: '#81557a',
                fontSize: '12px'
            }}
        />
    );

    // var t1 = moment.unix(props.tableRow.row.submitted_at);
    // var t2 = moment();

    // var diff = t2.diff(t1, 'minutes');

    // if (diff <= 30)
    //     return (
    //         <Table.Cell
    //             {...props}
    //             style={{
    //                 padding: 2,
    //                 color: '#81557a',
    //                 fontSize: '12px'
    //             }}
    //         />
    //     );
    // else
    //     return (
    //         <Table.Cell
    //             {...props}
    //             style={{
    //                 padding: 2,
    //                 color: '#d34c3e',
    //                 fontSize: '12px'
    //             }}
    //         />
    //     );
};

export default withRouter(withStyles(styles)(PackageSummary));
