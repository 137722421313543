import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core';
import React from 'react';

const TaskDetail = ({ onClose, task }) => {
	return (
		<>
			<DialogTitle>Chi tiết công việc</DialogTitle>
			<DialogContent>
				<div>
					<h3>Nội dung</h3>
					<p>{task.summary}</p>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Đóng</Button>
			</DialogActions>
		</>
	);
};

export default TaskDetail;
