import axios from "./axios"

const getLevels = async () => {
	const { data } = await axios({
		url: "/telesale/lead/merchant/high-level-manager-info",
		method: "get"
	})
	return { ...data, data: data.data ? [...data.data] : [] }
}

const getReportByLevel = async (params) => {
	const { data } = await axios({
		url: "/telesale/lead/merchant/business-summary-hlm",
		method: "get",
		params: params
	})
	return data
}

const assignLevelForAgent = async (params) => {
	const { data } = await axios({
		url: "telesale/lead/merchant-hlm/assign",
		method: "POST",
		data: params,
	})
	return data
}

export default {
	getLevels,
	getReportByLevel,
	assignLevelForAgent
}
