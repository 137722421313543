import axios from "./axios"

const importBranchDirector = async (body) => {
	const form = new FormData()
	form.append("excel-file", body)
	const { data } = await axios({
		url: "/telesale/lead//merchant-branch-director/import",
		method: "POST",
		data: form,
		headers: { 'Content-Type': "multipart/form-data;" }
	})
	return data
}

export default {
	importBranchDirector
}
