import React from 'react';
import DialogWrapper from 'components/shared/DialogWrapper';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogContent, DialogActions, Button } from '@material-ui/core';
import { useConvertLeadToF0 } from './services';

const ConvertF0 = () => {
	const history = useHistory();
	const { state } = useLocation();
	const { mutateAsync } = useConvertLeadToF0();
	const onSubmit = () => {
		mutateAsync({
			agent_id: state.agent_id,
			lead_id: state.f0_id,
			id: state.id
		}).then(() => history.goBack());
	};
	return (
		<DialogWrapper history={history} title="Chuyển lead thành f0">
			<DialogContent>
				<p>Bạn muốn chuyển lead này thành F0 ?</p>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => history.goBack()}>Huỷ</Button>
				<Button onClick={onSubmit} variant="contained" color="primary">
					Chuyển
        </Button>
			</DialogActions>
		</DialogWrapper>
	);
};

export default ConvertF0;
