const statusColorList = [
	{ color: '#FCE22A', desc: 'pending' },
	{ color: '#03C988', desc: 'done' },
	{ color: '#F0FF42', desc: 'support' },
	{ color: '#FFFFFF', desc: 'default' },
];

export const generatorColorStatus = (status) => {
	if (!status) return statusColorList[0].color;
	const index = parseInt(status.replace(/[^0-9]/g, ''));
	return statusColorList[index - 1].color;
};

const priorityColorList = [
	{ color: '#FFFFFF', desc: 'default' },
	{ color: '#bce784', desc: 'normal' },
	{ color: '#5dd39e', desc: 'important' },
	{ color: '#348aa7', desc: 'veryimportant' },
];

export const generatorColorPriority = (pri) => {
	if (!pri) return priorityColorList[0].color;
	const index = parseInt(pri.replace(/[^0-9]/g, ''));
	return priorityColorList[index + 1].color;
};
