import React, { useState, useEffect } from 'react';
import { useBusinessReport, useBranch, useTeam } from 'hooks/lead';
import { useLevels } from 'hooks/level';
import {
	Select,
	InputLabel,
	MenuItem,
	FormGroup,
	TextField,
	Box,
	Typography,
	Button,
} from '@material-ui/core';
import moment from 'moment';
import { useProduct } from 'hooks/products';
import DataTable from 'components/shared/DataTable';
import { pageSizes } from 'utils/datatable';
import { Chart } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
} from 'chart.js';
import { returnDropDown } from '../helperForHlm';
import FormSearchMerchantLevel from '../Summary/FormSearchMerchantLevel';
import { optionsChart, mainChartData } from './chart';
import './style.css';

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController
);

const tableConfig = (levels) => {
	const tbl = {
		col: [
			{ name: 'display_name', title: 'Tên' },
			{ name: 'team_name', title: 'Phòng' },
			{ name: 'total_revenue', title: 'Tổng doanh thu' },
			{ name: 'total_order', title: 'Tổng đơn hàng' },
			{ name: 'total_amount', title: 'Tổng số lượng' },
		],
		config: [
			{ columnName: 'display_name', align: 'center', width: 200 },
			{ columnName: 'telesale_agent_code', align: 'center', width: 200 },
			{ columnName: 'team_name', align: 'center', width: 200 },
			{ columnName: 'branch_name', align: 'center', width: 200 },
			{ columnName: 'total_revenue', align: 'center', width: 200 },
			{ columnName: 'total_order', align: 'center', width: 200 },
			{ columnName: 'total_amount', align: 'center', width: 200 },
		],
	};
	if (levels) {
		let lastElm = levels.at(-1);
		if (lastElm) {
			tbl.col.unshift({
				name: "branch_name",
				title: lastElm.level_name,
			});
			tbl.config.unshift({
				columnName: `sp_name_lv${lastElm.level_id}`,
				align: 'center',
				width: 200,
			});
		}
		levels = levels.slice(1, -1);

		tbl.col.unshift(
			...levels.map((item) => ({
				name: `sp_name_lv${item.level_id}`,
				title: item.level_name,
			}))
		);
		tbl.config.push(
			...levels.map((item) => ({
				columnName: `sp_name_lv${item.level_id}`,
				align: 'center',
				width: 200,
			}))
		);
	}

	return tbl;
};

const BussinessReport = () => {
	const { data: levels, isLoading: levelsLoading } = useLevels();
	const [url, setUrl] = useState('business-summary-agent');
	const [params, setParams] = useState({
		product_code: 'ALL',
		report_date_from: moment()
			.subtract(14, 'day')
			.startOf('day')
			.format('YYYY-MM-DD'),
		report_date_to: moment()
			.endOf('day')
			.format('YYYY-MM-DD'),
		pagesize: 15,
		page: 1,
		team_name: 'ALL',
		branch_name: 'ALL',

		...levels?.data
			?.sort((a, b) => a.level_id - b.level_id)
			.slice(1, -1)
			.filter((item) => item.level_id <= url.split('=')[1])
			.map((item) => {
				return { ['sp_name_lv' + item.level_id]: 'ALL' };
			}),
		/* orderby: "", */
		/* asc: false */
	});
	const [mainChart, setMainChart] = useState({
		lebels: [],
		datasets: [],
	});
	const { data, isLoading, refetch } = useBusinessReport(
		{
			...params,
			report_date_to: moment(params.report_date_to).endOf('day').unix(),
			report_date_from: moment(params.report_date_from).startOf('day').unix(),
		},
		url
	);
	const { data: products } = useProduct({}, 'merchant-product');
	const { data: branchs } = useBranch({});
	const { data: teams } = useTeam({
		branch_name: params.branch_name !== 'ALL' ? params.branch_name : '',
	});

	useEffect(() => {
		if (data?.list) {
			setMainChart({ ...mainChartData(data.list.data) });
		}
	}, [data?.list]);

	return (
		<div className="report-business">
			<form className="report-business-form-search">
				<TextField
					label="Từ ngày"
					value={params.report_date_from}
					onChange={(e) =>
						setParams({ ...params, report_date_from: e.target.value })
					}
					type="date"
					fullWidth
				/>
				<TextField
					label="Đến ngày"
					value={params.report_date_to}
					onChange={(e) =>
						setParams({ ...params, report_date_to: e.target.value })
					}
					fullWidth
					type="date"
				/>
				<FormGroup>
					<InputLabel htmlFor="url">Loại</InputLabel>
					<Select
						value={url}
						onChange={(e) => setUrl(e.target.value)}
						inputProps={{
							name: 'name',
							id: 'url',
						}}
					>
						{returnDropDown({ level: levels?.data, type: 'business' }).map(
							(item) => (
								<MenuItem value={item.url} key={item.url}>
									<div>{item.name}</div>
								</MenuItem>
							)
						)}
					</Select>
				</FormGroup>
				<FormGroup>
					<InputLabel htmlFor="product_code">Sản phẩm</InputLabel>
					<Select
						value={params.product_code}
						onChange={(e) =>
							setParams({
								...params,
								product_code: e.target.value,
								page: 1,
							})
						}
						inputProps={{
							name: 'name',
							id: 'code',
						}}
					>
						<MenuItem value="ALL">Tất cả</MenuItem>
						{products?.data?.map((pro) => (
							<MenuItem value={pro.code} key={pro.code}>
								{pro.code && <div>{pro.name}</div>}
								{!pro.code && <div>{pro.name}</div>}
							</MenuItem>
						))}
					</Select>
				</FormGroup>
				{(url === 'business-summary-agent' ||
					url === 'business-summary-team') && (
						<FormGroup>
							<InputLabel>Phòng (AD)</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								value={params.team_name}
								label="Team"
								onChange={(e) =>
									setParams({ ...params, team_name: e.target.value, page: 1 })
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{teams?.data?.map((item, index) => (
									<MenuItem key={index} value={item.name}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormGroup>
					)}
				{(url === 'business-summary-agent' ||
					url === 'business-summary-branch' ||
					url === 'business-summary-team') && (
						<FormGroup>
							<InputLabel>Chi nhánh (SD)</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								value={params.branch_name}
								label="Chi nhánh"
								onChange={(e) =>
									setParams({
										...params,
										branch_name: e.target.value,
										page: 1,
									})
								}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{branchs?.data?.map((item, index) => (
									<MenuItem value={item.name} key={index}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormGroup>
					)}
				{levels?.data
					?.sort((a, b) => a.level_id - b.level_id)
					.slice(1, -1)
					.filter(
						(item) =>
							item.level_id <= url.split('=')[1] ||
							url === 'business-summary-agent' ||
							url === 'business-summary-team' ||
							url === 'business-summary-branch'
					)
					.map((item, index) => (
						<FormSearchMerchantLevel
							level={item.level_id}
							params={params}
							setParams={setParams}
							title={item.level_name}
							key={index}
						/>
					))}

				<Button color="primary" variant="contained" onClick={refetch}>
					Tìm
				</Button>
			</form>
			<Box marginTop="20px">
				<Typography variant="h5" components="h5">
					Thống kê tổng quan:
				</Typography>
				<Box>
					<Typography component="h6" variant="h6">
						Tổng doanh thu:{' '}
						{parseInt(data?.summary?.data[0]?.total_revenue).toLocaleString()}
					</Typography>
				</Box>
			</Box>
			{!levelsLoading && (
				<DataTable
					rows={data?.list?.data}
					columns={tableConfig(levels?.data).col}
					columnWidths={tableConfig(levels?.data).config}
					pageSizes={pageSizes}
					pageSize={params.pagesize}
					currentPage={params.page - 1}
					loading={isLoading}
					totalCount={data?.list?.total_count}
					changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				/>
			)}

			<Box>
				<Box marginTop="30px">
					<Typography variant="h5" component="h5">
						Biểu đồ
					</Typography>
				</Box>
				<Chart
					options={optionsChart}
					data={mainChart}
					className="custom-chart"
				/>
			</Box>
		</div>
	);
};

export default BussinessReport;
