import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'const.js';
import moment from 'moment';

import React from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';
import { blue, green } from '@material-ui/core/colors';

import Icon from '@material-ui/core/Icon';

import CreateProduct from './CreateProduct.js';
import UpdateProduct from './UpdateProduct.js';
import DeleteProduct from './DeleteProduct.js';
import DataTable from 'components/shared/DataTable';

//import { Link, Route, withRouter } from 'react-router-dom';

const URL = cs.BaseURL + '/telesale/inventory/list/product';

const activeStatus = [
	'Chưa phê duyệt',
	'Phê duyệt',
	'',
	'Yêu cầu sửa lại',
	'Tắt',
];

function formatValue(value) {
	// format display of time
	if (value && value.lead_created_at_unix)
		value.lead_created_at_unix = moment
			.unix(value.lead_created_at_unix)
			.format('DD/MM/YYYY HH:mm');

	// format short forms
	if (value && value.current_province)
		value.current_province = value.current_province
			.replace('Thành phố', 'TP')
			.replace('Tỉnh', '');

	if (value && value.active !== null) {
		value.active = activeStatus[value.active];
	}

	if (value && value.price) {
		value.price = value.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return value;
}

class InventoryProductList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			columns: [
				{ name: 'mc_code', title: 'Đối tác' },
				{ name: 'product_name', title: 'Sản phẩm' },
				{ name: 'product_code', title: 'Mã sản phẩm' },
				{ name: 'price', title: 'Giá' },
				{ name: 'action', title: 'Thao tác' },
			],
			rows: [],
			selection: [],
			tableColumnExtensions: [
				{ columnName: 'mc_code', align: 'center', width: 150 },
				{ columnName: 'product_name', align: 'center', width: 450 },
				{ columnName: 'product_code', align: 'center', width: 250 },
				{ columnName: 'price', align: 'center', width: 150 },
				{ columnName: 'action', align: 'center', width: 150 },
			],
			// pageSizes: [5, 10, 20, 30, 60],
			totalCount: 0,
			pageSize: 15,
			currentPage: 0,
			loading: true,
			sorting: [{ columnName: 'lead_created_at_unix', direction: 'desc' }],
			search_product_name: '',
			search_team_name: '',
			showImportProduct: false,
		};

		this.changeCurrentPage = this.changeCurrentPage.bind(this);
		this.changePageSize = this.changePageSize.bind(this);

		this.loadData();
	}

	changeCurrentPage(currentPage) {
		this.setState(
			{
				loading: true,
				currentPage: currentPage,
			},
			() => {
				this.loadData();
			}
		);
	}

	changePageSize(pageSize) {
		this.setState(
			{
				loading: true,
				pageSize: pageSize,
				currentPage: 0,
			},
			() => {
				this.loadData();
			}
		);
	}

	queryString() {
		const {
			pageSize,
			currentPage,
			sorting,

			search_product_name,
		} = this.state;

		let queryString = `${URL}?pagesize=${pageSize}&page=${currentPage + 1}`;

		const columnSorting = sorting[0];
		// if (columnSorting) {
		//     queryString = `${queryString}&orderby=${columnSorting.columnName}`;

		//     if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
		//     else queryString = `${queryString}&asc=true`;
		// }

		// if (searchAdrState && searchAdrState !=='') queryString = `${queryString}&current_province=${searchAdrState}`;
		// if (search_submitted_start)
		//     queryString = `${queryString}&search_submitted_start=${moment(search_submitted_start)
		//         .startOf('day')
		//         .unix()}`;
		// if (search_submitted_end)
		//     queryString = `${queryString}&search_submitted_end=${moment(search_submitted_end)
		//         .endOf('day')
		//         .unix()}`;

		if (search_product_name && search_product_name !== '')
			queryString = `${queryString}&product_name=${search_product_name}`;

		return queryString;
	}

	loadData = (conditions) => {
		const queryString = this.queryString();

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					for (var i = 0; i < data.data.length; i++) {
						data.data[i] = formatValue(data.data[i]);
					}

					this.setState({
						rows: data.data,
						totalCount: data.total_count,
						loading: false,
					});
				} else {
					this.setState({
						rows: [],
						totalCount: 0,
						loading: false,
					});

					// prevent unauthorized access
					if (
						data &&
						(data.code === cs.erAuthenticationFailed.code ||
							data.code === cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	};

	handleChange = (name) => (event) => {
		if (name == 'search_deleted') {
			this.setState(
				{
					[name]: event.target.checked,
				},
				() => {
					this.setState({ currentPage: 0 });
					this.loadData();
				}
			);
		} else {
			this.setState(
				{
					[name]: event.target.value,
				},
				() => {
					this.setState({ currentPage: 0 });
					this.loadData();
				}
			);
		}
	};

	render() {
		const { classes } = this.props;

		// get value from state
		const {
			rows,
			columns,
			tableColumnExtensions,
			pageSizes,
			pageSize,
			currentPage,
			totalCount,
			loading,
			sorting,
		} = this.state;

		return (
			<div>
				<Button
					color="inherit"
					aria-label="Import Product"
					component={Link}
					to={'/import/product'}
				>
					<Icon>cloud_upload</Icon>
					<span style={{ marginLeft: 10 }}>Tạo hàng loạt Sản phẩm</span>
				</Button>
				<ExpansionPanel>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Typography className={classes.heading}>Tìm kiếm ...</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<form
							className={classes.search_container}
							noValidate
							autoComplete="off"
						>
							<TextField
								id="search_product_name"
								label="Sản phẩm"
								type="search"
								className={classes.search_field}
								value={this.state.search_product_name}
								onChange={this.handleChange('search_product_name')}
								margin="normal"
							/>
						</form>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<Button
					color="inherit"
					aria-label="Create Product"
					component={Link}
					to={'/product-inventory/create'}
				>
					<Icon>person_add</Icon>
					<span style={{ marginLeft: 10 }}>Thêm sản phẩm</span>
				</Button>{' '}
				<Button color="inherit" aria-label="Refresh" onClick={this.loadData}>
					<Icon>refresh</Icon>
					<span style={{ marginLeft: 10 }}>Làm mới</span>
				</Button>
				<DataTable
					rows={rows}
					columns={columns}
					columnWidths={tableColumnExtensions}
					pageSizes={pageSizes}
					pageSize={pageSize}
					currentPage={currentPage}
					loading={loading}
					sorting={sorting}
					totalCount={totalCount}
					changeCurrentPage={this.changeCurrentPage}
					changePageSize={this.changePageSize}
					changeSorting={this.changeSorting}
					CellComponent={CellComponent}
					autoLoadData={this.loadData}
				/>
				<Route
					exact
					path="/product-inventory/create"
					component={CreateProduct}
				/>
				<Route
					exact
					path="/product-inventory/update/:id"
					component={UpdateProduct}
				/>
				<Route
					exact
					path="/product-inventory/delete/:id"
					component={DeleteProduct}
				/>
			</div>
		);
	}
}

InventoryProductList.propTypes = {
	classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
	grid_Container: {
		width: '100%',
		marginTop: theme.spacing(2),
		overflowX: 'auto',
	},
	tableStriped: {
		'& tbody tr:nth-of-type(odd)': {
			backgroundColor: 'rgb(186, 207, 255)',
		},
		'& tbody tr:nth-of-type(even)': {
			backgroundColor: 'rgb(217, 226, 244)',
		},
	},
	searchPanel: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	search_container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	search_field: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: 200,
		minWidth: 120,
	},
	search_select_menu: {
		width: 200,
	},
	grid_header: {
		backgroundColor: green,
	},
	gender_button: {
		marginTop: 20,
		marginLeft: 40,
	},
});

class ActionCell extends React.Component {
	render() {
		return (
			<Table.Cell
				style={{
					padding: 1,
					justifyContent: 'center',
					fontSize: '12px',
				}}
			>
				<span>
					<Button
						color="primary"
						style={{
							margin: 0,
							padding: 0,
						}}
						component={Link}
						to={'/product-inventory/update/' + this.props.row.id}
					>
						<Icon>edit</Icon>
					</Button>

					<Button
						color="secondary"
						style={{
							margin: 0,
							padding: 0,
						}}
						component={Link}
						to={'/product-inventory/delete/' + this.props.row.id}
					>
						<Icon>delete_outline</Icon>
					</Button>

					{/* <Button
                        color="primary"
                        style={{
                            margin: 0,
                            padding: 0
                        }}
                        component={Link}
                        to={'/product-inventory/allocate/' + this.props.row.id+'/'+this.props.row.agent_phone}
                    >
                        Phân quyền
                    </Button> */}
				</span>
			</Table.Cell>
		);
	}
}

const CellComponent = (props) => {
	const { column } = props;
	if (column.name === 'action') {
		return <ActionCell {...props} />;
	}

	return (
		<Table.Cell
			{...props}
			style={{
				padding: 2,
				//color: '#81557a',
				fontSize: '12px',
			}}
		/>
	);

	// var t1 = moment.unix(props.tableRow.row.submitted_at);
	// var t2 = moment();

	// var diff = t2.diff(t1, 'minutes');

	// if (diff <= 30)
	//     return (
	//         <Table.Cell
	//             {...props}
	//             style={{
	//                 padding: 2,
	//                 color: '#81557a',
	//                 fontSize: '12px'
	//             }}
	//         />
	//     );
	// else
	//     return (
	//         <Table.Cell
	//             {...props}
	//             style={{
	//                 padding: 2,
	//                 color: '#d34c3e',
	//                 fontSize: '12px'
	//             }}
	//         />
	//     );
};

export default withRouter(withStyles(styles)(InventoryProductList));
