import { useQuery, useMutation } from "@tanstack/react-query"
import { toast } from 'react-toastify'
import dc_articleApi from "api/dc_article.api"

export const useListMedia = (params) => {
	return useQuery(["medias", params], () => dc_articleApi.getListMedia(params))
}

export const usePosts = (params) => {
	return useQuery(["posts", params], ({ queryKey }) => dc_articleApi.listPost(queryKey[1]))
}

export const useCreatePost = () => {
	return useMutation(post => dc_articleApi.createPost(post), {
		onSuccess: (data) => {
			if (data.code === 100) {
				toast.success("Tạo bài viết thành công")
				return data
			}
			toast.error(data.error)
			return data
		},
		onError: () => {
			toast.error("Có lỗi xảy ra")
			return data
		}
	})
}

export const useEditPost = () => {
	return useMutation(post => dc_articleApi.editPost(post),
		{

			onSuccess: (data) => {
				if (data.code === 100) {
					toast.success("Sửa bài viết thành công")
					return data
				}
				toast.error(data.error)
				return data
			},
			onError: () => {
				toast.error("Có lỗi xảy ra")
				return data
			}
		}
	)
}

export const useDeletPost = () => {
	return useMutation(params => dc_articleApi.deletePost(params), {

		onSuccess: (data) => {
			if (data.code === 100) {
				toast.success("Xoá bài viết thành công")
				return data
			}
			toast.error(data.error)
			return data
		},
		onError: () => {
			toast.error("Có lỗi xảy ra")
			return data
		}
	})
}

export const useCategory = () => {
	return useQuery(["category"], () => dc_articleApi.listCategory())
}

export const useProduct = () => {
	return useQuery(["product"], () => dc_articleApi.listProduct())
}

export const useSavePost = () => {
	return useMutation(post => dc_articleApi.createPost(post), {

		onSuccess: (data) => {
			if (data.code === 100) {
				toast.success("Lưu bài viết thành công")
				return data
			}
			toast.error(data.error)
			return data
		},
		onError: () => {
			toast.error("Có lỗi xảy ra")
			return data
		}
	})
}

export const useGetAgentArticle = (params) => {
	return useQuery(["agent_article", params], () => dc_articleApi.getAgentArticle(params))
}


export const useGetLeadArticle = (params) => {
	return useQuery(["lead_article", params], () => dc_articleApi.getLeadArticle(params))
}

export const useGetLinkShareArticle = (params) => {
	return useMutation((params) => dc_articleApi.getLinkShareArticle(params))
}

export const useApproveArticle = () => {
	return useMutation((params) => dc_articleApi.approveArticle(params), {

		onSuccess: (data) => {
			if (data.code === 100) {
				toast.success("Phê duyệt bài viết thành công")
				return data
			}
			toast.error(data.error)
			return data
		},
		onError: () => {
			toast.error("Có lỗi xảy ra")
			return data
		}
	})
}

export const useHubReject = () => {
	return useMutation((params) => dc_articleApi.hubReject(params), {

		onSuccess: (data) => {
			if (data.code === 100) {
				toast.success("Từ chối bài viết thành công")
				return data
			}
			toast.error(data.error)
			return data
		},
		onError: () => {
			toast.error("Có lỗi xảy ra")
			return data
		}
	})
}

export const useUpdateButton = () => {
	return useMutation((params) => dc_articleApi.updateButton(params), {
		onSuccess: (data) => {
			if (data.code === 100) {
				toast.success("Cập nhật button bài viết thành công")
				return data
			}
			toast.error(data.error)
			return data
		},
		onError: () => {
			toast.error("Có lỗi xảy ra")
			return data
		}
	})
}
