import axios from './axios';

const getAgentProduct = async (params) => {

	const fix = {};

	for (const key in params) {
		if (params[key] === 'ALL') fix[key] = '';
		else fix[key] = params[key];
	}
	const { data } = await axios({
		url: 'telesale/lead/agent-product/list',
		method: 'GET',
		params: fix,
	});
	return data;
};

const updateFuntionGetLead = async (params) => {
	if (params.status === 0) {
		const { data } = await axios({
			url: '/telesale/lead/agent/enable-auto-assign-ready',
			data: {
				id: params.id,
				code: params.code,
			},
			method: 'POST',
		});
		return data;
	} else {
		const { data } = await axios({
			url: '/telesale/lead/agent/disable-auto-assign-ready',
			data: {
				id: params.id,
				code: params.code,
			},
			method: 'POST',
		});
		return data;
	}
};


const createAgentLocation = async (body) => {
	const { data } = await axios({
		url: '/telesale/lead/agent-location/create',
		method: 'POST',
		data: body,
	});
	return data;
};

const createMulAgentLocation = async (body) => {
	const { data } = await axios({
		url: '/telesale/lead/agent-location/create-multiple',
		method: 'POST',
		data: body,
	});
	return data;
};

export default {
	getAgentProduct,
	updateFuntionGetLead,
	createAgentLocation,
	createMulAgentLocation,
};
