import React, { useState } from 'react';
import { Link, Route } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import CreateAgentProduct from './CreateAgentProduct.js';
import EditAgentProduct from './EditAgentProduct.js';
import DeleteAgentProduct from './DeleteAgentProduct.js';
import DataTable from 'components/shared/DataTable.jsx';
import { useAgentProduct } from 'hooks/leadAgent';
import { pageSizes } from 'utils/datatable.js';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	TextField,
	Typography,
	Icon,
	Box,
} from '@material-ui/core';
import { More } from '@material-ui/icons';

const tableConfig = {
	columns: [
		{ name: 'agent_name', title: 'Họ và tên' },
		{ name: 'agent_phone', title: 'SĐT' },
		{ name: 'mc_code', title: 'Mã đối tác' },
		{ name: 'mc_product_code', title: 'Mã SP' },
		{ name: 'mc_product_name', title: 'Tên SP' },
		{ name: 'current_order_qty', title: 'Số lượng lead hiện tại' },
		{ name: 'max_order_qty', title: 'Số lượng lead tối đa' },
		{ name: 'action', title: 'Thao tác' },
	],
	tableColumnExtensions: [
		{ columnName: 'agent_name', align: 'center', width: 150 },
		{ columnName: 'agent_phone', align: 'center', width: 150 },
		{ columnName: 'agent_mc_ref_code', align: 'center', width: 150 },
		{ columnName: 'mc_code', align: 'center', width: 150 },
		{ columnName: 'mc_product_code', align: 'center', width: 150 },
		{ columnName: 'mc_product_name', align: 'center', width: 150 },
		{ columnName: 'current_order_qty', align: 'center', width: 220 },
		{ columnName: 'max_order_qty', align: 'center', width: 200 },
		{ columnName: 'action', align: 'center', width: 150 },
	],
};

const Product = () => {
	const [params, setParams] = useState({
		pagesize: 15,
		page: 1,
		agent_name: '',
		agent_phone: '',
		agent_mc_ref_code: '',
		mc_product_code: '',
		mc_product_name: '',
	});
	const { data, refetch, isLoading } = useAgentProduct(params);

	return (
		<div>
			<Accordion>
				<AccordionSummary expandIcon={<More />}>
					<Typography>Tìm kiếm ...</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box
						display="grid"
						gridTemplateColumns="repeat(6, 1fr)"
						gridColumnGap={10}
						width="100%"

					>
						<TextField
							id="Telesale_agent"
							label="Họ & Tên"
							type="search"
							value={params.agent_name}
							onChange={(e) =>
								setParams({ ...params, agent_name: e.target.value })
							}
						/>

						<TextField
							id="product_code"
							label="Phone"
							type="search"
							value={params.agent_phone}
							onChange={(e) =>
								setParams({ ...params, agent_phone: e.target.value })
							}
						/>
						<TextField
							id="search_mc_product_code"
							label="Mã sản phẩm"
							type="search"
							value={params.mc_product_code}
							onChange={(e) =>
								setParams({ ...params, mc_product_code: e.target.value })
							}
						/>

						<TextField
							id="search_mc_product_name"
							label="Tên SP"
							type="search"
							value={params.mc_product_name}
							onChange={(e) =>
								setParams({ ...params, mc_product_name: e.target.value })
							}
						/>
					</Box>
				</AccordionDetails>
			</Accordion>

			<Button
				color="inherit"
				aria-label="Create New Content"
				component={Link}
				to={'/agent-product/create'}
			>
				<Icon>person_add</Icon>
				<span style={{ marginLeft: 10 }}>Thêm mới</span>
			</Button>
			<Button color="inherit" aria-label="Refresh" onClick={refetch}>
				<Icon>refresh</Icon>
				<span style={{ marginLeft: 10 }}>Làm mới</span>
			</Button>

			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.tableColumnExtensions}
				currentPage={params.page - 1}
				totalCount={data?.total_count}
				pageSize={params.pagesize}
				pageSizes={pageSizes}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				loading={isLoading}
				CellComponent={CellComponent}
			/>

			<Route
				exact
				path="/agent-product/create"
				component={CreateAgentProduct}
			/>
			<Route
				exact
				path="/agent-product/edit/:id"
				component={EditAgentProduct}
			/>
			<Route
				exact
				path="/agent-product/delete/:id"
				component={DeleteAgentProduct}
			/>
		</div>
	);
};
class ActionCell extends React.Component {
	render() {
		return (
			<Table.Cell
				style={{
					padding: 1,
					justifyContent: 'center',
					fontSize: '12px',
				}}
			>
				<span>
					<Button
						color="primary"
						style={{
							margin: 0,
							padding: 0,
						}}
						component={Link}
						to={'/agent-product/edit/' + this.props.row.id}
					>
						<Icon>edit</Icon>
					</Button>
					<Button
						color="secondary"
						style={{
							margin: 0,
							padding: 0,
						}}
						component={Link}
						to={'/agent-product/delete/' + this.props.row.id}
					>
						<Icon>remove_circle</Icon>
					</Button>
				</span>
			</Table.Cell>
		);
	}
}

const CellComponent = (props) => {
	const { column, row } = props;
	switch(column.name){
		case 'action':
			return <ActionCell {...props} />;
		case 'agent_phone':
			return (
				<Table.Cell>{row.agent_phone?.replace(/\d(?!\d{0,3}$)/g, '*')}</Table.Cell>
			);
		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						// color: '#81557a',
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default Product;
