import React from 'react';
import Icon from '@material-ui/core/Icon';
import cs from '../const.js';
import { getRole, isSupplierForBookingOfMerchant } from 'service.js';

export const menu = [
	{
		icon: <Icon>bar_chart</Icon>,
		title: 'Thống kê',
		items: [
			/* { */
			/* 	icon: <Icon >bar_chart</Icon>, */
			/* 	title: 'Phân tích bán hàng', */
			/* 	to: '/report/statistic', */
			/* }, */
			{
				icon: <Icon>bar_chart</Icon>,
				title: 'Phân tích doanh nghiệp',
				to: '/report/business',
			},
			{
				icon: <Icon>bar_chart</Icon>,
				title: 'Thống kê số lượng',
				to: '/report/daily',
			},
			/* { */
			/* 	icon: <Icon >bar_chart</Icon>, */
			/* 	title: 'Thống kê lead', */
			/* 	to: '/report/detail', */
			/* }, */
			{
				icon: <Icon>bar_chart</Icon>,
				title: 'Thống kê chi tiết',
				to: '/report/summary',
			},
		],
		accesses: [
			cs.Role_Mc_Board_Of_Control_Member,
			cs.Role_Merchant_Admin,
			cs.Role_Telesale_Merchant_Manager,
			cs.Role_Telesale_Merchant_Branch_Director,
			cs.Role_Telesale_Merchant_Leader,
			cs.Role_Mc_Salesplus_CEO,
		],
	},
	{
		icon: <Icon> query_builder</Icon>,
		title: 'Quản lý vận chuyển',
		items: [
			{
				icon: <Icon>query_builder</Icon>,
				title: 'Chờ xác nhận đã thanh toán',
				to: '/order_to_confirm',
				accesses: [
					cs.Role_Mc_Board_Of_Control_Member,
					cs.Role_Mc_Salesplus_CEO,
				],
				validFunctionRoles: [
					cs.Function_Role_MC_Executive_Warehouse,
					cs.Function_Role_MC_Order_Coordinator,
				],
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: isSupplierForBookingOfMerchant()
					? 'Đơn hàng đặt từ merchant chờ phân bổ'
					: 'Đơn hàng đặt cho supplier',
				to: '/order-book/to-allocate',
				accesses: [
					cs.Role_Mc_Board_Of_Control_Member,
					cs.Role_Mc_Salesplus_CEO,
				],
				validFunctionRoles: [
					cs.Function_Role_MC_Executive_Warehouse,
					cs.Function_Role_MC_Order_Coordinator,
					cs.Function_Role_Supplier_Coordinator_For_Booking_Of_Mc,
					cs.Function_Role_Supplier_Warehouse_Executive_For_Booking_Of_Mc,
				],
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: isSupplierForBookingOfMerchant()
					? 'Đơn hàng đang được chuẩn bị'
					: 'Đơn hàng đang được supplier chuẩn bị',
				to: '/order-book/being-packed',
				accesses: [
					cs.Role_Mc_Board_Of_Control_Member,
					cs.Role_Mc_Salesplus_CEO,
				],
				validFunctionRoles: [
					cs.Function_Role_MC_Executive_Warehouse,
					cs.Function_Role_MC_Order_Coordinator,
					cs.Function_Role_Supplier_Coordinator_For_Booking_Of_Mc,
					cs.Function_Role_Supplier_Warehouse_Executive_For_Booking_Of_Mc,
				],
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: isSupplierForBookingOfMerchant()
					? 'Đơn hàng đang gửi đến merchant'
					: 'Đơn hàng đang gửi từ supplier',
				to: '/order-book/being-shipped',
				accesses: [
					cs.Role_Mc_Board_Of_Control_Member,
					cs.Role_Mc_Salesplus_CEO,
				],
				validFunctionRoles: [
					cs.Function_Role_MC_Executive_Warehouse,
					cs.Function_Role_MC_Order_Coordinator,
					cs.Function_Role_Supplier_Coordinator_For_Booking_Of_Mc,
					cs.Function_Role_Supplier_Warehouse_Executive_For_Booking_Of_Mc,
				],
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: isSupplierForBookingOfMerchant()
					? 'Đơn hàng đã gửi đến merchant'
					: 'Đơn hàng từ nhà cung cấp',
				to: '/order-book/delivered',
				accesses: [
					cs.Role_Mc_Board_Of_Control_Member,
					cs.Role_Mc_Salesplus_CEO,
				],
				validFunctionRoles: [
					cs.Function_Role_MC_Executive_Warehouse,
					cs.Function_Role_MC_Order_Coordinator,
					cs.Function_Role_Supplier_Coordinator_For_Booking_Of_Mc,
					cs.Function_Role_Supplier_Warehouse_Executive_For_Booking_Of_Mc,
				],
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: 'Chờ phân bổ cho NV kho',
				to: '/order_to_allocate',
				accesses: [
					cs.Role_Mc_Board_Of_Control_Member,
					cs.Role_Mc_Salesplus_CEO,
				],
				validFunctionRoles: [
					cs.Function_Role_MC_Executive_Warehouse,
					cs.Function_Role_MC_Order_Coordinator,
					cs.Function_Role_Supplier_General_Director_Level,
					cs.Function_Role_Supplier_Executive_Warehouse_For_End_User_Order,
					cs.Function_Role_Supplier_Sales_Coordinator_For_End_User_Order,
				],
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: 'Chờ đóng gói hàng',
				to: '/order_being_packed',
				accesses: [
					cs.Role_Mc_Board_Of_Control_Member,
					cs.Role_Mc_Salesplus_CEO,
				],
				validFunctionRoles: [
					cs.Function_Role_MC_Executive_Warehouse,
					cs.Function_Role_MC_Order_Coordinator,
					cs.Function_Role_Supplier_General_Director_Level,
					cs.Function_Role_Supplier_Executive_Warehouse_For_End_User_Order,
					cs.Function_Role_Supplier_Sales_Coordinator_For_End_User_Order,
				],
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: 'Bưu cục đang ship',
				to: '/order_shipped',
				accesses: [
					cs.Role_Mc_Board_Of_Control_Member,
					cs.Role_Mc_Salesplus_CEO,
				],
				validFunctionRoles: [
					cs.Function_Role_MC_Executive_Warehouse,
					cs.Function_Role_MC_Order_Coordinator,
					cs.Function_Role_Supplier_General_Director_Level,
					cs.Function_Role_Supplier_Executive_Warehouse_For_End_User_Order,
					cs.Function_Role_Supplier_Sales_Coordinator_For_End_User_Order,
				],
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: 'KH đã nhận hàng',
				to: '/order_delivered',
				accesses: [
					cs.Role_Mc_Board_Of_Control_Member,
					cs.Role_Mc_Salesplus_CEO,
				],
				validFunctionRoles: [
					cs.Function_Role_MC_Executive_Warehouse,
					cs.Function_Role_MC_Order_Coordinator,
					cs.Function_Role_Supplier_General_Director_Level,
					cs.Function_Role_Supplier_Executive_Warehouse_For_End_User_Order,
					cs.Function_Role_Supplier_Sales_Coordinator_For_End_User_Order,
				],
			},
		],
		accesses: [cs.Role_Mc_Board_Of_Control_Member, cs.Role_Mc_Salesplus_CEO],
		validFunctionRoles: [
			cs.Function_Role_MC_Executive_Warehouse,
			cs.Function_Role_MC_Order_Coordinator,
			cs.Function_Role_Supplier_General_Director_Level,
			cs.Function_Role_Supplier_Executive_Warehouse_For_End_User_Order,
			cs.Function_Role_Supplier_Sales_Coordinator_For_End_User_Order,
			cs.Function_Role_Supplier_Coordinator_For_Booking_Of_Mc,
			cs.Function_Role_Supplier_Warehouse_Executive_For_Booking_Of_Mc,
		],
	},

	{
		icon: <Icon>query_builder</Icon>,
		title: 'Quản lý danh sách lead',
		items: [
			{
				icon: <Icon>playlist_add_circle</Icon>,
				title: 'Đăng ký nhận F(n+1) của lead',
				to: '/subcription',
				level: 0,
			},
			{
				icon: <Icon>apps</Icon>,
				title: 'Tất cả',
				to: '/lead/all',
				nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
				rolealwaysShow: [92],
				level: 0,
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: 'Lead chưa được phân bổ',
				to: '/lead/0',
				nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
			},
			{
				icon: <Icon>query_builder</Icon>,
				title: 'Lead được thu hồi',
				to: '/lead/-2',
				nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
			},
			{
				icon: <Icon>cached</Icon>,
				title: 'Chưa gọi',
				to: '/lead/1',
				nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
			},
			{
				icon: <Icon>call_end_sharp</Icon>,
				title: 'SĐT không có/ Tắt máy',
				to: '/lead/2',
				nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
			},
			{
				icon: <Icon>phone_disabled</Icon>,
				title: 'Không nghe máy',
				to: '/lead/3',
				nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
			},
			{
				icon: <Icon>error_sharp</Icon>,
				title: 'Sai tên/ sai số',
				to: '/lead/4',
				nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
			},
			{
				icon: <Icon>cancel</Icon>,
				title: 'Từ chối',
				to: '/lead/5',
				nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
			},
			{
				icon: <Icon>handshake</Icon>,
				title: 'Đang dùng sản phẩm',
				to: '/lead/51',
			},
			{
				icon: <Icon>support_agent</Icon>,
				title: 'Là người bán',
				to: '/lead/52',
			},
			{
				icon: <Icon>phone_call_back_sharp</Icon>,
				title: 'Hẹn gọi lại',
				to: '/lead/6',
				nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
			},
			{
				icon: <Icon>event_available</Icon>,
				title: 'Quan tâm',
				to: '/lead/7',
			},
			{
				icon: <Icon>call_end_sharp</Icon>,
				title: 'Tư vấn cho tôi',
				to: '/lead/61',
			},
			{
				icon: <Icon>event_available</Icon>,
				title: 'Hẹn gặp',
				to: '/lead/71',
			},
			{
				icon: <Icon>emoji_people</Icon>,
				title: 'Đã gặp khách hàng',
				to: '/lead/72',
			},
			{
				icon: <Icon>diversity_3</Icon>,
				title: 'Đã tìm hiểu nhu cầu khách hàng',
				to: '/lead/73',
			},
			{
				icon: <Icon>emoji_objects</Icon>,
				title: 'Đã trình bày giải pháp với khách hàng',
				to: '/lead/74',
			},
			{
				icon: <Icon>production_quantity_limits</Icon>,
				title: 'Đã mua hàng',
				to: '/lead/8',
			},
			{
				icon: <Icon>domain_disabled</Icon>,
				title: 'Sai địa bàn',
				to: '/lead/41',
			},
		],
		nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
	},

	{
		icon: <Icon>settings</Icon>,
		title: 'NV bán hàng',
		items: [
			{
				title: 'Quản lý cấp',
				to: '/managing-level',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
					cs.Role_Telesale_Merchant_Leader,
				],
				level: 0,
			},
			{
				title: 'Phân bổ lead tự động NV bán hàng theo nhóm sản phẩm',
				to: '/agent-product',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
					cs.Role_Telesale_Merchant_Leader,
				],
			},

			{
				title: 'Phân bổ lead tự động NV bán hàng theo địa bàn',
				to: '/agent-location',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
					cs.Role_Telesale_Merchant_Leader,
				],
			},
			{
				title: 'Danh sách NV bán hàng',
				to: '/agent-list',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
					cs.Role_Telesale_Merchant_Leader,
				],
			},
			{
				title: 'Phân bổ Trưởng phòng cho Chi nhánh',
				to: '/branch-director',
				accesses: [cs.Role_Telesale_Merchant_Manager],
			},
			{
				//icon: <Icon  >query_builder</Icon>,
				title: 'Phân bổ Lead cho NV bán hàng',
				to: '/assign-agent',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
					cs.Role_Telesale_Merchant_Leader,
				],
			},
			{
				title:
					getRole() === cs.Role_Telesale_Merchant_Manager
						? 'Phân bổ Nhân viên cho Trưởng phòng, Chi nhánh'
						: 'Phân bổ Nhân viên cho Trưởng phòng',
				to: '/managing-agent',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
				],
			},
			{
				title: 'Thời gian biểu',
				to: '/timetable',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
				],
			},
			{
				title: 'Bảng xếp hạng',
				to: '/ranking',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
				],
			},
			{
				title: 'Thiết lập KPI',
				to: '/kpi',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
					cs.Role_Telesale_Merchant_Leader,
				],
			},
			{
				title: 'Kpi theo doanh số',
				to: '/revenue-kpi',
				accesses: [
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
					cs.Role_Telesale_Merchant_Leader,
				],
			},
			{
				title: 'Bản tin',
				to: '/news',
				accesses: [
					cs.Role_Telesale_Merchant_User,
					cs.Role_Telesale_Merchant_Manager,
					cs.Role_Telesale_Merchant_Branch_Director,
					cs.Role_Telesale_Merchant_Leader,
				],
			},
		],
		accesses: [
			cs.Role_Telesale_Merchant_User,
			cs.Role_Telesale_Merchant_Manager,
			cs.Role_Telesale_Merchant_Leader,
			cs.Role_Telesale_Merchant_Branch_Director,
		],
		nonaccesses: [cs.Role_Mc_Board_Of_Control_Member],
	},

	{
		icon: <Icon>settings</Icon>,
		title: 'Quản lý công việc',
		items: [
			{
				title: 'Quản trị công việc đại lý',
				to: '/works_agent',
				accesses: [95],
			},
			{
				title: 'Quản trị công việc ĐHKD',
				to: '/works_user',
			},
		],
	},
	{
		icon: <Icon>query_builder</Icon>,
		title: 'Kho hàng',
		//to: "/kpi",
		items: [
			{
				// icon: <Icon  >query_builder</Icon>,
				title: 'Kho hàng',
				to: '/inventory',
			},
		],
		accesses: [
			cs.Role_Mc_Board_Of_Control_Member,
			cs.Role_Telesale_Merchant_Manager,
			cs.Role_Telesale_Merchant_Branch_Director,
			cs.Role_Telesale_Merchant_Leader,
		],
	},
	{
		icon: <Icon>menu_book</Icon>,
		title: 'Bài viết',
		to: '/posts',
	},
	{
		icon: <Icon>build</Icon>,
		title: ' Cài đặt',
		to: '/settings',
	},
];
