import DialogWrapper from 'components/shared/DialogWrapper';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import React, { useState } from 'react';
import { useDeleteRevenueKpi } from './services';
import { useHistory, useLocation } from 'react-router-dom';

const RemoveRevenue = () => {
	const history = useHistory();
	const { state } = useLocation()
	const [params, _] = useState({
		id: state.id,
	});

	const { mutateAsync } = useDeleteRevenueKpi();

	const handleSubmit = () => {
		mutateAsync(params).then(() => {
			history.goBack();
		});
	};

	return (
		<DialogWrapper title="Remove kpi" history={history}>
			<DialogContent>Bạn muốn xoá kpi ?</DialogContent>
			<DialogActions>
				<Button onClick={() => history.goBack()}>Huỷ</Button>
				<Button color="primary" variant="contained" onClick={handleSubmit}>
					Xoá
				</Button>
			</DialogActions>
		</DialogWrapper>
	);
};

export default RemoveRevenue;
