import axios from './axios';

const changePassword = async (params) => {
	const { data } = await axios({
		url: '/common/auth/change_password',
		method: 'POST',
		data: params,
	});
	return data;
};
const login = async (params) => {
	const { data } = await axios({
		url: '/common/auth/check-login-api',
		method: 'POST',
		data: params,
	});
	return data;
};

export default {
	changePassword,
	login,
};
