import { isValidFunctionRole } from '../../../service';

var user = JSON.parse(localStorage.getItem('aidriven-general-user'));

/*
 * Check role được accept
 */
export function hasAccess(item) {
	if (!item.accesses) {
		return true;
	}
	if (item.accesses && item.accesses.length <= 0) {
		return true;
	}
	/* if (user.role === 92) return true; */
	return item.accesses.includes(user.role);
}

/*
 * Check role không accept
 */
export function hasNonAccess(item) {
	if (!item.nonaccesses) return false;
	if (item.nonaccesses && item.nonaccesses.length <= 0) return false;
	if (item.nonaccesses.includes(user.role)) {
		return true;
	}
}

/*
 * Check function role
 */
export function hasOneOfFunctionRoles(item) {
	if (!item.validFunctionRoles) return true;
	if (item.validFunctionRoles && Array.isArray(item.validFunctionRoles)) {
		if (item.validFunctionRoles.length == 0) return true;
		if (item.validFunctionRoles.length > 0) {
			return isValidFunctionRole(item.validFunctionRoles);
		}
	}
	return false;
}

/*
 *	Check level
 */
export function hasLevel(item) {
	if (
		item.level === undefined ||
		item.level === user.user_level ||
		!user.user_level
	)
		return true;
	return false;
}

export function checkRoleAlwaysShow(item) {
	if (!item.roleAlwaysShow) return false;
	if (item.roleAlwaysShow.includes(user.role)) return true;
	return false;
}
