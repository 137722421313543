export const tableConfig = (url, levels) => {
	const tbl = {
		col: [
			{ name: 'total_assigned_qty', title: 'Phân bổ' },
			{ name: 'assigned_qty', title: 'Chưa gọi' },
			{ name: 'total_called_qty', title: 'Đã gọi' },
			{ name: 'called_rate', title: '% gọi' },
			{ name: 'purchased_qty', title: 'Đã mua hàng' },
			{ name: 'purchased_rate', title: '% đã mua hàng' },
			{
				name: 'solution_presented_qty',
				title: 'Đã trình bày giải pháp với KH',
			},
			{
				name: 'solution_presented_rate',
				title: '% đã trình bày giải pháp với KH',
			},
			{ name: 'demand_determined_qty', title: 'Tìm hiểu nhu cầu KH' },
			{ name: 'demand_determined_rate', title: '% tìm hiểu nhu cầu KH' },
			{ name: 'have_met_qty', title: 'Đã gặp KH' },
			{ name: 'have_met_rate', title: '% đã gặp KH' },
			{ name: 'appointment_qty', title: 'Hẹn gặp' },
			{ name: 'sucessful_appointment_rate', title: '% hẹn gặp' },
			{ name: 'interested_qty', title: 'Quan tâm' },
			{ name: 'call_again_qty', title: 'Hẹn gọi lại' },
			{ name: 'wrong_phone_qty', title: 'Không có SĐT/Tắt máy' },
			{ name: 'busy_qty', title: 'Không nghe máy' },
			{ name: 'wrong_info_qty', title: 'Sai thông tin' },
			{ name: 'reject_qty', title: 'Từ chối' },
			{ name: 'buyed_reject_qty', title: 'Đang dùng sản phẩm' },
			{ name: 'is_seller_reject_qty', title: 'Là người bán' },
			{ name: 'wrong_location_qty', title: 'Sai địa bạn' },
			{ name: 'messaged_qty', title: 'Đã nhắn tin' },
			{ name: 'processing_qty', title: 'Đang xử lý' },
			{ name: 'processing_rate', title: '% Đang xử lý' },
		],
		config: [
			{ columnName: 'assigned_qty', align: 'center', width: 150 },
			{ columnName: 'total_assigned_qty', align: 'center', width: 150 },
			{ columnName: 'total_called_qty', align: 'center', width: 150 },
			{ columnName: 'called_rate', align: 'center', width: 150 },
			{ columnName: 'wrong_phone_qty', align: 'center', width: 150 },
			{ columnName: 'busy_qty', align: 'center', width: 150 },
			{ columnName: 'wrong_info_qty', align: 'center', width: 150 },
			{ columnName: 'reject_qty', align: 'center', width: 150 },
			{ columnName: 'buyed_reject_qty', align: 'center', width: 150 },
			{ columnName: 'is_seller_reject_qty', align: 'center', width: 150 },
			{ columnName: 'call_again_qty', align: 'center', width: 150 },
			{
				columnName: 'total_successful_called_qty',
				align: 'center',
				width: 150,
			},
			{ columnName: 'sucessful_called_rate', align: 'center', width: 150 },
			{ columnName: 'appointment_qty', align: 'center', width: 150 },
			{ columnName: 'sucessful_appointment_rate', align: 'center', width: 150 },
			{ columnName: 'have_met_qty', align: 'center', width: 150 },
			{ columnName: 'have_met_rate', align: 'center', width: 150 },
			{ columnName: 'purchased_rate', align: 'center', width: 150 },
			{ columnName: 'interested_qty', align: 'center', width: 150 },
			{ columnName: 'purchased_qty', align: 'center', width: 150 },
			{ columnName: 'demand_determined_qty', align: 'center', width: 150 },
			{ columnName: 'demand_determined_rate', align: 'center', width: 150 },
			{ columnName: 'solution_presented_qty', align: 'center', width: 150 },
			{ columnName: 'solution_presented_rate', align: 'center', width: 150 },
			{ columnName: 'wrong_location_qty', align: 'center', width: 150 },
			{ columnName: 'messaged_qty', align: 'center', width: 150 },
			{ columnName: 'team_name', align: 'center', width: 150 },
			{ columnName: 'branch_name', align: 'center', width: 150 },
			{ columnName: 'total_exp', align: 'center', width: 150 },
			{ columnName: 'processing_qty', align: 'center', width: 150 },
			{ columnName: 'processing_rate', align: 'center', width: 150 },
			{ columnName: 'display_name', align: 'center', width: 150 },
		],
	};

	// thêm level vào các cột của bảng
	if (levels && levels?.data) {
		let fix = [...levels.data];
		tbl.config.push(
			...fix.map((item) => ({
				columnName: `sp_name_lv${item.level_id}`,
				align: 'center',
				width: 150,
			}))
		);
		const currentLevel = url.split('=')[1];

		if (currentLevel) {
			fix = fix.filter((item) => item.level_id <= currentLevel);
		}
		if (
			url === 'realtime-summary-agent' ||
			url === 'realtime-summary-team' ||
			url === 'realtime-summary-branch'
		)
			fix = fix.sort((a, b) => b.level_id - a.level_id).slice(1, -1);
		else fix = fix.sort((a, b) => b.level_id - a.level_id).slice(0, -1);

		tbl.col.unshift(
			...fix.map((item) => ({
				name: `sp_name_lv${item.level_id}`,
				title: item.level_name,
			}))
		);
	}

	if (url === 'realtime-summary-agent') {
		tbl.col.unshift({ name: 'branch_name', title: 'Chi nhánh (SD)' });
		tbl.col.unshift({ name: 'team_name', title: 'Phòng (AD)' });
		tbl.col.unshift({ name: 'display_name', title: 'Đại lý' });
		tbl.col.push({ name: 'total_exp', title: 'Điểm kinh nghiệm' });
	} else if (url === 'realtime-summary-team') {
		tbl.col.unshift({ name: 'branch_name', title: 'Chi nhánh (SD)' });
		tbl.col.unshift({ name: 'display_name', title: 'Phòng (AD)' });
	} else if (url === 'realtime-summary-branch')
		tbl.col.unshift({ name: 'display_name', title: 'Chi nhánh (SD)' });

	return tbl;
};

export const mainChartData = (data) => {
	const colorList = [
		'#61FC14',
		'#677096',
		'#DD5A29',
		'#2471A3',
		'#138D75',
		'#F1C40F',
		'#9987EC',
	];
	const list = [
		{ name: 'total_assigned_qty', title: 'Tổng lead đã phân bổ' },
		{ name: 'assigned_qty', title: 'Chưa gọi' },
		{ name: 'total_called_qty', title: 'Đã gọi' },
		{ name: 'total_successful_called_qty', title: 'Gọi thành công' },
		{ name: 'appointment_qty', title: 'Hẹn gặp' },
		{ name: 'have_met_qty', title: 'Đã gặp' },
		{ name: 'purchased_qty', title: 'Đã mua' },
	];
	const bar = list.map((item, index) => ({
		type: 'bar',
		label: item.title,
		backgroundColor: colorList[index],
		borderWidth: 0,
		fill: false,
		data: data.map((i) => i[item.name]),
		yAxisID: 'A',
	}));

	const labels = data.map((i) => i.display_name);
	return { labels: labels, datasets: bar };
};
export const rateChartData = (data) => {
	const colorList = ['#DD5A29', '#2471A3', '#138D75', '#F1C40F', '#9987EC'];

	const list = [
		{ name: 'called_rate', title: 'Tỷ lệ gọi' },
		{ name: 'sucessful_called_rate', title: 'Tỷ lệ gọi thành công' },
		{ name: 'sucessful_appointment_rate', title: 'Tỷ lệ hẹn gặp thành công' },
		{ name: 'have_met_rate', title: 'Tỷ lệ đã gặp thành công' },
		{ name: 'purchased_rate', title: 'Tỷ lệ đã mua' },
	];
	const bar = list.map((item, index) => ({
		type: 'bar',
		label: item.title,
		backgroundColor: colorList[index],
		borderWidth: 0,
		fill: false,
		data: data.map((i) => parseFloat(i[item.name])),
		yAxisID: 'A',
	}));

	const labels = data.map((i) => i.display_name);
	return { labels: labels, datasets: bar };
};

export const optionsChart = {
	scales: {
		A: {
			type: 'linear',
			position: 'left',
			title: {
				text: 'Số lượng',
				display: true,
				align: 'end',
			},
		},
	},
	plugins: {
		legend: {
			position: 'bottom',
			maxWidth: 8,
		},
		title: {
			display: true,
			text: 'Hi',
		},
	},
};
export const rateChartOption = {
	scales: {
		A: {
			type: 'linear',
			position: 'left',
			title: {
				text: '%',
				display: true,
				align: 'end',
			},
			min: 0,
			max: 100,
		},
	},
	plugins: {
		legend: {
			position: 'bottom',
			maxWidth: 8,
		},
		title: {
			display: true,
			text: 'Hi',
		},
	},
};
