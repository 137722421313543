import axios from './axios';

const importMerchant = async (body) => {
	const form = new FormData();
	form.append('excel-file', body);
	const { data } = await axios({
		url: '/telesale/lead/merchant-leader/import',
		method: 'POST',
		data: form,
		headers: { 'Content-Type': 'multipart/form-data;' },
	});
	return data;
};

const getMerchantList = async (params) => {
	const fix = {};

	for (const key in params) {
		if (params[key] === 'ALL') fix[key] = '';
		else fix[key] = params[key];
	}

	const { data } = await axios({
		url: '/telesale/lead/merchant/list',
		method: 'GET',
		params: fix,
	});
	return data;
};

const searchMerchantProduct = async (params) => {
	const fix = {};

	for (const key in params) {
		if (params[key] === 'ALL') fix[key] = '';
		else fix[key] = params[key];
	}

	const { data } = await axios({
		url: '/telesale/lead/merchant-product/list',
		method: 'GET',
		params: fix,
	});
	return data;
};

export default {
	importMerchant,
	getMerchantList,
	searchMerchantProduct,
};
