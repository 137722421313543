import axios from './axios';
import { v4 as uuidv4 } from 'uuid';


const searchBranch = async (params) => {
	const { data } = await axios({
		url: '/telesale/lead/user/branch-list',
		method: 'GET',
		params: params,
	});
	return data;
};

const getCurrentMerchant = async () => {
	const { data } = await axios({
		url: '/telesale/lead/merchant',
		method: 'GET',
	});
	return data;
};
const autoAssignAgent = async (props) => {
	const { data } = await axios({
		url: '/telesale/lead/auto-assign-agent',
		data: props,
		method: 'POST',
	});
	return data;
};
const autoButton = async (props) => {
	const { data } = await axios({
		url: '/telesale/lead/auto-button',
		data: props,
		method: 'POST',
	});
	return data;
};
const searchBranchDirector = async (params) => {
	const { data } = await axios({
		url: '/telesale/lead/merchant-branch-director/list',
		params: params,
		method: 'GET',
	});
	return data;
};


const searchLeader = async (params) => {
	const { data } = await axios({
		url: '/telesale/lead/leader/list',
		params: params,
		method: 'GET',
	});
	return data;
};

const businessReport = async (params, url) => {
	const fix = {};
	for (const key in params) {
		if (params[key] === 'ALL') fix[key] = '';
		else fix[key] = params[key];
	}

	const { data } = await axios({
		url: `/telesale/lead/${url}`,
		params: fix,
		method: 'get',
	});
	let list = [];
	if (data.list) {
		list = data.list.data.map((item) => {
			return {
				...item,
				id: uuidv4(),
				total_revenue: parseInt(item.total_revenue).toLocaleString(),
				total_revenue_fix: item.total_revenue / 100000000,
			};
		});
	}
	return { ...data, list: { ...data.list, data: list } };
};

const importLead = async (params) => {
	const { data } = await axios({
		url: '/telesale/lead/import-lead',
		method: 'POST',
		data: params,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	});
	return data;
};

export default {
	searchBranch,
	getCurrentMerchant,
	autoAssignAgent,
	autoButton,
	searchBranchDirector,
	searchLeader,
	businessReport,
	importLead,
};
