
import React from 'react'
import { Dialog, DialogTitle, Button, DialogActions } from '@material-ui/core';
import { useHubReject } from 'hooks/dc_article';

const HubReject = ({ open, onCancel, item }) => {
	const { mutateAsync } = useHubReject()
	const onOk = () => {
		if (item.id)
			mutateAsync({ id: item.id })
		onCancel()
	}
	const handleCancel = () => {
		onCancel()
	}
	return (
		<Dialog
			open={open}
		>
			<DialogTitle>
				Từ chối bài viết này
			</DialogTitle>
			<DialogActions>
				<Button onClick={handleCancel}>Huỷ</Button>
				<Button onClick={onOk}>OK</Button>
			</DialogActions>
		</Dialog>
	)
}

export default HubReject
