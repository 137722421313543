import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'const.js';
import React, { useState } from 'react';
import { Link, Route } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import CreateKPI from './CreateKPI.js';
import UpdateKPI from './UpdateKPI.js';
import DeleteKPI from './DeleteKPI.js';
import AllocateKPI from './AllocateKPI.js';
import DataTable from 'components/shared/DataTable.jsx';
import { useKpi } from './services.js';
import { pageSizes } from 'utils/datatable.js';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Button,
	TextField,
	Icon,
} from '@material-ui/core';
import './style.css';
import moment from 'moment';

const isTelesale_Merchant_Manager = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	var userrole = JSON.parse(String(user)).role;
	return userrole === cs.Role_Telesale_Merchant_Manager;
};

const tableConfig = {
	columns: [
		{ name: 'year', title: 'Năm' },
		{ name: 'month', title: 'Tháng' },
		{ name: 'agent_name', title: 'Agent' },
		{ name: 'agent_phone', title: 'SĐT' },
		{ name: 'team_name', title: 'Team name' },

		{ name: 'mc_product_code', title: 'Mã sản phẩm' },
		{ name: 'mc_product_name', title: 'Sản phẩm' },

		{ name: 'kpi_plan', title: 'KPI được giao' },
		{ name: 'kpi_actual', title: 'KPI thực tế' },
		{ name: 'action', title: 'Thao tác' },
	],
	config: [
		{ columnName: 'month', align: 'center', width: 150 },
		{ columnName: 'year', align: 'center', width: 100 },

		{ columnName: 'team_name', align: 'center', width: 200 },
		{ columnName: 'agent_name', align: 'center', width: 200 },
		{ columnName: 'agent_phone', align: 'center', width: 150 },
		{ columnName: 'mc_product_name', align: 'center', width: 150 },
		{ columnName: 'mc_product_code', align: 'center', width: 200 },
		{ columnName: 'kpi_plan', align: 'right', width: 200 },
		{ columnName: 'kpi_actual', align: 'right', width: 150 },

		{ columnName: 'action', align: 'center', width: 150 },
	],
};

const LeadKPIList = () => {
	const [params, setParams] = useState({
		pagesize: 15,
		page: 1,
		agent_name: '',
		agent_phone: '',
		agent_code: '',
		mc_product_code: '',
		mc_product_name: '',
		team_name: '',
		month: '',
		year: '',
	});
	const { data, isLoading, refetch } = useKpi(params);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setParams({
			...params,
			[name]: value,
		});
	};

	return (
		<div className="kpi">
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Tìm kiếm ...</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<form className="kpi-form-search">
						<TextField
							label="Agent"
							value={params.search_agent_name}
							onChange={handleChange}
							name="agent_name"
						/>

						<TextField
							label="SĐT"
							value={params.search_agent_phone}
							name="agent_phone"
							onChange={handleChange}
						/>

						<TextField
							label="Code"
							value={params.search_agent_code}
							onChange={handleChange}
							name="agent_code"
						/>

						<TextField
							label="Sản phẩm"
							value={params.search_mc_product_name}
							onChange={handleChange}
							name="mc_product_name"
						/>

						<TextField
							label="Mã sản phẩm"
							value={params.search_mc_product_code}
							onChange={handleChange}
							name="mc_product_code"
						/>

						<TextField
							label="Tháng"
							value={params.search_month}
							onChange={handleChange}
							name="month"
						/>

						<TextField
							label="Năm"
							onChange={handleChange}
							value={params.search_year}
							name="year"
						/>
						{isTelesale_Merchant_Manager() && (
							<TextField
								label="Team name"
								value={params.search_team_name}
								onChange={handleChange}
								name="team_name"
							/>
						)}
					</form>
				</AccordionDetails>
			</Accordion>
			<Button
				color="inherit"
				aria-label="Create KPI"
				component={Link}
				to={'/kpi/create'}
			>
				<Icon>person_add</Icon>
				<span style={{ marginLeft: 10 }}>Đặt KPI</span>
			</Button>{' '}
			<Button color="inherit" aria-label="Refresh" onClick={refetch}>
				<Icon>refresh</Icon>
				<span style={{ marginLeft: 10 }}>Làm mới</span>
			</Button>
			<Button
				color="inherit"
				aria-label="Refresh"
				component={Link}
				to={'/kpi/allocate'}
			>
				<Icon>format_list_bulleted</Icon>
				<span style={{ marginLeft: 10 }}> Đặt KPI chung</span>
			</Button>
			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.config}
				currentPage={params.page - 1}
				totalCount={data?.total_count}
				pageSize={params.pagesize}
				pageSizes={pageSizes}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				loading={isLoading}
				CellComponent={CellComponent}
			/>
			<Route exact path="/kpi/create" component={CreateKPI} />
			<Route exact path="/kpi/update/:id" component={UpdateKPI} />
			<Route exact path="/kpi/delete/:id" component={DeleteKPI} />
			<Route exact path="/kpi/allocate" component={AllocateKPI} />
		</div>
	);
};

class ActionCell extends React.Component {
	render() {
		return (
			<Table.Cell
				style={{
					padding: 1,
					justifyContent: 'center',
					fontSize: '12px',
				}}
			>
				<span>
					<Button
						color="primary"
						style={{
							margin: 0,
							padding: 0,
						}}
						component={Link}
						to={'/kpi/update/' + this.props.row.id}
					>
						<Icon>edit</Icon>
					</Button>

					<Button
						color="secondary"
						style={{
							margin: 0,
							padding: 0,
						}}
						component={Link}
						to={'/kpi/delete/' + this.props.row.id}
					>
						<Icon>delete_outline</Icon>
					</Button>
				</span>
			</Table.Cell>
		);
	}
}

const CellComponent = (props) => {
	const { column, row } = props;
	switch (column.name) {
		case 'action':
			return <ActionCell {...props} />;
		case 'birthday':
			return (
				<Table.Cell> {moment(row.birthday * 1000).format('DD/MM/YYYY')}</Table.Cell>
			);
		case 'agent_phone':
			return (
				<Table.Cell>{row.agent_phone?.replace(/\d(?!\d{0,3}$)/g, '*')}</Table.Cell>
			);

		default:
			return (
				<Table.Cell
					{...props}
					style={{
						padding: 2,
						//color: '#81557a',
						fontSize: '12px',
					}}
				/>
			);
	}
};

export default LeadKPIList;
