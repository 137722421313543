import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PackageBuy from 'telesales/packages/PackageBuy.js';
import PackageInfo from 'telesales/packages/PackageInfo.js';
import PackageSummary from 'telesales/packages/PackageSummary.js';
import PackageUsage from 'telesales/packages/PackageUsage.js';
import MerchantBuy from 'telesales/packages-merchant/MerchantBuy.js';
import MerchantUsage from 'telesales/packages-merchant/MerchantUsage.js';
import Product from 'telesales/agents/product';
import Location from 'telesales/agents/location';
import AssignAgent from 'telesales/agents/agent/AssignAgent.js';
import AssignLeadsToLeader from 'telesales/lead/AssignLeadsToLeader.js';
import ManagingAgent from 'telesales/agents/agent/ManagingAgent.js';
import Agent from 'telesales/agents/agent';
import LeadKPIList from 'telesales/LeadKPI/LeadKPIList.js';
import MerchantSummary from 'telesales/packages-merchant/MerchantSummary.js';
import EmployeeList from 'telesales/employee';
import MonthlyDashboard from 'telesales/reports/MonthlyDashboard';
import InventoryProductList from 'telesales/inventory/InventoryProduct/InventoryProductList';
import InventoryList from 'telesales/inventory/Inventory/InventoryList';
import InventoryOutputList from 'telesales/inventory/Out/InventoryOutputList';
import InventoryInputList from 'telesales/inventory/In/InventoryInputList';
import Settings from 'telesales/settings/Settings';
import ImportLead from 'telesales/lead/ImportLead';
import cs from 'const';
import { getRole, isSupplierForBookingOfMerchant } from 'service';
import ImportProductNew from './inventory/InventoryProduct/ImportProductNew';
import BranchDirectorList from './branch';
import OrderToConfirmPaid from 'telesales/ship/OrderToConfirmPaid';
import OrderToAllocate from 'telesales/ship/OrderToAllocate';
import OrderBeingPacked from 'telesales/ship/OrderBeingPacked';
import OrderBeingShipped from 'telesales/ship/OrderBeingShipped';
import OrderDeliveredToCustomer from 'telesales/ship/OrderDeliveredToCustomer';
import OrderDetail from 'telesales/ship/OrderDetail';
import ReportDashboard from 'telesales/reports/ReportDashboard';
import OrderBookToAllocate from 'telesales/booking/OrderBookToAllocate';
import OrderBookBeingPacked from 'telesales/booking/OrderBookBeingPacked';
import OrderBookBeingShipped from 'telesales/booking/OrderBookBeingShipped';
import OrderBookDeliveredToMerchant from 'telesales/booking/OrderBookDeliveredToMerchant';
import OrderBookDetail from 'telesales/booking/OrderBookDetail';
import ReportSummary from './reports/Summary';
import Lead from './lead';
import BussinessReport from './reports/business';
import HighLevelManager from './HighLevelManager';
import LockAgent from './agents/agent/LockAgent';
import StatusHistory from './lead/StatusHistory';
import ReAssign from './lead/ReAssign';
import ReleaseAllLead from './agents/agent/ReleaseAllLead';
import ReleaseLead from './lead/ReleaseLead';
import Posts from './Posts';
import NewPost from './Posts/NewPost';
import UpdateAgent from './agents/agent/UpdateAgent';
import Subcription from './subcription';
import Approve from './subcription/Approve';
import ResetSubLead from './subcription/Reset';
import ConvertF0 from './subcription/ConvertF0';
import AddFBUID from './subcription/AddFBUID';
import NewsList from './news/NewsList';
import CreateNews from './news/CreateNews';
import DeleteNews from './news/DeleteNews';
import RequestEditNews from './news/RequestEditNews';
import ApproveNews from './news/ApproveNews';
import CancelNews from './news/CancelNews';
import SendLogin from './agents/agent/SendLogin';
import UpdateNew from './news/UpdateNew';
import DailySummaryByDate from './reports/DailySummaryByDate';
import RevenueKpi from './LeadKPI/revenue';
import EditRevenue from './LeadKPI/revenue/edit';
import RemoveRevenue from './LeadKPI/revenue/remove';
import Timetable from './timetable';
import Ranking from './ranking';
import WorkManagement from './work_management';
import TaskDetail from './work_management/detail';
import { checkRoleWithId } from 'utils/auth';

export class AppRoute extends Component {
	render() {
		return (
			<>
				{checkRoleWithId(95) && (
					<>
						<Route path="/works_agent" exact component={WorkManagement} />
						<Route path="/works_agent/:id" component={TaskDetail} />
					</>
				)}
				<Route path="/works_user" exact component={WorkManagement} />
				<Route path="/works_user/:id" component={TaskDetail} />
				<Route path="/ranking" component={Ranking} />
				<Route path="/timetable" component={Timetable} />
				<Route path="/report/daily" component={DailyDashboardArea} />
				<Route path="/report/monthly" component={MonthlyDashboardArea} />
				<Route path="/report/statistic" component={ReportDashboardArea} />
				<Route path="/report/business" component={BusinessDashboardArea} />
				<Route path="/report/summary" component={ReportSummaryArea} />

				<Route path="/import/product" component={ImportProductArea} />
				<Route path="/order_to_confirm" component={OrderToConfirmPaidArea} />
				<Route path="/order_to_allocate" component={OrderToAllocateArea} />
				<Route path="/order_being_packed" component={OrderBeingPackedArea} />
				<Route path="/order_shipped" component={OrderBeingShippedArea} />
				<Route
					path="/order_delivered"
					component={OrderDeliveredToCustomerArea}
				/>
				<Route path="/order/detail/:id" component={OrderDetail} />

				<Route
					path="/order-book/to-allocate"
					component={OrderBookToAllocateArea}
				/>
				<Route
					path="/order-book/being-packed"
					component={OrderBookBeingPackedArea}
				/>
				<Route
					path="/order-book/being-shipped"
					component={OrderBookBeingShippedArea}
				/>
				<Route
					path="/order-book/delivered"
					component={OrderBookDeliveredToMerchantArea}
				/>
				<Route path="/order-book/detail/:id" component={OrderBookDetail} />

				{getRole() !== cs.Role_Mc_Board_Of_Control_Member && (
					<>
						<Route path="/lead/:id" component={LeadDetailArea} />
						<Route path="/lead/:id/history" component={StatusHistory} />
						<Route path="/lead/:id/reassign" component={ReAssign} />
						<Route
							exact
							path="/release-all-lead/:id"
							component={ReleaseAllLead}
						/>
						<Route
							exact
							path="/lead/:id/release-lead"
							component={ReleaseLead}
						/>
					</>
				)}
				{(getRole() === cs.Role_Telesale_Hub_Leader ||
					getRole() === cs.Role_Telesale_Hub_Manager ||
					getRole() === cs.Role_Telesale_Hub_User) && (
						<>
							<Route path="/import/lead" component={ImportLeadArea} />
						</>
					)}

				{(getRole() === cs.Role_Telesale_Hub_Manager ||
					getRole() === cs.Role_Telesale_Hub_Leader) && (
						<>
							<Route path="/package-buy" component={PackageBuyArea} />
							<Route path="/package-info" component={PackageInfoArea} />
							<Route path="/package-summary" component={PackageSummaryArea} />
							<Route path="/package-usage" component={PackageUsageArea} />
						</>
					)}

				{getRole() === cs.Role_Mc_Board_Of_Control_Member && (
					<>
						<Route path="/merchant-buy" component={MerchantBuyArea} />
						<Route path="/merchant-usage" component={MerchantUsageArea} />
						<Route path="/merchant-summary" component={MerchantSummaryArea} />
						<Route path="/inventory" component={InventoryArea} />
						<Route path="/inventory-output" component={InventoryOutputArea} />
					</>
				)}

				{(getRole() === cs.Role_Telesale_Merchant_Leader ||
					getRole() === cs.Role_Telesale_Merchant_Branch_Director ||
					getRole() === cs.Role_Telesale_Merchant_Manager) && (
						<>
							<Route path="/merchant-buy" component={MerchantBuyArea} />
							<Route path="/merchant-usage" component={MerchantUsageArea} />
							<Route path="/merchant-summary" component={MerchantSummaryArea} />
							<Route path="/agent-location" component={AgentLocationArea} />
							<Route path="/agent-product" component={AgentProductArea} />
							<Route path="/assign-agent" component={AssignAgentArea} />
							<Route path="/agent-list" component={AgentListArea} />
							<Route path="/agent-list/send-email" component={SendLogin} />
							<Route path="/kpi" component={KPIListArea} />
							<Route path="/product-inventory" component={InventoryProductArea} />
							<Route path="/inventory" component={InventoryArea} />
							<Route path="/inventory-input" component={InventoryInputArea} />
							<Route path="/inventory-output" component={InventoryOutputArea} />
							<Route path="/revenue-kpi" component={RevenueKpi} />
							<Route path="/revenue-kpi/edit" component={EditRevenue} />
							<Route path="/revenue-kpi/delete" component={RemoveRevenue} />
						</>
					)}

				{(getRole() === cs.Role_Telesale_Merchant_Manager ||
					getRole() === cs.Role_Telesale_Merchant_Branch_Director) && (
						<>
							<Route path="/assign-leader" component={AssignLeadsToLeaderArea} />
							<Route path="/branch-director" component={BranchDirectorArea} />
							<Route path="/managing-agent" component={ManagingAgentArea} />
						</>
					)}
				<Route path="/managing-level" component={HighLevelManager} />

				{getRole() === cs.Role_Telesale_Merchant_User && (
					<>
						<Route path="/managing-agent" component={ManagingAgentArea} />
						<Route exact path="/agent-list" component={AgentListArea} />
					</>
				)}
				<Route exact path="/agent-list/edit" component={UpdateAgent} />
				<Route exact path="/agent-list/lock/:id/:code" component={LockAgent} />

				{(getRole() === cs.Role_Mc_Board_Of_Control_Member ||
					getRole() === cs.Role_Merchant_Admin ||
					getRole() === cs.Role_Telesale_Merchant_Manager ||
					getRole() === cs.Role_Telesale_Merchant_Branch_Director) && (
						<>
							<Route path="/employee" component={EmployeeArea} />
						</>
					)}
				<Route path="/settings" component={SettingsArea} />
				<Route exact path="/posts" component={Posts} />
				<Route exact path="/posts/new" component={NewPost} />
				<Route exact path="/posts/edit/:id" component={NewPost} />

				<Route path="/subcription" component={Subcription} />
				<Route path="/subcription/conver-sub-f0" component={ConvertF0} />
				<Route path="/subcription/add-fb-uid" component={AddFBUID} />
				<Route path="/subcription/detail" component={Approve} />
				<Route path="/subcription/reset" component={ResetSubLead} />

				<Route path="/news" component={NewsList} />
				<Route path="/news/create" component={CreateNews} />
				<Route path="/news/delete/:id" component={DeleteNews} />
				<Route path="/news/update/:id" component={UpdateNew} />
				<Route path="/news/edit/:id" component={RequestEditNews} />
				<Route path="/news/approve/:id" component={ApproveNews} />
				<Route path="/news/cancel/:id" component={CancelNews} />
			</>
		);
	}
}

export default AppRoute;

const DailyDashboardArea = () => (
	<div>
		<Typography component="div">
			<DailySummaryByDate />
		</Typography>
	</div>
);

const ReportSummaryArea = () => (
	<div>
		<Typography component="div">
			<ReportSummary />
		</Typography>
	</div>
);
const MonthlyDashboardArea = () => (
	<div>
		<Typography component="div">
			<MonthlyDashboard />
		</Typography>
	</div>
);

const ReportDashboardArea = () => (
	<div>
		<Typography component="div">
			<ReportDashboard />
		</Typography>
	</div>
);

const BusinessDashboardArea = () => (
	<div>
		<Typography component="div">
			<BussinessReport />
		</Typography>
	</div>
);

// Quan ly van chuyen
const OrderToConfirmPaidArea = () => (
	<div>
		<Typography variant="h5" component="h4">
			Chờ Xác Nhận Đã Thanh Toán
    </Typography>
		<div>
			<OrderToConfirmPaid />
		</div>
	</div>
);

const OrderToAllocateArea = () => (
	<div>
		<Typography variant="h5" component="h4">
			Chờ Phân Bổ cho Nhân viên kho
    </Typography>
		<div>
			<OrderToAllocate />
		</div>
	</div>
);

const OrderBeingPackedArea = () => (
	<div>
		<Typography variant="h5" component="h4">
			Chờ Đóng Gói
    </Typography>
		<div>
			<OrderBeingPacked />
		</div>
	</div>
);

const OrderBeingShippedArea = () => (
	<div>
		<Typography variant="h5" component="h4">
			Bưu cục đang ship
    </Typography>
		<div>
			<OrderBeingShipped />
		</div>
	</div>
);

const OrderDeliveredToCustomerArea = () => (
	<div>
		<Typography variant="h5" component="h4">
			Khách hàng Đã Nhận Hàng
    </Typography>
		<div>
			<OrderDeliveredToCustomer />
		</div>
	</div>
);

const OrderBookToAllocateArea = () => (
	<div>
		<Typography variant="h5" component="h4">
			{isSupplierForBookingOfMerchant()
				? 'Đơn hàng đặt từ merchant chờ phân bổ'
				: 'Đơn hàng đặt cho supplier'}
		</Typography>
		<div>
			<OrderBookToAllocate />
		</div>
	</div>
);

const OrderBookBeingPackedArea = () => (
	<div>
		<Typography variant="h5" component="h4">
			{isSupplierForBookingOfMerchant()
				? 'Đơn hàng đang được chuẩn bị'
				: 'Đơn hàng đang được supplier chuẩn bị'}
		</Typography>
		<div>
			<OrderBookBeingPacked />
		</div>
	</div>
);

const OrderBookBeingShippedArea = () => (
	<div>
		<Typography variant="h5" component="h4">
			{isSupplierForBookingOfMerchant()
				? 'Đơn hàng đang gửi đến merchant'
				: 'Đơn hàng đang gửi từ supplier'}
		</Typography>
		<div>
			<OrderBookBeingShipped />
		</div>
	</div>
);

const OrderBookDeliveredToMerchantArea = () => (
	<div>
		<Typography variant="h5" component="h4">
			{isSupplierForBookingOfMerchant()
				? 'Đơn hàng đã gửi đến merchant'
				: 'Đơn hàng từ nhà cung cấp'}
		</Typography>
		<div>
			<OrderBookDeliveredToMerchant />
		</div>
	</div>
);

const LeadDetailArea = () => (
	<div>
		<div>
			<Lead />
		</div>
	</div>
);

const PackageBuyArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Lịch sử giao dịch của đối tác
    </Typography>
		<div>
			<PackageBuy />
		</div>
	</div>
);

const MerchantBuyArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Danh sách giao dịch
    </Typography>
		<div>
			<MerchantBuy />
		</div>
	</div>
);

const PackageInfoArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Thông tin gói phân tích
    </Typography>
		<div>
			<PackageInfo />
		</div>
	</div>
);

const PackageSummaryArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Bảng số dư tài khoản
    </Typography>
		<div>
			<PackageSummary />
		</div>
	</div>
);

const PackageUsageArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Lịch sử sử dụng của đối tác
    </Typography>
		<div>
			<PackageUsage />
		</div>
	</div>
);

const MerchantUsageArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Danh sách sử dụng điểm
    </Typography>
		<div>
			<MerchantUsage />
		</div>
	</div>
);

const MerchantSummaryArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Số dư tài khoản
    </Typography>
		<div>
			<MerchantSummary />
		</div>
	</div>
);

const AgentProductArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Danh sách Agent theo sản phẩm
    </Typography>
		<div>
			<Product />
		</div>
	</div>
);

const AgentLocationArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Danh sách Agent theo địa bàn
    </Typography>
		<div>
			<Location />
		</div>
	</div>
);

const AssignAgentArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Phân bổ Lead cho Agent
    </Typography>
		<div>
			<AssignAgent />
		</div>
	</div>
);

const AssignLeadsToLeaderArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Phân bổ Lead cho Trưởng phòng
    </Typography>
		<div>
			<AssignLeadsToLeader />
		</div>
	</div>
);

const ManagingAgentArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			{getRole() === cs.Role_Telesale_Merchant_Manager
				? 'Quản lý Agent theo Trưởng phòng, Chi nhánh'
				: 'Quản lý Agent theo Trưởng phòng'}
		</Typography>
		<div>
			<ManagingAgent />
		</div>
	</div>
);

const AgentListArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Danh sách Agent
    </Typography>
		<div>
			<Agent />
		</div>
	</div>
);

const KPIListArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Danh sách KPI cho Agent
    </Typography>
		<div>
			<LeadKPIList />
		</div>
	</div>
);

const InventoryProductArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Thêm sản phẩm vào kho
    </Typography>
		<div>
			<InventoryProductList />
		</div>
	</div>
);

const InventoryArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Kho hàng
    </Typography>
		<div>
			<InventoryList />
		</div>
	</div>
);

const InventoryInputArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Nhập kho
    </Typography>
		<div>
			<InventoryInputList />
		</div>
	</div>
);

const InventoryOutputArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Xuất kho
    </Typography>
		<div>
			<InventoryOutputList />
		</div>
	</div>
);

const EmployeeArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Danh sách nhân viên
    </Typography>
		<div>
			<EmployeeList />
		</div>
	</div>
);

const SettingsArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Cài đặt hệ thống
    </Typography>
		<div>
			<Settings />
		</div>
	</div>
);

const ImportLeadArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Tạo Lead hàng loạt
    </Typography>
		<div>
			<ImportLead />
		</div>
	</div>
);

const ImportProductArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Tạo Product hàng loạt
    </Typography>
		<div>
			<ImportProductNew />
		</div>
	</div>
);

const BranchDirectorArea = () => (
	<div>
		<Typography variant="h5" component="h2">
			Danh sách trưởng phòng
    </Typography>
		<div>
			<BranchDirectorList />
		</div>
	</div>
);
