import leadAgentApi from 'api/leadAgent.api';
import { useQuery, useMutation } from '@tanstack/react-query';
import { authAxios } from 'utils/api';
import moment from 'moment';

export const useAgentProduct = (params) => {
	return useQuery(['agent-product', params], () =>
		leadAgentApi.getAgentProduct(params)
	);
};

export const useAgentLead = (params) => {
	return useQuery(['agent', params], async () => {
		const fix = {};

		for (const key in params) {
			if (params[key] === 'ALL') fix[key] = undefined;
			else fix[key] = params[key];
		}
		if (!params.searchTime) {
			fix.last_save_contact_at_start = undefined;
			fix.last_save_contact_at_end = undefined;
			fix.searchTime = undefined;
		} else {
			fix.last_save_contact_at_start = moment(fix.last_save_contact_at_start)
				.startOf('day')
				.unix();

			fix.last_save_contact_at_end = moment(fix.last_save_contact_at_end)
				.endOf('day')
				.unix();
		}
		const { data } = await authAxios({
			url: '/telesale/lead/agent/list',
			params: fix,
			method: 'GET',
		});
		return data;
	});
};

export const useAgentLocation = (params) => {
	const query = useQuery(['agentLocation', params], () =>
		leadAgentApi.searchAgentLocation(params)
	);
	return query;
};

export const useUpdateFunctionGetLead = () => {
	return useMutation((params) => leadAgentApi.updateFuntionGetLead(params));
};
