import React, { useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import {
	DialogContent,
	DialogTitle,
	Dialog,
	Grid,
	Button,
	TextField,
	InputLabel,
	MenuItem,
	Switch,
	FormControl,
	Select,
	Box,
	FormLabel,
	TextareaAutosize,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Editor from 'components/shared/Edittor';
import { useNewsLeader, useNewsProduct } from 'hooks/news';
import { useCreateNews } from './services';

const CreatePackage = () => {
	const [news, setNews] = useState({
		title: '',
		description: '',
		category: '',
		sub_category: '',
		priority: '',
		like_able: false,
		comment_able: false,
		team_name: '',
		mc_product_code: '',
		content: '',
	});
	const [thumbnail, setThumbnail] = useState(null);
	const history = useHistory();
	const { data: leads } = useNewsLeader();
	const { data: products } = useNewsProduct();
	const { mutateAsync } = useCreateNews();

	const handleDialogAgree = () => {
		const formData = new FormData();
		formData.append('title', news?.title);
		formData.append('description', news?.description);
		formData.append('category', news?.category);
		formData.append('sub_category', news?.sub_category);
		formData.append('priority', news?.priority);
		formData.append('article', thumbnail);
		formData.append('like_able', news?.like_able ? 1 : 0);
		formData.append('comment_able', news?.comment_able ? 1 : 0);
		formData.append('team_name', news?.team_name);
		formData.append('mc_product_code', news?.mc_product_code);
		formData.append('content', news?.content);
		mutateAsync(formData).then(() => history.goBack());
	};

	const handleChange = (name) => (event) => {
		setNews({
			...news,
			[name]: event.target.value,
		});
	};

	return (
		<Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="xl">
			<DialogTitle id="edit_form-dialog-title">Tạo tin mới</DialogTitle>
			<DialogContent>
				<Grid container style={{ gap: '10px', flexWrap: 'nowrap' }}>
					<Grid xs={9} item>
						<FormControl fullWidth>
							<TextField
								fullWidth
								autoFocus
								margin="dense"
								id="title"
								required={true}
								value={news.title}
								onChange={handleChange('title')}
								label="Title "
								type="name"
							/>
						</FormControl>
						<FormControl
							fullWidth
							style={{
								marginTop: 20,
							}}
						>
							<FormLabel>Mô tả</FormLabel>
							<TextareaAutosize
								value={news.description}
								onChange={(e) => {
									setNews({ ...news, description: e.target.value });
								}}
							/>
						</FormControl>
						<FormControl
							fullWidth
							style={{
								marginTop: 20,
							}}
						>
							<FormLabel>Nội dung:</FormLabel>
							<Editor
								value={news.content}
								onChange={(e) => {
									setNews({ ...news, content: e });
								}}
							/>
						</FormControl>
					</Grid>

					<Grid xs={3} item>
						<FormControl fullWidth>
							<InputLabel htmlFor="merchantId">Team name </InputLabel>
							<Select
								value={news.team_name}
								onChange={handleChange('team_name')}
								inputProps={{
									name: 'package_type',
									id: 'package_type',
								}}
							>
								{leads?.data?.map((item, index) => (
									<MenuItem value={item.name} key={index}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl fullWidth>
							<InputLabel htmlFor="merchantId">Sản phẩm </InputLabel>
							<Select
								value={news.mc_product_code}
								onChange={handleChange('mc_product_code')}
								inputProps={{
									name: 'package_type',
									id: 'package_type',
								}}
							>
								{/* <MenuItem value="{item}">e</MenuItem> */}
								{products?.data?.map((item) => (
									<MenuItem value={item.code} key={item.code}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<TextField
							fullWidth
							autoFocus
							margin="dense"
							id="priority"
							required={true}
							value={news.priority}
							onChange={handleChange('priority')}
							label="Ưu tiên"
							type="number"
							InputProps={{ inputProps: { min: 0, max: 10 } }}
						/>

						<Grid container direction="row" alignItems="flex-start" spacing={4}>
							<Grid item xs={6}>
								Like
								<Switch
									checked={news.like_able}
									value={news.like_able}
									onChange={() =>
										setNews({ ...news, like_able: !news.like_able })
									}
									color="primary"
								/>
								{news.like_able ? 'Có' : 'Không'}
							</Grid>

							<Grid item xs={6}>
								Comment
								<Switch
									checked={news.comment_able}
									value={news.comment_able}
									onChange={() =>
										setNews({ ...news, comment_able: !news.comment_able })
									}
									color="primary"
								/>
								{news.comment_able ? 'Có' : 'Không'}
							</Grid>
						</Grid>

						<Grid container direction="column" alignItems="stretch" spacing={1}>
							<Grid item>
								<DropzoneArea
									acceptedFiles={['image/*']}
									dropzoneText={
										thumbnail
											? thumbnail.name.slice(0, 5)
											: 'Upload file ảnh bài viết (*)'
									}
									maxFileSize={50000000}
									filesLimit={1}
									showAlerts={false}
									showPreviewsInDropzone={false}
									onChange={(files) => setThumbnail(files[0])}
								/>
							</Grid>
						</Grid>
						<Box
							marginTop="10px"
							display="grid"
							gridTemplateColumns="1fr 1fr"
							gridColumnGap="10px"
						>
							<Button onClick={history.goBack} color="secondary" fullWidth>
								Huỷ
							</Button>
							<Button
								onClick={handleDialogAgree}
								color="primary"
								variant="contained"
								fullWidth
							>
								Lưu
							</Button>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default CreatePackage;
