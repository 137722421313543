import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ChangePassword from 'telesales/auth/ChangePassword';
import cs from 'const';
import { ThemeContext } from 'contexts/ThemeContext';
import { TableSettingContext } from 'contexts/TableSettingContext';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const height_marks = [
    {
        value: 300,
        label: '300px'
    },
    {
        value: 400
    },
    {
        value: 500,
        label: '500px'
    },
    {
        value: 600
    },
    {
        value: 700
    },
    {
        value: 800,
        label: '800px'
    }
];

const interval_marks = [
    {
        value: 30,
        label: '30s'
    },
    {
        value: 60,
        label: '60s'
    },
    {
        value: 90,
        label: '90s'
    },
    {
        value: 120,
        label: '120s'
    }
];

const GridItem = ({ title = '', bodycontent, thirdcontent = null }) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <Typography variant="h6" component="h2" style={{ float: 'right' }}>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                {bodycontent}
            </Grid>
            <Grid item xs>
                {thirdcontent}
            </Grid>
        </Grid>
    );
};

function Settings() {
    const classes = useStyles();
    const themecontext = useContext(ThemeContext);
    const tablecontext = useContext(TableSettingContext);

    const [open, setOpen] = React.useState(false);
    const [tableheight, setTableHeight] = React.useState(tablecontext.tableheight);
    const [intervaltime, setIntervalTime] = React.useState(tablecontext.intervaltime);
    const [showChangePassword, setShowChangePassword] = React.useState(false);

    const handleThemeChange = () => {
        themecontext.handleThemeChange();
    };

    // const handleTableBodyThemeChange = (color) => {
    //     themecontext.handleTableBodyThemeChange(color.hex);
    // };
    // const handleTableHeadThemeChange = (color) => {
    //     themecontext.handleTableHeadThemeChange(color.hex);
    // };

    const handleHeightChange = (event, value) => {
        setTableHeight(value);
    };

    const handleIntervalChange = (event, value) => {
        setIntervalTime(value);
    };

    const handleSaveHeightChange = () => {
        tablecontext.handleHeightChange(tableheight);
    };

    const handleReloadChange = (event) => {
        tablecontext.handleAutoReload(event.target.checked);
    };

    const handleSaveIntervalChange = () => {
        tablecontext.handleIntervalTime(intervaltime);
        // tablecontext.handleAutoReload(event.target.checked);
    };

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleLogOut = () => {
        const revokeTokenURL = cs.BaseURL + '/auth/logout';
        let queryString = `${revokeTokenURL}`;
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-general-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            })
            .catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            });

        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    };

    const handleShowChangePassword = () => {
        setShowChangePassword(true);
    };

    const handleCloseChangePassword = () => {
        setShowChangePassword(false);
    };

    return (
        <div className={classes.root}>
            <Divider light />

            <GridItem
                title={'Giao diện:'}
                bodycontent={
                    <Button
                        color="inherit"
                        onClick={handleThemeChange}
                        aria-label="Dark"
                    >
                        {themecontext.isDark ? (
                            <Icon>brightness_4</Icon>
                        ) : (
                            <Icon>brightness_high</Icon>
                        )}

                        <span style={{ marginLeft: 10 }}>
                            {themecontext.isDark ? 'Tối' : 'Sáng'}
                        </span>
                    </Button>
                }
            />

            {/* <GridItem
                title={'Giao diện bảng:'}
                bodycontent={
                    <div style={{display:'flex', justifyContent:'space-around'}} >
                        <div style={{ justifyContent: 'center' }}>
                            <h3 style={{ marginLeft: 130, marginTop: -10 }}>HEAD</h3>
                            <HuePicker
                                color={themecontext.TableHeadColor}
                                onChange={handleTableHeadThemeChange}
                            />
                        </div>
                        <div style={{justifyContent: 'center' }}>
                            <h3 style={{ marginLeft: 130, marginTop: -10 }}>BODY</h3>
                            <HuePicker
                                color={themecontext.TableBodyColor}
                                onChange={handleTableBodyThemeChange}
                            />
                        </div>
                    </div>
                }
            /> */}

            <GridItem
                title={'Mật khẩu:'}
                bodycontent={
                    <Button
                        color="inherit"
                        onClick={handleShowChangePassword}
                        aria-label="Đổi mật khẩu"
                    >
                        <Icon>vpn_key</Icon>
                        <span style={{ marginLeft: 10 }}>Đổi mật khẩu</span>
                    </Button>
                }
            />

            <GridItem
                title={'Tự động reload data:'}
                bodycontent={
                    <Switch
                        color="primary"
                        name="checkedB"
                        checked={tablecontext.autoreload}
                        onChange={handleReloadChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
            />

            {tablecontext.autoreload && (
                <GridItem
                    title={'Thời gian reload:'}
                    bodycontent={
                        <Slider
                            onChange={handleIntervalChange}
                            defaultValue={tablecontext.intervaltime}
                            aria-labelledby="discrete-slider"
                            step={10}
                            min={30}
                            max={120}
                            marks={interval_marks}
                            valueLabelDisplay="on"
                        />
                    }
                    thirdcontent={
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={tablecontext.intervaltime === intervaltime}
                            onClick={
                                intervaltime === tablecontext.intervaltime
                                    ? null
                                    : handleSaveIntervalChange
                            }
                        >
                            Save
                        </Button>
                    }
                />
            )}

            <GridItem
                title={'Độ dài table:'}
                bodycontent={
                    <Slider
                        onChange={handleHeightChange}
                        defaultValue={tablecontext.tableheight}
                        aria-labelledby="discrete-slider"
                        step={50}
                        min={300}
                        max={800}
                        marks={height_marks}
                        valueLabelDisplay="on"
                    />
                }
                thirdcontent={
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={tablecontext.tableheight === tableheight}
                        onClick={
                            tableheight === tablecontext.tableheight ? null : handleSaveHeightChange
                        }
                    >
                        Save
                    </Button>
                }
            />

            <Divider light style={{ marginTop: '100px' }} />
            <GridItem
                title={'Đăng xuất:'}
                bodycontent={
                    <Button
                        color="inherit"
                        aria-label="Đăng xuất"
                        onClick={handleLogOut}
                    >
                        <Icon>exit_to_app</Icon>
                        <span style={{ marginLeft: 10 }}>Đăng xuất</span>
                    </Button>
                }
            />

            <ChangePassword open={showChangePassword} onClose={handleCloseChangePassword} />
        </div>
    );
}

export default Settings;
