import { useQuery } from '@tanstack/react-query';
import { authAxios } from 'utils/api';

export const useRankExp = (params) => {
	return useQuery(['ranking-exp', params], async () => {
		const { data } = await authAxios({
			url: 'telesale/agent/ranking/exp',
			params: params,
			method: 'GET',
		});
		return data;
	});
};

export const useRankRevenue = (params) => {
	return useQuery(['ranking-revenue', params], async () => {
		const fix = {};
		for (const key in params) {
			if (params[key] === 'ALL') fix[key] = '';
			else fix[key] = params[key];
		}

		const { data } = await authAxios({
			url: 'telesale/agent/ranking/revenue',
			params: params,
			method: 'GET',
		});
		return data;
	});
};

export const useSubmerchant = (params) => {
	return useQuery(['submerchants', params], async () => {
		const { data } = await authAxios({
			url: '/telesale/agent/submerchants',
			params: params,
			method: 'GET',
		});

		return data?.code ? [] : data;
	});
};
