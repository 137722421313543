import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import DialogWrapper from 'components/shared/DialogWrapper';
import {
	DialogContent,
	DialogActions,
	Box,
	FormGroup,
	Button,
} from '@material-ui/core';
import { useAgentLead } from 'hooks/leadAgent';
import { useReAssignLead } from './services';
import { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const ReAssign = () => {
	const routerParams = useParams();
	const history = useHistory();
	const { state } = useLocation();
	const [paramsSearchAgent, _] = useState({
		name: '',
		code: '',
		phone: '',
		adr_state_code: '',
		adr_district_code: '',
		adr_ward_code: '',
		team_code: '',
		team_name: '',
		page: 0,
		pagesize: 15,
		locked: 0,
	});
	const { data } = useAgentLead(paramsSearchAgent);
	const [params, setParams] = useState({
		agent_id: 0,
		lead_id: state.id,
		status: state.lead_status,
	});

	const { mutateAsync, isLoading } = useReAssignLead();
	const handleSubmit = () => {
		if (params.agent_id !== 0) mutateAsync(params).then(() => history.goBack());
	};

	return (
		<div>
			<DialogWrapper
				title={'Phân bổ lại cho lead ' + routerParams.id}
				history={history}
			>
				<DialogContent>
					<Box>
						<FormGroup>
							<Autocomplete
								onChange={(e, v) =>
									v && setParams({ ...params, agent_id: v.id })
								}
								options={data?.data}
								getOptionLabel={(option) => option.name}
								renderInput={(params) => (
									<TextField {...params} label="Agent" margin="normal" />
								)}
							/>
						</FormGroup>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="danger" onClick={() => history.goBack()}>
						Huỷ
					</Button>
					<Button
						disabled={isLoading}
						variant="contained"
						color="primary"
						onClick={() => handleSubmit()}
					>
						Ok
					</Button>
				</DialogActions>
			</DialogWrapper>
		</div>
	);
};

export default ReAssign;
