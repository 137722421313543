import axios from "./axios"

const lockUser = async (params) => {
	const { data } = await axios({
		url: 'telesale/lead/agent/lock',
		method: "POST",
		data: params
	})
	return data
}


const unLockUser = async (params) => {
	const { data } = await axios({
		url: 'telesale/lead/agent/unlock',
		method: "POST",
		data: params
	})
	return data
}

export default {
	lockUser,
	unLockUser
}
