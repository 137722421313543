import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { authAxios } from 'utils/api';

/*
	@params{
		pagesize=15
		page=1 
		agent_name= 
		agent_phone=
		agent_code=
		mc_product_code=
		mc_product_name=
		team_name=
		month=
		year= 
	}
*/
export const useRevenueKpi = (params) => {
	return useQuery(['revenuekpi', params], async () => {
		const fix = {};

		for (const key in params) {
			if (params[key] === 'ALL') fix[key] = '';
			else fix[key] = params[key];
		}
		const { data } = await authAxios({
			url: '/telesale/lead/revenue-kpi/list',
			method: 'GET',
			params: fix,
		});
		return data;
	});
};

/*
	@params {
		"year": 2018,
		"month": 6,
		"agent_code": "CBH-0974801580",
		"agent_name": "TRẦN QUANG ANH",
		"agent_phone": "0974801580",
		"mc_product_code": "CBH-Default-Product-Code",
		"mc_product_name": "Default-Product-Name",
		"team_name": "CHB-TruongPhong1-1",
		"sub_mc_id": 0,
		"sub_mc_code": "CBH",
		"revenue_plan": "2"
	}
*/
export const useCreateRevenueKpi = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/revenue-kpi/create',
				method: 'POST',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code !== 100) toast.error(data?.error);
				else toast.success('Tạo kpi thành công');
			},
			onError: () => {
				toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);
};
/*
	@params{
		"id": 1,
		"year": 2023,
		"month": 1,
		"agent_code": "CBH-0974801580",
		"agent_name": "TRẦN QUANG ANH",
		"agent_phone": "0974801580",
		"mc_product_code": "CBH-Default-Product-Code",
		"mc_product_name": "Default-Product-Name",
		"team_name": "CHB-TruongPhong1-1",
		"revenue_plan": "20"
	}
*/
export const useUpdateRevenueKpi = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/revenue-kpi/update',
				method: 'POST',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code !== 100) toast.error(data?.error);
				else toast.success('Sửa kpi thành công');
			},
			onError: () => {
				toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);
};

/*
	@params{
		id:0
	}
*/
export const useDeleteRevenueKpi = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/revenue-kpi/delete',
				method: 'POST',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code !== 100) toast.error(data?.error);
				else toast.success('Xoá kpi thành công');
			},
			onError: () => {
				toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);
};

/*
	@params{
		 "year": 2019,
    "month": 6,
    "mc_product_code": "CBH-Default-Product-Code",
    "mc_product_name": "Default-Product-Name",
    "revenue_plan": "2"
	}
*/
export const useCreateRevenueKpiProduct = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/revenue-kpi/product',
				method: 'POST',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code !== 100) toast.error(data?.error);
				else toast.success('Tạo kpi thành công');
			},
			onError: () => {
				toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);
};

/*
	@params{
	 "year": 2019,
    "month": 6,
    "team_name": "CHB-TruongPhong1-1",
    "revenue_plan": "2"
	}
*/
export const useCreateRevenueKpiTeam = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/revenue-kpi/teamname',
				method: 'POST',
				data: params,
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code !== 100) toast.error(data?.error);
				else toast.success('Tạo kpi thành công');
			},
			onError: () => {
				toast.error('Có lỗi xảy ra, thử lại sau');
			},
		}
	);
};
