import {
	Button,
	Dialog,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useAgentLead } from 'hooks/leadAgent';
import React, { useState } from 'react';
import './style.css';
import moment from 'moment';
import { generatorColorStatus, generatorColorPriority } from './helper';
import { useTimeTable, useTimeTableConfig } from './services';
import TaskDetail from './taskDetail';
import { useBranch, useTeam } from 'hooks/lead';
import Loading from 'components/loading';
import { useLevels } from 'hooks/level';
import FormSearchMerchantLevel from 'telesales/reports/Summary/FormSearchMerchantLevel';

const searchDateTypeList = [
	{ value: 'no', label: 'Không' },
	{ value: 'range', label: 'Khoảng thời gian' },
	{ value: 'date', label: 'Ngày cụ thể' },
];

const Timetable = () => {
	const { data: levels } = useLevels();
	const [params, setParams] = useState({
		agent_id: 'ALL',
		from_date: moment().subtract(14, 'day').format('YYYY-MM-DD'),
		to_date: moment().add(14, 'days').format('YYYY-MM-DD'),
		date: moment().format('YYYY-MM-DD'),
		title: 'ALL',
		status: 'ALL',
		priority: 'ALL',
		page: 1,
		pagesize: 10,
		team_name: 'ALL',
		branch_name: 'ALL',
		...levels?.data
			?.sort((a, b) => a.level_id - b.level_id)
			.slice(1, -1)
			.map((item) => {
				return { ['sp_name_lv' + item.level_id]: 'ALL' };
			}),
	});

	const [searchDateType, setSearchDateType] = useState('range');
	const [openTaskDetail, setOpenTaskDetail] = useState({
		open: false,
		task: {},
	});

	const { data: branchs } = useBranch({});
	const { data: teams } = useTeam({
		branch_name: params.branch_name,
		page: 0,
		pagesize: 0,
	});
	const { data: agents } = useAgentLead({
		page: 0,
		pagesize: 0,
		team_name: params.team_name,
		branch_name: params.branch_name,
	});
	const { data: config } = useTimeTableConfig();
	const handleChange = (e) => {
		const { name, value } = e.target;
		setParams({
			...params,
			[name]: value,
		});
	};
	const { data, refetch, isLoading } = useTimeTable({
		...params,
		searchDateType,
	});
	const handleSubmit = () => {
		refetch();
	};
	return (
		<div className="timetable">
			<form className="form-search">

				{levels?.data
					?.sort((a, b) => a.level_id - b.level_id)
					.slice(1, -1)
					.map((item, index) => (
						<FormSearchMerchantLevel
							level={item.level_id}
							params={params}
							setParams={setParams}
							title={item.level_name}
							key={index}
						/>
					))}
				<FormControl>
					<InputLabel>Chi nhánh (SD)</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						value={params.branch_name}
						label="Chi nhánh"
						onChange={(e) =>
							setParams({
								...params,
								branch_name: e.target.value,
								page: 1,
							})
						}
					>
						<MenuItem value="ALL">Tất cả</MenuItem>
						{branchs?.data?.map((item, index) => (
							<MenuItem value={item.fullname} key={index}>
								{item.fullname}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel>Phòng (AD)</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						value={params.team_name}
						label="Team"
						onChange={(e) =>
							setParams({ ...params, team_name: e.target.value, page: 1 })
						}
					>
						<MenuItem value="ALL">Tất cả</MenuItem>
						{teams?.data?.map((item, index) => (
							<MenuItem key={index} value={item.fullname}>
								{item.fullname}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel>Đại lý</InputLabel>
					<Select
						name="agent_id"
						value={params.agent_id}
						onChange={handleChange}
					>
						<MenuItem value="ALL">Tất cả</MenuItem>
						{agents?.data.map((item) => (
							<MenuItem key={item.code} value={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<FormControl>
						<InputLabel>Công việc</InputLabel>
						<Select
							value={params.title}
							label="Tiêu đề"
							onChange={handleChange}
							name="title"
						>
							<MenuItem value={'ALL'}>Tất cả</MenuItem>
							{config?.data.title.map((item) => (
								<MenuItem key={item.code} value={item.code}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</FormControl>

				<FormControl>
					<InputLabel>Trạng thái</InputLabel>
					<Select
						value={params.status}
						label="Trạng thái"
						onChange={handleChange}
						name="status"
					>
						<MenuItem value={'ALL'}>Tất cả</MenuItem>
						{config?.data.status.map((item) => (
							<MenuItem key={item.code} value={item.code}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel>Độ ưu tiên</InputLabel>
					<Select
						value={params.priority}
						label="Độ ưu tiên"
						onChange={handleChange}
						name="priority"
					>
						<MenuItem value={'ALL'}>Tất cả</MenuItem>
						{config?.data.priority.map((item) => (
							<MenuItem key={item.code} value={item.code}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel>Tìm kiếm theo thời gian</InputLabel>
					<Select
						value={searchDateType}
						onChange={(e) => setSearchDateType(e.target.value)}
					>
						{searchDateTypeList.map((item) => (
							<MenuItem value={item.value} key={item.value}>
								{item.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{searchDateType === 'range' && (
					<>
						<TextField
							label="Từ ngày"
							value={params.from_date}
							onChange={handleChange}
							name="from_date"
							type="date"
							fullWidth
						/>
						<TextField
							label="Đến ngày"
							value={params.to_date}
							onChange={handleChange}
							name="to_date"
							type="date"
							fullWidth
						/>
					</>
				)}
				{searchDateType === 'date' && (
					<FormControl className="date">
						<TextField
							label="Ngày"
							value={params.date}
							onChange={handleChange}
							name="date"
							type="date"
							fullWidth
						/>
					</FormControl>
				)}
			</form>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					maxWidth: '50%',
					margin: '10px auto',
				}}
			>
				<FormControl>
					<Button variant="contained" color="primary" onClick={handleSubmit}>
						Tìm
          </Button>
				</FormControl>
			</div>
			<div
				className="timetable-list"
				style={{
					gridTemplateColumns: `repeat(${
						data?.data?.length >= 2 ? 2 : 1
						}, 1fr)`,
				}}
			>
				{data?.data?.map((item) => (
					<div className="timetable-item" key={item.id}>
						<h4 className="agent-name">
							{item.name} - {item.phone}
						</h4>
						<div className="task-list">
							{Object.keys(item.timetable).length === 0 && (
								<p className="no-data">
									Đại lý chưa lên lịch trong thời gian này
								</p>
							)}

							{Object.entries(item.timetable).map(([key, value]) => (
								<div className="task-item" key={key}>
									<div className="task-title">{key}</div>
									<div>
										<table>
											<thead>
												<tr>
													<th>Thời gian</th>
													<th>Tiêu đề</th>
													<th>Nội dung</th>
													<th>Trạng thái</th>
													<th>Ưu tiên</th>
												</tr>
											</thead>
											<tbody>
												{value.map((i) => (
													<tr key={i.id}>
														<td className="task-item-time">
															{i.from} - {i.to}
														</td>
														<td className="task-item-title">{i.title}</td>
														<td
															className="task-item-summary "
															onClick={() =>
																setOpenTaskDetail({
																	open: true,
																	task: i,
																})
															}
														>
															<p className="truncate">{i.summary}</p>
														</td>
														<td
															style={{
																backgroundColor: generatorColorStatus(
																	i.status_code
																),
															}}
															className="task-item-status"
														>
															{i.status}
														</td>
														<td
															style={{
																backgroundColor: generatorColorPriority(
																	i.priority_code
																),
															}}
															className="task-item-priority"
														>
															{i.priority}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
			<div className="pagination">
				<Pagination
					count={
						data?.total_count && Math.ceil(data.total_count / params.pagesize)
					}
					onChange={(e, p) => setParams({ ...params, page: p })}
					defaultPage={params.page}
					page={params.page}
				/>
			</div>
			<Dialog
				open={openTaskDetail.open}
				onClose={() => setOpenTaskDetail({ ...openTaskDetail, open: false })}
			>
				<TaskDetail
					task={openTaskDetail.task}
					onClose={() => setOpenTaskDetail({ ...openTaskDetail, open: false })}
				/>
			</Dialog>
			{isLoading && <Loading />}
		</div>
	);
};

export default Timetable;
