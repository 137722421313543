import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { authAxios } from 'utils/api';

export const useAgentLocation = (params) => {
	return useQuery(['agent-location', params], async () => {
		const fix = {};
		for (const key in params) {
			if (params[key] === 'ALL') fix[key] = '';
			else fix[key] = params[key];
		}
		const { data } = await authAxios({
			url: '/telesale/lead/agent-location/list',
			method: 'GET',
			params: fix,
		});
		return data;
	});
};

/*
	@params
	id: string
*/
export const useDeleteAgentLocation = () => {
	return useMutation(
		async (params) => {
			const { data } = await authAxios({
				url: '/telesale/lead/agent-location/delete',
				data: params,
				method: 'POST',
			});
			return data;
		},
		{
			onSuccess: (data) => {
				if (data?.code === 100) toast.success('Xoá agent thành công');
				else toast.error('Có lỗi xảy ra ' + data.error);
			},
			onError: (data) => {
				toast.error('Có lỗi xảy ra');
			},
		}
	);
};
