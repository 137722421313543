import React from 'react';
import {
	Grid,
	Button,
	TextField,
	DialogActions,
	DialogContent,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
} from '@material-ui/core';
import cs from 'const.js';
import DialogWrapper from 'components/shared/DialogWrapper';
const createKPIURL = cs.BaseURL + '/telesale/lead/kpi/create';
const agentURL = cs.BaseURL + '/telesale/lead/agent/list';
const productURL = cs.BaseURL + '/telesale/lead/agent-product/list';

const range = (start, stop, step) =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

class CreateKPI extends React.Component {
	constructor(props) {
		super(props);
		const currentYear = new Date().getFullYear();

		this.state = {
			year: '',
			month: '',
			agent_code: '',
			agent_name: '',
			agent_phone: '',
			team_name: '',

			mc_product_code: '',
			mc_product_name: '',

			kpi_plan: '',

			products: [],
			agents: [],
			years: range(currentYear, currentYear - 50, -1),
			months: Array.from(new Array(12), (x, i) => i + 1),
		};
		this.handleDialogAgree = this.handleDialogAgree.bind(this);
		this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
		this.loadAgents();
	}

	handleDropZoneChange(files) {
		this.setState({
			file: files[0],
		});
	}

	loadAgents() {
		let queryString = `${agentURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					data.data.unshift({ adr_state: '', adr_state_code: null });
					this.setState({
						agents: data.data,
					});
				} else {
					this.setState({
						states: [],
					});
					if (
						data &&
						(data.code == cs.erAuthenticationFailed.code ||
							data.code == cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					states: [],
				});
			});
	}

	loadProducts(id) {
		let queryString = `${productURL}?agent_id=${id}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					data.data.unshift({ name: '', code: '' });
					this.setState({
						products: data.data,
					});
				} else {
					this.setState({
						products: [],
					});
					if (
						data &&
						(data.code == cs.erAuthenticationFailed.code ||
							data.code == cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					products: [],
				});
			});
	}

	handleDialogAgree = () => {
		let requestBody = {
			year: this.state.year,
			month: this.state.month,
			agent_code: this.state.agent_code,
			agent_name: this.state.agent_name,
			agent_phone: this.state.agent_phone,
			mc_product_code: this.state.mc_product_code,
			mc_product_name: this.state.mc_product_name,
			team_name: this.state.team_name,
			kpi_plan: this.state.kpi_plan,
		};

		if (this.state.kpi_plan && isNaN(this.state.kpi_plan)) {
			window.alert('Incorrect amount !');
			return;
		}

		if (
			!this.state.year ||
			!this.state.month ||
			!this.state.agent_code ||
			!this.state.agent_name ||
			!this.state.agent_phone ||
			!this.state.mc_product_code ||
			!this.state.mc_product_name ||
			!this.state.team_name ||
			!this.state.kpi_plan
		) {
			window.alert('Please input information !');
			return;
		}
		let queryString = `${createKPIURL}`;

		fetch(queryString, {
			method: 'POST',
			body: JSON.stringify(requestBody),

			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (
					data &&
					(data.code == cs.erAuthenticationFailed.code ||
						data.code == cs.erAccountIsLocked.code)
				) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}

				if (data && data.error && data.code != cs.Succeed.code) {
					this.props.toastManager.add(JSON.stringify(data.error), {
						appearance: 'error',
						autoDismiss: true,
						pauseOnHover: true,
					});
				} else {
					this.props.toastManager.add('KPI is created !', {
						appearance: 'success',
						autoDismiss: true,
						pauseOnHover: true,
					});
				}
				this.props.history.push({
					pathname: '/kpi',
					state: { reload: true },
				});
			})
			.catch(() => { });
	};

	handleChange = (name) => (event) => {
		if (name == 'like_able' || name == 'comment_able')
			this.setState({ [name]: event.target.checked });
		else
			this.setState(
				{
					[name]: event.target.value,
				},
				() => {
					if (name == 'agent_code') {
						let agent = this.state.agents.filter(
							(el) => el.code === event.target.value
						);
						this.loadProducts(agent[0].id);
						this.setState({
							mc_product_code: '',
							mc_product_name: '',
							agent_name: agent[0].name,
							agent_phone: agent[0].phone,
							team_name: agent[0].team_name || '',
						});
					}
					if (name == 'mc_product_code') {
						let product = this.state.products.filter(
							(el) => el.mc_product_code === event.target.value
						);
						this.setState({ mc_product_name: product[0].mc_product_name });
					}
				}
			);
	};

	render() {
		return (
			<div>
				<DialogWrapper title={'Create KPI'} {...this.props}>
					<DialogContent>
						<FormControl fullWidth>
							<InputLabel htmlFor="merchantId">Agent </InputLabel>
							<Select
								value={this.state.agent_code}
								onChange={this.handleChange('agent_code')}
								inputProps={{
									name: 'package_type',
									id: 'package_type',
								}}
							>
								{/* <MenuItem value="{item}">e</MenuItem> */}
								{this.state.agents.map((item) => (
									<MenuItem value={item.code}>{item.name}</MenuItem>
								))}
							</Select>
						</FormControl>

						<TextField
							disabled
							fullWidth
							//autoFocus
							margin="dense"
							id="title"
							required={true}
							value={this.state.agent_name}
							onChange={this.handleChange('agent_name')}
							label="Agent name "
							type="name"
						/>

						<TextField
							disabled
							fullWidth
							//autoFocus
							margin="dense"
							id="agent_phone"
							required={true}
							value={this.state.agent_phone}
							onChange={this.handleChange('agent_phone')}
							label="Agent phone"
							type="text"
						/>

						<TextField
							disabled
							fullWidth
							//autoFocus
							margin="dense"
							id="team_name"
							required={true}
							value={this.state.team_name}
							//onChange={this.handleChange('team_name')}
							label="Team name "
							type="text"
						/>

						<FormControl fullWidth>
							<InputLabel htmlFor="merchantId">Product name </InputLabel>
							<Select
								value={this.state.mc_product_code}
								onChange={this.handleChange('mc_product_code')}
								inputProps={{
									name: 'package_type',
									id: 'package_type',
								}}
							>
								{/* <MenuItem value="{item}">e</MenuItem> */}
								{this.state.products.map((item) => (
									<MenuItem value={item.mc_product_code}>
										{item.mc_product_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<TextField
							fullWidth
							autoFocus
							margin="dense"
							id="priority"
							required={true}
							value={this.state.kpi_plan}
							onChange={this.handleChange('kpi_plan')}
							label="KPI Plan"
							type="number"
						//InputProps={{ inputProps: { min: 0, max: 10 } }}
						/>

						<Grid
							container
							direction="row"
							justify="space-between"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel htmlFor="merchantId">Month </InputLabel>
									<Select
										value={this.state.month}
										onChange={this.handleChange('month')}
										inputProps={{
											name: 'package_type',
											id: 'package_type',
										}}
									>
										{/* <MenuItem value="{item}">e</MenuItem> */}
										{this.state.months.map((item) => (
											<MenuItem value={item}>{item}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel htmlFor="merchantId">Year </InputLabel>
									<Select
										value={this.state.year}
										onChange={this.handleChange('year')}
										inputProps={{
											name: 'package_type',
											id: 'package_type',
										}}
									>
										{/* <MenuItem value="{item}">e</MenuItem> */}
										{this.state.years.map((item) => (
											<MenuItem value={item}>{item}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.history.goBack} color="primary">
							Cancel
						</Button>
						<Button onClick={this.handleDialogAgree} color="primary">
							Create KPI
						</Button>
					</DialogActions>
				</DialogWrapper>
			</div>
		);
	}
}

export default CreateKPI;
