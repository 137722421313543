import React, { useState } from 'react';
import { useListMedia, useCategory, useProduct } from 'hooks/dc_article';
import { AiOutlineUpload, AiOutlineReload } from 'react-icons/ai';
import { FileUploader } from 'react-drag-drop-files';
import { useMutation } from '@tanstack/react-query';
import dc_articleApi from 'api/dc_article.api';
import './images.css';
import { ImFileVideo } from 'react-icons/im';
import { MdImageNotSupported } from 'react-icons/md';
import {
	DialogActions,
	DialogTitle,
	DialogContent,
	Dialog,
	Button,
	Grid,
	TablePagination,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

const path = process.env.REACT_APP_MEDIA_PATH;

const fileTypes = ['JPG', 'PNG', 'GIF', 'mp4', 'webp'];

const menuItemStyle = {
	padding: '5px',
	outline: 'none',
	width: '100%',
};

const Images = ({ post, setPost, media, setMedia }) => {
	const routerParams = useParams();
	const [getMediaParams, setMediaParams] = useState({
		page: 1,
		pagesize: 20,
	});
	const { data, refetch } = useListMedia(getMediaParams);
	const [open, setOpen] = useState(false);
	const [openDelete, setOpenDelete] = useState({ open, item: {} });
	const [file, setFile] = useState(null);

	const uploadFileMutation = useMutation(() => dc_articleApi.uploadMedia(file));
	const delelteFileMutation = useMutation(() =>
		dc_articleApi.deleteMedia({ id: openDelete.item.id })
	);

	const handleChange = (f) => {
		setFile(f);
	};
	const onOk = () => {
		uploadFileMutation.mutateAsync().finally(() => refetch());
	};
	const onOkDelete = () => {
		if (openDelete.item) {
			delelteFileMutation.mutateAsync().finally(() => {
				refetch();
				setOpenDelete({ open: false });
			});
		}
	};

	const { data: categories } = useCategory();
	const { data: products } = useProduct();

	return (
		<div className="media_manage">
			<h1>Thông tin bài viết</h1>
			<div style={{ width: '100%' }}>
				<p>Danh mục</p>
				<select
					value={post.category_id}
					onChange={(e) => setPost({ ...post, category_id: e.target.value })}
					style={menuItemStyle}
				>
					<option value={-1}>Không</option>
					{categories?.data.map((item) => (
						<option value={item.id} key={item.id}>
							{item.category_name}
						</option>
					))}
				</select>
			</div>
			<div style={{ marginTop: '10px' }}>
				<p>Sản phẩm</p>
				<select
					value={post.product_id}
					onChange={(e) => setPost({ ...post, product_id: e.target.value })}
					style={menuItemStyle}
				>
					<option value={-1} disabled>
						Chưa chọn
					</option>
					{products?.data.map((item) => (
						<option value={item.id} key={item.id}>
							{item.name}
						</option>
					))}
				</select>
			</div>

			{routerParams.id && (
				<div>
					<p>Ảnh bìa cũ</p>
					<div>
						{post.media_path?.length > 0 ? (
							<div className="thumbnail">
								{<img src={path + post.media_path[0]} />}
							</div>
						) : (
							<div className="thumbnail">
								<MdImageNotSupported size="2em" />
							</div>
						)}
					</div>
				</div>
			)}
			<div>
				<p>Ảnh bìa mới</p>
				<div>
					{media.thumbnail.id !== -1 ? (
						<div className="thumbnail">
							{<img src={path + media.thumbnail.file_path} />}
						</div>
					) : (
						<div className="thumbnail">
							<MdImageNotSupported size="2em" />
						</div>
					)}
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<h2>Thư viện ảnh/video</h2>
				<Button color="primary" onClick={() => setOpen(true)}>
					<AiOutlineUpload />
				</Button>
				<Button onClick={refetch}>
					<AiOutlineReload />
				</Button>
			</div>
			<Grid spacing={2} container>
				{data?.data.map((item) => (
					<Grid item xs={6} key={item.id}>
						<div className="media_item">
							{/image/.test(item.mime_type) && (
								<>
									<img
										src={path + item.file_path}
										alt={item.file_name}
										style={{ width: '100%' }}
									/>
									<div className="media_action">
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												flexWrap: 'wrap',
											}}
										>
											<Button
												onClick={() => {
													setPost({
														...post,
														content:
															post.content +
															`<img src=\"${path + item.file_path}\">`,
													});
													setMedia({
														...media,
														media: [...media.media, item.id],
													});
												}}
											>
												Chèn ảnh
											</Button>
											<Button
												onClick={() =>
													setMedia({
														...media,
														thumbnail: {
															id: item.id,
															file_path: item.file_path,
														},
													})
												}
											>
												Đặt ảnh bìa
											</Button>
											<Button
												type="link"
												danger
												size="small"
												onClick={() =>
													setOpenDelete({ open: true, item: item })
												}
											>
												Xoá
											</Button>
										</div>
									</div>
								</>
							)}
							{/video/.test(item.mime_type) && (
								<>
									<div className="media_item_video">
										<ImFileVideo size="3em" />
										<p>Video</p>
									</div>
									<div className="media_action">
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												flexWrap: 'wrap',
											}}
										>
											<Button
												type="link"
												danger
												size="small"
												onClick={() =>
													setOpenDelete({ open: true, item: item })
												}
											>
												Xoá
											</Button>
											<a href={path + item.file_path} target="_blank">
												Xem
											</a>
										</div>
									</div>
								</>
							)}
						</div>
					</Grid>
				))}
			</Grid>
			<TablePagination
				rowsPerPageOptions={[5, 10, 20, { label: 'All', value: -1 }]}
				colSpan={6}
				count={data?.total_count}
				rowsPerPage={getMediaParams.pagesize}
				page={getMediaParams.page - 1}
				labelRowsPerPage="I/T"
				SelectProps={{
					inputProps: { 'aria-label': '/' },
					native: true,
				}}
				onPageChange={(e, p) =>
					setMediaParams({ ...getMediaParams, page: p + 1 })
				}
				onRowsPerPageChange={(e) =>
					setMediaParams({ ...getMediaParams, pagesize: e.target.value })
				}
			/>
			<Dialog open={open} title="">
				<DialogTitle>Upload ảnh/video</DialogTitle>
				<DialogContent>
					<FileUploader
						handleChange={handleChange}
						name="file"
						types={fileTypes}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)}>Huỷ</Button>
					<Button onClick={onOk}>Thêm</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={openDelete.open} title="">
				<DialogTitle>Xoá file</DialogTitle>
				<DialogActions>
					<Button onClick={() => setOpenDelete({ open: false })}>Huỷ</Button>
					<Button onClick={onOkDelete}>Xoá</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Images;
