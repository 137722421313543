import { useQuery } from '@tanstack/react-query';
import { authAxios } from 'utils/api';

export const useStates = (params) => {
	const query = useQuery(['states', params], async () => {
		const fix = {};
		for (const key in params) {
			if (params[key] === 'ALL') fix[key] = '';
			else fix[key] = params[key];
		}
		const { data } = await authAxios({
			url: '/telesale/address/state',
			method: 'GET',
			params: fix,
		});
		return data;
	});
	return query;
};

export const useDistricts = (params) => {
	const query = useQuery(['districts', params], async () => {
		const fix = {};
		for (const key in params) {
			if (params[key] === 'ALL') fix[key] = '';
			else fix[key] = params[key];
		}
		const { data } = await authAxios({
			url: '/telesale/address/districts',
			method: 'GET',
			params: fix,
		});
		return data;
	});
	return query;
};

export const useWards = (params) => {
	const query = useQuery(['wards', params], async () => {
		const fix = {};
		for (const key in params) {
			if (params[key] === 'ALL') fix[key] = '';
			else fix[key] = params[key];
		}
		const { data } = await authAxios({
			url: '/telesale/address/wards',
			method: 'GET',
			params: fix,
		});
		return data;
	});
	return query;
};
