import axios from "./axios"

const searchProduct = async (params, url) => {
	const { data } = await axios({
		url: "/telesale/lead/" + url + "/list",
		method: "GET",
		params: params
	})
	return data
}

const searchInventoryProduct = async (params) => {
	const { data } = await axios({
		url: "/telesale/inventory/list/product",
		method: "GET",
		params: params
	})
	return data
}

const searchHubProduct = async (params)=>{
	const { data } = await axios({
		url: "/telesale/lead/hub-product/list",
		method: "GET",
		params: params
	})
	return data
}

export default {
	searchProduct,
	searchInventoryProduct,
	searchHubProduct
}
