import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Icon from '@material-ui/core/Icon';
import DataTable from 'components/shared/DataTable';
import { useCurrentMerchant } from 'hooks/lead';
import { Box, Input, Typography } from '@material-ui/core';
import { pageSizes } from "utils/datatable"
import { useMutation } from '@tanstack/react-query';
import leadApi from "api/lead.api"
import { useAutoLocTime } from 'hooks/helper';
import helperApi from 'api/helper.api';
import DialogAssignAgent from './DialogAssignAgent';
import DialogImportLeader from './DialogImportLeader';
import DialogImportMerchant from './DialogImportMerchant';
import moment from 'moment';
import { useLead } from 'telesales/lead/services';

const tableConfig = {
	columns: [
		{ name: 'lead_created_at_unix', title: 'Ngày' },
		{ name: 'full_name', title: 'Họ và tên KH' },
		{ name: 'current_province', title: 'Tỉnh/TP' },
		{ name: 'hub', title: 'Hub' },
		{ name: 'hide_tel', title: 'SĐT' },
		{ name: 'product_code', title: 'Sản phẩm' },
		{ name: 'number_of_calls_to_lead', title: 'Số lần gọi' },
		{ name: 'status_desc', title: 'Trạng thái' },
		{ name: 'action', title: 'Options' }
	],
	tableColumnExtensions: [
		{ columnName: 'lead_created_at_unix', align: 'center', width: 110 },
		{ columnName: 'full_name', align: 'center', width: 200 },
		{ columnName: 'current_province', align: 'center', width: 150 },
		{ columnName: 'hub', align: 'center', width: 250 },
		{ columnName: 'hide_tel', align: 'center', width: 150 },
		{ columnName: 'product_code', align: 'center', width: 200 },
		{ columnName: 'number_of_calls_to_lead', align: 'center', width: 150 },
		{ columnName: 'status_desc', align: 'center', width: 195 },
		{ columnName: 'action', align: 'center', width: 150 }
	],
}


const AssignAgent = () => {
	const [params, setParams] = useState({
		page: 1,
		pagesize: 15,
		status: 0,

		search_submitted_start: moment()
			.subtract(1, 'month')
			.startOf('day')
			.format('YYYY-MM-DD'),

		search_submitted_end: moment()
			.endOf('day')
			.format('YYYY-MM-DD')
	})

	const { data, isLoading } = useLead(params)
	const [open, setOpen] = useState(false)
	const [openAssignDialog, setOpenAssignDialog] = useState(false)
	const [openImportLeader, setOpenImportLeader] = useState(false)
	const [openImportMerchant, setOpenImportMerchant] = useState(false)
	const { data: currentMerchant, refetch } = useCurrentMerchant()
	const [autoLocTime, setAutoLocTime] = useState("")
	const [openImportAgent, setOpenImportAgent] = useState(false)
	const { data: loctime } = useAutoLocTime()
	const [assignParams, setAssignParams] = useState({
		number: 0
	})

	const assignAgent = useMutation(() => leadApi.autoAssignAgent(assignParams), {
		onSuccess: (data) => {
			setOpenAssignDialog(false)
		},
		onError: (data) => {
		}
	})

	const updateAutoLocTimeMutate = useMutation(() => helperApi.setAutoLocTime({ auto_lead_allocation_time: autoLocTime.toString() }), {
		onSuccess: () => {
		},
		onError: () => {
		}
	})

	const autoButtonMutate = useMutation(() =>
		leadApi.autoButton({
			autoassign: currentMerchant?.data?.auto_lead_allocation_on ? 0 : 1
		}), {
		onSuccess: () => {
			refetch()
		}
	}
	)

	const handleAssignAgent = () => {
		assignAgent.mutate()
	}

	const handleUpdateLocTime = () => {
		if (autoLocTime === "") {
			return
		}
		updateAutoLocTimeMutate.mutate()
	}

	return (
		<div>
			<Box display="grid" gridTemplateColumns="repeat(6, 1fr)" gridGap="10px" alignItems="center">
				<Box display="flex" alignItems="center">
					<Typography variant="h6">
						Phân công tự động
					</Typography>
					<Switch
						defaultChecked={currentMerchant?.data?.auto_lead_allocation_on === 0 ? true : false}
						onChange={() => autoButtonMutate.mutate()}
						color="primary"
					/>
					{currentMerchant?.data?.auto_lead_allocation_on ? 'Bật' : 'Tắt'}
				</Box>
				<Box display="flex" justifyContent="center">
					<Button
						color="primary"
						onClick={() => setOpen(true)}
					>
						<Icon>help</Icon>
					</Button>
				</Box>
				<Box display="flex" justifyContent="center">
					<Button
						color="inherit"
						aria-label="Create New Content"
						onClick={() => setOpenAssignDialog(true)}
					>
						<Icon>ballot</Icon>
						<span>Phân công theo số lượng</span>
					</Button>
				</Box>
				<Box>
					<Box >
						<Typography variant="h6" align="center">Thời gian phân bổ</Typography>
						<Box display="flex" gap="3" alignItems="center" margin="4px" justifyContent="space-evenly">
							<Input type="time" defaultValue={new Date(loctime?.data?.value)} style={{ margin: "0 20px" }} onChange={(e) => setAutoLocTime(e.target.value)} />
							<Button size="small" variant="outlined" color="secondary" onClick={() => handleUpdateLocTime()}>Cập nhật</Button>
						</Box>
					</Box>
				</Box>
				<Box display="flex" justifyContent="flex-end" gridColumn="span 2 / span 2" gridGap="10px">
					<Button color="primary" variant="contained" onClick={() => setOpenImportAgent(true)} >
						Import agent
				</Button>
					<Button color="primary" variant="contained" onClick={() => setOpenImportLeader(true)}>Import GĐ</Button>
					<Button color="primary" variant="contained" onClick={() => setOpenImportMerchant(true)}>Import Trưởng phòng</Button>
				</Box>
			</Box>

			<DataTable
				rows={data?.data}
				columns={tableConfig.columns}
				columnWidths={tableConfig.tableColumnExtensions}
				pageSizes={pageSizes}
				pageSize={params.pagesize}
				currentPage={params.page - 1}
				loading={isLoading}
				totalCount={data?.total_count}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				changePageSize={(p) => setParams({ ...params, pagesize: p })}
				CellComponent={CellComponent}
			/>
			<Dialog
				open={open}
				fullWidth
				maxWidth='sm'
			>
				<DialogTitle id="alert-dialog-slide-title">Phân công tự động</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Phân công tự động sẽ thực hiện công việc phân chia các lead phù hợp với các agent theo các tiêu chí đã được đặt trong cấu hình agent theo số lượng, sản phẩm, địa bàn. Phân công tự động sẽ chạy vào 5 giờ sáng mỗi ngày. Những lead không tìm được agent phù hợp sẽ ở trạng thái chưa phân bố. Để tránh tình trạng đó người dùng cần cấu hình agent phù hợp với nhu cầu của lead hoặc có thể phân bố trực tiếp cho lead được chỉ định
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={openAssignDialog} onClose={() => setOpenAssignDialog(false)}>
				<DialogTitle>Phân công theo số luọng</DialogTitle>
				<DialogContent>
					<TextField
						variant="outlined"
						margin="dense"
						value={assignParams.number}
						onChange={e => setAssignParams({ ...assignParams, number: e.target.value !== "" ? parseInt(e.target.value) : 0 })}
						label="Số lượng hồ sơ giao"
						type="number"
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="error" onClick={() => setOpenAssignDialog(false)}>Huỷ</Button>
					<Button variant="contained" onClick={handleAssignAgent}>OK</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={openImportAgent} fullWidth maxWidth="lg" onClose={() => setOpenImportAgent(false)}>
				<DialogAssignAgent onClose={() => setOpenImportAgent(false)} />
			</Dialog>

			<Dialog open={openImportLeader} onClose={() => setOpenImportLeader(false)} maxWidth="md" fullWidth>
				<DialogImportLeader onClose={() => setOpenImportLeader(false)} />
			</Dialog>

			<Dialog open={openImportMerchant} onClose={() => setOpenImportMerchant(false)} maxWidth="md" fullWidth>
				<DialogImportMerchant onClose={() => setOpenImportMerchant(false)} />
			</Dialog>
		</div>
	);
}

const CellComponent = props => {
	return (
		<Table.Cell
			{...props}
			style={{
				padding: 2,
				// color: '#81557a',
				fontSize: '12px'
			}}
		/>
	);
};


export default withRouter(AssignAgent);
