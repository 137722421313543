import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from 'const.js';
import DialogWrapper from 'components/shared/DialogWrapper';
const allocateKPIURL = cs.BaseURL + '/telesale/lead/kpi';

const mcLeaderURL = cs.BaseURL + '/telesale/lead/merchant-leader';
const mcProductURL = cs.BaseURL + '/telesale/lead/merchant-product/list';

const agentURL = cs.BaseURL + '/telesale/lead/agent/list';
const styles = theme => ({});

const isTelesaleMerchantManager = () => {
	var user = localStorage.getItem(cs.System_Code + '-general-user');
	var userrole = JSON.parse(String(user)).role;
	return (
		userrole === cs.Role_Telesale_Merchant_Manager
		//|| userrole === cs.Role_Telesale_Hub_Manager
	);
}

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

class AllocateKPI extends React.Component {
	constructor(props) {
		super(props);
		const currentYear = (new Date()).getFullYear();

		this.state = {
			type: 1,
			id: this.props.match.params.id,
			phone: this.props.match.params.phone,
			year: '',
			month: '',
			kpi_plan: '',
			team_name: '',
			mc_product_code: '',
			mc_product_name: '',
			products: [],
			agents: [],
			leaders: [],
			years: range(currentYear, currentYear - 50, -1),
			months: Array.from(new Array(12), (x, i) => i + 1)
		};
		this.handleDialogAgree = this.handleDialogAgree.bind(this);
		this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
		this.loadAgents();
		if (isTelesaleMerchantManager()) {
			this.loadLeaders();
		}
		this.loadProducts();
	}

	handleDropZoneChange(files) {
		this.setState({
			file: files[0]
		});
	}

	loadAgents() {
		let queryString = `${agentURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					data.data.unshift({ adr_state: '', adr_state_code: null });
					this.setState({
						agents: data.data
					});
				} else {
					this.setState({
						states: []
					});
					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					states: []
				});
			});
	}

	loadLeaders() {
		let queryString = `${mcLeaderURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					data.data.unshift({ adr_state: '', adr_state_code: null });
					this.setState({
						leaders: data.data
					});
				} else {
					this.setState({
						leaders: []
					});
					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					leaders: []
				});
			});
	}


	loadProducts() {
		// let queryString = `${mcProductURL}?agent_id=${id}`;
		let queryString = `${mcProductURL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.data) {
					data.data.unshift({ name: '', code: '' });
					this.setState({
						products: data.data
					});
				} else {
					this.setState({
						products: []
					});
					if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					products: []
				});
			});
	}

	handleDialogAgree = () => {
		if (this.state.kpi_plan && isNaN(this.state.kpi_plan)) {
			window.alert('Incorrect amount !');
			return;
		}

		if (!this.state.year || !this.state.month
			|| !this.state.kpi_plan
		) {
			window.alert('Please input information !');
			return;
		}

		if (this.state.type === 2 && isTelesaleMerchantManager() && !this.state.team_name) {
			window.alert('Please input information 2!');
			return;
		}

		if (this.state.type === 3 && (!this.state.mc_product_code || !this.state.mc_product_name)) {
			window.alert('Please input information 3!');
			return;
		}
		let requestBody = {
			year: this.state.year,
			month: this.state.month,
			kpi_plan: this.state.kpi_plan
		}
		let queryString = `${allocateKPIURL}`;
		switch (this.state.type) {
			case 1:
				queryString += '/merchant';

				break;
			case 2:
				queryString += '/teamname';
				requestBody.team_name = this.state.team_name
				break;
			case 3:
				queryString += '/product';
				requestBody.mc_product_name = this.state.mc_product_name;
				requestBody.mc_product_code = this.state.mc_product_code;
				break;
			default:
				break;
		}

		fetch(queryString, {
			method: 'POST',
			body: JSON.stringify(requestBody),

			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token')
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}

				if (data && data.error && data.code != cs.Succeed.code) {
					this.props.toastManager.add(JSON.stringify(data.error), {
						appearance: 'error',
						autoDismiss: true,
						pauseOnHover: true
					});
				} else {
					this.props.toastManager.add('KPI is created !', {
						appearance: 'success',
						autoDismiss: true,
						pauseOnHover: true
					});
				}
				this.props.history.push({
					pathname: '/kpi',
					state: { reload: true }
				});
			})
			.catch(() => { });
	};

	handleChange = name => event => {
		if (name == "like_able" || name == "comment_able") this.setState({ [name]: event.target.checked });
		else
			this.setState(
				{
					[name]: event.target.value
				},
				() => {
					if (name == "agent_code") {
						let agent = this.state.agents.filter((el) => el.code === event.target.value)
						this.loadProducts(agent[0].id);
						this.setState({
							mc_product_code: '',
							mc_product_name: '',
							agent_name: agent[0].name,
							agent_phone: agent[0].phone,
							team_name: agent[0].team_name || ""
						})
					}
					if (name == "mc_product_code") {
						let product = this.state.products.filter((el) => el.code === event.target.value)
						this.setState({ mc_product_name: product[0].name })
					}

				}
			);
	};

	render() {
		const { classes } = this.props;

		return (
			<div>
				<DialogWrapper title={'Phân KPI theo'} {...this.props}>
					<DialogContent>
						<FormControl fullWidth>
							<InputLabel htmlFor="merchantId">Type </InputLabel>
							<Select
								value={this.state.type}
								onChange={this.handleChange('type')}
								inputProps={{
									name: 'package_type',
									id: 'package_type'
								}}
							>
								{isTelesaleMerchantManager() && <MenuItem value={1}>Merchant</MenuItem>}
								<MenuItem value={2}>Team name</MenuItem>
								<MenuItem value={3}>Product</MenuItem>
							</Select>
						</FormControl>

						<TextField
							fullWidth
							autoFocus
							margin="dense"
							id="priority"
							required={true}
							value={this.state.kpi_plan}
							onChange={this.handleChange('kpi_plan')}
							label="KPI cho tháng (KH đồng ý/tháng)"
							type='number'
						/>
						<Grid
							container
							direction="row"
							justify="space-between"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel htmlFor="merchantId">Month </InputLabel>
									<Select
										value={this.state.month}
										onChange={this.handleChange('month')}
										inputProps={{
											name: 'package_type',
											id: 'package_type'
										}}
									>
										{/* <MenuItem value="{item}">e</MenuItem> */}
										{this.state.months.map(item => (
											<MenuItem value={item}>{item}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel htmlFor="merchantId">Year </InputLabel>
									<Select
										value={this.state.year}
										onChange={this.handleChange('year')}
										inputProps={{
											name: 'package_type',
											id: 'package_type'
										}}
									>
										{/* <MenuItem value="{item}">e</MenuItem> */}
										{this.state.years.map(item => (
											<MenuItem value={item}>{item}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						{
							this.state.type === 2 && isTelesaleMerchantManager() &&

							<FormControl fullWidth>
								<InputLabel htmlFor="merchantId">Team name </InputLabel>
								<Select
									value={this.state.team_name}
									onChange={this.handleChange('team_name')}
									inputProps={{
										name: 'package_type',
										id: 'package_type'
									}}
								>
									{this.state.leaders.map(item => (
										<MenuItem value={item.name}>{item.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						}

						{this.state.type === 3 &&
							<FormControl fullWidth>
								<InputLabel htmlFor="merchantId">Product name </InputLabel>
								<Select
									value={this.state.mc_product_code}
									onChange={this.handleChange('mc_product_code')}
									inputProps={{
										name: 'package_type',
										id: 'package_type'
									}}
								>
									{this.state.products.map(item => (
										<MenuItem value={item.code}>{item.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.history.goBack} color="primary">
							Cancel
                        </Button>
						<Button onClick={this.handleDialogAgree} color="primary">
							Create KPI
                        </Button>
					</DialogActions>
				</DialogWrapper>
			</div>
		);
	}
}

AllocateKPI.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AllocateKPI);
