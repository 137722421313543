import axios from "./axios"

const getAutoLocTime = async () => {
	const { data } = await axios({
		url: "telesale/lead/get-auto-allocation-time",
		method: "GET"
	})
	return data
}

const setAutoLocTime = async (body) => {
	const { data } = await axios({
		url: "telesale/lead/set-auto-allocation-time",
		method: "POST",
		data: body
	})
	return data
}

export default {
	getAutoLocTime,
	setAutoLocTime
}
