import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useChangePassword } from 'hooks/auth';

const ChangePassword = ({ open, onClose }) => {
	const [user, setUser] = useState({
		old_password: '',
		new_password: '',
	});
	const [confirm, setConfirm] = useState('');

	const { mutateAsync: changePassword } = useChangePassword();

	const onSubmit = () => {
		if (
			!user.new_password.match(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
			)
		) {
			window.alert(
				'Mật khẩu phải bao gồm ít nhất 1 chữ cái viết hoa, 1 chữ cái viết thường, 1 số, 1 kí tự đặc biệt và độ dài từ 8 kí tự trở lên'
			);
			return;
		}
		changePassword(user);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle id="change-pw">Đổi mật khẩu</DialogTitle>
			<DialogContent>
				<TextField
					margin="dense"
					id="current_password"
					value={user.old_password}
					onChange={(e) => setUser({ ...user, old_password: e.target.value })}
					label="Mật khẩu cũ"
					type="password"
					fullWidth
				/>
				<TextField
					margin="dense"
					id="new_password"
					value={user.new_password}
					onChange={(e) => setUser({ ...user, new_password: e.target.value })}
					label="Mật khẩu mới"
					type="password"
					fullWidth
				/>
				<TextField
					margin="dense"
					id="confirm_password"
					value={confirm}
					onChange={(e) => setConfirm(e.target.value)}
					label="Xác nhận mật khẩu "
					type="password"
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="primary">
					Bỏ qua
        </Button>
				<Button
					onClick={onSubmit}
					disabled={confirm !== user.new_password}
					color="primary"
				>
					Xác nhận
        </Button>
			</DialogActions>
		</Dialog>
	);
};

export default ChangePassword;
