import { getItemLocal } from 'utils/auth';
export const returnDropDown = ({ level, type }) => {
	const role = getItemLocal('aidriven-general-user').role;
	const list = [{ url: type + '-summary-agent', name: 'Đại lý' }];

	if (role === 96) {
		list.push(
			{ url: type + '-summary-team', name: 'Phòng (AD)' },
			{ url: type + '-summary-branch', name: 'Chi nhánh (SD)' }
		);
	} else if (role === 97) {
		list.push({ url: type + '-summary-team', name: 'Team' });
	}
	const user_level = getItemLocal('aidriven-general-user').user_level;
	if (level) {
		level
			.sort((a, b) => -a.level_id + b.level_id)
			.slice(1, -1)
			.filter((item) => item.level_id > user_level)
			.map((item) =>
				list.push({
					url: `${type}-summary-hlm?level=${item.level_id}`,
					name: item.level_name,
				})
			);
	}
	return list;
};
