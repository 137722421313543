import axios from "./axios"

const createInventory = async (body) => {
	const { data } = await axios({
		url: "/telesale/inventory/in/create",
		method: "GET",
		data: body
	})
	return data
}

export default {
	createInventory
}
