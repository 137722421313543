import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    LineSeries,
    Title,
    Legend,
    Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { EventTracker } from '@devexpress/dx-react-chart';
import { withStyles } from '@material-ui/core/styles';
import { Animation } from '@devexpress/dx-react-chart';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

import DataTable from 'components/shared/DataTable';
import cs from 'const.js';

const STATISTIC_URL = cs.MarketplaceURL + '/api/statistics/get';
const AGENT_LIST_URL = cs.MarketplaceURL + '/api/statistics/get-agent-list';
const TEAM_LIST_URL = cs.MarketplaceURL + '/api/statistics/get-team-list';
const SUPPLIER_LIST_URL = cs.MarketplaceURL + '/api/statistics/get-supplier-list';

const format = () => (tick) => tick;
const legendStyles = () => ({
    root: {
        display: 'flex',
        margin: 'auto',
        flexDirection: 'row'
    }
});
const legendLabelStyles = (theme) => ({
    label: {
        paddingTop: theme.spacing(1),
        whiteSpace: 'nowrap'
    }
});
const legendItemStyles = () => ({
    item: {
        flexDirection: 'column'
    }
});

const legendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
);
const legendLabelBase = ({ classes, ...restProps }) => (
    <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }) => (
    <Legend.Item className={classes.item} {...restProps} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);
const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);

const styles = (theme) => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing(2),
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 200,
        minWidth: 120
    },
    search_product_field: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 400,
        minWidth: 300
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        // backgroundColor: blue
    },
    chart: {
        marginTop: 30,
        paddingRight: '20px'
    },
    title: {
        whiteSpace: 'pre'
    }
});

const ValueLabel = (props) => {
    const { text } = props;
    return <ValueAxis.Label {...props} text={`${text}`} />;
};

const titleStyles = {
    title: {
        whiteSpace: 'pre'
    }
};
const TitleText = withStyles(titleStyles)(({ classes, ...props }) => (
    <Title.Text {...props} className={classes.title} />
));

const STATISTIC_TYPE = [
    { name: 'NV bán hàng', value: 1 },
    { name: 'Team', value: 2 },
    { name: 'Supplier', value: 3 },
    { name: 'Toàn bộ công ty', value: 4 }
];

class ReportDashboard extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            supplierList: [],
            teamList: [],
            agentList: [],
            supplier: '',
            team: '',
            agent: '',
            data: [],
            chartData: [],
            gridData: [],
            gridTotalCount: 0,
            gridCurrentPage: 0,
            pageSize: 15,
            loading: true,
            gridColumns: [
                { name: 'time', title: 'Ngày' },
                { name: 'totalRevenue', title: 'Doanh thu' },
                { name: 'totalSoldProducts', title: 'Sản phẩm đã bán' },
                { name: 'totalOrder', title: 'Đơn hàng' }
            ],
            gridColumnConfig: [
                { columnName: 'time', align: 'center', width: 200 },
                { columnName: 'totalOrder', align: 'center', width: 200 },
                { columnName: 'totalRevenue', align: 'center', width: 200 },
                { columnName: 'totalSoldProducts', align: 'center', width: 200 }
            ],
            search_date_start: moment()
                .subtract(1, 'day')
                .startOf('day')
                .format('YYYY-MM-DD'),

            search_date_end: moment().endOf('day').format('YYYY-MM-DD'),
            statisticType: 0
        };
    }

    loadData = () => {
        const body = {
            inputStartDateStr: this.state.search_date_start,
            inputEndDateStr: this.state.search_date_end,
            timeGroupTypeId: 1,
            statisticsTypeId: this.state.statisticType
        };
        fetch(STATISTIC_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-general-token')
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({ data: data.data[0] }, () => this.filterDataByMerchant());
                }
            })
            .catch(() => {});
    };

    filterDataByMerchant = () => {
        if (this.state.statisticType == 4) {
            const statistics = this.state.data.statisticsObj;
            if (statistics.length > 0) {
                const data = statistics[0].chartObjList;
                this.setState({ chartData: data, gridData: data });
            }
        }
    };

    getSupplierList = () => {
        fetch(SUPPLIER_LIST_URL, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-general-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ supplierList: data.data });
            })
            .catch(() => {});
    };

    getTeamList = () => {
        fetch(TEAM_LIST_URL, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-general-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ teamList: data.data });
            })
            .catch(() => {});
    };

    getAgentList = () => {
        fetch(AGENT_LIST_URL, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-general-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ agentList: data.data });
            })
            .catch(() => {});
    };

    handleDateChange = (name) => (event) => {
        this.setState({ [name]: event.target.value });
    };

    handleStatisticTypeChange = (name) => (event) => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.loadData();
                if (this.state.statisticType == 1) {
                    this.getAgentList();
                }
                if (this.state.statisticType == 2) {
                    this.getTeamList();
                }
                if (this.state.statisticType == 3) {
                    this.getSupplierList();
                }
            }
        );
    };

    filterData = (valueField) => {
        const statistics = this.state.data.statisticsObj;
        if (statistics.length > 0) {
            const filterStatistics = statistics.filter((item) => item.valueField == valueField);
            if (filterStatistics.length > 0) {
                const chartData = filterStatistics[0].chartObjList;
                this.setState({ chartData: chartData, gridData: chartData });
            }
        }
    };

    handleItemChange = (name) => (event) => {
        this.setState({ [name]: event.target.value });
        this.filterData(event.target.value);
    };

    render() {
        const { chartData } = this.state;
        const { classes } = this.props;
        const {
            gridData,
            gridColumns,
            gridColumnConfig,
            gridCurrentPage,
            gridTotalCount,
            pageSize,
            loading
        } = this.state;
        return (
            <div>
                <form className={classes.search_container} noValidate autoComplete="off">
                    <TextField
                        id="search_date_start"
                        label="Từ ngày"
                        className={classes.search_field}
                        value={this.state.search_date_start}
                        onChange={this.handleDateChange('search_date_start')}
                        type="date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />

                    <TextField
                        id="search_date_end"
                        label="Đến ngày"
                        className={classes.search_field}
                        value={this.state.search_date_end}
                        onChange={this.handleDateChange('search_date_end')}
                        type="date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </form>
                <form>
                    <FormControl className={classes.search_product_field}>
                        <InputLabel htmlFor="statisticId">Thống kê doanh thu của</InputLabel>
                        <Select
                            value={this.state.statisticType}
                            onChange={this.handleStatisticTypeChange('statisticType')}
                            inputProps={{
                                name: 'statistic',
                                id: 'statisticId'
                            }}
                        >
                            {STATISTIC_TYPE.map((item) => (
                                <MenuItem value={item.value}>
                                    <div>{item.name}</div>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </form>
                <form>
                    {this.state.statisticType === 3 && (
                        <FormControl className={classes.search_product_field}>
                            <InputLabel htmlFor="statisticId">Danh sách Supplier</InputLabel>
                            <Select
                                value={this.state.supplier}
                                onChange={this.handleItemChange('supplier')}
                                inputProps={{
                                    name: 'statistic',
                                    id: 'statisticId'
                                }}
                            >
                                {this.state.supplierList.map((item) => (
                                    <MenuItem value={item.supplierId}>
                                        <div>{item.supplierName}</div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {this.state.statisticType === 2 && (
                        <FormControl className={classes.search_product_field}>
                            <InputLabel htmlFor="statisticId">Danh sách Team</InputLabel>
                            <Select
                                value={this.state.team}
                                onChange={this.handleItemChange('team')}
                                inputProps={{
                                    name: 'statistic',
                                    id: 'statisticId'
                                }}
                            >
                                {this.state.teamList.map((item) => (
                                    <MenuItem value={item.value}>
                                        <div>{item.displayName}</div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {this.state.statisticType === 1 && (
                        <FormControl className={classes.search_product_field}>
                            <InputLabel htmlFor="statisticId">Danh sách NV bán hàng</InputLabel>
                            <Select
                                value={this.state.agent}
                                onChange={this.handleItemChange('agent')}
                                inputProps={{
                                    name: 'statistic',
                                    id: 'statisticId'
                                }}
                            >
                                {this.state.agentList.map((item) => (
                                    <MenuItem value={item.code}>
                                        <div>{item.name}</div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </form>
                <Paper>
                    <Chart data={chartData} className={classes.chart}>
                        <ArgumentAxis tickFormat={format} />
                        <ValueAxis labelComponent={ValueLabel} />
                        <LineSeries
                            name="Doanh thu"
                            valueField="totalRevenue"
                            argumentField="time"
                        />
                        <Legend
                            position="bottom"
                            rootComponent={Root}
                            itemComponent={Item}
                            labelComponent={Label}
                        />
                        <Title
                            text={`Báo cáo thống kê doanh thu trên ${'\n'} Salesplus`}
                            textComponent={TitleText}
                        />
                        <EventTracker />
                        <Tooltip />
                        <Animation />
                    </Chart>
                </Paper>

                <Paper>
                    <Chart data={chartData} className={classes.chart}>
                        <ArgumentAxis tickFormat={format} />
                        <ValueAxis labelComponent={ValueLabel} />
                        <LineSeries
                            name="Sản phẩm đã bán"
                            valueField="totalSoldProducts"
                            argumentField="time"
                        />
                        <LineSeries name="Đơn hàng" valueField="totalOrder" argumentField="time" />
                        <Legend
                            position="bottom"
                            rootComponent={Root}
                            itemComponent={Item}
                            labelComponent={Label}
                        />
                        <Title
                            text={`Báo cáo thống kê sản phẩm và đơn hàng trên ${'\n'} Salesplus`}
                            textComponent={TitleText}
                        />
                        <EventTracker />
                        <Tooltip />
                        <Animation />
                    </Chart>
                </Paper>

                <DataTable
                    rows={gridData}
                    columns={gridColumns}
                    columnWidths={gridColumnConfig}
                    pageSize={pageSize}
                    currentPage={gridCurrentPage}
                    loading={loading}
                    totalCount={gridTotalCount}
                    CellComponent={this.CellComponent}
                    autoLoadData={this.loadData}
                />
            </div>
        );
    }
}

export default withStyles(styles)(ReportDashboard);
