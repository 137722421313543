import React, { useState } from 'react';
import './style.css';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { useRevenueKpi } from './services';
import DataTable from 'components/shared/DataTable';
import { pageSizes } from 'utils/datatable';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Create from './create';
import { Link } from 'react-router-dom';
import CreateKpiTeam from './createKpiTeam';
import CreateKpiProduct from './createKpiProduct';
import { useTeam } from 'hooks/lead';

const config = {
	columns: [
		{ name: 'action', title: '#' },
		{ name: 'year', title: 'Year' },
		{ name: 'month', title: 'Month' },
		{ name: 'agent_code', title: 'Agent code' },
		{ name: 'agent_name', title: 'Agent name' },
		{ name: 'agent_phone', title: 'Agent phone' },
		{ name: 'mc_code', title: 'Mc code' },
		{ name: 'sub_mc_id', title: 'Sub mc id' },
		{ name: 'sub_mc_code', title: 'Sub mc code' },
		{ name: 'team_name', title: 'Team name' },
		{ name: 'mc_product_code', title: 'Mc product code' },
		{ name: 'mc_product_name', title: 'Mc product name' },
		{ name: 'revenue_plan', title: 'Revenue plan' },
		{ name: 'revenue_actual', title: 'Revenue actual' },
	],
	columnWidths: [
		{ columnName: 'action', align: 'center', width: 150 },
		{ columnName: 'year', align: 'center', width: 50 },
		{ columnName: 'month', align: 'center', width: 50 },
		{ columnName: 'agent_code', align: 'center', width: 150 },
		{ columnName: 'agent_name', align: 'center', width: 150 },
		{ columnName: 'agent_phone', align: 'center', width: 150 },
		{ columnName: 'mc_code', align: 'center', width: 150 },
		{ columnName: 'sub_mc_id', align: 'center', width: 150 },
		{ columnName: 'sub_mc_code', align: 'center', width: 150 },
		{ columnName: 'team_name', align: 'center', width: 150 },
		{ columnName: 'mc_product_code', align: 'center', width: 150 },
		{ columnName: 'mc_product_name', align: 'center', width: 150 },
		{ columnName: 'revenue_plan', align: 'center', width: 150 },
		{ columnName: 'revenue_actual', align: 'center', width: 150 },
	],
};

const RevenueKpi = () => {
	const [params, setParams] = useState({
		pagesize: 15,
		page: 1,
		agent_name: '',
		agent_phone: '',
		agent_code: '',
		mc_product_code: '',
		mc_product_name: '',
		team_name: '',
		month: 'ALL',
		year: 'ALL',
	});
	const [openCreate, setOpenCreate] = useState(false);
	const [openCreateKpiProduct, setOpenCreateKpiProduct] = useState(false);
	const [openCreateKpiTeam, setOpenCreateKpiTeam] = useState(false);
	const handleChange = (event) => {
		const { name, value } = event.target;
		setParams({ ...params, [name]: value });
	};
	const { data, isLoading, refetch } = useRevenueKpi(params);
	const { data: teams } = useTeam({});
	return (
		<div className="revenue-kpi">
			<Accordion>
				<AccordionSummary>Tìm kiếm</AccordionSummary>
				<AccordionDetails>
					<form className="revenue-kpi-form-search">
						<TextField
							label="Agent name"
							name="agent_name"
							value={params.agent_name}
							onChange={handleChange}
						/>
						<TextField
							label="Agent phone"
							name="agent_phone"
							value={params.agent_phone}
							onChange={handleChange}
						/>
						<TextField
							label="Agent code"
							name="agent_code"
							value={params.agent_code}
							onChange={handleChange}
						/>

						<TextField
							label="Product code"
							name="mc_product_code"
							value={params.mc_product_code}
							onChange={handleChange}
						/>
						<TextField
							label="Product name"
							name="mc_product_name"
							value={params.mc_product_name}
							onChange={handleChange}
						/>
						<FormControl>
							<InputLabel>Team</InputLabel>
							<Select
								name="team_name"
								value={params.team_name}
								onChange={handleChange}
							>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{teams?.data?.map((item, index) => (
									<MenuItem key={index} value={item.name}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<InputLabel>Month</InputLabel>
							<Select name="month" onChange={handleChange} value={params.month}>
								<MenuItem value="ALL">Tất cả</MenuItem>
								{Array.from({ length: 12 }, (_, i) => (
									<MenuItem value={i + 1} key={i}>
										{i + 1}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</form>
				</AccordionDetails>
			</Accordion>
			<div className="button-search">
				<Button onClick={() => setOpenCreate(true)} variant="contained">
					Tạo KPI
				</Button>
				<Button
					onClick={() => setOpenCreateKpiProduct(true)}
					variant="contained"
				>
					Tạo KPI theo sản phẩm
				</Button>
				<Button onClick={() => setOpenCreateKpiTeam(true)} variant="contained">
					Tạo KPI theo team
				</Button>
				<Button variant="contained" color="primary" onClick={refetch}>
					Tìm
				</Button>
			</div>

			<DataTable
				rows={data?.data}
				columns={config.columns}
				columnWidths={config.columnWidths}
				currentPage={params.page - 1}
				totalCount={data?.total_count}
				pageSize={params.pagesize}
				pageSizes={pageSizes}
				changeCurrentPage={(p) => setParams({ ...params, page: p + 1 })}
				loading={isLoading}
				CellComponent={CellComponent}
			/>
			<Dialog
				open={openCreate}
				maxWidth="xs"
				fullWidth
				onClose={() => setOpenCreate(false)}
			>
				<Create onClose={() => setOpenCreate(false)} refetch={refetch} />
			</Dialog>

			<Dialog
				open={openCreateKpiTeam}
				onClose={() => setOpenCreateKpiTeam(false)}
				maxWidth="xs"
				fullWidth
			>
				<CreateKpiTeam
					onClose={() => setOpenCreateKpiTeam(false)}
					refetch={refetch}
				/>
			</Dialog>

			<Dialog
				open={openCreateKpiProduct}
				onClose={() => setOpenCreateKpiProduct(false)}
				maxWidth="xs"
				fullWidth
			>
				<CreateKpiProduct
					onClose={() => setOpenCreateKpiProduct(false)}
					refetch={refetch}
				/>
			</Dialog>
		</div>
	);
};

const ActionCell = (props) => {
	return (
		<Table.Cell
			style={{
				padding: 1,
			}}
		>
			<Link
				to={{
					pathname: `/revenue-kpi/edit`,
					state: props.row,
				}}
			>
				<Button variant="contained" color="primary" size="small">
					Edit
				</Button>
			</Link>
			<Link
				to={{
					pathname: `/revenue-kpi/delete`,
					state: props.row,
				}}
			>
				<Button variant="contained" color="secondary" size="small">
					Remove
				</Button>
			</Link>
		</Table.Cell>
	);
};

const CellComponent = (props) => {
	const { column } = props;
	if (column.name === 'action') {
		return <ActionCell {...props} />;
	}

	return (
		<Table.Cell
			{...props}
			style={{
				padding: 2,
				fontSize: '12px',
			}}
		/>
	);
};

export default RevenueKpi;
