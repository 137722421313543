import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'const.js';
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DataTable from 'components/shared/DataTable';
import { getRole } from 'service';
import { isValidFunctionRole } from 'service';

const EXECUTIVE_ORDER_LIST_URL =
	cs.MarketplaceURL + '/api/executive/order/list';
// const EXECUTIVE_ORDER_LIST_URL = cs.BaseURL + '/telesale/external/executive/order/list';
const EXECUTIVE_ORDER_COORDINATE =
	cs.MarketplaceURL + '/api/executive/order/coordinate';
// const EXECUTIVE_ORDER_COORDINATE = cs.BaseURL + '/telesale/external/executive/order/coordinate';
const SUPPLIER_LIST_URL =
	cs.BaseURL + '/telesale/inventory/out/shop-supplier/list';
const WAREHOUSE_EXECUTIVE_LIST_URL =
	cs.BaseURL + '/telesale/inventory/out/executive-warehouse/list';

function formatValue(value) {
	if (value && value.tongThanhToan) {
		value.tongThanhToan = value.tongThanhToan
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return value;
}

class OrderToAllocate extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			columns: [
				{ name: 'action', title: 'Thao tác' },
				{ name: 'tongThanhToan', title: 'Tổng thanh toán' },
				{ name: 'maHoaDon', title: 'Mã hóa đơn' },
				{ name: 'supplierName', title: 'Nhà cung cấp' },
				{ name: 'maDonHang', title: 'Mã đơn hàng' },
				{ name: 'leadFullAddress', title: 'Địa chỉ khách hàng' },
				{ name: 'leadCommuneName', title: 'Phường/Xã' },
				{ name: 'leadDistrictName', title: 'Quận/Huyện' },
				{ name: 'leadProvinceName', title: 'Tỉnh/Thành phố' },
			],
			rows: [],
			tableColumnExtensions: [
				{ columnName: 'action', align: 'center', width: 200 },
				{ columnName: 'tongThanhToan', align: 'center', width: 200 },
				{ columnName: 'maHoaDon', align: 'center', width: 200 },
				{ columnName: 'supplierName', align: 'center', width: 200 },
				{ columnName: 'maDonHang', align: 'center', width: 200 },
				{ columnName: 'leadCommuneName', align: 'center', width: 200 },
				{ columnName: 'leadDistrictName', align: 'center', width: 200 },
				{ columnName: 'leadProvinceName', align: 'center', width: 200 },
				{ columnName: 'leadFullAddress', align: 'center', width: 300 },
			],
			// pageSizes: [5, 10, 20, 30, 60],
			totalCount: 0,
			pageSize: 10,
			currentPage: 1,
			loading: true,
			sorting: [{ columnName: 'update_time', direction: 'desc' }],
			supplierList: [],
			warehouseExecutiveList: [],
		};

		this.changeCurrentPage = this.changeCurrentPage.bind(this);
		this.changePageSize = this.changePageSize.bind(this);
		//this.loadSupplierList ();
		// this.loadWarehouseExcecutiveList();
		this.loadData();
	}

	// Lấy danh sách supplier của Merchant
	loadSupplierList() {
		let queryString = `${SUPPLIER_LIST_URL}`;

		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					//console.log ("supplierList: " + JSON.stringify(data.data));
					this.setState({
						supplierList: data.data,
					});
				} else {
					this.setState({
						supplierList: [],
					});

					if (
						data &&
						(data.code == cs.erAuthenticationFailed.code ||
							data.code == cs.erAccountIsLocked.code)
					) {
						sessionStorage.clear();
						localStorage.clear();
						window.location.reload();
					}
				}
			})
			.catch(() => {
				this.setState({
					supplierList: [],
				});
			});
	}

	changeCurrentPage(currentPage) {
		this.setState(
			{
				loading: true,
				currentPage: currentPage,
			},
			() => {
				this.loadData();
			}
		);
	}

	changePageSize(pageSize) {
		this.setState(
			{
				loading: true,
				pageSize: pageSize,
				currentPage: 0,
			},
			() => {
				this.loadData();
			}
		);
	}

	loadData = () => {
		const { currentPage, pageSize } = this.state;
		const body = {
			statusCategoryId: cs.MarketplaceURL_OrderStatus_BeingAllocated,
			page: currentPage,
			size: pageSize,
		};
		fetch(EXECUTIVE_ORDER_LIST_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
			body: JSON.stringify(body),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data) {
					for (var i = 0; i < data.data.length; i++) {
						data.data[i] = formatValue(data.data[i]);
					}
					this.setState({
						rows: data.data,
						totalCount: data.total_count,
						loading: false,
					});
				} else {
					this.setState({
						rows: [],
						totalCount: 0,
						loading: false,
					});
				}
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	};

	render() {
		// get value from state
		const {
			rows,
			columns,
			tableColumnExtensions,
			pageSizes,
			pageSize,
			currentPage,
			totalCount,
			loading,
			sorting,
		} = this.state;

		return (
			<div>
				<ExpansionPanel>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>Tìm kiếm ...</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails></ExpansionPanelDetails>
				</ExpansionPanel>

				<Button color="inherit" aria-label="Refresh" onClick={this.loadData}>
					<Icon>refresh</Icon>
					<span style={{ marginLeft: 10 }}>Làm mới</span>
				</Button>

				<DataTable
					rows={rows}
					columns={columns}
					columnWidths={tableColumnExtensions}
					pageSizes={pageSizes}
					pageSize={pageSize}
					currentPage={currentPage}
					loading={loading}
					sorting={sorting}
					totalCount={totalCount}
					changeCurrentPage={this.changeCurrentPage}
					changePageSize={this.changePageSize}
					changeSorting={this.changeSorting}
					CellComponent={CellComponent}
					autoLoadData={this.loadData}
				/>

			</div>
		);
	}
}

function SelectionDialog(props) {
	const [warehouseExecutiveList, setWarehouseExecutiveList] = useState([]);
	const [warehouseExecutiveId, setWarehouseExecutiveId] = useState('');

	useEffect(() => {
		loadWarehouseExcecutiveList();
	}, []);

	const loadWarehouseExcecutiveList = () => {
		const queryString = `${WAREHOUSE_EXECUTIVE_LIST_URL}`;
		fetch(queryString, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && !data.data && data.error && data.code !== cs.Succeed) {
					setWarehouseExecutiveList([]);
				} else {
					console.log(data.data);
					setWarehouseExecutiveList(data.data);
				}
			})
			.catch(() => { });
	};

	const handleSubmit = () => {
		const queryString = `${EXECUTIVE_ORDER_COORDINATE}?maDonHang=${props.row.maDonHang}&userId=${warehouseExecutiveId}`;
		fetch(queryString, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: localStorage.getItem(cs.System_Code + '-general-token'),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.data.length == 0 && data.error_code == 1) {
					props.handleClose();
				} else {
					props.handleClose();
				}
			})
			.catch(() => { });
	};

	const handleChange = (e) => {
		setWarehouseExecutiveId(e.target.value);
	};

	return (
		<Dialog
			open={props.dialogOpen}
			aria-labelledby="draggable-dialog-title"
			maxWidth="md"
			fullWidth
			onClose={() => props.handleClose()}
		>
			<DialogTitle>Điều phối đơn hàng</DialogTitle>
			<DialogContent>
				<DialogContentText>Phân bổ đơn hàng cho</DialogContentText>
				<FormControl fullWidth>
					<InputLabel htmlFor="nvk">Nhân viên kho</InputLabel>
					<Select
						value={warehouseExecutiveId}
						onChange={handleChange}
						inputProps={{
							name: 'nvk',
							id: 'nvk',
						}}
					>
						<MenuItem value={warehouseExecutiveId}>Nhân viên kho</MenuItem>
						{warehouseExecutiveList.map((item) => (
							<MenuItem value={item.id}>{item.name}</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => props.handleClose()} autoFocus color="secondary">
					Hủy
        </Button>
				<Button onClick={handleSubmit} color="primary">
					Xác nhận
        </Button>
			</DialogActions>
		</Dialog>
	);
}

class ActionCell extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dialogOpen: false,
		};
	}

	handleOpen = () => {
		this.setState({
			dialogOpen: true,
		});
	};

	handleClose = () => {
		this.setState({
			dialogOpen: false,
		});
	};

	render() {
		return (
			<span>
				<Button
					color="primary"
					style={{
						margin: 0,
						padding: 0,
					}}
					onClick={this.handleOpen}
				>
					Phân bổ
        </Button>
				<Button
					color="primary"
					style={{
						margin: 0,
						padding: 0,
					}}
					component={Link}
					to={'/order/detail/' + this.props.row.maDonHang}
				>
					<Icon>edit</Icon>
          Chi tiết
        </Button>
					<SelectionDialog
						{...this.props}
						handleClose={this.handleClose}
						dialogOpen={this.state.dialogOpen}
						row={this.props.row}
					/>
			</span>
		);
	}
}

const validFunctionRoles = [
	cs.Function_Role_MC_Order_Coordinator,
	cs.Function_Role_Supplier_Sales_Coordinator,
];

const CellComponent = (props) => {
	const { column } = props;
	if (
		column.name === 'action' &&
		(getRole() == cs.Role_Mc_Board_Of_Control_Member ||
			getRole() == cs.Role_Mc_Salesplus_CEO ||
			isValidFunctionRole(validFunctionRoles))
	) {
		return (
			<Table.Cell
				{...props}
				style={{
					padding: 2,
					fontSize: '11px',
				}}
			>
				<ActionCell {...props} />
			</Table.Cell>
		);
	}

	return (
		<Table.Cell
			{...props}
			style={{
				padding: 2,
				fontSize: '11px',
			}}
		/>
	);
};

export default OrderToAllocate;
